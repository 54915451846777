import axios from 'axios';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

interface ProfileCatalogModalProps {
  show: boolean;
  onHide: () => void;
}

const ProfileCatalogModal: React.FC<ProfileCatalogModalProps> = ({ show, onHide }) => {

    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const pdfFilename = localStorage.getItem('pdfFilename');
    const [pdfUrl, setPdfUrl] = useState<string>('');

    const fetchPdfImage = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getLinkupFile`, {
                rfc: luisa_rfc,
                file_name: pdfFilename
            });
            const pdfUrl = response.data.presignedUrl;
            setPdfUrl(pdfUrl);
        } catch (error) {
            console.error('Error al obtener el pdf:', error);
        }
    }

    fetchPdfImage();
    
  return (
    <Modal show={show} onHide={onHide} size='lg' scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Catalogo de productos y servicios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {pdfUrl && (
                        <iframe
                            title='modalPdf'
                            src={pdfUrl}
                            width="100%"
                            height="600px"
                            style={{ border: "none" }}
                        >
                            This browser does not support PDFs. Please download the PDF to view it: <a href={pdfUrl}>Download PDF</a>
                        </iframe>
                    )}
                </Modal.Body>
            </Modal>
  );
};

export default ProfileCatalogModal;
