import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import handleLogout from '../../functions/functions';

interface ModuleSectionProps {
    title: string;
    roleName: string;
    items: { label: string; ids: string[]; tag?: string }[];
    searchTerm: string;
    permissions: { [key: string]: boolean };
}

const ModuleSection: React.FC<ModuleSectionProps> = ({ title, roleName, items, searchTerm, permissions }) => {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});

    const filteredItems = items.filter(item =>
        item.label.toLowerCase().includes(searchTerm)
    );
    // console.log(permissions);

    useEffect(() => {
        const initialCheckedItems: { [key: string]: boolean } = {};

        items.forEach(item => {
            item.ids.forEach((id, idx) => {
                const action = idx === 0 ? 'ver' : idx === 1 ? 'crear' : 'eliminar';

                if (permissions && typeof permissions === 'object') {
                    if ('crear' in permissions && 'ver' in permissions && 'eliminar' in permissions) {
                        initialCheckedItems[id] = permissions[action] || false;
                    } else if (item.tag && permissions[item.tag]) {
                        const permissionData = permissions[item.tag];

                        if (Array.isArray(permissionData) && permissionData.length > 0 && typeof permissionData[0] === 'object') {
                            initialCheckedItems[id] = permissionData[0][action] || false;
                        } else if (typeof permissionData === 'object') {
                            initialCheckedItems[id] = permissionData[action] || false;
                        }
                    }
                }
            });
        });

        setCheckedItems(initialCheckedItems);
    }, [items, permissions]);


    const handleCheckboxChange = async (sectionTitle: string, action: string, checked: boolean, id: string, sectionSubTitle?: string) => {
        if (roleName === '' || roleName === 'Roles') {
            showWarnNotification('Selecciona un rol antes de asignar permisos');
            return;
        }

        setCheckedItems(prevState => ({
            ...prevState,
            [id]: checked,
        }));

        try {
            const payload: any = {
                rfc: luisa_rfc,
                name: roleName,
                section: sectionTitle,
                action: action,
                isEnabled: checked,
            };

            // Si el checkbox pertenece a una sección con `tag`, lo incluimos en el payload
            if (sectionSubTitle) {
                payload.subSection = sectionSubTitle;
            }

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/updateRole`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${luisa_token}`
                }
            });

            if (response.status === 200) {
                // console.log(response.data);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    console.log(error.response.data.error);
                    showErrorNotification('Error');
                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                    showErrorNotification('Error');
                } else {
                    console.log('Error message:', error.message);
                    showErrorNotification('Error');
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    };


    const showWarnNotification = (text: String) => {
        toast.warning(text, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <div className="row mb-5">
            <ToastContainer />
            {filteredItems.length > 0 && (
                <>
                    <h1>{title}</h1>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <section className="wrapper">
                            <main className="row title">
                                <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                    <li className="fw-bold w-25">Sección</li>
                                    <li className="fw-bold">Ver</li>
                                    <li className="fw-bold">Crear</li>
                                    <li className="fw-bold">Eliminar</li>
                                </ul>
                            </main>
                        </section>
                        {filteredItems.map((item, index) => (
                            <section key={index}>
                                <article className="data-users">
                                    <ul>
                                        <li className="w-25">{item.label}</li>
                                        {item.ids.map((id, idx) => {
                                            const action = idx === 0 ? 'ver' : idx === 1 ? 'crear' : idx === 3 ? 'editar' : 'eliminar';
                                            const isChecked = checkedItems[id] ?? false; // Asegura que isChecked nunca sea undefined
                                            return (
                                                <li key={idx}>
                                                    <div className="form-group">
                                                        <input
                                                            type="checkbox"
                                                            id={id}
                                                            checked={isChecked}  // Usa el estado controlado
                                                            onChange={(e) =>
                                                                handleCheckboxChange(title, action, e.target.checked, id, item.tag)
                                                            }
                                                        />
                                                        <label htmlFor={id}></label>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </article>
                            </section>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default ModuleSection;
