import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import loadingGif from '../../assets/img/loading.gif'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import handleLogout from '../../functions/functions';

interface LinkupCostOnDemandProps {
    show: boolean;
    onHide: () => void;
    isLoading?: boolean;
    onClick?: () => void;
}

const LinkupCostOnDemand: React.FC<LinkupCostOnDemandProps> = ({ show, onHide, isLoading = false}) => {

    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const [activePrice, setActivePrice] = useState(0);

    const fetchLinkupCosts = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getLinkupCost`,
                {
                    headers: {
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            if (response.status === 200) {
    
                const data = response.data.data[0].costo;
                setActivePrice(data);
    
            } else {
                setActivePrice(0);
            }
        } catch(error){
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.log("Ocurrio un error: ", error);
        }

    };

    const handlePay = async () => {
        const setAmount = activePrice * 100;

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getPaymentLinkup`, {
                rfc: luisa_rfc,
                amount: setAmount
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            if (response.status === 200) {
                const linkPay = response.data.data;
                window.location.href = linkPay;
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
            showErrorNotification('Ocurrio un error al proceder con el pago de la vinculación');
        }

    }

    useEffect(() => {
        fetchLinkupCosts();
    });

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <ToastContainer />
            <Modal show={show} onHide={onHide} className='costModal'>
                <Modal.Header closeButton>
                    <Modal.Title><h2>Costo por vinculación</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <h4>Costos</h4>
                        <label htmlFor="Total" className="form-label mt-2">El costo de la vinculación es de: </label>
                        <div className="input-group">
                            <span className="input-group-text bg-transparent text-white" id="basic-addon2"><i className="bi bi-currency-dollar"></i></span>
                            <input type="number" className="form-control centered-placeholder centered-input-container" readOnly id="total"
                                placeholder='0.0' aria-describedby="total"
                                value={activePrice}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        <Button variant="light" onClick={onHide} disabled={isLoading}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={() => { handlePay() }}>
                            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Pagar'}
                        </Button>
                    </>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LinkupCostOnDemand;