import axios from 'axios';
import React, { useEffect, useState } from "react";
import TopbarCoparmex from "../../main/TopBarCoparmex";
import "../../../assets/css/administration.css";
import { useNavigate } from 'react-router-dom';
import "../../../assets/css/ControlTable.css";
import "../../../assets/css/affiliationCosts.css"
import AffiliationMonthlyCost from '../../modals/AffiliationMonthlyCost';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import handleLogout from '../../../functions/functions';
import { BusinessItem, MonthlyCosts, HistoricItem } from '../../interfaces/CostsInterface';

const AffiliationCosts = () => {
    const limit = 10;
    const navigate = useNavigate();
    //const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const luisa_token = localStorage.getItem('luisa_token');
    const [businessData, setBusinessData] = useState<BusinessItem[]>([]);
    const [monthlyCosts, setMonthlyCosts] = useState<MonthlyCosts[]>([]);
    const [activeButton, setActiveButton] = useState('annual'); // 'annual' o 'monthly' and 'historic'
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const handleOpenModal = () => {
        if(activeButton === 'historic'){
            setActiveButton('annual');
        }
        
        setIsModalOpen(true);
    }

    const handleCloseModal = () => setIsModalOpen(false);
    
    
    const [historicData, setHistoricData] = useState<HistoricItem[]>([]);

    const fetchCosts = async (search = "") => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/get_business_costs_affiliation`,
                {
                    params: {
                        search,
                        page: currentPage,
                        limit
                    },
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 200) {
                //const { business, totalPages } = response.data;
                const { result } = response.data;
                setBusinessData(result);
                //setTotalPages(totalPages);
            }
        } catch (error) {
            console.error("Error al obtener datos:", error);
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
        }
    };

    const fetchMonthlyCosts = async (search = "") => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/get_business_monthly_costs_affiliation`,
                {
                    params: {
                        search,
                        page: currentPage,
                        limit
                    },
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 200) {
                //const { business, totalPages } = response.data;
                const costs = response.data.costs.mensual;
                setMonthlyCosts(costs);
                //setTotalPages(totalPages);
            }
        } catch (error) {
            console.error("Error al obtener datos:", error);
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
        }
    };

    const fetchHistoric = async (search = "") => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/get_business_historic_costs_affiliation`,
                {
                    params: {
                        search,
                        page: currentPage,
                        limit
                    },
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 200) {
                //const { business, totalPages } = response.data;
                console.log("[TEST] ");
                console.log(response.data);
                const historic = response.data.historic.historic;
                setHistoricData(historic);
                //setTotalPages(totalPages);
            }
        } catch (error) {
            console.error("Error al obtener datos:", error);
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
        }
    };

    useEffect(() => {
        fetchCosts();
        fetchMonthlyCosts();
        fetchHistoric();
    }, [currentPage, limit]);

    const formatDate = (dateString: string) => {
        if (!dateString) return "Fecha no válida";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "Fecha no válida";
        return new Intl.DateTimeFormat("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        }).format(date);
    };

    const handleRowClick = (item: BusinessItem) => {
        navigate(`/control-table-detail`, { state: { data: item } });
    };

    const handlePageClick = (page: number | string) => {
        if (typeof page === 'number' && page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const getPaginationRange = () => {
        const totalPagesToShow = 2;
        if (totalPages <= totalPagesToShow) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const range = [];
        const leftLimit = Math.max(2, currentPage - 1);
        const rightLimit = Math.min(totalPages - 1, currentPage + 1);

        range.push(1);
        if (leftLimit > 2) range.push("...");
        for (let i = leftLimit; i <= rightLimit; i++) range.push(i);
        if (rightLimit < totalPages - 1) range.push("...");
        range.push(totalPages);

        return range;
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handleButtonClick = (buttonType: string) => {
        setActiveButton(buttonType); // Cambia el botón activo
    };

    const handleButtonConfirm = async (annualCost: string, profile: string, type: string) => {
        setIsLoading(true);
        const luisa_user = localStorage.getItem("luisa_username");
        if (type === 'annual') {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/update_costs_affiliation_annual`,
                    {
                        quantity: annualCost,  // Aquí pasas annualCost directamente
                        profile: profile,       // Se mantiene explícito para mayor claridad
                        username: luisa_user
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${luisa_token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    const msg = response.data.data;
                    showNotification(msg);
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                }
                fetchCosts(); //cargar los nuevos datos a la tabla
                fetchHistoric();
                handleCloseModal();
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                console.error('Error al aprobar:', error);
                showErrorNotification('Ocurrió un error al actualizar el precio');
            } finally {
                setIsLoading(false);
            }
        } 
        else {
            const cleanedCost = annualCost.replace(/\$/g, '');
            const monthlyCost = cleanedCost.split(', ').map(item => item.replace(/,/g, ''));
            const luisa_user = localStorage.getItem("luisa_username");
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/update_costs_affiliation_monthly`,
                    {
                        field: profile,
                        monthlyCosts: monthlyCost,
                        username: luisa_user,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${luisa_token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    const msg = response.data.data;
                    showNotification(msg);
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                }
                fetchMonthlyCosts();
                fetchHistoric();
                handleCloseModal();
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                console.error('Error al aprobar:', error);
                showErrorNotification('Ocurrió un error al actualizar el precio');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const showErrorNotification = (text: string) => {
        toast.error(text, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showNotification = (text: string) => {
        toast.success(text, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    function formatearPrecio(precio: string) {
        // Convertir el string a número y dar formato
        const precioNumero = parseFloat(precio);
        return precioNumero.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    return (
        <>
            <TopbarCoparmex />
            <div className="py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9 mt-4'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>
                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/costos')}>
                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                            </svg>
                            Costos de Afiliación
                        </h1>
                        <div className='p-3 sub-header'>
                            <span>Luisa te ayuda a dar los costos configurados a los socios y prospectos</span>
                        </div>
                        <div className="affiliation-buttons">
                            <button
                                className={activeButton === 'annual' ? 'affiliation-active' : 'affiliation-inactive'}
                                onClick={activeButton === 'annual' ? undefined : () => handleButtonClick('annual')}
                            >
                                Costos Anuales
                            </button>
                            <button
                                className={activeButton === 'monthly' ? 'affiliation-active' : 'affiliation-inactive'}
                                onClick={activeButton === 'monthly' ? undefined : () => handleButtonClick('monthly')}
                            >
                                Costos Mensuales
                            </button>
                            <button
                                className={activeButton === 'historic' ? 'affiliation-active' : 'affiliation-inactive'}
                                onClick={activeButton === 'historic' ? undefined : () => handleButtonClick('historic')}
                            >
                                Historial de cambios
                            </button>
                            <button className="affiliation-upload"
                                onClick={handleOpenModal}
                            >
                                Alta de costos
                            </button>
                        </div>
                        {/*<div className='col-lg-6 col-md-6 col-sm-12 searchBar mb-4 mt-4 float-end'>
                            <div className="input-group h-100">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar nombre"
                                    aria-label="vinculacion"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div> */}
                        {activeButton === 'annual' ? (
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-4' id='linkupTable'>
                                <section className="wrapper">
                                    <main className="row title">

                                        <ul className="d-flex justify-content-between align-items-baseline ">

                                            <li className="fw-bold">Perfil</li>
                                            <li className='fw-bold'>Anual</li>
                                            <li className='fw-bold'>Colaboradores</li>
                                        </ul>
                                    </main>
                                </section>
                                <section>
                                    {businessData.length > 0 ? (
                                        businessData.map((item, index) => (
                                            <article key={index}
                                                className='data-users mt-3'
                                            >
                                                <ul className="d-flex justify-content-between align-items-baseline">
                                                    <li className='responsive-truncate me-4'>{item.nombre}</li>
                                                    <li className='d-md-block'>{`$ ${formatearPrecio(item.valor)}`}</li>
                                                    <li className='d-md-block responsive-truncate'>{item.colaboradores}</li>
                                                </ul>
                                            </article>
                                        ))
                                    ) : (
                                        <p>No hay datos disponibles</p>
                                    )}
                                    
                                </section>
                            </div>
                        ) : activeButton === 'historic' ? (

                        <div className="table-container mt-4">
                            <section className="wrapper">
                                <main className="row title">
                                    <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                        <li className="widhtFirstli fw-bold">Fecha de Alta</li>
                                        <li className="d-none d-md-block fw-bold">Modalidad de modificación</li>
                                        <li className="d-none d-md-block fw-bold">Modificado por</li>
                                    </ul>
                                </main>
                            </section>
                            <section>
                                {historicData.length > 0 ? (
                                    
                                    historicData.map((item, index) => (
                                        <article className='data-users mt-2 ' key={index}>
                                            <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                                <li className='widhtFirstli'>{formatDate(item.fecha)}</li>
                                                <li className=' responsive-truncate'>{`${item.modality}`}</li>
                                                <li className="d-flex align-items-center responsive-truncate" style={{ whiteSpace: "nowrap", overflow: "visible", textOverflow: "ellipsis" }} title={item.nombre}>
                                                    {item.nombre }
                                                </li>
                                            </ul>
                                        </article>
                                    ))
                                ) : (
                                    <p>No hay costos configurados</p>
                                    
                                )}
                                     {/* Paginador */}
                                     {
                                    <div className="pagination-controls">
                                        {currentPage > 1 && (
                                            <button onClick={handlePreviousPage} className="pagination-button-prev-next">
                                                <i className="bi bi-chevron-left"></i> Anterior
                                            </button>
                                        )}
                                        {getPaginationRange().map((page, index) => (
                                            <button
                                                key={index}
                                                onClick={() => typeof page === 'number' && handlePageClick(page)}
                                                className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                                                disabled={typeof page !== 'number'}
                                            >
                                                {page}
                                            </button>
                                        ))}
                                    
                                        {currentPage < totalPages && (
                                            <button onClick={handleNextPage} className="pagination-button-prev-next">
                                                Siguiente <i className="bi bi-chevron-right"></i>
                                            </button>
                                        )}
                                    <ToastContainer />
                                    </div>}                        
                            </section>
                       
                        </div>

                        ) : (
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-4' id='monthlyTable'>
                                <section className="wrapper text-black">
                                    <div className='scroll-show' style={{ overflowX: 'scroll', width: '100%', maxWidth: '100%', whiteSpace: 'nowrap' }}>
                                        <main className="row title border-title" style={{ minWidth: '2000px' }}>
                                            <ul className="d-flex align-items-baseline" style={{ gap: '15px', minWidth: '1500px' }}>
                                                <li className="fw-bold text-center" style={{ minWidth: '150px' }}>Perfil</li>
                                                <li className="fw-bold text-center" style={{ minWidth: '130px' }}>Colaboradores</li>
                                                {["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"].map((mes, i) => (
                                                    <li key={i} className="fw-bold text-center" style={{ minWidth: '120px' }}>{mes}</li>
                                                ))}
                                            </ul>
                                        </main>

                                        <section style={{ minWidth: '2000px' }}>
                                            {monthlyCosts.length > 0 ? (
                                                monthlyCosts.map((item, index) => (
                                                    <article key={index} className='data-users mt-3'>
                                                        <ul className="d-flex align-items-center" style={{ gap: '15px', minWidth: '1500px' }}>
                                                            <li className='ms-2 text-center' style={{ minWidth: '150px' }}>{item.perfil}</li>
                                                            <li className='text-center' style={{ minWidth: '130px' }}>{item.colaboradores}</li>
                                                            {Array(12).fill(0).map((_, monthIndex) => (
                                                                <li key={monthIndex} className='text-center' style={{ minWidth: '120px' }}>
                                                                    {item.costo_mensual[monthIndex] !== undefined && item.costo_mensual[monthIndex] !== ""
                                                                        ? `$ ${formatearPrecio(item.costo_mensual[monthIndex])}`
                                                                        : "-"}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </article>
                                                ))
                                            ) : (
                                                <p>No hay datos disponibles</p>
                                            )}
                                        </section>
                                    </div>
                                </section>
                                <ToastContainer />
                            </div>
                            
                        )}


                    </div>
                </div>
            </div>

            <AffiliationMonthlyCost
                show={isModalOpen}
                setActiveButton={handleButtonClick}
                activeButton={activeButton}
                onClick={handleButtonConfirm}
                isLoading={isLoading}
                onHide={() => {
                    setIsModalOpen(false);
                }}
            />
        </>
    );
};

export default AffiliationCosts;
