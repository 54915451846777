import TopbarCoparmex from "../../main/TopBarCoparmex";
import "../../../assets/css/administration.css";
import { useNavigate } from 'react-router-dom';
import { Box, useMediaQuery, useTheme, Typography } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { SetStateAction, useState } from "react";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import axios from "axios";
import { format } from 'date-fns';
import loadingGif from '../../../assets/img/loading.gif'
import handleLogout from '../../../functions/functions';

interface ChartData {
    title: string;
    totalAmount: number;
    color: string;
    isSmall?: boolean;
}

interface tableEvents {
    title: string;
    totalAmount: number;
}

interface tableData {
    date: string;
    totalAmountForDate: number;
    events: tableEvents[];
}

const AccountsReceivable = () => {

    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [paymentsData, setPaymentsData] = useState<tableData[]>([]);
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const toggleExpand = (index: SetStateAction<number | null>) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    //Datepicker
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    //Chart
    const [chartData, setChartData] = useState<ChartData[]>([]);
    const [chartColors, setChartColors] = useState(['#003366', '#4169E1', '#00BFFF', '#40E0D0', '#00FFFF', '#50C878', '#98FF98', '#228B22', '#4B0082', '#008080']);
    const isMobile = useMediaQuery('(max-width:600px)');
    const chartSize = isMobile ? 200 : 350;
    const [hoveredItem, setHoveredItem] = useState<string | null>(null);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleMouseEnter = (item: string) => {
        setHoveredItem(item);
    };

    const fetchPaymentSummary = async () => {
        try {
            setIsLoading(true);
            const formattedStartDate = startDate ? format(startDate, 'dd-MM-yyyy') : null;
            const formattedEndDate = endDate ? format(endDate, 'dd-MM-yyyy') : null;

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/fetchAccountsPayable`, {
                params: {
                    //rfc: luisa_rfc,
                    iniDate: formattedStartDate,
                    finDate: formattedEndDate
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${luisa_token}`
                }
            });

            const data = response.data;
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;

            const allDates = new Set([
                ...data.transaction_per_day_args.map((t: any) => new Date(t.fecha).toISOString().split('T')[0]),
                ...data.telefonia.map((t: any) => new Date(t.fecha).toISOString().split('T')[0]),
                ...data.incommingCall.map((t: any) => new Date(t.fecha).toISOString().split('T')[0]),
                ...data.outgoingCall.map((t: any) => new Date(t.fecha).toISOString().split('T')[0]),
            ]);

            const result: tableData[] = Array.from(allDates).map(dateStr => {
                const fecha = new Date(dateStr);
                const formattedDate = `${(fecha.getMonth() + 1).toString().padStart(2, '0')}/${fecha.getDate().toString().padStart(2, '0')}/${fecha.getFullYear()}`; // MM/DD/YYYY

                const transaction = data.transaction_per_day_args.find((t: any) =>
                    new Date(t.fecha).toISOString().split('T')[0] === dateStr
                );

                const telefoniaCost = data.telefonia.find((t: any) =>
                    new Date(t.fecha).toISOString().split('T')[0] === dateStr
                );

                const incomingCallCost = data.incommingCall.find((t: any) =>
                    new Date(t.fecha).toISOString().split('T')[0] === dateStr
                );

                const outgoingCallCost = data.outgoingCall.find((t: any) =>
                    new Date(t.fecha).toISOString().split('T')[0] === dateStr
                );

                return {
                    date: formattedDate,
                    totalAmountForDate: (transaction?.price || 0) + (telefoniaCost?.costo || 0) + (incomingCallCost?.costo || 0) + (outgoingCallCost?.costo || 0),
                    events: [
                        ...(transaction ? [{ title: "Modelo de LLM", totalAmount: transaction.price }] : []),
                        ...(telefoniaCost ? [{ title: "VAPI (Telefonía)", totalAmount: telefoniaCost.costo }] : []),
                        ...(incomingCallCost ? [{ title: "TWILIO Llamada (Entrante)", totalAmount: incomingCallCost.costo }] : []),
                        ...(outgoingCallCost ? [{ title: "TWILIO Llamada (Salida)", totalAmount: outgoingCallCost.costo }] : [])
                    ]
                };
            });


            setPaymentsData(result);

            // Crear datos para la gráfica agrupando por evento (ignorando la fecha)
            const eventTotals = result.reduce((acc: Record<string, number>, item) => {
                item.events.forEach(event => {
                    if (!acc[event.title]) acc[event.title] = 0;
                    acc[event.title] += event.totalAmount;
                });
                return acc;
            }, {});
            eventTotals["VAPI (Telefonía)"] = data.vapi;
            eventTotals["TWILIO Llamada (Entrante)"] = data.totalCostReceived;
            eventTotals["TWILIO Llamada (Salida)"] = data.totalCostCalls;

            // Calculate total for identifying small segments
            const totalAmount = Object.values(eventTotals).reduce((sum: number, amount: number) => sum + amount, 0) as number;

            // Convertir el resultado en el formato que necesita la gráfica
            /*const chartDataForGraph = Object.entries(eventTotals).map(([title, totalAmount]) => {
                const percentage = (totalAmount as number / (totalAmount as any)) * 100;
                return {
                    title,
                    totalAmount: totalAmount as number,
                    color: setColor(),
                    isSmall: percentage < 5 // Mark segments with less than 5% as small
                };
            });

            setChartData(chartDataForGraph); */
            let availableColors = [...chartColors];

            const chartDataForGraph = Object.entries(eventTotals).map(([title, totalAmount]) => {
                const percentage = (totalAmount as number / (totalAmount as any)) * 100;

                // Si nos quedamos sin colores, reiniciamos el array
                if (availableColors.length === 0) {
                    availableColors = [...chartColors];
                }

                // Selecciona un color aleatorio de los disponibles
                const randomIndex = Math.floor(Math.random() * availableColors.length);
                const selectedColor = availableColors[randomIndex];
                //console.log("el color es: ", selectedColor);
                // Elimina el color seleccionado del array de disponibles
                availableColors.splice(randomIndex, 1);

                return {
                    title,
                    totalAmount: totalAmount as number,
                    color: selectedColor,
                    isSmall: percentage < 5
                };
            });

            setChartData(chartDataForGraph);
            setIsLoading(false);


        } catch (error) {
            console.error('Error fetching:', error);
            setIsLoading(false);
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
        }
    };

    const setColor = () => {
        const randomIndex = Math.floor(Math.random() * chartColors.length);
        return chartColors[randomIndex];
    };

    const totalAmount = chartData.reduce((sum, item) => sum + item.totalAmount, 0);

    const getPercentage = (value: number) => {
        return ((value / totalAmount) * 100).toFixed(2);
    };

    const handleDate = (date: string | Date): string => {
        const fecha = new Date(date);
        fecha.setDate(fecha.getDate() + 1); // Sumar un día
        const day = String(fecha.getDate()).padStart(2, '0'); // Asegura que el día tenga 2 dígitos
        const month = String(fecha.getMonth() + 1).padStart(2, '0'); // Asegura que el mes tenga 2 dígitos
        const year = fecha.getFullYear(); // Año

        return `${day}-${month}-${year}`; // Formato DD-MM-YYYY
    };

    const downloadCSV = () => {
        if (paymentsData.length === 0) {
            alert('No hay datos disponibles para descargar');
            return;
        }

        let csvContent = 'Fecha,Detalle,Monto\n';

        paymentsData.forEach((transaction, index) => {
            if (index > 0) {
                csvContent += '\n';
            }

            csvContent += `${new Date(transaction.date).toLocaleDateString()},Total,$${transaction.totalAmountForDate.toFixed(2)}\n`;

            transaction.events.forEach(event => {
                csvContent += `${new Date(transaction.date).toLocaleDateString()},${event.title},$${event.totalAmount.toFixed(2)}\n`;
            });
        });

        csvContent += `-,transacciones acumuladas,$${totalAmount}`;

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        link.setAttribute('href', url);
        link.setAttribute('download', `gastos_x_uso_${timestamp}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <TopbarCoparmex />
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'>
                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/finance')}>
                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                            </svg>
                            Gastos por uso (Dolares)
                        </h1>

                        <div className="row --config-container">
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className="row mt-4">
                                    <div className='col-sm-12 col-md-6 col-lg-6'>
                                        <span className="v-align-span">Transacciones acumuladas en el rango seleccionado:</span>
                                    </div>
                                    <div className='col-sm-12 col-md-6 col-lg-6'>
                                        <button className="btn btn-outline-span px-4">
                                            $ {totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Dolares
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                    <h5 className="fw-bolder text-blue mt-4">Transacciones:</h5>
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-3">
                                    <div className="d-flex justify-content-end mt-4">
                                        <button
                                            className="btn btn-sm btn-outline-primary"
                                            onClick={downloadCSV}
                                            disabled={paymentsData.length === 0 || isLoading}
                                        >
                                            <i className="bi bi-download me-1"></i>
                                            Descargar CSV
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className="col-lg-12 col-md-12 col-sm-12 finance-table">
                                    <section className="wrapper mt-4">
                                        <main className="row title">
                                            <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                                <li className="widhtFirstli fw-bold">Fecha</li>
                                                <li className="fw-bold">Entrada</li>
                                                <li></li>
                                            </ul>
                                        </main>
                                    </section>
                                    {isLoading ? (
                                        <div className="text-center">
                                            <img src={loadingGif} alt="Cargando..." className="w-25" />
                                        </div>
                                    ) : paymentsData.length > 0 ? (
                                        <>
                                            {paymentsData.map((transaction, index) => (
                                                <section key={index} className="row-fadeOut-wrapper finance-tab">
                                                    <article className={`art-style ${expandedIndex === index ? 'expanded' : ''}`}>
                                                        <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline mt-2">
                                                            <li>{handleDate(transaction.date)}</li>

                                                            <li className="d-none d-md-block">${transaction.totalAmountForDate.toFixed(2)} Dolares</li>
                                                            <li>
                                                                <i
                                                                    className="bi bi-caret-down-fill"
                                                                    role="button"
                                                                    onClick={() => toggleExpand(index)}
                                                                ></i>
                                                            </li>
                                                        </ul>
                                                        {expandedIndex === index && (
                                                            <div className="more-content ps-5">
                                                                <ul>
                                                                    {transaction.events.map((event, eventIndex) => (
                                                                        <li key={eventIndex} className="d-flex align-items-center">
                                                                            <span className="fw-bold mb-0 d-flex align-items-center">
                                                                                <div className="circle-blue me-2"></div>
                                                                                <span className="responsive-truncate">{event.title}</span>
                                                                                <span className="mx-2"> | </span>
                                                                                <span className="responsive-truncate">${event.totalAmount.toFixed(2)} Dolares</span>
                                                                            </span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </article>
                                                </section>
                                            ))}
                                        </>
                                    ) : (
                                        <p className="text-center">No hay datos disponibles</p>
                                    )}

                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div className="col-lg-12 col-md-12 col-sm-12 px-4 pickers">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', maxWidth: '100%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                <label htmlFor="start-date" className="fw-bold">Desde el:</label>
                                                <DatePicker
                                                    className="date-picker"
                                                    value={startDate}
                                                    onChange={(newValue) => {
                                                        setStartDate(newValue);
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            className: 'btn btn-primary',
                                                            fullWidth: true,
                                                            variant: 'filled'
                                                        },
                                                        openPickerIcon: { fontSize: 'large' }
                                                    }}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                <label htmlFor="end-date" className="fw-bold">Hasta el:</label>
                                                <DatePicker
                                                    className="date-picker"
                                                    value={endDate}
                                                    onChange={(newValue) => setEndDate(newValue)}
                                                    slotProps={{
                                                        textField: {
                                                            className: 'btn btn-primary',
                                                            fullWidth: true,
                                                            variant: 'filled'
                                                        },
                                                        openPickerIcon: { fontSize: 'large' }
                                                    }}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                <label htmlFor="end-date" className="fw-bold">&nbsp;</label>
                                                <button className="btn btn-primary" onClick={fetchPaymentSummary}>Buscar</button>
                                            </div>

                                        </div>

                                    </LocalizationProvider>

                                </div>
                                <div className="col-md-10 m-auto">
                                    {chartData.length > 0 ? (
                                        <>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto' }}>
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    <PieChart
                                                        series={[
                                                            {
                                                                arcLabel: (item) => {
                                                                    const percentage = getPercentage(item.value);
                                                                    return parseFloat(percentage) >= 2 ? `${percentage}%` : '';
                                                                },
                                                                arcLabelMinAngle: 10,
                                                                highlightScope: { fade: 'global', highlight: 'item' },
                                                                data: chartData.map((item) => ({
                                                                    label: item.title,
                                                                    value: item.totalAmount,
                                                                    color: item.color,
                                                                })),
                                                                innerRadius: isMobile ? 20 : 50,
                                                                outerRadius: isMobile ? 80 : 100,
                                                                paddingAngle: 1,
                                                                cornerRadius: 2,
                                                                startAngle: 1,
                                                                endAngle: 360,
                                                                cx: chartSize / 2,
                                                                cy: chartSize / 2,
                                                            },
                                                        ]}
                                                        sx={{
                                                            [`& .${pieArcLabelClasses.root}`]: {
                                                                fontWeight: 'bold',
                                                                fontSize: isMobile ? '10px' : '13px',
                                                                textAlign: 'center',
                                                                fill: 'white',
                                                            },
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                        height={chartSize}
                                                        width={chartSize}
                                                        className="pie-labels"
                                                    />
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'center',
                                                        gap: 1,
                                                        mt: 2,
                                                        maxWidth: isMobile ? '100%' : '80%'
                                                    }}
                                                >
                                                    {chartData.map((item, index) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                backgroundColor: activeIndex === index ? 'rgba(0,0,0,0.08)' : 'transparent',
                                                                padding: '4px 8px',
                                                                borderRadius: '4px',
                                                                cursor: 'pointer',
                                                                transition: 'background-color 0.2s ease',
                                                            }}
                                                            onMouseOver={() => setActiveIndex(index)}
                                                            onMouseOut={() => setActiveIndex(null)}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: 12,
                                                                    height: 12,
                                                                    backgroundColor: item.color,
                                                                    borderRadius: '2px',
                                                                    mr: 1
                                                                }}
                                                            />
                                                            <Typography variant="body2" sx={{ fontSize: isMobile ? '11px' : '13px' }}>
                                                                {item.title}: <strong>{getPercentage(item.totalAmount)}%</strong>
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountsReceivable;