import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface DeleteWorkCommissionEntryProps {
    show: boolean;
    title: string;
    onHide: () => void;
    onConfirm: () => void;
}

const DeleteWorkCommissionEntry: React.FC<DeleteWorkCommissionEntryProps> = ({ show, title, onHide, onConfirm }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmar Eliminación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Estás seguro de que deseas eliminar la publicación "<strong>{title}</strong>"?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteWorkCommissionEntry;
