import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../../assets/css/vinculacion.css';
import { LinkupInformation, LinkupBusiness, PieData } from '../interfaces/LinkupResult';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import handleLogout from '../../functions/functions';

const ResultadoVinculacion: React.FC = () => {
    const luisa_token = localStorage.getItem('luisa_token');
    const [linkupResult, setLinkupResult] = useState<LinkupInformation[]>([]);
    const [chartData, setChartData] = useState<PieData[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const user_id = location.state.name;
    const filename = location.state.filename;
    localStorage.setItem('userID', user_id);
    localStorage.setItem('pdfFilename', filename);

    const isMobile = useMediaQuery('(max-width:600px)');
    const chartSize = isMobile ? 200 : 400;
    const [label, setLabel] = useState<PieData[]>([]);

    const handleProfile = (rfc: string) => {
        navigate('/profile_business?id=' + rfc);
        //navigate('/profile_business', { state: { id: 1, rfc: rfc, user_id: user_id } });
    }

    const handleBack = () => {
        navigate('/linkup');
    }

    const showNotification = () => {
        toast('📌 Recuerda completar tu perfil para poder aprovechar al máximo tus vinculaciones!', {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const COLORS = ['#88C9FF', '#0071CE', '#FFFFFF', '#4BC0C0', '#9966FF'];

    useEffect(() => {
        const fetchLinkup = async () => {
            try {
                // const response = await axios.post(`http://localhost:3333/backend/getLinkupResult`, {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getLinkupResult`, {
                    user_id: user_id
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });
                if (response.status === 200) {
                    const linkupData = response.data;
                    const linkupScore = linkupData.linkup_information.map((item: any, index: number) => ({
                        value: ((item.linkup_score) * 100).toFixed(2),
                        color: COLORS[index % COLORS.length],  // Asignación de color para cada sector
                    }));
                    const label = linkupData.linkup_information.map((item: any) => ({
                        label: item.business_name
                    }))

                    setChartData(linkupScore);
                    setLabel(label);
                    setLinkupResult([linkupData]);
                    setChartData(linkupScore);
                }

            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        console.log(getError);

                    } else if (error.request) {
                        console.log('Request made but no response received:', error.request);
                    } else {
                        console.log('Error message:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            }
        };
        //showNotification();
        fetchLinkup();
    }, []);

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <div className='flex-column align-items-center py-3 px-5 linkup-bg '>
                <div className='mt-5'>
                    <div className="col-lg-7 col-md-7 col-sm-7 m-auto">
                        {linkupResult.map((linkup, index) => (
                            <h1 key={index} className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'><i className="bi bi-arrow-return-left me-3 point" onClick={handleBack}></i>{linkup.linkupName}</h1>
                        ))}
                        <div className='resultado-vinculacion mb-3 p-3'><span>Resultado de tu vinculación</span></div>
                        <ul className="list-group">
                            {linkupResult.map((linkupInfo, index) => (
                                <div key={index}>
                                    {linkupInfo.linkup_information.map((business, index) => (
                                        <li key={index} className='li-w d-flex align-items-center mb-3 p-3'>
                                            <button className='btn btn-link text-decoration-none d-flex align-items-center' onClick={() => handleProfile(business.business_rfc)}>
                                                <div className="circle-blue me-2"></div><span className='responsive-truncate'><u>{business.business_name}</u></span>
                                            </button>
                                            <span className='mx-2 s-color'>|</span>
                                            <span className='text-small responsive-truncate'> {business.linkup_type}</span>
                                        </li>
                                    ))}
                                </div>
                            ))}
                        </ul>
                    </div>
                    <div className='col-md-7 m-auto'>
                        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', margin: 'auto' }}>
                            {/* Gráfico de pastel */}
                            <PieChart
                                series={[
                                    {
                                        arcLabel: (item) => `${item.value}%`,
                                        highlightScope: { fade: 'global', highlight: 'item' },
                                        data: chartData,
                                        innerRadius: isMobile ? 20 : 40,
                                        outerRadius: isMobile ? 80 : 100,
                                        paddingAngle: isMobile ? 4 : 3,
                                        cornerRadius: 6,
                                        startAngle: -45,
                                        endAngle: 225,
                                        cx: chartSize / 2,
                                        cy: chartSize / 2,
                                    },
                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                        fontSize: isMobile ? '10px' : '12px',
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                height={chartSize}
                                width={chartSize}
                            />

                            {/* Leyenda personalizada con espacio adicional arriba */}
                            <Box sx={{ display: 'block', flexWrap: 'wrap', justifyContent: 'center', }}>
                                {label.map((item, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
                                        <Box
                                            sx={{
                                                width: 16,
                                                height: 16,
                                                backgroundColor: chartData[index].color, // Asegura que el color coincida con el gráfico
                                                mr: 1,
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <Typography variant="body2">{item.label}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
};


export default ResultadoVinculacion;
