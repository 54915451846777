import React, { useState, useEffect } from 'react';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';
import "../../assets/css/Promotion.css";
import "../../assets/css/adminEvent.css";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewEventModal from './NewEventModal';
import EventDetailModal from './EventDetailModal';
import EventEditModal from './EventEditModal';
import handleLogout, { truncateFileName } from '../../functions/functions';
import { Tooltip } from 'react-tooltip'

interface Event {
    id: string;
    title: string;
    location: string;
    event_date: string; // ISO string format for date
    event_time: string; // ISO string format for date
    status_event: string;
}

const AdminEvents = () => {
    const navigate = useNavigate();
    const [events, setEvents] = useState<Event[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<{ title: string; attendees: any[]; id: string }>({ title: '', attendees: [], id: '' });
    const [attendeesLoading, setAttendeesLoading] = useState<boolean>(false);
    const [attendeesError, setAttendeesError] = useState<string | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 10;

    const [search, setSearch] = useState('');

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const handleCloseEditModal = () => setIsEditModalOpen(false);

    const handleOpenDetailModal = async (event: any) => {
        setSelectedEvent({ title: event.title, attendees: [], id: event.id });
        setIsDetailModalOpen(true);
        setAttendeesLoading(true);
        setAttendeesError(null);

        try {
            const luisa_token = localStorage.getItem('luisa_token');
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/events/getAttendeesList`,
                { event_id: String(event.id) },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`,
                    },
                }
            );

            const attendeesData = response.data?.data?.[0]?.attendees || [];
            const formattedAttendees = attendeesData.map((attendee: any) => ({
                name: attendee.name || attendee.rfc,
                phone: attendee.phone || 'No disponible',
                email: attendee.email || 'No disponible',
                pago: attendee.payment_confirmation,
                assistance: attendee.assistance,
                id: attendee._id,
            }));
            setSelectedEvent((prev) => ({
                ...prev,
                attendees: formattedAttendees,
            }));
        } catch (error) {
            console.error('Error fetching attendees:', error);
            setAttendeesError('Error al cargar la lista de asistentes.');
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
        } finally {
            setAttendeesLoading(false);
        }
    };

    const handleEditModal = async (event: any) => {
        setSelectedEvent({ title: event.title, attendees: [], id: event.id });
        setIsEditModalOpen(true);
    };

    const handleCloseDetailModal = () => {
        setIsDetailModalOpen(false);
        setSelectedEvent({ title: '', attendees: [], id: '' });
    };

    const handleSaveEvent = (newEvent: { title: string; location: string; event_date: string; event_time: string, status_event: string }) => {
        const newEventData: Event = {
            id: (Math.random() * 1000000).toString(),
            ...newEvent,
        };
        setEvents([...events, newEventData]);
    };

    const fetchEvents = async () => {
        try {
            setIsLoading(true);
            const luisa_token = localStorage.getItem('luisa_token');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/events/getAllEvents`, {
                params: {
                    filter: 'all'
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${luisa_token}`
                }
            });

            // Obtener la fecha actual sin la hora
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const eventsData: Event[] = response.data.data.map((event: any) => {
                // Convertir event_date a objeto Date y eliminar la hora
                const eventDate = new Date(event.event_date);
                eventDate.setHours(0, 0, 0, 0);

                // Extraer la hora y minutos de event_time
                const [hours, minutes] = event.event_time.split(':').map(Number);
                const eventDateTime = new Date(event.event_date);
                eventDateTime.setHours(hours, minutes, 0, 0);

                let status_event = event.status_event;

                if (status_event !== "eliminado") {
                    const eventDateUTC = new Date(event.event_date); // Fecha en UTC
                    const eventDate = new Date(eventDateUTC.getUTCFullYear(), eventDateUTC.getUTCMonth(), eventDateUTC.getUTCDate()); // Normalizada a UTC

                    const todayUTC = new Date();
                    const today = new Date(todayUTC.getUTCFullYear(), todayUTC.getUTCMonth(), todayUTC.getUTCDate()); // Normalizada a UTC

                    console.log("Event Date:", eventDate);
                    console.log("Today Date:", today);

                    if (eventDate.getTime() === today.getTime()) {
                        status_event = "en proceso";
                    } else if (eventDateUTC < new Date()) {
                        status_event = "caducado";
                    }
                    // if (eventDate.getTime() === today.getTime()) {
                    //     status_event = "en proceso";
                    // } else if (eventDateTime < new Date()) {
                    //     status_event = "caducado";
                    // }
                }

                return {
                    id: event._id,
                    title: event.title,
                    location: event.location,
                    event_date: event.event_date,
                    event_time: event.event_time,
                    attendees: event.attendees || [],
                    status_event
                };
            });

            setEvents(eventsData);
            setFilteredEvents(eventsData);
            setTotalPages(Math.ceil(eventsData.length / limit));
            setError(null);
        } catch (error) {
            console.error('Error fetching events:', error);
            setError('Failed to fetch events');
            setEvents([]);
            setFilteredEvents([]);
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleAttendeeUpdate = (attendeeId: string, newStatus: boolean) => {
        setSelectedEvent((prevState) => {
            const updatedAttendees = prevState.attendees.map((attendee) =>
                attendee.id === attendeeId ? { ...attendee, assistance: newStatus } : attendee
            );
            return { ...prevState, attendees: updatedAttendees };
        });
    };

    // Search functionality
    useEffect(() => {
        if (search.trim() === '') {
            setFilteredEvents(events);
        } else {
            const searchTerm = search.toLowerCase();
            const filtered = events.filter(event =>
                event.title.toLowerCase().includes(searchTerm) ||
                event.location.toLowerCase().includes(searchTerm)
            );
            setFilteredEvents(filtered);
        }
        setCurrentPage(1); // Reset to first page when search changes
        setTotalPages(Math.ceil(filteredEvents.length / limit));
    }, [search, events]);

    useEffect(() => {
        fetchEvents();
    }, []);

    // Get paginated data
    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * limit;
        const endIndex = startIndex + limit;
        return filteredEvents.slice(startIndex, endIndex);
    };

    // Update total pages when filtered events change
    useEffect(() => {
        setTotalPages(Math.ceil(filteredEvents.length / limit));
    }, [filteredEvents, limit]);

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePageClick = (page: number | string) => {
        if (typeof page === 'number') {
            setCurrentPage(page);
        }
    };

    const getPaginationRange = () => {
        const totalPagesToShow = 2; // Número de páginas a mostrar en el rango
        if (totalPages <= totalPagesToShow) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const range = [];
        const leftLimit = Math.max(2, currentPage - 1);
        const rightLimit = Math.min(totalPages - 1, currentPage + 1);

        // Primera página siempre visible
        range.push(1);

        if (leftLimit > 2) {
            range.push("...");
        }

        for (let i = leftLimit; i <= rightLimit; i++) {
            range.push(i);
        }

        if (rightLimit < totalPages - 1) {
            range.push("...");
        }

        // Última página siempre visible
        range.push(totalPages);

        return range;
    };

    return (
        <>
            <TopbarCoparmex />
            <div className="py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-12 col-md-12 col-lg-9'>
                        <h1 className='fw-bolder text-blue mb-3 mb-md-4 animate__animated animate__fadeInUp mt-4'>
                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/administracion')}>
                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                            </svg>
                            Administración de Eventos
                        </h1>
                    </div>
                    <div className="col-sm-12 col-lg-9">
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-end mb-3 gap-3">
                            <button className="btn btn-primary btn-events" onClick={() => navigate('/new-event')}>
                                + Nuevo Evento
                            </button>
                            <div className='searchBar w-100 w-md-75 w-lg-50'>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="bi bi-search"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar nombre del evento o ubicación"
                                        aria-label="vinculacion"
                                        aria-describedby="basic-addon1"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="text-center my-5">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : error ? (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    ) : (
                        <div className='col-lg-9 col-md-12 col-sm-12'>
                            <div className='events-content'>
                                <section className="wrapper mt-4 row-min-width">
                                    <main className="row title row-min-width">
                                        <ul className="d-flex  align-items-center px-3" style={{ marginBottom: "10px", marginTop: "10px" }}>
                                            <li className="fw-bold text-center">Todos los eventos</li>
                                            <li className="fw-bold text-center">Ubicación</li>
                                            <li className="fw-bold text-center">Estatus</li>
                                            <li className="fw-bold text-center">Fecha</li>
                                            <li className="fw-bold text-center"></li>
                                        </ul>
                                    </main>
                                </section>
                                <Tooltip id="location"></Tooltip>
                                <Tooltip id="title"></Tooltip>
                                {filteredEvents.length === 0 ? (
                                    <div className="text-center my-4">
                                        <p>No se encontraron eventos que coincidan con la búsqueda.</p>
                                    </div>
                                ) : (
                                    getPaginatedData().map((event, index) => (
                                        <section key={event.id || index} style={{ marginTop: "10px" }} className='row-min-width'>
                                            <article className="data-users hov-art">
                                                <ul className="d-flex align-items-center px-3 hov-ul" style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                                    <li className="flex-fill text-center "
                                                        data-tooltip-id="title"
                                                        data-tooltip-content={event.title}
                                                        data-tooltip-place="top">
                                                        {truncateFileName(event.title, 25)}
                                                    </li>

                                                    <li className="flex-fill text-center ms-4"
                                                        data-tooltip-id="location"
                                                        data-tooltip-content={event.location}
                                                        data-tooltip-place="top">
                                                        {truncateFileName(event.location, 20)}
                                                    </li>
                                                    <li className="flex-fill text-center">
                                                        <span className={`px-3 py-1 ${event.status_event === 'creado'
                                                            ? '--style-active'
                                                            : event.status_event === 'en proceso'
                                                                ? '--style-pendiente'
                                                                : event.status_event === 'caducado'
                                                                    ? '--status-style-proceso'
                                                                    : '--status-style-devolucion'
                                                            }`}>
                                                            {event.status_event === 'creado' ? (
                                                                <>activo</>
                                                            ) : (
                                                                <>{event.status_event}</>
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li className="flex-fill text-center">
                                                        {new Date(event.event_date).toISOString().split('T')[0].split('-').reverse().join('/')}
                                                    </li>

                                                    <li className='cita-option'>
                                                        <i className="bi bi-pencil-square bi-edit me-3 point fs-4" onClick={() => handleEditModal(event)}></i>
                                                        <i className="bi bi-eye bi-blue point fs-4" onClick={() => handleOpenDetailModal(event)}></i>
                                                    </li>
                                                </ul>
                                            </article>
                                        </section>
                                    ))
                                )}
                            </div>
                        </div>
                    )}
                    {/* Controles del paginador */}
                    {filteredEvents.length > 0 && (
                        <div className="pagination-controls mt-4">
                            {currentPage > 1 && (
                                <button onClick={handlePreviousPage} className="pagination-button-prev-next">
                                    <i className="bi bi-chevron-left"></i> Anterior
                                </button>
                            )}

                            {getPaginationRange().map((page, index) => (
                                <button
                                    key={index}
                                    onClick={() => typeof page === 'number' && handlePageClick(page)}
                                    className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                                    disabled={typeof page !== 'number'}
                                >
                                    {page}
                                </button>
                            ))}

                            {currentPage < totalPages && (
                                <button onClick={handleNextPage} className="pagination-button-prev-next">
                                    Siguiente <i className="bi bi-chevron-right"></i>
                                </button>
                            )}
                        </div>
                    )}
                </div>

                {/* Modal de Nuevo Evento */}
                {isModalOpen && (
                    <NewEventModal
                        show={isModalOpen}
                        fetchEvents={fetchEvents}
                        onClose={handleCloseModal}
                    />
                )}

                {/* Modal de Detalles del Evento */}
                {isDetailModalOpen && (
                    <EventDetailModal
                        show={isDetailModalOpen}
                        onClose={handleCloseDetailModal}
                        eventTitle={selectedEvent.title}
                        attendees={selectedEvent.attendees}
                        eventId={selectedEvent.id}
                        onAttendeeUpdate={handleAttendeeUpdate}
                    />
                )}

                {/* Modal de Edicion del Evento */}
                {isEditModalOpen && (
                    <EventEditModal
                        show={isEditModalOpen}
                        onClose={handleCloseEditModal}
                        eventTitle={selectedEvent.title}
                        eventId={selectedEvent.id}
                        fetchEvents={fetchEvents} />
                )}
            </div>
        </>
    );
};

export default AdminEvents;