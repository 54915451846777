import React, { useEffect, useState, ChangeEvent } from "react";
import { Card, Button, Form, Row, Col, Collapse } from "react-bootstrap";
import axios from "axios";
import config from "../../config/config";
import TopbarCoparmex from "../main/TopBarCoparmex";
import loadingGif from "../../assets/img/loading.gif";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { LinkupProfile } from "../interfaces/LinkupProfile"; //ya no se va a usar
import { UserProfile, UserNoBusinessProfile } from "../interfaces/UserProfile";
import ProfileImageUploader from "../modals/ImageUploaderModal";
import {
  validatePassword,
  validateEmail,
  isValidPhoneNumber,
} from "../../functions/functions";
import { colors } from "@mui/material";
import "../../assets/css/miperfil.css";
import handleLogout from '../../functions/functions';

// Definir la estructura de los datos del usuario


//Probablemente cambiar este endpoint
const fetchUserData = async () => {
  const token = localStorage.getItem("luisa_token");
  const rfc = localStorage.getItem("luisa_rfc");
  const email = localStorage.getItem("luisa_email");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/getUserInfo`,
      {
        rfc: rfc, email:email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      const values = response.data.business_data;
      console.log(values);
      return response.data.business_data; // Devolver los datos recibidos
    }
  } catch (error) {  
    if (axios.isAxiosError(error) && error.response) {
      const status = error.response.status;
      if (status === 401 || status === 403) {
          console.log("Token expirado, cerrando sesión...");
          handleLogout();
      }
  }
    console.error("Error al obtener los datos del usuario:", error);
    throw error; // Propagar el error para manejarlo en el componente
  }
};



//function to have an empty string if the bd does not have the info
function setDefaultValues<UserNoBusinessProfile>(
  data: UserNoBusinessProfile,
  fields: (keyof UserNoBusinessProfile)[]
): UserNoBusinessProfile {
  fields.forEach((field) => {
    if (!data[field]) {
      // Verifica si el campo es 'image' para asignar "defecto.png"
      if (field === "profile_image") {
        (data[field] as any) = "https://via.placeholder.com/100";
      } else {
        (data[field] as any) = "";
      }
    }
  });
  return data;
}

function Perfil_Usuario() {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<UserNoBusinessProfile>({
    //business_name: "",
    //business_activity: "",
    //business_product_service: "",
    //business_description: "",
    //business_location: "",
    user_name: "",
    user_aP: "",
    user_aM: "",
    user_mail: "",
    user_phone: "",
    //email_payment: "",
    password: "",
    profile_image: "",
  });
  const [originalData, setOriginalData] = useState<UserNoBusinessProfile>({
    //business_name: "",
    //business_activity: "",
    //business_product_service: "",
    //business_description: "",
    //business_location: "",
    user_name: "",
    user_aP: "",
    user_aM: "",
    user_mail: "",
    user_phone: "",
    //email_payment: "",
    password: "",
    profile_image: "",
  });
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
  const [isNameValid, setIsNameValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isEmailPaymentValid, setIsEmailPaymentValid] = useState<boolean>(true);
  const [isCompanyNameValid, setIsCompanyNameValid] = useState<boolean>(true);
  const [passwordNew, setPaswordNew] = useState(false);
  const [sendPassword, setSendPassword] = useState<boolean>(true);
  
  //Test to use image
  const [showUploader, setShowUploader] = useState(false);
  const [imageFormData, setImageFormData] = useState<FormData | undefined>(
    undefined
  );
  const [open, setOpen] = useState(false);
  
  const handleOpenUploader = () => setShowUploader(true);
  const handleCloseUploader = () => setShowUploader(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordVisibility, setPasswordVisibility] = useState({
    currentPassword: false,
    password: false,
    confirmPassword: false,
  });
  const [passwordData, setPasswordData] = useState({
    password: '',
    confirmPassword: '',
  });

  const handlePasswordVisibility = (field: keyof typeof passwordVisibility) => {
    setPasswordVisibility({
      ...passwordVisibility,
      [field]: !passwordVisibility[field],
    });
  };

  // Petición al backend para actualizar los datos del usuario
const updateUserData = async (
  updatedData: UserNoBusinessProfile,
  imageFormData: FormData | undefined
): Promise<void> => {
  const token = localStorage.getItem("luisa_token");
  const rfc = localStorage.getItem("luisa_rfc") ?? "";
  const email = localStorage.getItem("luisa_email") ?? "";
  //const paramData = updatedData ?? "";

  try {
    setIsLoading(true);
    let imageFormData2: FormData;
    if (imageFormData) {
      imageFormData2 = imageFormData; // Asignar el FormData existente
    } else {
      imageFormData2 = new FormData(); // Inicializar un nuevo FormData
    }

    imageFormData2.append("rfc", rfc); // Agregar RFC al FormData
    imageFormData2.append("email", email); // Agregar RFC al FormData

    Object.keys(updatedData).forEach((key) => {
      imageFormData2.append(key, updatedData[key as keyof UserNoBusinessProfile]);
    });

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/upload_data_image`,
      imageFormData2,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("Datos guardados:", response.data);
    alert("Datos guardados correctamente");
    localStorage.setItem("luisa_email", updatedData.user_mail);
    localStorage.setItem("luisa_username", `${updatedData.user_name} ${updatedData.user_aP} ${updatedData.user_aM}`);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    if (axios.isAxiosError(error) && error.response) {
      const status = error.response.status;
      if (status === 401 || status === 403) {
          console.log("Token expirado, cerrando sesión...");
          handleLogout();
      }
  }
    console.error("Error al actualizar los datos del usuario:", error);
    alert("Error al guardar los datos");
    throw error; // Re-lanza el error para que pueda ser manejado por el llamador de la función
  }
};

  const handleImageUpdate = (newImage: string, formData: FormData) => {
    //setProfileImage(newImage); // Actualiza la imagen de perfil
    setUserData((prevUserData) => ({
      ...prevUserData, // Mantiene los demás campos de userData
      profile_image: newImage, // Solo modifica profile_image
    }));
    setImageFormData(formData);
    setIsModified(true);
    setShowUploader(false); // Cierra el modal
  };
  ///////////////////////
  // Cargar los datos del usuario al montar el componente
  useEffect(() => {
    const getUserData = async () => {
      const data = await fetchUserData();
      setUserData(data);
      //check if there is no data in bd so that the cancel button work
      const data_original = setDefaultValues(data, [
        "user_phone",
        "user_name",
        "user_aP",
        "user_aM",
        "user_email",
        "profile_image",
      ]);
      setOriginalData(data);
    };
    getUserData();
  }, []);

  // Verificar si los datos fueron modificados
  useEffect(() => {
    //Tomar solo los valores numericos del telefono
    const numeros1 = String(userData.user_phone).match(/\d+/g) || [];
    const numeros2 = String(originalData.user_phone).match(/\d+/g) || [];
    const isChanged =
      //userData.business_name !== originalData.business_name ||
      //userData.business_activity !== originalData.business_activity ||
      //userData.business_product_service !==originalData.business_product_service ||
      //userData.business_description !== originalData.business_description ||
      //userData.business_location !== originalData.business_location ||
      userData.user_name !== originalData.user_name ||
      userData.user_aP !== originalData.user_aP ||
      userData.user_aM !== originalData.user_aM ||
      userData.user_mail !== originalData.user_mail ||
      userData.profile_image !== originalData.profile_image ||
      numeros1[0] !== numeros2[0] || //el match me regresaba un arreglo
      //(isPhoneValid && userData.user_phone !== originalData.user_phone) ||
      //userData.email_payment !== originalData.email_payment ||
      userData.password !== originalData.password;
    setIsModified(isChanged);
    //setIsPhoneValid(userData?.user_phone?.length === 10);isValidPhoneNumber
    //setIsCompanyNameValid(userData.business_name.length > 0);
    setIsNameValid(userData.user_name.length > 0);
    setIsPhoneValid(isValidPhoneNumber(userData?.user_phone));
    setIsPasswordValid(validatePassword(userData?.password));
    setIsEmailValid(validateEmail(userData?.user_mail));
    //setIsEmailPaymentValid(validateEmail(userData?.email_payment));
    setPaswordNew(userData.password !== originalData.password); //para mostrar el mensaje si el usuario movio el password
  }, [userData, originalData]); // Este useEffect se ejecuta cada vez que se modifican los inputs

  // Manejar cambios en los inputs
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Validar el campo de teléfono
    if (name === "user_phone") {
      // Permitimos solo números y restringimos a 10 caracteres
      const phoneValue = value.replace(/\D/g, ""); // Elimina cualquier caracter que no sea dígito
      if (phoneValue?.length <= 10) {
        setUserData({ ...userData, user_phone: phoneValue });
        //showNotification();
      }
    } else {
      // Para otros campos, actualizamos sin validación especial
      setUserData({ ...userData, [name]: value });
    }
    if (name === "password" || name === "confirmPassword") {
      const passwordNew = name === "password" ? value : passwordData.password;
      const passwordConfirm = name === "confirmPassword" ? value : passwordData.confirmPassword;
      setPasswordData({
        ...passwordData,
        [name]: value, // Actualiza el valor en el estado para cada input
      });
      // Compara las contraseñas
      setPasswordsMatch(passwordNew === passwordConfirm);
      if(passwordNew === passwordConfirm && isPasswordValid){
        setSendPassword(true);
      } else {
        setSendPassword(false);
      }
    }
  };

  // Guardar los cambios
  const handleSave = async () => {

    await updateUserData(userData, imageFormData);

    setOriginalData(userData); // Actualizamos los datos originales después de guardarlos
    
  };

  // Cancelar cambios
  const handleCancel = () => {
    setUserData(originalData); // Restauramos los valores originales
  };

  const handleClose = () => {
    setOpen(false); // Cierra el dropdown al presionar "Cancelar"
  };

  return (
    <>
      <TopbarCoparmex />
      <div className="linkup-bg">
        <div className="container p-linkup">
          <Card className="bg-transparent border border-0">
            <Card.Header
              className="bg-transparent border border-0"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h1 className="fw-bolder text-blue mb-4 animate__animated animate__fadeInUp">
                Mi perfil
              </h1>
              <img
                src={userData.profile_image}
                alt="Imagen de perfil"
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  cursor: "pointer",
                  boxShadow: "0px 0px 80.5px -26px #000000",
                }}
                onClick={handleOpenUploader}
              />
              <ProfileImageUploader
                show={showUploader}
                onClose={handleCloseUploader}
                onImageUpdate={handleImageUpdate}
              />
            </Card.Header>
            <Card.Body className="mt-3">
              <p style={{ color: "#0047BA" }}>
                Configura la información de tu perfil o cambia tu contraseña.
              </p>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor="business_name"
                    style={{ color: "#0047BA" }}
                  >
                    Nombre:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="user_name"
                    name="user_name"
                    value={userData.user_name}
                    className="--stylebtnVariant text-light"
                    onChange={handleChange}
                  />
                  {!isNameValid && (
                    <Form.Text className="text-danger">
                      El nombre no debe estar vacio.
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor="business_activity"
                    style={{ color: "#0047BA" }}
                  >
                    Apellido Paterno:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="user_aP"
                    name="user_aP"
                    value={userData.user_aP}
                    className="--stylebtnVariant text-light"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor="business_act"
                    style={{ color: "#0047BA" }}
                  >
                    Apellido Materno:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="user_aM"
                    name="user_aM"
                    value={userData.user_aM}
                    className="--stylebtnVariant text-light"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="user_mail" style={{ color: "#0047BA" }}>
                    Email:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="user_mail"
                    name="user_mail"
                    value={userData.user_mail}
                    className="--stylebtnVariant text-light"
                    onChange={handleChange}
                  />
                  {!isEmailValid && (
                    <Form.Text className="text-danger">
                      El email no es valido.
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="user_phone" style={{ color: "#0047BA" }}>
                    Teléfono:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="user_phone"
                    name="user_phone"
                    value={userData.user_phone}
                    className="--stylebtnVariant text-light"
                    onChange={handleChange}
                  />
                  {!isPhoneValid && (
                    <Form.Text className="text-danger">
                      El número de teléfono debe tener 10 dígitos.
                    </Form.Text>
                  )}
                </Form.Group>
                <div className="bg-primaryO p-3">
                  <div className="row m-auto">
                    <Button
                      onClick={() => setOpen(!open)}
                      aria-controls="password-collapse"
                      aria-expanded={open}
                      className="btnNuevaContraseña"
                    >
                      Cambiar Contraseña{" "}
                      <i className="bi bi-caret-down-fill"></i>
                    </Button>
                  </div>
                  <Collapse in={open}>
                    <div id="password-collapse">
                      {/*
                      <Form.Group className="mb-3">
                        <Form.Label className="text-light">
                          Contraseña Actual
                        </Form.Label>
                        <div className="input-group">
                          <Form.Control
                            type={
                              passwordVisibility.currentPassword
                                ? "text"
                                : "password"
                            }
                            name="currentPassword"
                            placeholder="Contraseña Actual"
                            className="inputsPaswordStyles"
                          />
                          <span
                            className="input-group-text"
                            onClick={() =>
                              handlePasswordVisibility("currentPassword")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={`bi ${
                                passwordVisibility.currentPassword
                                  ? "bi-eye-slash"
                                  : "bi-eye"
                              }`}
                            ></i>
                          </span>
                        </div>
                      </Form.Group>  */}

                      <Form.Group className="mb-3">
                        <Form.Label className="text-light">
                          Cambiar Contraseña
                        </Form.Label>
                        <div className="input-group">
                          <Form.Control
                            type={
                              passwordVisibility.password
                                ? "text"
                                : "password"
                            } // Cambia el tipo del input
                            id="password"
                            name="password"
                            placeholder="Contraseña Nueva"
                            className="inputsPaswordStyles"
                            value={passwordData.password}
                            onChange={handleChange}
                          />
                          <span
                            className="input-group-text"
                            onClick={() =>
                              handlePasswordVisibility("password")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={`bi ${
                                passwordVisibility.password
                                  ? "bi-eye-slash"
                                  : "bi-eye"
                              }`}
                            ></i>
                          </span>
                        </div>
                        {!isPasswordValid && (
                          <Form.Text className="text-danger">
                            La contraseña no cumple con 10 caracteres, una minuscula,
                            mayuscula, simbolo y número.
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-light">
                          Confirmar Nueva Contraseña
                        </Form.Label>
                        <div className="input-group">
                          <Form.Control
                            type={
                              passwordVisibility.confirmPassword
                                ? "text"
                                : "password"
                            }
                            name="confirmPassword"
                            placeholder="Contraseña Nueva"
                            className="inputsPaswordStyles"
                            value={passwordData.confirmPassword}
                            onChange={handleChange}
                          />
                          <span
                            className="input-group-text"
                            onClick={() =>
                              handlePasswordVisibility("confirmPassword")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={`bi ${
                                passwordVisibility.confirmPassword
                                  ? "bi-eye-slash"
                                  : "bi-eye"
                              }`}
                            ></i>
                          </span>
                        </div>
                        {!passwordsMatch && (
                          <Form.Text className="text-danger">
                            Las contraseñas no coinciden
                          </Form.Text>
                        )}
                      </Form.Group>
                      <div className="d-flex justify-content-center">
                        <Button
                          variant="danger"
                          onClick={handleClose}
                          className="btnSecondary col-md-4 col-sm-4 col-lg-4"
                        >
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </Form>
            </Card.Body>
            <Card.Footer className="text-end d-flex align-items-center justify-content-around bg-transparent border border-0">
              <Button
                variant="danger"
                className="col-sm-4 col-md-5 col-lg-5 btnSecondary"
                onClick={handleCancel}
                disabled={!isModified}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                className="col-sm-8 col-md-5 col-lg-4"
                onClick={handleSave}
                disabled={
                  isLoading ||
                  !isModified ||
                  !isPhoneValid ||
                  !isEmailPaymentValid ||
                  !isEmailValid ||
                  !isCompanyNameValid ||
                  !sendPassword
                }
              >
                {isLoading ? (
                  <img className="loading" src={loadingGif} alt="Cargando..." />
                ) : (
                  "Guardar Cambios"
                )}
              </Button>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </>
  );
}
export default Perfil_Usuario;
