import { useState, useEffect, useRef } from 'react';
import '../../assets/css/luisaChat.css'; // ensure your .loader CSS is here
import luisa_img from '../../assets/img/luisa.png';
import user_img from '../../assets/img/user_pp.png';
import handleLogout from '../../functions/functions';
import axios from 'axios';

interface Message {
  role: string;
  content: string; // May contain HTML (e.g. <span class="loader"></span>)
}

const LuisaChat = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputText, setInputText] = useState('');
  const [isDetailVisible, setIsDetailVisible] = useState(false);

  const luisa_token = localStorage.getItem('luisa_token');
  const luisa_thread = localStorage.getItem('luisa_thread');

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  // Reverse messages from backend so oldest is first in the array
  const reverseAndSetMessages = (data: Message[]) => {
    const reversed = [...data].reverse();
    setMessages(reversed);
  };

  const fetchMessages = async (threadId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/getConversationMessages/${threadId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${luisa_token}`,
          },
        }
      );

      if (!response.data || response.data.length === 0) {
        setMessages([{ role: 'assistant', content: '¡Hola! Soy Luisa. Estoy aquí para ayudarte a encontrar información sobre las comisiones de trabajo. ¿En qué puedo ayudarte hoy?' }]);
      } else {
        reverseAndSetMessages(response.data);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
    }
      console.error('Error fetching conversation messages:', error);
    }
  };

  const createThread = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/createWebLuisaThread`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${luisa_token}`,
          }
        }
      );

      const newThreadId = response.data?.id;
      if (newThreadId) {
        localStorage.setItem('luisa_thread', newThreadId);
        setMessages([{ role: 'assistant', content: '¿Tienes alguna duda sobre las comisiones de trabajo?' }]);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
    }
      console.error('Error creating new thread:', error);
    }
  };

  // On mount
  useEffect(() => {
    if (!luisa_thread) {
      createThread();
    } else {
      fetchMessages(luisa_thread);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Toggle chat detail
  const toggleDetail = () => {
    setIsDetailVisible((prev) => !prev);
  };

  // Send message
  const handleSendMessage = async () => {
    if (!inputText.trim()) return;

    const userMessage = { role: 'user', content: inputText };
    const loadingMessage = {
      role: 'assistant',
      content: `
        <span class="loader">
          <span></span>
          <span></span>
          <span></span>
        </span>
      ` // HTML con tres puntos animados
    };

    setInputText('');
    // 1. Append user message + loader
    setMessages((prev) => [...prev, userMessage, loadingMessage]);

    try {
      const currentThread = localStorage.getItem('luisa_thread');
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/processWebLuisaMessage`,
        {
          thread_id: currentThread,
          message: inputText,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${luisa_token}`,
          }
        }
      );

      reverseAndSetMessages(response.data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
    }
      console.error('Error sending message:', error);
      // Optionally replace the loader message with an error message
    }
  };

  // Clear messages / new thread
  const handleClearMessages = async () => {
    await createThread();
  };

  return (
<>
  <div className="luisa-detail-postion">
    <div className={`luisa-detail ${isDetailVisible ? 'show' : 'hide'}`}>
      <div className="p-3">
        <div className="d-flex justify-content-end py-1">
          <div className="w-100 lead">Luisa Comisiones</div>
          <button
            className="btn-limpiar btn-outline-dark border-0"
            onClick={handleClearMessages}
          >
            Limpiar
          </button>
        </div>

        {/* Messages Container */}
        <div className="luisa-messages" ref={messagesContainerRef}>
          {messages.map((msg, index) => {
            if (msg.role === 'assistant') {
              return (
                <div
                  className="luisa-message-container-1 d-flex w-100"
                  key={index}
                >
                  <img
                    src={luisa_img}
                    className="w-10 luisa-icon"
                    alt="Assistant"
                  />
                  {/* 
                    Use dangerouslySetInnerHTML so that 
                    <span class="loader"></span> is interpreted as HTML 
                  */}
                  <div
                    className={`luisa-message ${
                      msg.role === 'assistant' ? 'ms-2' : 'me-2'
                    } mt-2`}
                    dangerouslySetInnerHTML={{
                      __html: msg.content.replace(
                        /(https?:\/\/[^\s]+)/g, // Detecta enlaces en el texto
                        '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
                      ),
                    }}
                  />
                  {msg.role !== 'assistant' && (
                    <img src={user_img} className="w-10 luisa-icon" alt="User" />
                  )}
                </div>
              );
            } else {
              return (
                <div
                  className="luisa-message-container-2 d-flex w-100"
                  key={index}
                >
                  <div className="luisa-message-2 me-2 mt-2">
                    {msg.content}
                  </div>
                  <img
                    src={user_img}
                    className="w-10 luisa-icon"
                    alt="User"
                  />
                </div>
              );
            }
          })}
        </div>
      </div>

      {/* Input for new messages */}
      <div className="luisa-input-container">
        <div className="input-group ms-2">
          <textarea
            className="form-control luisa-input"
            placeholder="Haz una pregunta"
            aria-describedby="button-addon2"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            id="button-addon2"
            onClick={handleSendMessage}
            style={{ marginRight: '10px' }}
          >
            <i className="bi bi-send-fill p-2 mt-1"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  {/* Toggle Chat Bubble */}
  <div className="luisa-bubble-postion">
    <div className="luisa-bubble-btn bg-custom-1" onClick={toggleDetail}>
      <p className="luisa-bubble-btn-text py-3">💬 Pregúntale a Luisa</p>
    </div>
  </div>
</>
  );
};
export default LuisaChat;