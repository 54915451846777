import TopbarCoparmex from '../main/TopBarCoparmex';
import layout from '../../assets/files/carga_usuarios_layout.csv';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { ResultData } from '../interfaces/UserInterface';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import handleLogout from '../../functions/functions';

const UploadFile = () => {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const [filtro, setFiltro] = useState('');
    const [query, setQuery] = useState('');
    const [fileName, setFileName] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [userResults, setUserResults] = useState<ResultData[]>([]);
    const [filteredResults, setFilteredResults] = useState<ResultData[]>([]);
    const [displayedResults, setDisplayedResults] = useState<ResultData[]>([]);
    const [visibleUpload, setVisibleUpload] = useState<boolean>(true);
    const [visibleTable, setVisibleTable] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 10;

    const navigate = useNavigate();

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile && isValidFileType(selectedFile.name)) {
            setFile(selectedFile);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .xls, .xlsx o .csv');
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile && isValidFileType(droppedFile.name)) {
            setFile(droppedFile);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .xls, .xlsx o .csv');
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const isValidFileType = (fileName: string) => {
        const allowedExtensions = ['xls', 'xlsx', 'csv'];
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        return fileExtension && allowedExtensions.includes(fileExtension);
    };

    const handleRemoveFile = () => {
        setFile(null);
        (document.getElementById('fileInput') as HTMLInputElement).value = '';
    };

    const handleDownload = () => {
        fetch(layout)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = "carga_usuarios_layout.csv";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    const handleUploadFile = async () => {
        if (!file) {
            showWarnNotification('Selecciona un archivo para comenzar con la carga');
            return
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('rfc', `${luisa_rfc}`);

        try {
            //const response = await axios.post(`http://localhost:3333/backend/uploadUsers`, formData, {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/uploadUsers`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=UTF-8',
                    'Authorization': `Bearer ${luisa_token}`
                }
            });
            
            console.log(response);

            if (response.status === 200) {
                const data = response.data;

                const normalizedResults = data.results.map((item: any) => ({
                    ...item,
                    usuario: item.usuario.map((field: any) => (field === null || field === "" ? " - " : field)),
                }));

                setUserResults(normalizedResults);
                setFilteredResults(normalizedResults);
                setTotalPages(Math.ceil(normalizedResults.length / limit));
                setVisibleTable(true);
                setVisibleUpload(false);
            } else {
                showErrorNotification('Ocurrio un error al realizar la carga');
            }

            setFile(null);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.message;
                    if (getError === 'Empty file') {
                        showWarnNotification('El archivo que intentas subir no contiene registros');
                    }
                    else {
                        showErrorNotification('Ocurrio un error al realizar la carga');
                    }
                } else if (error.request) {
                    showErrorNotification('Ocurrio un error al realizar la carga');
                    console.log('Request made but no response received:', error.request);
                } else {
                    showErrorNotification('Ocurrio un error al procesar el archivo');
                    console.log('Error message:', error.message);
                }
            } else {
                showErrorNotification('Ocurrio un error al realizar la carga');
            }
        }
    };

    useEffect(() => {
        const applyFilters = () => {
            let results = [...userResults];

            if (filtro) {
                results = results.filter(user => filtro === 'adicional' ? user.status !== 'error' : user.status === 'error');
            }

            if (query) {
                results = results.filter(user =>
                    user.usuario[0].toLowerCase().includes(query.toLowerCase()) ||
                    user.usuario[3].toLowerCase().includes(query.toLowerCase())
                );
            }

            setFilteredResults(results);
            setTotalPages(Math.ceil(results.length / limit));
            setCurrentPage(1);
        };

        applyFilters();
    }, [filtro, query, userResults]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * limit;
        const endIndex = startIndex + limit;
        setDisplayedResults(filteredResults.slice(startIndex, endIndex));
    }, [currentPage, filteredResults]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const displayField = (field: any) => {
        return field === null || field === "" ? "Sin información" : field;
    };

    // Paginación
    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const getPaginationRange = () => {
        const totalPagesToShow = 2;
        if (totalPages <= totalPagesToShow) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const range = [];
        const leftLimit = Math.max(2, currentPage - 1);
        const rightLimit = Math.min(totalPages - 1, currentPage + 1);

        range.push(1);

        if (leftLimit > 2) {
            range.push("...");
        }

        for (let i = leftLimit; i <= rightLimit; i++) {
            range.push(i);
        }

        if (rightLimit < totalPages - 1) {
            range.push("...");
        }

        range.push(totalPages);

        return range;
    };

    const handlePageClick = (page: number | string) => {
        if (typeof page === 'number') {
            setCurrentPage(page);
        }
    };

    // String acortado
    const truncateName = (name: string, maxLength: number) => {
        if (name.length <= maxLength) {
            return name;
        }
        const extIndex = name.lastIndexOf('.');
        const ext = extIndex !== -1 ? name.substring(extIndex) : '';
        const truncatedName = name.substring(0, maxLength - ext.length - 3);
        return `${truncatedName}...${ext}`;
    };

    // Notificaciones tipo cinta
    const showWarnNotification = (text: String) => {
        toast.warning(text, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showNotification = (text: String) => {
        toast.success(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <ToastContainer />
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4' onClick={() => navigate('/configuration')}><i className="bi bi-arrow-return-left me-3 point"></i>Carga Masiva</h1>
                        <div className="d-flex align-items-center animate__animated animate__fadeIn">
                            <div className="col-md-12 col-lg-12 col-sm-12 mb-4">
                                <div className="linkup-form-bg me-2">
                                    <p>Luisa clasifica usuarios en administradores y afiliados, con accesos y funcionalidades especificas según su rol, incluyendo perfiles personalizados y diversas herramientas</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center animate__animated animate__fadeIn">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="mb-3 linkup-form-bg" style={{ display: visibleUpload ? 'block' : 'none' }}>
                                    <button type="button" className='h-100 btn btn-primary rounded-pill' onClick={handleDownload}>Descargar Excel Carga Masiva</button>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div
                                            className="bg-folder-gray rounded mt-3 pt-4 text-center text-white"
                                            onClick={() => !fileName && document.getElementById('fileInput')?.click()}
                                            onDrop={handleDrop}
                                            onDragOver={handleDragOver}
                                            style={{ cursor: fileName ? 'default' : 'pointer' }}
                                        >
                                            <input
                                                type="file"
                                                id="fileInput"
                                                style={{ display: 'none' }}
                                                accept=".xls,.xlsx,.csv"
                                                onChange={handleFileSelect}
                                            />
                                            <i className="bi bi-folder-plus px-5 mx-1 point bg-folder-gray"></i>
                                            <p className="mt-3">
                                                {file ? (
                                                    <>
                                                        {file.name}
                                                        <button className='btn btn-outline-secondary text-white'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRemoveFile();
                                                            }}
                                                            style={{
                                                                border: 'none',
                                                                background: 'none',
                                                                color: 'white',
                                                                cursor: 'pointer',
                                                                marginLeft: '8px'
                                                            }}
                                                        >
                                                            X
                                                            {/* <i className='bi bi-trash-fill bi-red'></i> */}
                                                        </button>

                                                    </>
                                                ) : (
                                                    'Sube tu archivo Excel aquí'
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <button type="button" onClick={handleUploadFile} className="btn btn-primary col-md-2">Iniciar carga</button>
                                </div>
                                <div className='flex-column align-items-center' style={{ display: visibleTable ? 'block' : 'none' }}>
                                    <div className='row justify-content-center mt-4'>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className='row'>
                                                <div className='col-md-6 col-lg-6 col-sm-12 d-lg-block'>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <Form>
                                                            <Form.Group controlId="dropdownSelect1">
                                                                <Form.Select
                                                                    className={`btn ${filtro === "adicional" ? 'btn-success' : filtro === "incluida" ? 'btn-danger' : 'btn-secondary'}`}
                                                                    value={filtro}
                                                                    onChange={(e) => setFiltro(e.target.value)}
                                                                >
                                                                    <option value="">Ambos</option>
                                                                    <option value="adicional">Aceptados</option>
                                                                    <option value="incluida">Negados</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 searchBar'>
                                                    <div className="input-group h-100">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="bi bi-search"></i>
                                                        </span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Buscar por nombre"
                                                            aria-label="vinculacion"
                                                            aria-describedby="basic-addon1"
                                                            value={query}
                                                            onChange={handleSearchChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-sm-12' id='table'>
                                            <section className="wrapper">
                                                <main className="row title">
                                                    <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                                        <li className="widhtFirstli fw-bold">Nombre completo</li>
                                                        <li className='fw-bold'>RFC</li>
                                                        <li className='d-none d-md-block fw-bold'>Email</li>
                                                        <li className='d-none d-md-block fw-bold'>Telefono</li>
                                                        <li className="d-none d-md-block fw-bold">N° empleado</li>
                                                        <li className="d-none d-md-block fw-bold">Rol</li>
                                                        <li className="d-none d-md-block fw-bold"><i className="bi bi-check-circle bi-green"></i> / <i className="bi bi-x-circle bi-red"></i></li>
                                                    </ul>
                                                </main>
                                            </section>
                                            {displayedResults.map((userData, index) => (
                                                <section key={index} className="row-fadeOut-wrapper">
                                                    {userData.status === "error" && (<Tooltip id="err" ></Tooltip>)}
                                                    <Tooltip id="info"></Tooltip>
                                                    <article className="data-users">
                                                        <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                                            <li className="widhtFirstli"
                                                                data-tooltip-id="info"
                                                                data-tooltip-content={`${userData.usuario[0]} ${userData.usuario[1]} ${userData.usuario[2] || ''}`}
                                                                data-tooltip-place="top"
                                                            >
                                                                {truncateName(`${userData.usuario[0]} ${userData.usuario[1]} ${userData.usuario[2] || ''}`, 18)}
                                                            </li>
                                                            <li>{userData.usuario[3]}</li>
                                                            <li className='d-none d-md-block'
                                                                data-tooltip-id="info"
                                                                data-tooltip-content={userData.usuario[4]}
                                                                data-tooltip-place="top"
                                                            >
                                                                {truncateName(userData.usuario[4], 15)}
                                                            </li>
                                                            <li className='d-none d-md-block'>{userData.usuario[5]}</li>
                                                            <li className='d-none d-md-block'>{userData.usuario[6]}</li>
                                                            <li className='d-none d-md-block'>{userData.usuario[7]}</li>
                                                            <li data-tooltip-id="err"
                                                                data-tooltip-html={userData.message.replace(/, /g, ',<br/>')}
                                                                data-tooltip-place="top"
                                                            >
                                                                {userData.status === "error" ? (
                                                                    <i className="bi bi-x-circle bi-red"></i>
                                                                ) : (
                                                                    <i className="bi bi-check-circle bi-green"></i>
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </article>
                                                </section>
                                            ))}
                                        </div>
                                        <div className="pagination-controls">
                                            {currentPage > 1 && (
                                                <button onClick={handlePreviousPage} className="pagination-button-prev-next">
                                                    <i className="bi bi-chevron-left"></i> Anterior
                                                </button>
                                            )}

                                            {getPaginationRange().map((page, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => typeof page === 'number' && handlePageClick(page)}
                                                    className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                                                    disabled={typeof page !== 'number'}
                                                >
                                                    {page}
                                                </button>
                                            ))}

                                            {currentPage < totalPages && (
                                                <button onClick={handleNextPage} className="pagination-button-prev-next">
                                                    Siguiente <i className="bi bi-chevron-right"></i>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
};

export default UploadFile;
