import React, { useState, RefObject, useEffect } from 'react';
import '../../assets/css/working_commissions.css';
import icon_default from '../../assets/icons/docs/icon-default.png';
import icon_pdf from '../../assets/icons/docs/icon-pdf.png';
import icon_xls from '../../assets/icons/docs/icon-xls.png';
import icon_txt from '../../assets/icons/docs/icon-txt.png';
import icon_audio from '../../assets/icons/docs/icon-audio.png';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { WorkCommissionEntryInterface } from '../interfaces/WorkCommissionEntryInterface';
import DeleteWorkCommissionEntry from './DeleteWorkCommissionEntry';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usePermissions from '../../hooks/usePermissions';
import { hasPermission } from '../../functions/functions';
import EditarPublicacionModal from './WorkingCommissionsEdit';

interface ModalComponentProps {
    showModal: boolean;
    handleCloseModal: () => void;
    modalBodyRef: RefObject<HTMLDivElement>;
    workCommissionRfc: string;
    workCommisionEntryDetail: WorkCommissionEntryInterface[];
    workCommissionEntryId: string;
    fetchWorkingCommissions: () => Promise<void>;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
    showModal,
    handleCloseModal,
    modalBodyRef,
    workCommissionRfc,
    workCommisionEntryDetail,
    workCommissionEntryId,
    fetchWorkingCommissions,
}) => {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const luisa_email = localStorage.getItem("luisa_email");
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [showModalDelete, setModalDelete] = useState(false);
    const [showModalEditar, setModalEditar] = useState(false);
    const [isNotificationActive, setIsNotificationActive] = useState(false);

    const { permissions } = usePermissions(luisa_rfc || '', luisa_email || '');

    const handleOpenModalDelete = () => setModalDelete(true);
    const handleCloseModalDelete = () => setModalDelete(false);

    const handleOpenEditarPublicacion = () => setModalEditar(true);
    const handleCloseEditarPublicacion = () => setModalEditar(false);

    const getFileIcon = (fileName: string) => {
        const extension = fileName.split('.').pop()?.toLowerCase();
        switch (extension) {
            case 'pdf':
                return icon_pdf;
            case 'xls':
            case 'xlsx':
            case 'csv':
                return icon_xls;
            case 'txt':
            case 'doc':
            case 'docx':
                return icon_txt;
            case 'mp3':
            case 'mp4':
            case 'mpeg':
            case 'mpga':
            case 'm4a':
            case 'wav':
            case 'webm':
                return icon_audio;
            default:
                return icon_default;
        }
    };

    const handleDownload = (fileUrl: string) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileUrl.split('/').pop() || 'default_filename';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < workCommisionEntryDetail.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const isPreviousDisabled = currentIndex === 0;
    const isNextDisabled = currentIndex === workCommisionEntryDetail.length - 1;

    useEffect(() => {
        if (showModal) {
            const selectedIndex = workCommisionEntryDetail.findIndex(entry => entry._id === workCommissionEntryId);
            if (selectedIndex !== -1) {
                setCurrentIndex(selectedIndex);
            }
        }
    }, [showModal, workCommissionEntryId, workCommisionEntryDetail]);

    const onModalClose = () => {
        setCurrentIndex(0);
        handleCloseModal();
    };

    const handleDeleteEntry = async () => {
        const entryId = workCommisionEntryDetail[currentIndex]?._id;
        // console.log('id: ' + entryId);
        // console.log('id -click: ' + workCommissionEntryId);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/deleteWorkCommissionEntry/`, {
                entry_id: entryId
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });

            if (response.status === 200) {
                handleCloseModalDelete();
                onModalClose();
                showNotification('Publicación eliminada correctamente');
            } else {
                showErrorNotification('Ocurrio un error al eliminar la publicación');
            }
        } catch (error) {
            showErrorNotification('Ocurrio un error al eliminar la publicación');
            console.error(`Could not delete entry: `, error);
        }
    };


    const showErrorNotification = (text: String) => {
        if (!isNotificationActive) {
            setIsNotificationActive(true);
            toast.error(text, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => setIsNotificationActive(false)
            });
        }
    };
    const showNotification = (text: String) => {
        if (!isNotificationActive) {
            setIsNotificationActive(true);
            toast.success(text, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => setIsNotificationActive(false)
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <Modal
                show={showModal}
                onHide={onModalClose}
                size="lg"
                scrollable
                dialogClassName="custom-modal"
            >
                <Modal.Header>
                    <Modal.Title className="w-100 d-flex align-items-center justify-content-around">
                        <span
                            className="fs-6"
                            style={{
                                cursor: isPreviousDisabled ? 'not-allowed' : 'pointer',
                                color: isPreviousDisabled ? 'gray' : 'inherit'
                            }}
                            onClick={isPreviousDisabled ? undefined : handlePrevious}
                        >
                            <i className="bi bi-arrow-left"></i> Anterior
                        </span>
                        <span className="fw-bold">{workCommisionEntryDetail[currentIndex]?.entry_title}</span>
                        <span
                            className="fs-6"
                            style={{
                                cursor: isNextDisabled ? 'not-allowed' : 'pointer',
                                color: isNextDisabled ? 'gray' : 'inherit'
                            }}
                            onClick={isNextDisabled ? undefined : handleNext}
                        >
                            Siguiente <i className="bi bi-arrow-right"></i>
                        </span>
                    </Modal.Title>
                </Modal.Header>

                <ModalBody ref={modalBodyRef}>
                    <div className="text-center">
                        <p className="text-secondary">
                            {new Date(workCommisionEntryDetail[currentIndex]?.published_date).toLocaleDateString('es-ES', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            })}
                        </p>
                        <img
                            className="w-50"
                            src={workCommisionEntryDetail[currentIndex]?.image_url}
                            alt={workCommisionEntryDetail[currentIndex]?.image}
                        />
                    </div>

                    <div className="--bg-light p-3 mt-3 --shadow-style">
                        <span className="fw-bold text-black">
                            Resumen generado <i className="bi bi-stars"></i>
                        </span>
                        <br />
                        <br />
                        <span className="text-black">{workCommisionEntryDetail[currentIndex]?.gen_ai_summary}</span>
                    </div>

                    <div className="--bg-light p-3 mt-3 --shadow-style" style={{ maxHeight: '300px', overflowY: 'auto', whiteSpace: 'pre-wrap' }}>
                        <span className="fw-bold text-black">Descripción</span>
                        <br />
                        <br />
                        <span className="text-black">{workCommisionEntryDetail[currentIndex]?.description}</span>
                    </div>


                    <h5 className="fw-bolder text-blue mt-4 text-center mb-4">Archivos adjuntos</h5>
                    <div className="row g-3">
                        {workCommisionEntryDetail[currentIndex]?.uploaded_files.map((file, index) => (
                            <div
                                key={index}
                                className="col-lg-6 col-md-12"
                                onClick={() => handleDownload(workCommisionEntryDetail[currentIndex]?.uploaded_files_urls[index])}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className="card --shadow-style --bg-light h-100 border-0">
                                    <div className="card-body d-flex align-items-center --bg-light">
                                        <div className="d-flex align-items-center w-100">
                                            <img
                                                src={getFileIcon(file)}
                                                alt={`Icono de ${file}`}
                                                className="me-3"
                                                style={{ width: '35px' }}
                                            />
                                            <span className="nombre_archivo text-truncate">{file}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ModalBody>

                <ModalFooter>
                    {/* <div className="d-flex justify-content-between w-100">

                        {hasPermission(permissions, "Comisiones", "eliminar") ? (
                            <button className="btn text-danger" onClick={handleOpenModalDelete}>
                                <i className="bi bi-trash"></i> Eliminar
                            </button>
                        ) : (
                            <></>
                        )}
                        <button className="btn btn-primary" onClick={handleOpenEditarPublicacion}>
                            <i className="bi bi-pencil-square"></i> Editar publicación
                        </button>
                    </div> */}
                    {workCommissionRfc === luisa_rfc ? (
                        <div className="d-flex justify-content-between w-100">
                            <button className="btn text-danger" onClick={handleOpenModalDelete}>
                                <i className="bi bi-trash"></i> Eliminar
                            </button>
                            <button className="btn btn-primary" onClick={handleOpenEditarPublicacion}>
                                <i className="bi bi-pencil-square"></i> Editar publicación
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}
                </ModalFooter>
            </Modal>

            {<DeleteWorkCommissionEntry
                show={showModalDelete}
                onHide={handleCloseModalDelete}
                title={workCommisionEntryDetail[currentIndex]?.entry_title}
                onConfirm={() => handleDeleteEntry()}
            />}

            {showModalEditar && (
                <EditarPublicacionModal
                    show={showModalEditar}
                    handleClose={handleCloseEditarPublicacion}
                    workCommisionEntryDetail={workCommisionEntryDetail[currentIndex]}
                    fetchWorkingCommissions={() => fetchWorkingCommissions()}
                    workId={workCommisionEntryDetail[currentIndex]?._id}
                    handleCloseModal={handleCloseModal}
                />
            )}
        </>
    );
};

export default ModalComponent;
