import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TopbarCoparmex from "../main/TopBarCoparmex";
import "../../assets/css/administration.css";
import { useNavigate } from 'react-router-dom';
import CreateWorkCommissionModal from '../modals/CreateWorkCommissionModal';
import EditWorkCommissionModal from '../modals/EditWorkCommissionModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../assets/css/ControlTable.css";

interface WorkCommissionData {
    name: string,
    president: string,
    objetive: string,
    icon: string,
    isDelegate: boolean,
    active?: boolean,
    id?: string,
    businesses_rfc?: string[]
}

const WorkCommission = () => {
    //const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    //const luisa_username = localStorage.getItem('luisa_username');
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [workCommissionInfo, setWorkCommissionInfo] = useState<[object]>([{}]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedCommission, setSelectedCommission] = useState<any>(null);

    const handleNewWorkCommission = async (workCommissionData: WorkCommissionData, resetForm: () => void) => {
        try {
            setIsLoading(true);
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/createWorkCommission`,
                {
                    commission_name: workCommissionData.name,
                    description: workCommissionData.objetive,
                    icon: workCommissionData.icon,
                    admin_business_rfc: workCommissionData.president,
                    is_delegation: workCommissionData.isDelegate
                    //profile_img
                    //rfc: luisa_rfc,
                    //username: luisa_username,
                    //cost: costo,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );
            
            setIsModalOpen(false);
            const isDelegation = workCommissionData.isDelegate ? "Delegación" : "Comisión";
            if (response.status === 200) {
               //fetchUsers();
                showNotification(`La ${isDelegation} ha sido registrada exitosamente`);
                await fetchWorkCommission();  
            } else if (response.status === 409) {
                showErrorNotification(`La ${isDelegation} ya se encuentra registrada con ese nombre`);
            }
                //showNotification('Prueba exitosa');
                await new Promise((resolve) => setTimeout(resolve, 4000));
                setIsLoading(false);
                resetForm();

        } catch (error) {
            setIsLoading(false);
            showErrorNotification('Ocurrió un error al guardar la comisión, intente de nuevo más tarde');
            console.error("Error: ", error);
        }
    }

    //preguntar si usa paginación
    const fetchWorkCommission = async (search = "") => {
        const getAll=true;
        try {
           const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/getAllWorkCommissions`,
                {
                    params: {
                        search,
                        getAll
                    },
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 200) {
                const { commisions, totalCount } = response.data;
                const totalFix = Math.round(totalCount / 10);
                setWorkCommissionInfo(commisions);
                //setTotalPages(totalFix);
            }
        } catch(error){
            showErrorNotification('Ocurrió un error al obtener las comisiones, intente más tarde');
            console.error("Error: ", error);
        }
    };

    const handleEditWorkCommission = async (workCommissionData: WorkCommissionData, resetForm: () => void) => {
        try {
            setIsLoading(true);
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/editWorkCommission`,
                {
                    commission_id: workCommissionData.id, 
                    commission_name:workCommissionData.name, 
                    description: workCommissionData.objetive, 
                    icon:workCommissionData.icon, 
                    admin_business_rfc:workCommissionData.president, 
                    members_rfc:workCommissionData.businesses_rfc, 
                    active: workCommissionData.active, 
                    is_delegation: workCommissionData.isDelegate
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );
            await fetchWorkCommission();  
            setIsLoading(false);
            setIsEditModalOpen(false);
            const isDelegation = workCommissionData.isDelegate ? "Delegación" : "Comisión" 
            showNotification(`${isDelegation} editada exitosamente`);
            
        }
        catch (error){
            setIsLoading(false);
            showErrorNotification('Ocurrió un error al editar, intente más tarde');
            console.error("Error: ", error);
        }
    }

    const handleDeleteWorkCommission = async (workCommissionData: WorkCommissionData) => {
        try {
            setIsLoading(true);
            console.log("el id a borrar es: ", workCommissionData.id);
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/deleteWorkCommission`,
                {
                    commission_id: workCommissionData.id, 
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );
            setIsLoading(false);
            const isDelegation = workCommissionData.isDelegate ? "Delegación" : "Comisión" 
            showNotification(`${isDelegation} eliminada exitosamente`);
            setIsEditModalOpen(false);
            await fetchWorkCommission();
        }
        catch (error){
            setIsLoading(false);
            showErrorNotification('Ocurrió un error al eliminar, intente más tarde');
            console.error("Error: ", error);
        }
    }

    const showNotification = (text: String) => {
        toast.success(text, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    
    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        fetchWorkCommission(search);
    }, [search]);

    return (
        <>
            <TopbarCoparmex />
            <ToastContainer />
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className="py-3 px-5 linkup-bg ">
                        <div className="row justify-content-center">
                            <div className='col-sm-10 col-md-12 col-lg-9'>
                                <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'>
                                    <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/administracion')}>
                                        <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                                    </svg>
                                    Comisiones de Trabajo
                                </h1>
                            
                                <div className="mb-5 d-flex justify-content-between align-items-end">
                                    {/* <button className="btn btn-primary" onClick={handleOpenModal}> */}
                                    <button className="btn btn-primary" onClick={() => setIsModalOpen(true)}>
                                        + Nueva Comisión
                                    </button>
                                    
                                    <div className='col-lg-6 col-md-6 col-sm-12 searchBar'>
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="bi bi-search"></i>
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Buscar comisiones de trabajo o delegación"
                                                aria-label="vinculacion"
                                                aria-describedby="basic-addon1"
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                        
                                <div className="row same-height-cards">
                                        {workCommissionInfo && workCommissionInfo.length > 0 && (
                                            workCommissionInfo.map((dato: any, index) => (
                                                
                                                <div key={dato._id || `temp-${index}-${Math.random()}`} className="col-md-6 col-lg-6 col-sm-12 mb-3 " 
                                                onClick={() => {
                                                    setSelectedCommission(dato); // Guarda la info del dato seleccionado
                                                    setIsEditModalOpen(true); // Abre el modal
                                                }}>
                                                    <div className="mb-3 linkup-form-bg cards-style card-item" >
                                                        <h2 className="text-blue">{dato.commission_name} {dato.icon}</h2>
                                                        <h5>Presidente: {dato.full_name}</h5>
                                                        <h6>Empresa RFC: {dato.admin_business_rfc}</h6>
                                                        <p className="mb- mt-4 card-description">Descripción: {dato.description}</p>
                                                        <div className="d-flex">
                                                            <span className="me-3">Socios: {dato.members_rfc?.length || 0}</span>
                                                            <span className={`px-3 py-1 me-3 ms-auto ${dato.active ? '--style-active' : '--status-style-devolucion'}`}>
                                                                {dato.active ? "activo" : "no activo"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    
            <CreateWorkCommissionModal show={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                onSave={(workCommissionData, resetForm) => {
                    handleNewWorkCommission(workCommissionData, resetForm);
                    
                }}
                isLoading = {isLoading}
            />
            <EditWorkCommissionModal show={isEditModalOpen}
                onClick={() => {
                    setIsEditModalOpen(false);
                }}
                onEdit={(info, resetForm) => {
                    handleEditWorkCommission(info, resetForm);
                    
                }}
                onDelete={(info) => {
                    handleDeleteWorkCommission(info);
                    
                }}
                dato = {selectedCommission}
                isLoading = {isLoading}
            />
        </>
    );
};

export default WorkCommission;