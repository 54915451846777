import axios from 'axios';
import { useEffect, useState } from 'react';
import loadingGif from '../../assets/img/loading.gif';
import { Button, Form, Modal, } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip'
import config from '../../config/config';
import { Slider } from '@mui/material';
import ConfirmationModal from '../modals/ConfirmationModal';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { Base, Files } from '../interfaces/KnowledgeInterface';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';
import "../../assets/css/KnowledgeTab.css";
import { truncateFileName } from '../../functions/functions';
import handleLogout from '../../functions/functions';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js`;

const KnowledgeTab = () => {

    /* Set app data */
    const luisa_token = localStorage.getItem('luisa_token');

    const [isLoading, setIsLoading] = useState(false);

    /*Knowledge Modals*/
    const [showAddBaseModal, setShowAddBaseModal] = useState(false);
    const [showFileModal, setShowFileModal] = useState(false);
    const [modalPdfUrl, setModalPdfUrl] = useState<string | null>(null);

    /* Knowledge Data */
    const [basesConocimiento, setBases] = useState<Base[]>([]);
    const [selectedBase, setSelectedBase] = useState<Base | null>(null);
    const [baseName, setBaseName] = useState('');
    const [originalBaseName, setOriginalBaseName] = useState('');
    const [selectedFile, setSelectedFile] = useState<Files | null>(null);
    const [selectedFilesModal, setSelectedFilesModal] = useState<File[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const handleBaseNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => setBaseName(event.target.value);
    const [sizeResponse, setSizeResponse] = useState('2');
    const [wordNumber, setWordNumber] = useState('150');
    const [fileDate, setFileDate] = useState('');
    const [totalSize, setTotalSize] = useState(0);
    const maxFileSize = 5 * 1024 * 1024;
    const maxTotalSize = 50 * 1024 * 1024;
    //Delete
    const [deleteInfo, setDeleteInfo] = useState<{ file: string | null, baseName: string | null }>({ file: null, baseName: null });
    const [selectedFilesDelete, setSelectedFilesDelete] = useState<string[]>([]);
    const [baseAssistantId, setBaseAssistantId] = useState('');
    //Sync
    const [isSyncing, setIsSyncing] = useState(false);
    //Tokens
    const [knowledgeTokens, setTokens] = useState(0);
    const [tokenPoints, setTokenPoints] = useState(0);
    const ganancia = 0.65;
    const valorTokenInput = 0.0000005;
    const multiply = 20;
    const precioGojiPoints = 0.0001;
    //Webscrap
    const [inputValue, setInputValue] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    const [titleModalAdd, setTitleModalAdd] = useState('');
    const [showModalConfirmDeleteBase, setConfirmDeleteBase] = useState(false);
    const [confirmModalType, setConfirmModalType] = useState('✅')
    const [confirmModalText, setConfirmModalText] = useState('Resultado de la Operación')
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    const [options, setOptions] = useState<Base[]>([]);
    const navigate = useNavigate();


    //Mostrar barra de web scraping
    const handleFocus = () => {
        setIsExpanded(true);
    };
    //Ocultar barra de web scraping
    const handleBlur = () => {
        if (!inputValue) {
            setIsExpanded(false);
        }
    };

    //Evento para añadir Base
    const handleAddBase = () => {
        setTitleModalAdd("Añadir Base de Conocimiento");
        setShowAddBaseModal(true);
    }
    //Evento para modificar Base
    const handleEditBase = (nombre: string) => {
        setBaseName(nombre);
        setOriginalBaseName(nombre);
        setTitleModalAdd("Editar Base de Conocimiento");
        setShowAddBaseModal(true);
    }
    //Evento para cerrar modal de añadir Base
    const handleCloseAddBaseModal = () => {
        setShowAddBaseModal(false);
        setBaseName("");
        setSelectedFilesModal([]);
        setTotalSize(0);
    };
    
    //Evento para cerrar modal de editar archivo
    const handleCloseFileModal = () => {
        setShowFileModal(false);
        setSelectedFile(null);
        setModalPdfUrl(null);
    };

    //Guardar el número de resultados
    const handleSliderResponse = (e: Event, value: number | number[]) => {
        setSizeResponse(value.toString());
    };
    //Guardar el número la longitud del contexto
    const handleSliderWord = (e: Event, value: number | number[]) => {
        setWordNumber(value.toString());
    };

    //Mostrar los archivos de la Base
    const handleShowFiles = async (baseName: string) => {
        try {
            //const response = await axios.post(`https://development.goji-mx.cloud/backend/v1/knowledgebases/getBusinessKnowledgeBasesFiles`, {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/getBusinessKnowledgeBasesFiles`, {
                //business_id: gojimx_businessId,
                business_id: '5215580177109',
                knowledge_base_nickname: baseName
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });

            if (response.status === 200) {
                const archivoBase = response.data;
                const fecha = archivoBase.archivos[0].fecha;
                const fechaFormateada = new Date(fecha);
                const dia = fechaFormateada.getDate();
                const mes = fechaFormateada.toLocaleString('es-ES', { month: 'short' });
                const anio = fechaFormateada.getFullYear();
                const fechaFull = dia + '-' + mes + '-' + anio;
                setFileDate(fechaFull);
                if (archivoBase.mensaje === 'No tiene archivos relacionados') {
                    archivoBase['stat'] = 'no-sync';
                } else {
                    archivoBase['stat'] = 'ok';
                }
                archivoBase['baseName'] = baseName;
                setSelectedBase(archivoBase);
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    setConfirmModalText('Base de conocimiento')
                    setConfirmModalType('❌');
                    setModalMessage('Ocurrió un error al obtener los archivos de la base, intenta de nuevo más tarde.');
                    setIsModalOpen(true);
                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    };

    //Mostrar modal del detalle de un archivo
    const handleFileClick = async (file: Files) => {
        setSelectedFile(file);
        setModalPdfUrl(file.file_url);
        setShowFileModal(true);
    };

    //Añadir un nuevo archivo a una nueva Base
    const handleFileChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
        let valid = true;
        let newFiles = [...selectedFilesModal];
        let newSize = totalSize;

        if (totalSize > maxTotalSize) {
            valid = false;
            setShowAddBaseModal(false);
            setConfirmModalType('⚠️');
            setModalMessage('El límite es de 25MB por Base');
            setIsModalOpen(true);
        }

        const files = Array.from(event.target.files || []);
        const validFiles = files.filter(file =>
            (file.name.endsWith('.pdf') || file.name.endsWith('.txt')) &&
            !selectedFilesModal.some(selectedFile => selectedFile.name === file.name)
        );

        // if (files.length > 5 || selectedFilesModal.length > 4) {
        //     valid = false;
        //     setShowAddBaseModal(false);
        //     setConfirmModalType('⚠️');
        //     setModalMessage('El límite es de 5 archivos por Base');
        //     setIsModalOpen(true);
        // }

        files.forEach((file) => {
            if (file.size > maxFileSize) {
                valid = false;
                setShowAddBaseModal(false);
                setConfirmModalType('⚠️');
                setModalMessage('Solo se aceptan archivos de máximo 5MB');
                setIsModalOpen(true);
            } else if (!newFiles.some((f) => f.name === file.name && f.size === file.size)) {
                newFiles.push(file);
                newSize += file.size;
            }
        });

        if (valid) {
            if (validFiles.length < files.length) {
                setShowAddBaseModal(false);
                setConfirmModalType('⚠️');
                setModalMessage('Solo se aceptan archivos PDF y TXT, y no se permiten archivos duplicados');
                setIsModalOpen(true);
            }
            setTotalSize(newSize);
            setSelectedFilesModal([...selectedFilesModal, ...validFiles]);
        }
    };
    //Añadir un nuevo archivo a una Base existente
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        const validFiles = files.filter(file =>
            (file.name.endsWith('.pdf') || file.name.endsWith('.txt')) &&
            !selectedFiles.some(selectedFile => selectedFile.name === file.name)
        );

        if (validFiles.length < files.length) {
            setShowAddBaseModal(false);
            setConfirmModalType('⚠️');
            setModalMessage('Solo se aceptan archivos PDF y TXT, y no se permiten archivos duplicados');
            setIsModalOpen(true);
        }

        setSelectedFiles([...selectedFiles, ...validFiles]);
        updateSelectedBaseStat('no-sync');
    };

    //Actualizar estatus de una Base
    const updateSelectedBaseStat = (newStat: string) => {
        if (selectedBase) {
            const updatedBase = { ...selectedBase, stat: newStat };
            setSelectedBase(updatedBase);
        }
    };
    //Quitar un archivo de una nueva Base
    const removeFileModal = (index: number) => {
        const fileToRemove = selectedFilesModal[index];
        if (fileToRemove) {
            setSelectedFilesModal(selectedFilesModal.filter((_, i) => i !== index));
            setTotalSize((prevSize) => prevSize - fileToRemove.size);
        }
    };
    //Quitar un archivo de una Base existente
    const removeFile = (index: number) => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
        if (selectedFiles.length === 1) {
            updateSelectedBaseStat('ok');
        }
    };
    
    //Mostrar todas las Bases de conocimiento existentes
    const fetchBases = async (isEdit?: boolean) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/getBusinessKnowledgeBases`, {
            //const response = await axios.post(`https://development.goji-mx.cloud/backend/v1/knowledgebases/getBusinessKnowledgeBases`, {
                //business_id: gojimx_businessId
                business_id: '5215580177109'
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });

            if (response.status === 200) {
                if (response.data.mensaje === "No tiene bases de conocimiento relacionadas") {
                    console.log('No hay bases');
                    setSelectedBase(null);
                    setBases([]);
                    setOptions([]);
                } else {
                    const baseData = response.data.bases;
                    setOptions(baseData);
                    setBases(baseData);
                    if (baseData.length > 0 && isEdit === undefined) {
                        handleShowFiles(baseData[0].knowledge_base_nickname);
                    }
                }
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            setConfirmModalText('Base de conocimiento')
            if (axios.isAxiosError(error)) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        console.log('Error de respuesta:', error.response);
                    } else if (error.request) {
                        console.log('Se hizo el request pero no hubo respuesta:', error.request);
                    } else {
                        console.log('Error:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            } else {
                setConfirmModalType('❌');
                setModalMessage('Ocurrió un error al cargar tus bases de conocimiento, intenta de nuevo más tarde.');
                setIsModalOpen(true);
                console.error('Error:', error);
            }
        }
    };

    //Obtener estimacion para crear una Base
    const getTokenEstimation = async () => {
        if (selectedFilesModal.length === 0) {
            setShowAddBaseModal(false);
            setConfirmModalType('⚠️');
            setConfirmModalText('Aviso')
            setModalMessage('Selecciona al menos un archivo');
            setIsModalOpen(true);
            return;
        }
        if (baseName === '') {
            setShowAddBaseModal(false);
            setConfirmModalType('⚠️');
            setConfirmModalText('Aviso')
            setModalMessage('Ingresa el nombre de la Base');
            setIsModalOpen(true);
            return;
        }
        if (baseName === '') {
            setShowAddBaseModal(false);
            setConfirmModalType('⚠️');
            setConfirmModalText('Aviso')
            setModalMessage('Ingresa el nombre de la Base');
            setIsModalOpen(true);
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        if (selectedFilesModal.length === 1) {
            /* Single File */
            try {
                formData.append('file', selectedFilesModal[0]);
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/getTokenEstimation`, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });

                if (response.status === 200) {
                    const responsePoints = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getRenataPoints`, {
                        businessId: '5215580177109'
                    },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${luisa_token}`
                            }
                        });
                    setTokenPoints(Number(responsePoints.data.renata_points));

                    const tokens = response.data.data.tokens;
                    const usageInput = ((tokens / (1 - ganancia)) * valorTokenInput) * multiply;
                    const usage_ammount = usageInput / precioGojiPoints;
                    setTokens(usage_ammount);
                    setShowAddBaseModal(false);
                    setConfirmModalType('ℹ️');
                    setConfirmModalText('Estimación');
                    setModalMessage("Los puntos necesarios para subir el archivo son: " + (usage_ammount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
                    setIsModalOpen(true);
                }

            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                setShowAddBaseModal(false);
                setConfirmModalText('Base de conocimiento')
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        if (getError === '[Renata-Backend] Knowledge Base Error: File is Empty') {
                            setConfirmModalType('⚠️');
                            setConfirmModalText('Aviso')
                            setModalMessage('El archivo que intentas subir esta vacio');
                            setIsModalOpen(true);
                        }
                        else {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
                            setIsModalOpen(true);
                        }
                    } else if (error.request) {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
                        setIsModalOpen(true);
                        console.log('Request made but no response received:', error.request);
                    } else {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
                        setIsModalOpen(true);
                        console.log('Error message:', error.message);
                    }
                } else {
                    setConfirmModalType('❌');
                    setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
                    setIsModalOpen(true);
                    console.error('An unknown error occurred:', error);
                }
            }
        } else {
            /* Multiple File */
            try {
                formData.append('business_id', '5215580177109');
                formData.append('knowledge_base_nickname', baseName);
                selectedFilesModal.forEach(file => {
                    formData.append('files', file);
                });
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/getTokenEstimationMultiFile`, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });

                if (response.status === 200) {
                    const tokens = response.data.data.tokens;
                    const usageInput = ((tokens / (1 - ganancia)) * valorTokenInput) * multiply;
                    const usage_ammount = usageInput / precioGojiPoints;
                    setTokens(usage_ammount);
                    setShowAddBaseModal(false);
                    setConfirmModalType('ℹ️');
                    setConfirmModalText('Estimación')
                    setModalMessage("Los puntos necesarios para subir el archivo son: " + (usage_ammount).toFixed(2));
                    setIsModalOpen(true);
                }

            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                setShowAddBaseModal(false);
                setConfirmModalText('Base de conocimiento')
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        if (getError === '[Renata-Backend] Knowledge Base Error: File is Empty') {
                            setConfirmModalType('⚠️');
                            setConfirmModalText('Aviso')
                            setModalMessage('El archivo que intentas subir esta vacio');
                            setIsModalOpen(true);
                        }
                        else {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
                            setIsModalOpen(true);
                        }
                    } else if (error.request) {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
                        setIsModalOpen(true);
                        console.log('Request made but no response received:', error.request);
                    } else {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
                        setIsModalOpen(true);
                        console.log('Error message:', error.message);
                    }
                } else {
                    setConfirmModalType('❌');
                    setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
                    setIsModalOpen(true);
                    console.error('An unknown error occurred:', error);
                }
            }
        }
        setIsLoading(false);
    };

    //Agregar una nueva Base
    const addNewBase = async () => {
        setIsLoading(true);
        setConfirmModalText('Base de conocimiento');
        console.log(baseName);
        
        if (basesConocimiento.length === 5) {
            setConfirmModalType('⚠️');
            setConfirmModalText('Aviso');
            setModalMessage('Alcanzaste el número máximo de bases de conocimiento.');
            setIsModalOpen(true);
        }else if (baseName === ''){
            setShowAddBaseModal(false);
            setConfirmModalType('⚠️');
            setModalMessage('Escribe un nombre antes de continuar');
            setIsModalOpen(true);
        }else if (selectedFilesModal.length === 0){
            setShowAddBaseModal(false);
            setConfirmModalType('⚠️');
            setModalMessage('Selecciona al menos un archivo para continuar');
            setIsModalOpen(true);
        }
        // else if (knowledgeTokens > tokenPoints) {
        //     setConfirmModalType('⚠️');
        //     setConfirmModalText('Aviso')
        //     setModalMessage('No tienes suficientes puntos para añadir esta base de conocimiento');
        //     setIsModalOpen(true);
        // } 
        else {
            // const estimation = handleDeductionTransaction(knowledgeTokens);
            // const estimationState = (await estimation).message;
            //if (estimationState === 'transaction saved successfully') {

                const formData = new FormData();
                // console.log(selectedFilesModal);
                
                if (selectedFilesModal.length === 1) {
                    /* Single File */
                    try {
                        formData.append('file', selectedFilesModal[0]);
                        formData.append('business_id', '5215580177109');
                        formData.append('knowledge_base_nickname', baseName);
                        formData.append('num_answer', sizeResponse);
                        formData.append('custom_chunck_size', wordNumber);
                        const response_base = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/createKnowledgeBase`, formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Authorization': `Bearer ${luisa_token}`
                                }
                            });

                        if (response_base.status === 200) {
                            setConfirmModalType('✅');
                            setConfirmModalText('Base de conocimiento')
                            setModalMessage('La base de conocimiento ha sido creada correctamente');
                            setIsModalOpen(true);
                            handleCloseAddBaseModal();
                            fetchBases();
                        }

                    } catch (error) {
                        if (axios.isAxiosError(error) && error.response) {
                            const status = error.response.status;
                            if (status === 401 || status === 403) {
                                console.log("Token expirado, cerrando sesión...");
                                handleLogout();
                            }
                        }
                        if (axios.isAxiosError(error)) {
                            if (error.response) {
                                const getError = error.response.data.error;
                                handleCloseAddBaseModal()
                                setConfirmModalText('Base de conocimiento')
                                if (getError === '[Renata-Backend] Knowledge Base Error: Max Knowledge Bases reached!!') {
                                    setConfirmModalType('⚠️');
                                    setModalMessage('Alcanzaste el número máximo de bases de conocimiento.');
                                    setIsModalOpen(true);
                                }
                                else {
                                    setConfirmModalType('❌');
                                    setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                                    setIsModalOpen(true);
                                }
                            } else if (error.request) {
                                setConfirmModalType('❌');
                                setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                                setIsModalOpen(true);
                                console.log('Request made but no response received:', error.request);
                            } else {
                                setConfirmModalType('❌');
                                setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                                setIsModalOpen(true);
                                console.log('Error message:', error.message);
                            }
                        } else {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                            setIsModalOpen(true);
                            console.error('An unknown error occurred:', error);
                        }
                    }
                } else {
                    /* Multiple files */
                    try {
                        formData.append('business_id', '5215580177109');
                        formData.append('knowledge_base_nickname', baseName);
                        formData.append('num_answer', sizeResponse);
                        formData.append('custom_chunck_size', wordNumber);
                        selectedFilesModal.forEach(file => {
                            formData.append('files', file);
                        });
                        const response_base = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/createKnowledgeBase_multifile`, formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Authorization': `Bearer ${luisa_token}`
                                }
                            });

                        if (response_base.status === 200) {
                            setConfirmModalType('✅');
                            setConfirmModalText('Base de conocimiento')
                            setModalMessage('La base de conocimiento ha sido creada correctamente');
                            setIsModalOpen(true);
                            handleCloseAddBaseModal();
                            fetchBases();
                        }

                    } catch (error) {
                        if (axios.isAxiosError(error) && error.response) {
                            const status = error.response.status;
                            if (status === 401 || status === 403) {
                                console.log("Token expirado, cerrando sesión...");
                                handleLogout();
                            }
                        }
                        if (axios.isAxiosError(error)) {
                            if (error.response) {
                                const getError = error.response.data.error;
                                handleCloseAddBaseModal()
                                setConfirmModalText('Base de conocimiento')
                                if (getError === '[Renata-Backend] Knowledge Base Error: Max Knowledge Bases reached!!') {
                                    setConfirmModalType('⚠️');
                                    setModalMessage('Alcanzaste el número máximo de bases de conocimiento.');
                                    setIsModalOpen(true);
                                }
                                else {
                                    setConfirmModalType('❌');
                                    setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                                    setIsModalOpen(true);
                                }
                            } else if (error.request) {
                                setConfirmModalType('❌');
                                setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                                setIsModalOpen(true);
                                console.log('Request made but no response received:', error.request);
                            } else {
                                setConfirmModalType('❌');
                                setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                                setIsModalOpen(true);
                                console.log('Error message:', error.message);
                            }
                        } else {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                            setIsModalOpen(true);
                            console.error('An unknown error occurred:', error);
                        }
                    }
                }
            // } else {
            //     setConfirmModalText('Base de conocimiento')
            //     setConfirmModalType('❌');
            //     setModalMessage('Ocurrió un error al procesar tu pago, ponte en contacto con un administrador.');
            //     setIsModalOpen(true);
            // }
        }
        setIsLoading(false);
    };

    //Agregar un archivo a una Base existente
    const addFileToBase = async (baseName: string) => {
        const formData = new FormData();
        formData.append('business_id', '5215580177109');
        formData.append('knowledge_base_nickname', baseName);
        setIsSyncing(true);
        updateSelectedBaseStat('sync');

        if (selectedFiles.length === 0 && inputValue === "") {
            setConfirmModalType('⚠️');
            setConfirmModalText('Sincronizacion de Archivo')
            setModalMessage('Selecciona al menos un archivo o ingresa una url para sincronizar');
            setIsModalOpen(true);
            updateSelectedBaseStat('ok');
            setIsSyncing(false);
            return;
        }

        if (inputValue.indexOf('https://es.wikipedia.org/wiki') > -1) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/uploadURLText2KnowledgeBase`, {
                    business_id: '5215580177109',
                    knowledge_base_nickname: baseName,
                    url_to_scrap: inputValue
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });

                if (response.status === 200) {
                    setConfirmModalType('✅');
                    setConfirmModalText('Sincronizacion de Archivo')
                    setModalMessage('El archivo fue vectorizado exitosamente a la base de conocimiento');
                    setIsModalOpen(true);
                    handleShowFiles(baseName);
                    setInputValue('');
                    updateSelectedBaseStat('ok');
                    setIsExpanded(false);
                }
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                handleCloseAddBaseModal();
                setConfirmModalText('Sincronizacion de Archivo');
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al subir el archivo al generar el archivo, ponte en contacto con un administrador.');
                        setIsModalOpen(true);
                    }
                }
                updateSelectedBaseStat('ok');
            }
        } else if (!(inputValue.indexOf('https://es.wikipedia.org/wiki') < -1) && inputValue !== "") {
            setConfirmModalType('⚠️');
            setConfirmModalText('Sincronizacion de Archivo')
            setModalMessage('Ingresa una url válida');
            setIsModalOpen(true);
            updateSelectedBaseStat('no-sync');
        } else {

            if (selectedFiles.length === 1) {
                try {
                    formData.append('file', selectedFiles[0]);
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/uploadFile2KnowledgeBase`, formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': `Bearer ${luisa_token}`
                            }
                        });

                    if (response.status === 200) {
                        setConfirmModalType('✅');
                        setConfirmModalText('Sincronizacion de Archivo')
                        setModalMessage('El archivo \'' + selectedFiles[0].name + '\' fue vectorizado exitosamente a la base de conocimiento');
                        setIsModalOpen(true);
                        handleShowFiles(baseName);
                        setSelectedFiles([]);
                    }

                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        const status = error.response.status;
                        if (status === 401 || status === 403) {
                            console.log("Token expirado, cerrando sesión...");
                            handleLogout();
                        }
                    }
                    handleCloseAddBaseModal();
                    setConfirmModalText('Sincronizacion de Archivo');
                    if (axios.isAxiosError(error)) {
                        if (error.response) {
                            const getError = error.response.data.error;
                            if (getError === '[Renata-Backend] Knowledge Base Error: MaxFiles for Knowledge Bases reached!!') {
                                setConfirmModalType('⚠️');
                                setModalMessage('Alcanzaste el número máximo de archivos por base de conocimiento.');
                                setIsModalOpen(true);
                            }
                            else {
                                setConfirmModalType('❌');
                                setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
                                setIsModalOpen(true);
                            }
                        } else if (error.request) {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
                            setIsModalOpen(true);
                            console.log('Request made but no response received:', error.request);
                        } else {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
                            setIsModalOpen(true);
                            console.log('Error message:', error.message);
                        }
                    } else {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
                        setIsModalOpen(true);
                        console.error('An unknown error occurred:', error);
                    }
                    setSelectedFiles([]);
                    updateSelectedBaseStat('ok');
                }
                handleShowFiles(baseName);
            } else {
                try {
                    selectedFiles.forEach(file => {
                        formData.append('files', file);
                    });
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/uploadMultiFiles2KnowledgeBase`, formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': `Bearer ${luisa_token}`
                            }
                        });

                    if (response.status === 200) {
                        setConfirmModalType('✅');
                        setConfirmModalText('Sincronizacion de Archivo')
                        setModalMessage('Los archivos han sido vectorizados exitosamente a la base de conocimiento');
                        setIsModalOpen(true);

                        setSelectedFiles([]);
                    }

                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        const status = error.response.status;
                        if (status === 401 || status === 403) {
                            console.log("Token expirado, cerrando sesión...");
                            handleLogout();
                        }
                    }
                    handleCloseAddBaseModal();
                    setConfirmModalText('Sincronizacion de Archivo');
                    if (axios.isAxiosError(error)) {
                        if (error.response) {
                            const getError = error.response.data.error;
                            if (getError === '[Renata-Backend] Knowledge Base Error: MaxFiles for Knowledge Bases reached!!') {
                                setConfirmModalType('⚠️');
                                setModalMessage('Solo puedes tener 5 archivos por base de conocimiento.');
                                setIsModalOpen(true);
                            }
                            else {
                                setConfirmModalType('❌');
                                setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
                                setIsModalOpen(true);
                            }
                        } else if (error.request) {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
                            setIsModalOpen(true);
                            console.log('Request made but no response received:', error.request);
                        } else {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
                            setIsModalOpen(true);
                            console.log('Error message:', error.message);
                        }
                    } else {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
                        setIsModalOpen(true);
                        console.error('An unknown error occurred:', error);
                    }
                    updateSelectedBaseStat('ok');
                    // setSelectedFiles([]);
                    // fetchBases();
                }
            }
        }
        handleShowFiles(baseName);
        setIsSyncing(false);
    }

    //Actualizar el nombre de una Base
    const updateBaseName = async () => {

        try {
            //TODO Agregar servicio de actualizacion de nombre y actualizar modales
            if (baseName === '') {
                setShowAddBaseModal(false);
                setConfirmModalType('⚠️');
                setModalMessage('Ingresa el nombre de la Base');
                setIsModalOpen(true);
                return;
            }
            setIsLoading(true);
            const data = {
                business_id: '5215580177109',
                knowledge_base_nickname: originalBaseName,
                new_nickname: baseName
            }

            const response = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/editKnowledgeBaseNickname`, data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });

            if (response.status === 200) {
                fetchBases(true);
                handleCloseAddBaseModal()
                setConfirmModalType('✅');
                setConfirmModalText('Base de conocimiento')
                setModalMessage('El nombre de la base fue actualizado correctamente');
                setIsModalOpen(true);
                handleShowFiles(baseName);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            setConfirmModalType('❌');
            setConfirmModalText('Base de conocimiento')
            setModalMessage('Ocurrió un error al actualizar el nombre de la base, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.error('Error changing base name:', error);
        }

        setIsLoading(false);
    };

    //Eliminar archivo de una Base
    const handleFileDelete = async () => {
        const len = selectedBase?.archivos.length;
        if (len === 1) {
            setConfirmModalType('❌');
            setConfirmModalText('Base de conocimiento')
            setModalMessage('La base de conocimiento debe tener al menos un archivo');
            setIsModalOpen(true);
        } else {
            if (!deleteInfo.file || !deleteInfo.baseName) return;
            try {
                const bName = deleteInfo.baseName;
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/deleteFileFromKnowledgeBase`, {
                    business_id: '5215580177109',
                    knowledge_base_nickname: deleteInfo.baseName,
                    file_name: deleteInfo.file
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });
                if (response.status === 200) {
                    setConfirmModalType('✅');
                    setConfirmModalText('Base de conocimiento')
                    setModalMessage('El archivo fue eliminado correctamente');
                    setIsModalOpen(true);
                    handleShowFiles(bName);
                }
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                setConfirmModalText('Base de conocimiento')
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
                        setIsModalOpen(true);
                        console.log(getError);
                    } else if (error.request) {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
                        setIsModalOpen(true);
                        console.log('Request made but no response received:', error.request);
                    } else {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
                        setIsModalOpen(true);
                        console.log('Error message:', error.message);
                    }
                } else {
                    setConfirmModalType('❌');
                    setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
                    setIsModalOpen(true);
                    console.error('An unknown error occurred:', error);
                }
            }
        }
    };

    //Eliminar multiples archivos de una Base
    const handleMultiFileDelete = async () => {
        //TODO Actualizar el request al servicio correspondiente e implementar try catch
        if (selectedFilesDelete.length === 0) return;
        if (!deleteInfo.file || !deleteInfo.baseName) return;
        const bName = deleteInfo.baseName;
        if (selectedFilesDelete.length === 1) {
            let data = JSON.stringify({
                "business_id": '5215580177109',
                "knowledge_base_nickname": deleteInfo.baseName,
                "file_name": selectedFilesDelete
            });

            let configuration = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/deleteFileFromKnowledgeBase`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${luisa_token}`
                },
                data: data
            };

            axios.request(configuration)
                .then((response) => {
                    if (response.status === 200) {
                        setConfirmModalType('✅');
                        setConfirmModalText('Base de conocimiento')
                        setModalMessage('Archivo eliminado correctamente');
                        setIsModalOpen(true);
                        handleShowFiles(bName);
                    } else {
                        setConfirmModalType('❌');
                        setConfirmModalText('Base de conocimiento')
                        setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
                        setIsModalOpen(true);
                        console.log(JSON.stringify(response.data));
                    }
                })
                .catch((error) => {
                    if (axios.isAxiosError(error) && error.response) {
                        const status = error.response.status;
                        if (status === 401 || status === 403) {
                            console.log("Token expirado, cerrando sesión...");
                            handleLogout();
                        }
                    }
                    console.log(error);
                });
        } else {

            let data = JSON.stringify({
                "business_id": '5215580177109',
                "knowledge_base_nickname": deleteInfo.baseName,
                "file_name": selectedFilesDelete
            });

            let configuration = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/deleteMultiFileFromKnowledgeBase`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${luisa_token}`
                },
                data: data
            };

            axios.request(configuration)
                .then((response) => {
                    if (response.status === 200) {
                        setConfirmModalType('✅');
                        setConfirmModalText('Base de conocimiento')
                        setModalMessage('Archivo eliminado correctamente');
                        setIsModalOpen(true);
                        handleShowFiles(bName);
                    } else {
                        setConfirmModalType('❌');
                        setConfirmModalText('Base de conocimiento')
                        setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
                        setIsModalOpen(true);
                        console.log(JSON.stringify(response.data));
                    }
                })
                .catch((error) => {
                    if (axios.isAxiosError(error) && error.response) {
                        const status = error.response.status;
                        if (status === 401 || status === 403) {
                            console.log("Token expirado, cerrando sesión...");
                            handleLogout();
                        }
                    }
                    console.log(error);
                });
        }
        setSelectedFilesDelete([]);
    };

    //Evento para abrir modal de eliminar archivo
    const handleFileDeleteModal = () => {
        setShowFileModal(false);
        setConfirmModalType('❌');
        setConfirmModalText('Eliminar archivo')
        setModalMessage('¿Estás seguro de que deseas eliminar este archivo? Esta acción no se puede deshacer.');
        setIsModalOpen(true);
        //handleFileDelete(selectedFile.);
        //handleCloseFileModal();
    };

    //Verificar si la Base esta en uso
    const handleCheckDeleteBase = async () => {
        let cont = 0;
        /*asistentes.forEach(asistente => {
            if (originalBaseName === asistente.assistant_knowledge_base) {
                cont = cont + 1;
                setBaseAssistantId(asistente.assistant_id);
            }
        });*/

        if (cont > 0) {
            setConfirmModalType('✅');
            setConfirmModalText('Base de conocimiento en uso')
            setModalMessage('La Base que intentas eliminar esta asignada a un asistente, seguro que deseas continuar ?');
            setConfirmDeleteBase(false);
            setIsModalOpen(true);
        }
        else {
            await handleDeleteBase();
        }
    }

    //Eliminar una Base
    const handleDeleteBase = async () => {

        if (baseAssistantId) {
            setIsLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/deleteBaseAssistant`, {
                    businessId: '5215580177109',
                    assistantId: baseAssistantId,
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });
                if (response.status === 200) {
                    setBaseAssistantId('');
                }
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                setConfirmModalText('Base de conocimiento')
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al eliminar la base del asistente, intenta de nuevo más tarde');
                        setIsModalOpen(true);
                        console.log(getError);
                    } else if (error.request) {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al eliminar la base del asistente, intenta de nuevo más tarde');
                        setIsModalOpen(true);
                        console.log('Request made but no response received:', error.request);
                    } else {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al eliminar la base del asistente, intenta de nuevo más tarde');
                        setIsModalOpen(true);
                        console.log('Error message:', error.message);
                    }
                } else {
                    setConfirmModalType('❌');
                    setModalMessage('Ocurrió un error al eliminar la base del asistente, intenta de nuevo más tarde');
                    setIsModalOpen(true);
                    console.error('An unknown error occurred:', error);
                }
                setBaseAssistantId('');
            }
        }

        try {
            let data = JSON.stringify({
                "business_id": '5215580177109',
                "knowledge_base_nickname": originalBaseName
            });

            let configuration = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BACKEND_URL}/v1/knowledgebases/deleteKnowledgeBase`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${luisa_token}`
                },
                data: data
            };

            await axios.request(configuration)
                .then((response) => {
                    if (response.status === 200) {
                        setConfirmModalType('✅');
                        setConfirmModalText('Base de conocimiento')
                        setModalMessage('Base eliminada correctamente');
                        setConfirmDeleteBase(false);
                        setIsModalOpen(true);
                        fetchBases();
                    } else {
                        setConfirmModalType('❌');
                        setConfirmModalText('Base de conocimiento')
                        setModalMessage('Ocurrió un error al eliminar la base, intenta de nuevo más tarde');
                        setIsModalOpen(true);
                        console.log(JSON.stringify(response.data));
                    }
                })
                .catch((error) => {
                    if (axios.isAxiosError(error) && error.response) {
                        const status = error.response.status;
                        if (status === 401 || status === 403) {
                            console.log("Token expirado, cerrando sesión...");
                            handleLogout();
                        }
                    }
                    console.log(error);
                });

        } catch (error) {
            console.error('Error deleting base :', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchBases();
    }, []);

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className='row justify-content-center p-linkup p-4'>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className='row px-3 animate__animated animate__fadeIn mb-3'>
                            <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeIn'>
                                <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/administracion')}>
                                    <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                                </svg>
                                Bases de conocimiento
                            </h1>
                            <div className='d-block w-100 p-5 --config-container'>
                                <h3 className='text-blue'>Configuración</h3>
                                <p className='text-blue mb-0 mt-4'>Bases de conocimiento</p>
                                <p className='mt-0 mb-4'>Una base de conocimiento es la información digital que usa Renata; El RAG mejora respuestas al buscar y organizar datos relevantes para ofrecer respuestas claras y precisas</p>
                                <div className="row px-2">
                                    <div className='col-3'>
                                        {basesConocimiento.length > 0 ? (
                                            <div className='mb-1  btn-base'>
                                                <div className='row mb-1'>
                                                    {basesConocimiento.map((base, index) => (
                                                        <button key={index} type="button" className="btn btn-sm border-0 btn-light w-100 btn-size mb-2 cita-option d-flex justify-content-center align-items-center p-1" onClick={() => { handleShowFiles(base.knowledge_base_nickname) }}>
                                                            <span className='me-3 fs-5'>🧠</span>
                                                            <Tooltip id="base"></Tooltip>
                                                            <span
                                                                data-tooltip-id="base"
                                                                data-tooltip-content={base.knowledge_base_nickname}
                                                                data-tooltip-place="top"
                                                            >{truncateFileName(base.knowledge_base_nickname, 15)}</span>
                                                            <span className='ms-3' onClick={() => handleEditBase(base.knowledge_base_nickname)}><i className="bi bi-pencil" ></i></span>
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <div className='row'>
                                            <button type="button" className="btn btn-sm border-0 btn-primary addbaseConocimineto w-100 d-flex justify-content-center align-items-center p-1" onClick={handleAddBase}>
                                                <i className="bi bi-plus-lg fs-4"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <Tooltip id="selBase"></Tooltip>
                                    <div className='col-9'>
                                        {/* originalDataFile */}
                                        {selectedBase ? (
                                            <div className='fw-bold b-vertical'>
                                                
                                                <h2 data-tooltip-id="selBase"
                                                    data-tooltip-content={selectedBase.baseName}
                                                    data-tooltip-place="top-start"
                                                    key={selectedBase.baseName}
                                                    className='fw-bold text-blue'>
                                                    🧠 {truncateFileName(selectedBase.baseName, 26)}
                                                </h2>
                                                <div>Estatus:
                                                    {selectedBase.stat === 'ok' && (
                                                        <span className="d-inline ms-3">
                                                            <i className="bi bi-check-circle link-success ms-1"></i> Sincronizado <span className='text-secondary ms-3'> {fileDate}</span>
                                                        </span>
                                                    )}
                                                    {selectedBase.stat === 'err' && (
                                                        <span className="d-inline">
                                                            <i className="bi bi-x-circle link-danger ms-1"></i> ERROR <span className='text-secondary'>Puedes volver a intentar</span>
                                                        </span>
                                                    )}
                                                    {selectedBase.stat === 'sync' && (
                                                        <span className="d-inline-flex ps-2">
                                                            <div className="i-circle">
                                                                <i className={`bi bi-arrow-repeat ${isSyncing ? 'spin' : ''}`}></i>
                                                            </div>
                                                            <span className='ps-1'>
                                                                Sincronizando <span className='text-secondary'>Tiempo estimado 2 minutos</span>
                                                            </span>
                                                        </span>
                                                    )}
                                                    {selectedBase.stat === 'no-sync' && (
                                                        <span className="d-inline">
                                                            <i className="bi bi-exclamation-circle text-warning ms-1"></i> No sincronizado <span className='fw-light'>Por favor presiona el botón</span>
                                                        </span>
                                                    )}
                                                </div>
                                                <hr className="border2 opacity-100" />
                                                <div className='d-flex justify-content-start align-items-center'>
                                                <p className="d-inline me-2 me-3">Archivos de conocimiento</p>
                                                    <button className="btn btn-primary --btn-sync btn-sm border-0 d-flex justify-content-center align-items-center p-1" disabled={isSyncing} onClick={() => addFileToBase(selectedBase.baseName)}>
                                                        <i className="bi bi-arrow-repeat me-1 fs-5"></i> Sincronizar
                                                    </button>
                                                </div>
                                                <div className="row p-4 mt-2 mx-1 rounded-4 --docs-container d-flex">
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        {selectedBase.archivos !== undefined && selectedBase.archivos.map((file, index) => (
                                                            <div
                                                                key={index}
                                                                className="col-10 mb-2"
                                                            >
                                                                <Tooltip id="btn"></Tooltip>
                                                                <div
                                                                    data-tooltip-id="btn"
                                                                    data-tooltip-content={file.file}
                                                                    data-tooltip-place="top"
                                                                    key={file.file}
                                                                    className={`btn btn-sm btn-secondary mt-2 --btn-docs w-100 ${selectedFilesDelete.includes(file.file) ? 'selected' : ''}`}
                                                                    onClick={() => {
                                                                        handleFileClick(file);
                                                                        setDeleteInfo({ file: file.file, baseName: selectedBase.baseName });
                                                                    }}
                                                                >
                                                                    <span>📄 {truncateFileName(file.file, 20)}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {selectedFiles.length > 0 && (
                                                        <div className='mt-1 row'>
                                                            {selectedFiles.map((file, index) => (
                                                                <div key={index} className="col-6">
                                                                    <button key={file.name} type="button" className="btn btn-sm btn-outline-secondary rounded-pill w-100">
                                                                        📄 {truncateFileName(file.name, 19)}
                                                                        <span onClick={() => removeFile(index)} >
                                                                            <i className="bi bi-x ms-2"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                        <div>
                                                            {/* <div className='w-50 searchBar hide'>
                                                                <div className="input-group h-100">
                                                                <span className="input-group-text bg-transparent" id="basic-addon1">
                                                                    <i className="bi bi-search text-white"></i>
                                                                </span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control bg-transparent"
                                                                    placeholder="Buscar..."
                                                                    // placeholder={'link de wikipedia para webscraping'}
                                                                    aria-label="vinculacion"
                                                                    aria-describedby="basic-addon1"
                                                                    value={inputValue}
                                                                    onChange={(e) => {
                                                                        setInputValue(e.target.value);
                                                                        updateSelectedBaseStat('no-sync');
                                                                        if (e.target.value === "") {
                                                                            updateSelectedBaseStat('ok');
                                                                        }
                                                                    }}
                                                                />
                                                                </div>
                                                            </div> */}
                                                            <div>
                                                                <label id='input-img' className='w-50'>
                                                                    <input
                                                                        className="form-control --back-style"
                                                                        type="file"
                                                                        id="formFile"
                                                                        multiple
                                                                        onChange={handleFileChange}>
                                                                    </input>
                                                                    <i className="bi bi-folder-plus px-5 mx-1 point"></i>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>) : (
                                            <p className="b-vertical text-light">
                                                Aún no cuentas con ninguna base de conocimiento, haz click en el botón "+".
                                            </p>
                                        )}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal show={isModalOpen}
                onHide={() => {
                    if (confirmModalText === 'Sincronizacion de Archivo' || confirmModalText === 'La base de conocimiento ha sido creada correctamente') {
                        setIsModalOpen(false);
                    } else if (modalMessage === 'Selecciona al menos un archivo para continuar') {
                        setIsModalOpen(false);
                        setShowAddBaseModal(true);
                    } else if (modalMessage === 'Escribe un nombre antes de continuar') {
                        setIsModalOpen(false);
                        setShowAddBaseModal(true);
                    } else if (confirmModalText === 'Eliminar archivo') {
                        setIsModalOpen(false);
                        setShowFileModal(true);
                    } else if (confirmModalText === 'Estimación' || confirmModalText === 'Aviso' || confirmModalText === 'base de conocimiento en uso' ||
                        modalMessage === 'Solo se aceptan archivos de máximo 5MB' || modalMessage === 'Solo se aceptan archivos PDF y TXT, y no se permiten archivos duplicados'
                        || modalMessage === 'El límite es de 25MB por Base' || modalMessage === 'El límite es de 5 archivos por Base') {
                        setIsModalOpen(false);
                        setShowAddBaseModal(true);
                    } else if (modalMessage === 'Archivo eliminado correctamente' || 'Base eliminada correctamente') {
                        setIsModalOpen(false);
                    } else if (modalMessage === 'Ocurrió un error al eliminar la base, intenta de nuevo más tarde') {
                        setIsModalOpen(false);
                        setConfirmDeleteBase(true);
                    } else {
                        setIsModalOpen(false);
                        setShowAddBaseModal(true);
                    }
                }}
                text={modalMessage}
                type={confirmModalType}
                titleText={confirmModalText}
                isLoading={isLoading}
                onClick={() => {
                    if (confirmModalText === 'Estimación') addNewBase();
                    if (confirmModalText === 'Eliminar archivo') handleFileDelete();
                    if (confirmModalText === 'Base de conocimiento en uso') handleDeleteBase();
                }}
            />

            <Modal show={showAddBaseModal} onHide={handleCloseAddBaseModal} className="bg-modal-cont">
                <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>{titleModalAdd}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='hola'>


                    <h5 className='fw-bold mb-2'>Nombre</h5>

                    <div>
                        <input
                            type="text --back-style"
                            className="form-control mb-3 bg-secondary border-secondary text-white"
                            placeholder="Ej. Base de conocimiento A"
                            value={baseName}
                            onChange={handleBaseNameChange}
                        />
                    </div>

                    {titleModalAdd === "Añadir Base de Conocimiento" && (
                        <div>
                            <h5 className='fw-bold mb-2'>Archivo de conocimiento</h5>
                            {/* <div className="bg-secondary border-secondary p-2 rounded-4 d-flex justify-content-evenly align-items-center"> */}
                            <div className="bg-secondary border-secondary p-2 rounded-4 mx-1 row">
                                <div className='col-md-12 col-lg-6'>
                                    <label id='input-img' className='d-flex'>
                                        <input
                                            className="form-control d-none --back-style"
                                            type="file"
                                            id="formFile"
                                            multiple
                                            onChange={handleFileChangeModal}>
                                        </input>
                                        <i className="bi bi-folder-plus --add-doc px-5 mx-1 border point"></i>
                                    </label>
                                </div>
                                {selectedFilesModal.length > 0 && (
                                    <div className='col-md-12 col-lg-6'>
                                        <Tooltip id="mod"></Tooltip>
                                        {selectedFilesModal.map((file, index) => (
                                            <div className='border border-dark rounded-pill px-2 mt-2' key={index}
                                                data-tooltip-id="mod"
                                                data-tooltip-content={file.name}
                                                data-tooltip-place="top">

                                                <i className="bi bi-file-earmark-text me-1"></i>
                                                {truncateFileName(file.name, 16)}
                                                <span
                                                    onClick={() => removeFileModal(index)} >
                                                    <i className="bi bi-x ms-1"></i>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <div className='col-md-12 col-lg-6'>
                                    <small>Tamaño total: {(totalSize / (1024 * 1024)).toFixed(2)} MB</small>
                                </div>
                            </div>
                            <div>
                                <h5 className='fw-bold mb-2 mt-4'>Número de resultados</h5>
                                <div className="row justify-content-center mt-4">
                                    <div className="col-9">
                                        <Slider
                                            value={parseInt(sizeResponse)}
                                            onChange={handleSliderResponse}
                                            min={2}
                                            max={5}
                                            step={1}
                                            valueLabelDisplay="auto"
                                            marks={[
                                                { value: 2, label: '2' },
                                                { value: 3, label: '3' },
                                                { value: 4, label: '4' },
                                                { value: 5, label: '5' }
                                            ]}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <Form.Control className='bg-secondary border-secondary text-white text-center' value={sizeResponse} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h5 className='fw-bold mt-4'>Longitud del contexto</h5>
                                <div className="row justify-content-center">
                                    <div className="col-9">
                                        <Slider
                                            value={parseInt(wordNumber)}
                                            onChange={handleSliderWord}
                                            min={150}
                                            max={250}
                                            step={10}
                                            valueLabelDisplay="auto"
                                            marks={[
                                                { value: 150, label: '150' },
                                                { value: 160, label: '160' },
                                                { value: 170, label: '170' },
                                                { value: 180, label: '180' },
                                                { value: 190, label: '190' },
                                                { value: 200, label: '200' },
                                                { value: 210, label: '210' },
                                                { value: 220, label: '220' },
                                                { value: 230, label: '230' },
                                                { value: 240, label: '240' },
                                                { value: 250, label: '250' },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <Form.Control className='bg-secondary border-secondary text-white text-center' value={wordNumber} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    {titleModalAdd === "Añadir Base de Conocimiento" && (
                        <div>
                            <Button className='btn btn-secondary btnSecondary me-2' variant="light" onClick={handleCloseAddBaseModal} disabled={isLoading}>
                                Cancelar
                            </Button>
                            <Button className='btn btn-primary me-2' variant="dark px-3" onClick={addNewBase} disabled={isLoading}>
                                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar'}
                            </Button>
                        </div>

                    )}

                    {titleModalAdd === "Editar Base de Conocimiento" && (
                        <div>
                            <Button className=' btn btn-secondary btnSecondary me-2' onClick={() => {
                                setTitleModalAdd("Eliminar Base de conocimiento");
                                setConfirmDeleteBase(true);
                                setShowAddBaseModal(false);
                            }
                            }
                            >Eliminar
                            </Button>
                            <Button className='btn btn-primary me-2' variant="dark px-3" onClick={updateBaseName} disabled={isLoading}>
                                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Actualizar'}
                            </Button>
                        </div>
                    )}


                </Modal.Footer>
            </Modal>

            <Modal show={showFileModal} onHide={handleCloseFileModal} size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Editar archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-9">
                            <h5 className='fw-bold mb-2'>Nombre</h5>
                            <div>
                                <input
                                    type="text --back-style"
                                    className="form-control mb-3 text-white bg-secondary border-secondary"
                                    placeholder="Ej. Base de conocimiento A"
                                    readOnly
                                    value={selectedFile?.file}
                                    onChange={handleBaseNameChange}
                                />
                            </div>
                        </div>
                        <div className='col-3 position-relative'>
                            <button className="btn btn-secondary btnSecondary position-absolute rounded-pill bottom-0 mb-3" onClick={handleFileDeleteModal}>Eliminar archivo</button>
                        </div>
                    </div>
                    {modalPdfUrl && (
                        <iframe
                            title='modalPdf'
                            src={modalPdfUrl}
                            width="100%"
                            height="600px"
                            style={{ border: "none" }}
                        >
                            This browser does not support PDFs. Please download the PDF to view it: <a href={modalPdfUrl}>Download PDF</a>
                        </iframe>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary' onClick={handleCloseFileModal}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalConfirmDeleteBase} onHide={() => {
                if (titleModalAdd === "Eliminar Base de conocimiento") {
                    setTitleModalAdd("Editar Base de Conocimiento");
                    setConfirmDeleteBase(false);
                    setShowAddBaseModal(true);

                }
            }
            }>
                <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>{titleModalAdd}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {titleModalAdd === "Eliminar Base de conocimiento" && (
                        <p>¿Estás seguro de que deseas eliminar este base de conocimiento? Esta acción no se puede deshacer.</p>
                    )}
                    {titleModalAdd === "Eliminar archivo" && (
                        <p>¿Estás seguro de que deseas eliminar este archivo de la base? Esta acción no se puede deshacer.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {titleModalAdd === "Eliminar Base de conocimiento" && (
                        <div>
                            <Button className='me-2' variant="light" onClick={() => {
                                setTitleModalAdd("Editar Base de Conocimiento");
                                setConfirmDeleteBase(false);
                                setShowAddBaseModal(true);
                            }
                            }>
                                Cancelar
                            </Button>
                            <Button className='me-2' variant="danger" onClick={handleCheckDeleteBase}>
                                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Eliminar base'}
                            </Button>
                        </div>
                    )}
                    {titleModalAdd === "Eliminar archivo" && (
                        <div>
                            <Button variant="light" onClick={() => setConfirmDeleteBase(false)}>
                                Cancelar
                            </Button>
                            <Button variant="danger" onClick={handleMultiFileDelete}>
                                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Eliminar archivo de la base'}

                            </Button>
                        </div>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default KnowledgeTab;