import React, { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import "../../assets/css/administration.css";
import { ToastContainer, toast } from 'react-toastify';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { Tooltip } from 'react-tooltip'
import loadingGif from '../../assets/img/loading.gif';
import "../../assets/css/NewEvent.css";
import handleLogout from '../../functions/functions';

interface EventEditModalProps {
    show: boolean;
    onClose: () => void;
    eventTitle: string;
    eventId: string;
    fetchEvents: () => Promise<void>;
}

const EventEditModal: React.FC<EventEditModalProps> = ({ show, onClose, eventTitle, eventId, fetchEvents }) => {
    const luisa_token = localStorage.getItem('luisa_token');
    const [paymentLocation, setPaymentLocation] = useState("");
    const [eventFormat, setEventFormat] = useState("");
    const [paymentMod, setPaymentMod] = useState("");
    const [fileBanner, setFileBanner] = useState<File | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [fileUrlBanner, setFileUrlBanner] = useState<string>('');

    const [imgFlyer, setImgFlyer] = useState<string>('');
    const [imgBanner, setImgBanner] = useState<string>('');

    const [isLoading, setIsLoading] = useState(false);

    const today = new Date();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [exclusive, setExclusive] = useState(false);

    const [formData, setFormData] = useState({
        title: '',
        price: '',
        spots: '',
        locationUrl: '',
        discount: '',
        discountQty: '',
        description: '',
        genPrice: '',
        location: '',
        eventTime: '',
    });

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    // Abrir modal de confirmación
    const handleCancelClick = () => {
        setShowConfirmModal(true);
    };

    // Cerrar modal de confirmación
    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
    };

    const handleConfirmCancel = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/events/deleteEvent`,
                { event_id: eventId },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );
            toast.success("Evento cancelado correctamente");
            setShowConfirmModal(false);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            await fetchEvents();
            onClose();
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            toast.error("Error al cancelar el evento");
            console.error("Error cancelando el evento:", error);
        }
    };

    const fetchEvent = async () => {

        try {
            const luisa_token = localStorage.getItem("luisa_token");

            const eventResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/events/getEventbyId`,
                { event_id: eventId },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            let eventData = eventResponse.data.data[0];
            let pctg = (eventData.discount.percentage).toString();
            let qty = (eventData.discount.quantity_discount).toString();
            // let reminderValue = '';
            // if (eventData.reminder.one_days) reminderValue = '1';
            // else if (eventData.reminder.three_days) reminderValue = '3';
            // else if (eventData.reminder.five_days) reminderValue = '5';

            setImgFlyer(eventData.img_url);
            setImgBanner(eventData.banner_image_url);
            //console.log(eventData);
            if (eventData.img_url) {
                const imageFlyerResponse = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/get_image_event/${eventData.img_url}`,
                    {
                        headers: {
                            Authorization: `Bearer ${luisa_token}`,
                        },
                    }
                );
                let flyerUrl = imageFlyerResponse.data.url;
                setFileUrl(flyerUrl);
            }

            const imageBannerResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/get_image_event/${eventData.banner_image_url}`,
                {
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            let bannerUrl = imageBannerResponse.data.url;
            setFileUrlBanner(bannerUrl);

            setFormData({
                title: eventData.title || '',
                eventTime: eventData.event_time || '',
                price: eventData.price_member || '',
                genPrice: eventData.price_general || '',
                spots: eventData.spots || '',
                discount: pctg || '',
                discountQty: qty || '',
                description: eventData.description || '',
                location: eventData.location || '',
                locationUrl: eventData.location_url || '',
            });
            setPaymentMod(eventData.event_payment_methods[0] || '');
            setPaymentLocation(eventData.reminder);
            setEventFormat(eventData.event_format || '');
            //console.log(eventData.event_date);

            const dateParts = eventData.event_date.split('T')[0].split('-');
            const year = parseInt(dateParts[0], 10);
            const month = parseInt(dateParts[1], 10) - 1; // Los meses en JavaScript son 0-indexados
            const day = parseInt(dateParts[2], 10)+1;

            setStartDate(eventData.event_date ? new Date(Date.UTC(year, month, day)) : null);


            setExclusive(eventData.members_only);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error("Error fetching event detail or image:", error);
        }
    }

    useEffect(() => {
        if (show && eventId) {
            fetchEvent();
        }
    }, [show, eventId]);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { id, value } = event.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const invalidKeys = ["e", "E", "+", "-"];
        if (invalidKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    const isValidFileType = (fileName: string) => {
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        return fileExtension && allowedExtensions.includes(fileExtension);
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile && isValidFileType(selectedFile.name)) {
            let url = window.URL.createObjectURL(selectedFile);
            setFileUrl(url);
            setFile(selectedFile);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .png, .jpg o .jpeg');
        }
    };

    const handleFileSelectBanner = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFileBanner = event.target.files?.[0];
        if (selectedFileBanner && isValidFileType(selectedFileBanner.name)) {
            let url = window.URL.createObjectURL(selectedFileBanner);
            setFileUrlBanner(url);
            setFileBanner(selectedFileBanner);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .png, .jpg o .jpeg');
        }
    };

    const handleExclusiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        setExclusive(!exclusive);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        //console.log(exclusive);

        setIsLoading(true);

        if (exclusive) {
            formData.genPrice = '0';
        }

        if (Object.values(formData).some((field) => !field) || !eventFormat || !paymentMod || !startDate) {
            showWarnNotification('Por favor completa todos los campos.');
            setIsLoading(false);
            return;
        }

        const data = new FormData();

        Object.entries(formData).forEach(([key, value]) => {
            data.append(key, value);
        });

        if (file) {
            data.append('image_url', file);
        } else {
            data.append('image_url', imgFlyer);
        }
        if (fileBanner) {
            data.append('banner_image_url', fileBanner);
        } else {
            data.append('banner_image_url', imgBanner);
        }

        let ini = new Date(startDate);

        data.append('id', eventId);
        data.append('price_member', formData.price);
        data.append('price_general', formData.genPrice);
        data.append('reminder', paymentLocation);
        data.append('location_url', formData.locationUrl);
        data.append('event_format', eventFormat);
        data.append('event_date', ini.getFullYear() + '-' + (ini.getMonth() + 1) + '-' + ini.getDate());
        data.append('event_time', formData.eventTime);
        data.append('event_payment_methods', paymentMod);
        data.append('discount_qty', formData.discountQty);
        data.append('members_only', exclusive ? 'true' : 'false');

        // Array.from(data.entries()).forEach(([key, value]) => {
        //     console.log(`${key}:`, value);
        // });

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/events/editEventInfo`,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 200) {
                showNotification('Se guardó el evento correctamente.');
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await fetchEvents();
                onClose();
            } else {
                showErrorNotification('Ocurrió un error al guardar el evento.');
            }
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setIsLoading(false);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            showErrorNotification('Ocurrió un error al guardar el evento.');
            console.error(error);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setIsLoading(false);
        }
    };

    const showWarnNotification = (text: string) =>
        toast.warning(text, { position: 'top-right', autoClose: 4000 });
    const showErrorNotification = (text: string) =>
        toast.error(text, { position: 'top-right', autoClose: 3000 });
    const showNotification = (text: string) =>
        toast.success(text, { position: 'top-right', autoClose: 4000 });

    if (!show) return null;

    return (
        <>
            <div className="modal d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <ToastContainer />
                <div className="modal-dialog modal-xl">
                    <div className="modal-content bg-edit">
                        <div className="modal-header">
                            <h5 className="modal-title text-blue"> Editar evento: <span className='fw-semibold'>{eventTitle}</span></h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            <form className='col-md-12 p-4 --form-style'>
                                <div className="">
                                    <div className="row">
                                        {/* IZQ */}
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label className='--textPrimaryVariant'>Título del evento</label>
                                                <input
                                                    id='title'
                                                    type="text"
                                                    className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                    placeholder='Nueva publicación'
                                                    autoComplete="off"
                                                    maxLength={40}
                                                    value={formData.title}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-2 d-flex gap-5 --flex-md-column --flex-lg-row">
                                                <div className='mb-3'>
                                                    <label htmlFor="event_date" className="form-label --textPrimaryVariant mb-0">Fecha</label>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                                            <DatePicker
                                                                className='btn btn-secondary'
                                                                value={startDate}
                                                                onChange={(newValue) => {
                                                                    setStartDate(newValue);
                                                                }}
                                                                minDate={today}
                                                                slotProps={{
                                                                    openPickerIcon: { fontSize: 'large' }
                                                                }}
                                                            />
                                                        </div>
                                                    </LocalizationProvider>
                                                </div>

                                                <div className='mb-3'>
                                                    <label htmlFor="event_date" className="--textPrimaryVariant responsive-truncate mb-0">Horario</label>
                                                    <input
                                                        type="time"
                                                        className="form-control --stylebtnVariant"
                                                        id="eventTime"
                                                        value={formData.eventTime}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 d-flex gap-5 --flex-md-column --flex-lg-row mt-4">
                                                <div>
                                                    <label className='--textPrimaryVariant responsive-truncate mb-0'>Precio socios</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text --stylebtnVariant">$</span>
                                                        <input
                                                            id='price'
                                                            type="number"
                                                            value={formData.price}
                                                            className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                            onChange={handleChange}
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className='--textPrimaryVariant responsive-truncate mb-0'>Precio general</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text --stylebtnVariant">$</span>
                                                        <input
                                                            id='genPrice'
                                                            type="number"
                                                            value={formData.genPrice}
                                                            className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                            onChange={handleChange}
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-4 mb-4 d-flex gap-4 --flex-md-column --flex-lg-row">
                                                <div>
                                                    <label className='--textPrimaryVariant responsive-truncate'>Lugares disponibles</label>
                                                    <input
                                                        id='spots'
                                                        type="number"
                                                        className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                        placeholder=''
                                                        autoComplete="off"
                                                        maxLength={40}
                                                        value={formData.spots}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div>
                                                    <label className='--textPrimaryVariant mb-1'>Descuento</label>
                                                    <div className="input-group">
                                                        <input
                                                            id='discount'
                                                            type="number"
                                                            value={formData.discount}
                                                            className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                            onChange={handleChange}
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                        <span className="input-group-text --stylebtnVariant">%</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Tooltip id="discount"></Tooltip>
                                                    <label
                                                        data-tooltip-id="discount"
                                                        data-tooltip-content={'A partir de cuantos boletos aplica el descuento'}
                                                        data-tooltip-place="top"
                                                        className='--textPrimaryVariant responsive-truncate'>
                                                        Cantidad Descuento
                                                    </label>
                                                    <input
                                                        id='discountQty'
                                                        type="number"
                                                        value={formData.discountQty}
                                                        className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                        onChange={handleChange}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-0 mb-3">
                                                <label className='--textPrimaryVariant'>Modalidad del pago</label>
                                                <select
                                                    id="paymentMod"
                                                    className="form-select --stylebtnVariant"
                                                    value={paymentMod}
                                                    onChange={(e) => { setPaymentMod(e.target.value) }}
                                                >
                                                    <option value="">Selecciona el pago</option>
                                                    <option value="stripe">Stripe (Crédito o débito)</option>
                                                    <option value="transferencia">Transferencia</option>
                                                    <option value="ambas">Ambas</option>
                                                </select>
                                            </div>
                                            <div className="mb-3 mt-4">
                                                <label htmlFor="event_date" className='--textPrimaryVariant'>Recordatorio</label>
                                                <select
                                                    id="paymentLocation"
                                                    className="form-select --stylebtnVariant"
                                                    value={paymentLocation}
                                                    onChange={(e) => { setPaymentLocation(e.target.value) }}
                                                >
                                                    <option value="">Selecciona los dias</option>
                                                    <option value="1">1 día</option>
                                                    <option value="3">3 días</option>
                                                    <option value="5">5 días</option>
                                                </select>
                                            </div>
                                            {/* <div className="mt-4 mb-3">
                                            <label className='--textPrimaryVariant'>Imagen del banner</label>
                                            <div className="p-4 --style-imgEvent">
                                                <label id='input-img' className="d-flex flex-column align-items-center">
                                                    <input
                                                        type="file"
                                                        accept=".jpg,.png,.jpeg"
                                                        className="d-none"
                                                        onChange={handleFileSelectBanner}
                                                    />
                                                    <i className="bi bi-folder-plus -style-event"></i>
                                                    <span className='text-light'>{fileBanner ? fileBanner.name : "Sube el fondo de tu evento"}</span>
                                                </label>
                                            </div>
                                        </div> */}
                                            <div className="mt-4 mb-3">
                                                <label className='--textPrimaryVariant'>Imagen del banner</label>
                                                <div className="p-4 --style-imgEvent">
                                                    <label id='input-img' className="d-flex flex-column align-items-center">
                                                        <input
                                                            type="file"
                                                            accept=".jpg,.png,.jpeg"
                                                            className="d-none"
                                                            onChange={handleFileSelectBanner}
                                                        />
                                                        {fileUrlBanner ? (
                                                            <img src={fileUrlBanner} alt="Banner del evento" className='--img-promo' />
                                                        ) : (
                                                            <>
                                                                <i className="bi bi-folder-plus -style-event"></i>
                                                                <span className='text-light'>{fileBanner ? fileBanner.name : "Sube el fondo de tu evento"}</span>
                                                            </>
                                                        )}
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label className='--textPrimaryVariant'>Descripción del evento</label>
                                                <textarea
                                                    id='description'
                                                    className={`form-control text-dark visionTextArea`}
                                                    placeholder="Descripción"
                                                    rows={6}
                                                    autoComplete="off"
                                                    maxLength={500}
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                ></textarea>
                                            </div>
                                            <div className="mt-4 mb-3">
                                                <label className='--textPrimaryVariant responsive-truncate'>Ubicación del evento</label>
                                                <input
                                                    id='location'
                                                    type="text"
                                                    className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                    placeholder=''
                                                    autoComplete="off"
                                                    maxLength={60}
                                                    value={formData.location}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mt-4 mb-3">
                                                <label className='--textPrimaryVariant'>Url de Google Maps</label>
                                                <input
                                                    id='locationUrl'
                                                    type="text"
                                                    className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                    placeholder=''
                                                    autoComplete="off"
                                                    maxLength={2100}
                                                    value={formData.locationUrl}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mt-4 mb-3">
                                                <label className='--textPrimaryVariant'>Formato del evento</label>
                                                <select
                                                    id="format"
                                                    className="form-select --stylebtnVariant"
                                                    value={eventFormat}
                                                    onChange={(e) => { setEventFormat(e.target.value) }}
                                                >
                                                    <option value="">Selecciona el formato</option>
                                                    <option value="presencial">Presencial</option>
                                                    <option value="online">En linea</option>
                                                    <option value="ambos">Ambos</option>
                                                </select>
                                            </div>
                                            <div className="mt-5 mb-5">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="exclusive"
                                                        onChange={handleExclusiveChange}
                                                        checked={exclusive}
                                                    />
                                                    <label className="form-check-label --textPrimaryVariant" >Evento exclusivo para socios</label>
                                                </div>
                                            </div>
                                            <div className="mt-4 mb-3">
                                                <label className='--textPrimaryVariant'>Flyer para redes sociales</label>
                                                <div className="p-4 --style-imgEvent">
                                                    <label id='input-img' className="d-flex flex-column align-items-center">
                                                        <input
                                                            type="file"
                                                            accept=".jpg,.png,.jpeg"
                                                            className="d-none"
                                                            onChange={handleFileSelect}
                                                        />
                                                        {fileUrl ? (
                                                            <img src={fileUrl} alt="Banner del evento" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
                                                        ) : (
                                                            <>
                                                                <i className="bi bi-folder-plus -style-event"></i>
                                                                <span className='text-light'>{file ? file.name : "Selecciona un archivo"}</span>
                                                            </>
                                                        )}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger me-auto" onClick={handleCancelClick}>
                                Cancelar evento
                            </button>
                            <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                Guardar
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={onClose}>
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {showConfirmModal && (
                <div className="modal d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirmar cancelación</h5>
                                <button type="button" className="btn-close" onClick={handleCloseConfirmModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>¿Estás seguro de que deseas cancelar el evento  <strong>"{eventTitle}</strong>", esta acción no se puede deshacer?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={handleConfirmCancel}>
                                    Confirmar
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={handleCloseConfirmModal}>
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default EventEditModal;
