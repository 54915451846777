import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import config from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { userInfo } from '../interfaces/UserProfile';
import handleLogout from '../../functions/functions';
import loadingGif from '../../assets/img/loading.gif';

interface UserData {
  name: string;
  surnameP: string;
  surnameM: string;
  rfc_user: string;
  email: string;
  phoneNumber: string;
  numEmployee: string;
  selectedRole: string;
}

interface ModalProps {
  isOpen: boolean;
  rfc: String;
  onClose: () => void;
  onSave: (data: UserData) => void;
  selectedUser : any| null;
  isLoading? : boolean

}

const EditIndividualUser: React.FC<ModalProps> = ({ isOpen, rfc, onClose, onSave, selectedUser, isLoading }) => {

  const modalRef = useRef<HTMLDivElement>(null);

  const [name, setName] = useState<string>('');
  const [surnameP, setSurnameP] = useState<string>('');
  const [surnameM, setSurnameM] = useState<string>('');
  const [newRfc, setNewRfc] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [roles, setRoles] = useState<string[]>([]);
  const [numEmployee, setNumEmployee] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>(''); // Estado para el rol seleccionado
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidRfc, setIsValidRfc] = useState(false);
  const luisa_token = localStorage.getItem('luisa_token');
  const [id, setId] = useState<string>('');

useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
  
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };
  
  if (isOpen) {
    document.addEventListener("mousedown", handleClickOutside);
  } else {
    document.removeEventListener("mousedown", handleClickOutside);
  }

  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [isOpen, onClose]);



useEffect(() => {
  if (isOpen) {
    console.log("se abre el modal");
    fetchInformation();
  } else {
    console.log("se cierra el modal");
  }

  document.body.style.overflow = 'hidden';

  return () => {
    document.body.style.overflow = 'unset';
  };
}, [isOpen]);
  
  const fetchInformation = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get_roles/${rfc}`,{
          headers: {
            Authorization: `Bearer ${luisa_token}`, // Aquí agregas el token
          },});
      setRoles(response.data.roles); // Agrega el vacío al final

      await setValues(selectedUser);

    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
    }
      console.error('Error fetching roles:', error);
    }
  };

  const setValues = async (selectedUser_param: any) => {
    setName(selectedUser_param?.nombre || "");
    setSurnameP(selectedUser_param?.apellido_paterno || "");
    setSurnameM(selectedUser_param?.apellido_materno || "");
    setNewRfc(selectedUser_param?.rfc_usuario || "");
    setEmail(selectedUser_param?.email || "");
    //setRoles(selectedUser_param.rol);
    setNumEmployee(selectedUser_param?.numero_empleado || "");
    setPhoneNumber(selectedUser_param?.whatsapp_number || "");
    setSelectedRole(selectedUser_param?.rol || "");
    setId(selectedUser_param?._id || "");

    setIsValidNumber(!!selectedUser_param.whatsapp_number);
    setIsValidEmail(!!selectedUser_param.email);
    setIsValidRfc(!!selectedUser_param.rfc_usuario);

  }

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
    setIsValidNumber(value.length >=10);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleSurnamePChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSurnameP(e.target.value);
  };
  const handleSurnameMChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSurnameM(e.target.value);
  };

  const handleNewRfcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim().toUpperCase();
    setNewRfc(value);
      // Expresión regular para validar el RFC
    const rfcPattern = /^[A-ZÑ&]{4}\d{6}[A-Z0-9]{3}$/i;
    if (rfcPattern.test(value)) {
      setIsValidRfc(true); // RFC válido
    } else {
      setIsValidRfc(false); // RFC no válido
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim(); 
    setEmail(value);
    // Expresión regular para validar el correo electrónico
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(value)) {
      setIsValidEmail(true); // Correo válido
    } else {
      setIsValidEmail(false); // Correo no válido
    }
  };

  const handleNumEmployeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumEmployee(e.target.value);
  };

const handleSaveClick = (e: React.FormEvent) => {
  e.preventDefault();
  const data: any = {
    name,
    surnameP,
    surnameM,
    rfc_user: newRfc,
    email,
    phoneNumber,
    numEmployee,
    selectedRole,
    id
  };
  onSave(data);
};

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles} ref={modalRef}>
        <h2>Editar usuario</h2>
        <hr style={{ border: "2px solid white" }} />
        <form>
          <div style={inputContainerStyles}>
          <label style={labelStyles}>Nombre <span style={{ color: "red" }}>*</span></label>
            <input 
              style={inputStyles}
              type="text" 
              value={name} 
              onChange={handleNameChange} 
              placeholder="Pablo" 
            />
          </div>
          <div style={inputContainerStyles}>
            <label style={labelStyles}>Apellido Paterno <span style={{ color: "red" }}>*</span></label>
            <input 
              style={inputStyles}
              type="text" 
              value={surnameP} 
              onChange={handleSurnamePChange} 
              placeholder="Perez" 
            />
          </div>
          <div style={inputContainerStyles}>
            <label style={labelStyles}>Apellido Materno <span style={{ color: "red" }}>*</span></label>
            <input 
              style={inputStyles}
              type="text" 
              value={surnameM} 
              onChange={handleSurnameMChange} 
              placeholder="Gonzales" 
            />
          </div>
          <div style={inputContainerStyles}>
            <label style={labelStyles}>RFC de persona física<span style={{ color: "red" }}>*</span></label>
            <input 
              style={inputStyles}
              type="text" 
              value={newRfc} 
              onChange={handleNewRfcChange} 
              placeholder="DFRT123211RDV" 
              maxLength={13}
            />
          </div>
          <div style={inputContainerStyles}>
            <label style={labelStyles}>Correo electronico <span style={{ color: "red" }}>*</span></label>
            <input 
              style={inputStyles}
              type="text" 
              value={email} 
              onChange={handleEmailChange} 
              placeholder="ejemplo123@gmail.com" 
            />
          </div>
          <div style={inputContainerStyles}>
            <label style={labelStyles}>Número de celular <span style={{ color: "red" }}>*</span></label>
            <input 
              style={inputStyles}
              type="tel" 
              value={phoneNumber} 
              onChange={handlePhoneNumberChange} 
              placeholder="2288123456" 
            />
          </div>
          <div style={inputContainerStyles}>
            <label style={labelStyles}>No. de empleado <span style={{ color: "red" }}>*</span></label>
            <input 
              style={inputStyles}
              type="text" 
              value={numEmployee} 
              onChange={handleNumEmployeeChange} 
              placeholder="500" 
            />
          </div>
          <div style={inputContainerStyles}>
        <label style={labelStyles}>Rol</label>
        <select 
            style={inputStyles}
            value={selectedRole} 
            onChange={(e) => setSelectedRole(e.target.value)}> 
            <option value="" >
                Selecciona un rol
            </option>
            {roles.map((role, index) => (
                role !== "" && ( // Evita agregar el valor vacío dos veces
                <option key={index} value={role}>
                    {role}
                </option>
                )
            ))}
        </select>

      </div>
          <div style={buttonContainerStyles}>
            <button className="btn btn-danger btnSecondary float-end col-md-5" type="button" onClick={onClose} style={buttonStyles} disabled={isLoading} >Cancelar</button>
            <button type="submit" className="btn btn-primary next-step float-end col-md-5"  
            disabled={!isValidNumber || name.length ===0 ||surnameP.length ===0 || surnameM.length ===0 || !isValidRfc || !isValidEmail || numEmployee.length ===0 || isLoading} 
            style={buttonStyles} onClick={handleSaveClick} >
              {isLoading ? <img className="loading" src={loadingGif} alt="Cargando..." /> : 'Guardar'}
              </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const modalStyles: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
};

const modalContentStyles: React.CSSProperties = {
  backgroundColor: '#001B71',
  padding: '20px',
  borderRadius: '10px',
  width: '90%',
  maxWidth: '550px',
  maxHeight: '95vh', // Ajusta la altura en pantallas pequeñas
  overflowY: 'auto', // Permite scroll interno si es necesario
  color: 'white',
};

const buttonContainerStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
};

const buttonCloseStyles: React.CSSProperties = {
  backgroundColor: '#D9534F', // Rojo
  color: 'white',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

const buttonSaveStyles: React.CSSProperties = {
  backgroundColor: '#007BFF', // Azul
  color: 'white',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

const inputContainerStyles: React.CSSProperties = {
  marginBottom: '15px',
};

const labelStyles: React.CSSProperties = {
  display: 'block',
  marginBottom: '5px',
  color: 'white', // Letras blancas
};

const inputStyles: React.CSSProperties = {
  width: '100%',
  padding: '8px', // Menos altura
  borderRadius: '20px', // Diseño más ovalado
  border: 'none',
  backgroundColor: '#ccc', // Fondo gris más claro
  color: 'white', // Texto blanco
  boxSizing: 'border-box', // Asegura que el padding no afecte al ancho
};

const buttonStyles: React.CSSProperties = {
  width: '100%', // Uso de ancho relativo en dispositivos pequeños
  maxWidth: '200px', // Ancho fijo solo en pantallas grandes
  marginLeft: '10px', // Espacio entre botones
};

export default EditIndividualUser;