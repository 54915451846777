import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import loadingGif from '../../assets/img/loading.gif';

interface LinkupCostModalProps {
    show: boolean;
    onHide: () => void;
    isLoading?: boolean;
    onClick?: (cost: number) => void;
}

const LinkupCostModal: React.FC<LinkupCostModalProps> = ({ show, onHide, isLoading = false, onClick = () => {} }) => {
    const [cost, setCost] = useState('');

    const formatCost = (value: string) => {
        const numericValue = value.replace(/[^0-9.]/g, '');
        if (numericValue === '') return '';

        const [integerPart, decimalPart] = numericValue.split('.');

        const formattedInteger = parseInt(integerPart || '0', 10).toLocaleString('en-US');

        return decimalPart !== undefined
            ? `${formattedInteger}.${decimalPart.substring(0, 2)}`
            : formattedInteger;
    };

    const handleCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;
        const formattedValue = formatCost(rawValue);
        setCost(formattedValue);
    };

    const handleSave = () => {
        const numericCost = parseFloat(cost.replace(/,/g, ''));
        if (!isNaN(numericCost)) {
            setCost('');
            onClick(numericCost);
        }
    };

    return (
        <Modal show={show} onHide={onHide} className="costModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Costos</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <label className="form-label">Configura los costos unitarios de la Vinculación</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text bg-transparent text-white">
                            <i className="bi bi-currency-dollar"></i>
                        </span>
                        <input
                            type="text"
                            className="form-control centered-placeholder centered-input-container"
                            placeholder="Costo de la vinculación"
                            value={cost}
                            onChange={handleCostChange}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onHide} disabled={isLoading}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={isLoading}>
                    {isLoading ? <img className="loading" src={loadingGif} alt="Cargando..." /> : 'Guardar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LinkupCostModal;
