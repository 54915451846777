import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TopbarCoparmex from '../main/TopBarCoparmex';
import '../../assets/css/working_commissions.css';
import ModalComponent from '../modals/WorkingCommissionsDetail';
import NuevaPublicacionModal from '../modals/WorkingCommissionsNuevaPublicacion';
import LuisaChat from '../main/LuisaChat';
import axios from 'axios';
import { hasPermission, truncateTextByWords } from '../../functions/functions';
import { WorkCommissionEntryInterface } from '../interfaces/WorkCommissionEntryInterface';
import { WorkCommissionInterface } from '../interfaces/WorkCommissionInterface';
import usePermissions from '../../hooks/usePermissions';

const WorkingCommissions = () => {
    const location = useLocation();
    const luisa_token = localStorage.getItem('luisa_token');
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_email = localStorage.getItem("luisa_email");
    const [showModal, setShowModal] = useState(false);
    const [showModalNueva, setModalNueva] = useState(false);
    
    const [fileName, setFileName] = useState("");
    const [workCommissionEntry, setWorkCommissionEntry] = useState<WorkCommissionEntryInterface[]>([]);
    const [workCommissionEntryId, setWorkCommissionEntryId] = useState("");
    const [workCommission, setWorkCommission] = useState<WorkCommissionInterface[]>([]);
    const [workCommissionId, setWorkCommissionId] = useState("");

    const [workCommissionName, setWorkCommissionName] = useState("");
    const [workCommissionRfc, setWorkCommissionRfc] = useState("");


    const modalBodyRef = useRef<HTMLDivElement>(null);
    const [entryIdParam, setEntryIdParam] = useState<string | null>(null);

    // Paginación
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4; // Ajusta el número de elementos por página

    // Barra de búsqueda
    const [searchQuery, setSearchQuery] = useState("");

    const { permissions } = usePermissions(luisa_rfc || '', luisa_email || '');

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const entryId = searchParams.get('entry_id');
        setEntryIdParam(entryId);
    }, []);

    const scrollToBottom = () => {
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
        }
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFileName(file ? file.name : "");
    };

    const fetchWorkingCommissions = async (commissionId: string) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/getAllWorkCommissionEntries/${commissionId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${luisa_token}`,
                    }
                }
            );
            if (response.status === 200 && Array.isArray(response.data)) {
                setWorkCommissionEntry(response.data);
                if (!entryIdParam && response.data.length > 0) {
                    console.log("setSelectedCommission(response.data[0]);");
                }
            }
        } catch (error) {
            console.error('Error fetching working commissions:', error);
        }
    };

    const fetchAllWorkCommissions = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/getAllWorkCommissions`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${luisa_token}`
                    }
                }
            );
            if (response.status === 200) {
                const commissions = response.data.commisions as WorkCommissionInterface[];
                setWorkCommission(commissions);

                if (!entryIdParam && commissions.length > 0) {
                    setWorkCommissionId(commissions[0]._id);
                    setWorkCommissionName(commissions[0].commission_name);
                    setWorkCommissionRfc(commissions[0].admin_business_rfc);
                }
            }
        } catch (error) {
            console.error('Error fetching all work commissions:', error);
        }
    };

    const fetchEntryInAnyCommission = async (entryId: string) => {
        for (const commission of workCommission) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/getAllWorkCommissionEntries/${commission._id}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${luisa_token}`
                        }
                    }
                );

                if (response.status === 200 && Array.isArray(response.data)) {
                    const foundEntry = response.data.find(
                        (entry: WorkCommissionEntryInterface) => entry._id === entryId
                    );
                    if (foundEntry) {
                        setWorkCommissionId(commission._id);
                        setWorkCommissionEntry(response.data);
                        setShowModal(true);
                        setEntryIdParam(null);
                        return;
                    }
                }
            } catch (error) {
                console.error(`Could not fetch entries for commission ${commission._id}`, error);
            }
        }
    };

    useEffect(() => {
        fetchAllWorkCommissions();
    }, []);

    useEffect(() => {
        if (entryIdParam && workCommission.length > 0) {
            fetchEntryInAnyCommission(entryIdParam);
        }
    }, [entryIdParam, workCommission]);

    useEffect(() => {
        if (workCommissionId) {
            fetchWorkingCommissions(workCommissionId);
        }
    }, [workCommissionId]);

    useEffect(() => {
        if (showModal) {
            scrollToBottom();
        }
    }, [showModal]);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleOpenNuevaPublicacion = () => setModalNueva(true);
    const handleCloseNuevaPublicacion = () => setModalNueva(false);

    // Paginación - Obtener los elementos actuales
    const indexOfLastEntry = currentPage * itemsPerPage;
    const indexOfFirstEntry = indexOfLastEntry - itemsPerPage;
    const filteredEntries = workCommissionEntry.filter((entry) =>
        entry.entry_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const currentEntries = filteredEntries.slice(indexOfFirstEntry, indexOfLastEntry);

    // Cambiar página
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(filteredEntries.length / itemsPerPage);

    return (
        <>
            <LuisaChat />
            <TopbarCoparmex />
            <div className="py-3 px-5 linkup-bg">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'>Blog de Trabajo</h1>
                            <div className="">
                                {/* {hasPermission(permissions, "Comisiones", "crear") ? (
                                    <button className='btn btn-primary' onClick={handleOpenNuevaPublicacion}><i className="bi bi-plus"></i> Nueva Publicación</button>
                                ) : (
                                    <button disabled className='btn btn-primary'><i className="bi bi-plus"></i> Nueva Publicación</button>
                                )} */}
                                {workCommissionRfc === luisa_rfc && hasPermission(permissions, "Comisiones", "crear") ?  (
                                    <button className='btn btn-primary' onClick={handleOpenNuevaPublicacion}><i className="bi bi-plus"></i> Nueva Publicación</button>
                                ) : (
                                    <>
                                        {/* {hasPermission(permissions, "Comisiones", "crear")  ? (
                                            <button className='btn btn-primary' onClick={handleOpenNuevaPublicacion}><i className="bi bi-plus"></i> Nueva Publicación</button>
                                        ) : (
                                            <button disabled className='btn btn-primary'><i className="bi bi-plus"></i> Nueva Publicación</button>
                                        )} */}
                                        <button disabled className='btn btn-primary'><i className="bi bi-plus"></i> Nueva Publicación</button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='p-3 sub-header animate__animated animate__fadeInUp'>
                            <span>Resumen Luisa <i className="bi bi-stars"></i></span><br /><br />
                            <span className='text-secondary'>Las Comisiones de Trabajo (CT) son órganos de estudio y consulta especializados que integran el talento de socios voluntarios, funcionarios y, en su caso, expertos externos para elaborar propuestas sobre su materia con el soporte técnico y político necesario, a fin de cumplir la misión y los objetivos institucionales.</span>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5 animate__animated animate__fadeInUp">
                                <div className="p-5 --backgroun-light">
                                    {workCommission.map((commission, index) => (
                                        <button
                                            key={index}
                                            className={`btn col-md-12 mt-3 ${workCommissionId === commission._id ? 'btn-primary' : '--btn-light'}`}
                                            onClick={() => { setCurrentPage(1); setWorkCommissionId(commission._id); setWorkCommissionName(commission.commission_name); setWorkCommissionRfc(commission.admin_business_rfc) }}
                                        >
                                            {commission.icon} {commission.commission_name}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12 mt-5 animate__animated animate__fadeInUp">
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    placeholder="Buscar por título o descripción"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <div className='overflow-auto' style={{ maxHeight: '57vh' }}>
                                    {currentEntries.length > 0 ? (
                                        currentEntries.map((entry, index) => (
                                            <div key={index} className="--backgroun-light p-4 mt-3">
                                                <h5 className="fw-bolder text-blue mb-4">{entry.entry_title}</h5>
                                                <div
                                                    className="--bg-light p-3 d-flex align-items-center --style-publicacion --cursor-pointer"
                                                    onClick={() => {
                                                        handleOpenModal();
                                                        setWorkCommissionEntryId(entry._id);
                                                    }}
                                                >
                                                    <img
                                                        src={entry.image_url}
                                                        alt={entry.image}
                                                        className="--img-commission rounded"
                                                    />
                                                    <span className="ms-3">{truncateTextByWords(entry.description, 50)}</span>
                                                    {/* Limita la descripción a 50 palabras */}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No hay publicaciones.</p>
                                    )}
                                </div>

                                {/* {currentEntries.map((entry) => (
                                    <div key={entry._id} className="--backgroun-light p-4 mt-5">
                                        <h5 className="fw-bolder text-blue mb-4">{entry.entry_title}</h5>
                                        <div
                                            className="--bg-light p-3 d-flex align-items-center --style-publicacion --cursor-pointer"
                                            onClick={() => {
                                                setSelectedCommission(entry);
                                                handleOpenModal();
                                            }}
                                        >
                                            <img
                                                src={entry.image_url}
                                                alt={entry.image}
                                                className="--img-commission"
                                            />
                                            <span className="ms-3">{entry.description}</span>
                                        </div>
                                    </div>
                                ))} */}

                                {/* Paginación */}
                                <div className="mt-4">
                                    {filteredEntries.length > itemsPerPage && (
                                        <nav className="pagination-controls ">
                                            <ul className="pagination w-50">
                                                {/* Botón Anterior */}
                                                <li className={`me-1 page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <button className="page-link page-link-prev-next" onClick={() => paginate(currentPage - 1)}>&laquo; Anterior</button>
                                                </li>

                                                {/* Páginas */}
                                                {currentPage > 2 && (
                                                    <>
                                                        <li className="page-item me-1">
                                                            <button className="page-link" onClick={() => paginate(1)}>1</button>
                                                        </li>
                                                        <li className="page-item me-1 disabled">
                                                            <span className="page-link">...</span>
                                                        </li>
                                                    </>
                                                )}

                                                {Array.from({ length: totalPages }, (_, i) => (
                                                    i + 1 >= currentPage - 1 && i + 1 <= currentPage + 1 && (
                                                        <li
                                                            key={i}
                                                            className={`me-1 page-item ${currentPage === i + 1 ? 'active' : ''}`}
                                                        >
                                                            <button className="page-link" onClick={() => paginate(i + 1)}>
                                                                {i + 1}
                                                            </button>
                                                        </li>
                                                    )
                                                ))}

                                                {currentPage < totalPages - 1 && (
                                                    <>
                                                        <li className="page-item disabled">
                                                            <span className="page-link">...</span>
                                                        </li>
                                                        <li className="page-item">
                                                            <button className="page-link" onClick={() => paginate(totalPages)}>{totalPages}</button>
                                                        </li>
                                                    </>
                                                )}

                                                {/* Botón Siguiente */}
                                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                    <button className="page-link page-link-prev-next" onClick={() => paginate(currentPage + 1)}>Siguiente &raquo;</button>
                                                </li>
                                            </ul>
                                        </nav>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<ModalComponent
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                modalBodyRef={modalBodyRef}
                workCommissionRfc={workCommissionRfc}
                workCommisionEntryDetail={workCommissionEntry}
                workCommissionEntryId={workCommissionEntryId}
                fetchWorkingCommissions={() => fetchWorkingCommissions(workCommissionId)}
            />}

            {<NuevaPublicacionModal
                show={showModalNueva}
                handleClose={handleCloseNuevaPublicacion}
                handleFileChange={handleFileChange}
                fileName={fileName}
                fetchWorkingCommissions={() => fetchWorkingCommissions(workCommissionId)}
                workId={workCommissionId}
            />}

        </>
    );
};

export default WorkingCommissions;
