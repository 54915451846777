import React, { useEffect, useState } from 'react';
import './assets/css/main.css';
import TopbarCoparmex from './components/main/TopBarCoparmex';
import { LocalizationProvider, DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import Logo from "./assets/img/coparmex-logo-cdmx.png";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { truncateFileName } from './functions/functions';
import Tooltip from '@mui/material/Tooltip';
import handleLogout from './functions/functions';

interface Event {
    _id: string;
    title: string;
    description: string;
    event_date: string;
    event_time: string;
    price_member: number;
    price_general: number;
    banner_image_url: string;
    discount: {
        quantity_discount: number;
        percentage: number;
    };
    image_url?: string;
    members_only: Boolean;
}

const CustomDay = ({ day, selected, events, ...other }: PickersDayProps<Dayjs> & { events: Event[] }) => {
    const dateStr = day.format('YYYY-MM-DD');
    const event = events.find(event => dayjs(event.event_date).format('YYYY-MM-DD') === dateStr);

    return (
        <Tooltip key={dateStr} title={event ? `${event.title} - ${event.event_time}` : ''} arrow>
            <span>
                <PickersDay
                    {...other}
                    day={day}
                    selected={selected}
                    sx={{
                        ...(event && {
                            backgroundColor: 'rgba(33, 150, 243, 0.3)',
                            borderRadius: '50%',
                            border: '2px solid #2196F3',
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                            padding: '10px',
                            maxWidth: '300px',
                        }),
                        '&:hover': {
                            backgroundColor: 'rgba(33, 150, 243, 0.6)',
                        },
                    }}
                />
            </span>
        </Tooltip>
    );
};


function Home() {
    const navigate = useNavigate();
    const luisa_user = localStorage.getItem('luisa_username');
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const [userName, setUserName] = useState('');
    const [events, setEvents] = useState<Event[]>([]);
    const [eventsCal, setEventsCal] = useState<Event[]>([]);
    const [eventDates, setEventDates] = useState<Set<string>>(new Set());
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

    const handleDateChange = (newDate: Dayjs | null) => {
        setSelectedDate(newDate);
    };

    useEffect(() => {
        if (luisa_user) {
            let output = luisa_user
                .toLowerCase()
                .split(' ') // Divide en palabras
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitaliza la primera letra de cada palabra
                .join(' '); // Une las palabras nuevamente
            setUserName(output);
        }
        

        const fetchEvents = async () => {
            try {
                const luisa_token = localStorage.getItem('luisa_token');
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/events/getAllEvents`, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });

                const eventsData: Event[] = response.data.data || [];

                const updatedEvents = await Promise.all(
                    eventsData.map(async (event) => {
                        try {
                            const imageResponse = await axios.get(
                                `${process.env.REACT_APP_BACKEND_URL}/get_image_event/${event.banner_image_url}`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${luisa_token}`
                                    }
                                }
                            );
                            return {
                                ...event,
                                image_url: imageResponse.data.url
                            };
                        } catch (error) {
                            if (axios.isAxiosError(error) && error.response) {
                                const status = error.response.status;
                                if (status === 401 || status === 403) {
                                    console.log("Token expirado, cerrando sesión...");
                                    handleLogout();
                                }
                            }
                            console.error(`Error fetching image for event ${event._id}:`, error);
                            return { ...event, image_url: '' };
                        }
                    })
                );

                setEvents(updatedEvents);

            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                console.error('Error fetching events:', error);
                setEvents([]);
            }
        };

        const fetchEventsUser = async () => {
            try {
                const luisa_token = localStorage.getItem('luisa_token');
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/events/getEventsByUser`, {
                    rfc: luisa_rfc
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });

                const eventsData: Event[] = response.data.data || [];

                const updatedEvents = await Promise.all(
                    eventsData.map(async (event) => {
                        try {
                            const imageResponse = await axios.get(
                                `${process.env.REACT_APP_BACKEND_URL}/get_image_event/${event.banner_image_url}`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${luisa_token}`
                                    }
                                }
                            );
                            return {
                                ...event,
                                image_url: imageResponse.data.url
                            };
                        } catch (error) {
                            if (axios.isAxiosError(error) && error.response) {
                                const status = error.response.status;
                                if (status === 401 || status === 403) {
                                    console.log("Token expirado, cerrando sesión...");
                                    handleLogout();
                                }
                            }
                            console.error(`Error fetching image for event ${event._id}:`, error);
                            return { ...event, image_url: '' };
                        }
                    })
                );
                setEventsCal(updatedEvents);
                const dates = new Set(eventsData.map(event => dayjs(event.event_date).format('YYYY-MM-DD')));
                setEventDates(dates);
                if (updatedEvents.length > 0) {
                    const firstEventDate = dayjs(updatedEvents[0].event_date);
                    setSelectedDate(firstEventDate);
                }

            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
        fetchEventsUser();
    }, [luisa_user]);

    const handleEventClick = (id: string) => {
        navigate(`/event_detail?id=${id}`);
    };

    const shouldDisableDate = (date: Dayjs) => !eventDates.has(date.format('YYYY-MM-DD'));

    const renderDay = (props: PickersDayProps<Dayjs>) => <CustomDay {...props} events={eventsCal} />;

    return (
        <>
            <TopbarCoparmex />
            <div className="py-3 px-5 linkup-bg">
                <div className='row justify-content-center'>
                    <div className="col-lg-9 col-md-10 col-sm-10">
                        <div className="row mt-4">
                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <h2 className='fw-bolder text-blue animate__animated animate__fadeInUp mt-4'>
                                    <span className='text-secondary'>Bienvenido</span> {userName}
                                </h2>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <img className="coparmex-logo" src={Logo} alt="COPARMEX Logo" />
                            </div>
                        </div>

                        <div className="row mt-1">
                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <button className="btn col-md-12 mt-3 btn-primary rounded-pill mb-2">Próximos eventos</button>
                                {events.length === 0 ? (
                                    <div className="alert alert-info" role="alert">
                                        No hay eventos disponibles
                                    </div>
                                ) : (
                                    <Carousel
                                        indicators={true}
                                        interval={5000}
                                        controls={false}
                                        className="w-100">
                                        {events.map((event) => (
                                            <Carousel.Item key={event._id}>
                                                <div
                                                    key={event._id}
                                                    className="w-100 bg-info rounded mb-3"
                                                    style={{
                                                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${event.image_url}')`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        backgroundRepeat: 'no-repeat',
                                                        color: '#fff',
                                                        cursor: 'pointer',
                                                        height: '335px',
                                                    }}
                                                    onClick={() => handleEventClick(event._id)}
                                                >
                                                    <div className="row p-4 px-5 mb-0 --flex-sm-column-reverse --flex-md-row">
                                                        <div className="col-sm-12 col-lg-12 text-start min-h-event">
                                                            <h3>{event.title} - <span>{new Date(event.event_date).toLocaleDateString()}</span></h3>
                                                            {event.members_only && <i>(Exclusivo para socios)</i>}
                                                            <p className="lead --style-text-oveflow d-sm-none d-md-block mt-1">
                                                                {truncateFileName(event.description, 100)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row px-4 pb-2 mt-0">
                                                        <div className="col-sm-12 col-lg-6 text-start ">
                                                        {event.discount.percentage > 0 ? (
                                                            <div className="btn btn-lg bg-white --btn-descuento">
                                                                {event.discount.percentage}% de descuento
                                                            </div>
                                                        ) : ( 
                                                        <></> )}
                                                        </div>
                                                        <div className="col-sm-12 col-lg-6 text-end">
                                                            <div className="btn btn-lg btn-primary --btn-costo">
                                                                <p className="mb-0">Miembro: MXN ${event.price_member.toFixed(2)}</p>
                                                                <p className="mb-0">General: MXN ${event.price_general.toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                )}
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 main-cal">
                                <button className="btn col-md-12 mt-3 btn-primary rounded-pill mb-2">Tus eventos próximos</button>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                    <DateCalendar
                                        className="rounded-4"
                                        disableHighlightToday
                                        shouldDisableDate={shouldDisableDate}
                                        value={selectedDate}  // Establecer la fecha seleccionada en el calendario
                                        onChange={handleDateChange}  // Asegúrate de manejar los cambios de fecha
                                        slots={{ day: renderDay }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
