import React, { useState, useEffect } from 'react';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';
import "../../assets/css/Promotion.css";
import axios from 'axios';
import handleLogout from '../../functions/functions';

interface Event {
    _id: string;
    title: string;
    description: string;
    event_date: string;
    event_time: string;
    price_member: number;
    price_general: number;
    banner_image_url: string;
    discount: {
        quantity_discount: number;
        percentage: number;
    };
    image_url?: string; // Add this field to store the real image URL
    members_only: Boolean;
}

const Events = () => {
    const navigate = useNavigate();
    const [events, setEvents] = useState<Event[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                setIsLoading(true);
                const luisa_token = localStorage.getItem('luisa_token');
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/v1/events/getAllEvents`, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });

                const eventsData: Event[] = response.data.data || [];

                // Fetch image URLs for each event
                const updatedEvents = await Promise.all(
                    eventsData.map(async (event) => {
                        try {
                            const imageResponse = await axios.get(
                                `${process.env.REACT_APP_BACKEND_URL}/get_image_event/${event.banner_image_url}`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${luisa_token}`
                                    }
                                }
                            );
                            return {
                                ...event,
                                image_url: imageResponse.data.url
                            };
                        } catch (error) {
                            if (axios.isAxiosError(error) && error.response) {
                                const status = error.response.status;
                                if (status === 401 || status === 403) {
                                    console.log("Token expirado, cerrando sesión...");
                                    handleLogout();
                                }
                            }
                            console.error(`Error fetching image for event ${event._id}:`, error);
                            return { ...event, image_url: '' }; // Fallback to empty string
                        }
                    })
                );

                setEvents(updatedEvents);
                setError(null);

                // Print all events to the console
                //console.log("Updated Events:", updatedEvents);
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                console.error('Error fetching events:', error);
                setError('Failed to fetch events');
                setEvents([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEvents();
    }, []);

    const handleEventClick = (id: string) => {
        navigate(`/event_detail?id=${id}`);
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                {error}
            </div>
        );
    }

    return (
        <>
            <TopbarCoparmex />
            <div className="flex-column align-items-center py-3 px-5 linkup-bg">
                <div className="row justify-content-center">
                    <div className='col-sm-12 col-md-10 col-lg-8'>
                        <div className='w-100 mt-4 mb-3'>
                            <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>
                                Eventos
                            </h1>
                        </div>

                        {events.length === 0 ? (
                            <div className="alert alert-info" role="alert">
                                No hay eventos disponibles
                            </div>
                        ) : (
                            events.map((event) => (
                                <div
                                    key={event._id}
                                    className='w-100 bg-info rounded mb-3'
                                    style={{
                                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${event.image_url}')`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        color: '#fff',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleEventClick(event._id)}
                                >
                                    <div className="row p-5 mb-0 --flex-sm-column-reverse --flex-md-row">
                                        <div className="col-sm-12 col-lg-6 text-start">
                                            <h3>{event.title}</h3>
                                            {event.members_only && (
                                                <i>(Exclusivo para socios)</i>
                                            )}
                                            <p className='lead --style-text-oveflow d-sm-none d-md-block'>
                                                {event.description}
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-lg-6 text-sm-start text-md-end">
                                            <p className='fs-5'>
                                                {/* {new Date(event.event_date).toLocaleDateString()} - {event.event_time} */}
                                                {new Date(event.event_date).toISOString().split('T')[0].split('-').reverse().join('/')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row px-5 pb-3 mt-0">
                                        <div className="col-sm-12 col-lg-6 text-start">
                                            <div className='btn btn-lg bg-white --btn-descuento'>
                                                {event.discount.percentage > 0
                                                    ? `${event.discount.percentage}% de descuento`
                                                    : 'Sin descuento'}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-6 text-end --mt-4">
                                            <div className='btn btn-lg btn-primary --btn-costo'>
                                                <p className='mb-0'>Miembro: MXN ${event.price_member.toFixed(2)}</p>
                                                <p className='mb-0'>General: MXN ${event.price_general.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Events;