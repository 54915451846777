import { useState, useEffect } from 'react';
import axios from 'axios';

const usePermissions = (rfc: string, email: string) => {
    const [permissions, setPermissions] = useState<Record<string, any>>({});

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getUserByMail`, {
                    rfc,
                    mail: email
                });

                const userPermissions = response.data.user.roles[0];
                setPermissions(userPermissions);
            } catch (error) {
                console.log("Error obteniendo permisos:", error);
                setPermissions({});
            }
        };

        if (rfc && email) {
            fetchPermissions();
        }
    }, [rfc, email]);

    return { permissions };
};

export default usePermissions;
