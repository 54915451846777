import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface DisclaimerModalProps {
    show: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    title?: string;
    message?: string;
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({
    show,
    onConfirm,
    onCancel,
    title,
    message
}) => {
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onConfirm}>
                    Continuar
                </Button>
                <Button variant="secondary" onClick={onCancel}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DisclaimerModal;
