import TopbarCoparmex from "../main/TopBarCoparmex";
import "../../assets/css/administration.css";
import { useNavigate } from 'react-router-dom';

const MenuAdmin = () => {
    const navigate = useNavigate();


    return (
        <>
            <TopbarCoparmex />
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeIn'>Administración</h1>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <div className="mb-3 linkup-form-bg cards-style min-h-cards" onClick={() => navigate('/control-table')}>
                                    <h2 className="text-blue">Mesa de control</h2>
                                    <p className="mb-3 mt-3">Centraliza y gestiona todas las solicitudes, proporcionando seguimiento y coordinación para asegurar su atención eficiente y oportuna.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <div className="mb-3 linkup-form-bg cards-style min-h-cards" onClick={() => navigate('/bases-conocimiento')}>
                                    <h2 className='text-blue'>Bases de conocimiento</h2>
                                    <p className='mb-3 mt-3'>Configura la información que Luisa mostrará al usuario.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <div className="mb-3 linkup-form-bg cards-style min-h-cards" onClick={() => navigate('/admin-events')}>
                                    <h2 className='text-blue'>Eventos</h2>
                                    <p className='mb-3 mt-3'>Administra nuevos eventos dentro de la plataforma de Luisa.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <div className="mb-3 linkup-form-bg cards-style min-h-cards" onClick={() => navigate('/costos')}>
                                    <h2 className='text-blue'>Costos</h2>
                                    <p className='mb-3 mt-3'>Administra costos de las afiliaciones</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <div className="mb-3 linkup-form-bg cards-style min-h-cards" onClick={() => navigate('/work-commission')}>
                                    <h2 className='text-blue'>Comisiones de trabajo</h2>
                                    <p className='mb-3 mt-3'>Configura las comisiones de trabajo</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <div className="mb-3 linkup-form-bg cards-style min-h-cards" onClick={() => navigate('/finance')}>
                                    <h2 className='text-blue'>Finanzas</h2>
                                    <p className='mb-3 mt-3'>Aqui podras visualizar las entradas y salidas de dinero de la plataforma</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuAdmin;