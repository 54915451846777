import React, { ChangeEvent, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import handleLogout from '../../functions/functions';

interface NewEventModalProps {
    show: boolean;
    onClose: () => void;
    fetchEvents: () => Promise<void>;
}

const NewEventModal: React.FC<NewEventModalProps> = ({ show, fetchEvents, onClose }) => {
    const luisa_token = localStorage.getItem('luisa_token');
    const [paymentLocation, setPaymentLocation] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [fileBanner, setFileBanner] = useState<File | null>(null);
    const [fileUrlBanner, setFileUrlBanner] = useState<string>('');

    const [formData, setFormData] = useState({
        title: '',
        price: '',
        spots: '',
        locationUrl: '',
        eventDate: '',
        //bannerImg: '',
        discount: '',
        discountQty: '',
        description: '',
        genPrice: '',
        location: '',
        format: '',
        eventTime: '',
        sitePay: '',
        //imgUrl: '',
        //reminder: '',
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { id, value } = event.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile && isValidFileType(selectedFile.name)) {
            let url = window.URL.createObjectURL(selectedFile);
            setFileUrl(url);
            setFile(selectedFile);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .png, .jpg o .jpeg');
        }
    };

    const handleFileSelectBanner = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFileBanner = event.target.files?.[0];
        if (selectedFileBanner && isValidFileType(selectedFileBanner.name)) {
            let url = window.URL.createObjectURL(selectedFileBanner);
            setFileUrlBanner(url);
            setFileBanner(selectedFileBanner);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .png, .jpg o .jpeg');
        }
    };

    const isValidFileType = (fileName: string) => {
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        return fileExtension && allowedExtensions.includes(fileExtension);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Validar campos vacíos
        if (Object.values(formData).some((field) => !field)) {
            showWarnNotification('Por favor completa todos los campos.');
            return;
        }

        const data = new FormData();

        Object.entries(formData).forEach(([key, value]) => {
            data.append(key, value);
        });

        if (file) {
            data.append('image_url', file);
        }
        if (fileBanner) {
            data.append('banner_image_url', fileBanner);
        }
        
        data.append('price_member', formData.price);
        data.append('price_general', formData.genPrice);
        data.append('reminder', paymentLocation);
        data.append('location_url', formData.locationUrl);
        data.append('event_format', formData.format);
        data.append('event_date', formData.eventDate);
        data.append('event_time', formData.eventTime);
        data.append('event_payment_methods', formData.sitePay);
        data.append('discount_qty', formData.discountQty);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/events/createEvent`,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 200) {
                showNotification('¡El evento se creó correctamente!');
            } else {
                showErrorNotification('Ocurrió un error al crear el evento.');
            }
            await new Promise((resolve) => setTimeout(resolve, 2000));
            await fetchEvents();
            onClose();
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            showErrorNotification('Ocurrió un error al realizar la publicación.');
            console.error(error);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            onClose();
        }
    };


    const showWarnNotification = (text: string) =>
        toast.warning(text, { position: 'top-right', autoClose: 4000 });
    const showErrorNotification = (text: string) =>
        toast.error(text, { position: 'top-right', autoClose: 3000 });
    const showNotification = (text: string) =>
        toast.success(text, { position: 'top-right', autoClose: 4000 });

    if (!show) return null;

    return (
        <div className="modal d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <ToastContainer />
            <div className="modal-dialog modal-lg newEvent">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Nuevo Evento</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="row">
                                {/* IZQ */}
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className='--textPrimaryVariant'>Título del evento</label>
                                        <input
                                            id='title'
                                            type="text"
                                            className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                            placeholder='Nueva publicación'
                                            autoComplete="off"
                                            maxLength={40}
                                            value={formData.title}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Precio para socios</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text --stylebtnVariant">$</span>
                                            <input
                                                id='price'
                                                type="number"
                                                value={formData.price}
                                                className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Lugares disponibles</label>
                                        <input
                                            id='spots'
                                            type="number"
                                            className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                            placeholder=''
                                            autoComplete="off"
                                            maxLength={40}
                                            value={formData.spots}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Url de ubicación</label>
                                        <input
                                            id='locationUrl'
                                            type="text"
                                            className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                            placeholder=''
                                            autoComplete="off"
                                            maxLength={40}
                                            value={formData.locationUrl}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="event_date" className="form-label">Fecha del evento</label>
                                        <input
                                            type="date"
                                            className="form-control --stylebtnVariant"
                                            id="eventDate"
                                            value={formData.eventDate}
                                            onChange={handleChange}

                                        />
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Sitio del pago</label>
                                        <input
                                            id='sitePay'
                                            type="text"
                                            className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                            placeholder=''
                                            autoComplete="off"
                                            maxLength={40}
                                            value={formData.sitePay}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="event_date" className="form-label">Recordatorio</label>
                                        <select
                                            id="paymentLocation"
                                            className="form-select --stylebtnVariant"
                                            value={paymentLocation}
                                            onChange={(e) => { handleChange(e); setPaymentLocation(e.target.value) }}
                                        >
                                            <option value="">Selecciona los dias</option>
                                            <option value="1">1 día</option>
                                            <option value="3">3 días</option>
                                            <option value="5">5 días</option>
                                        </select>
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Imagen del banner</label>
                                        <div className="d-flex align-items-center">
                                            <span className="me-3 text-secondary">
                                                {fileBanner ? fileBanner.name : "Selecciona un archivo"}
                                            </span>
                                            <label id='input-img' className="d-inline">
                                                <input
                                                    type="file"
                                                    accept=".jpg,.png,.jpeg"
                                                    className="d-none"
                                                    onChange={handleFileSelectBanner}
                                                />
                                                <i className="bi bi-folder-plus px-5 mx-1 border border-dark point"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* DER */}

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className='--textPrimaryVariant'>Descripción del evento</label>
                                        <textarea
                                            id='description'
                                            className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                            placeholder="Descripción"
                                            rows={2}
                                            autoComplete="off"
                                            maxLength={500}
                                            value={formData.description}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Precio general</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text --stylebtnVariant">$</span>
                                            <input
                                                id='genPrice'
                                                type="number"
                                                value={formData.genPrice}
                                                className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                                onChange={handleChange}
                                            />
                                        </div>

                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Ubicación del evento</label>
                                        <input
                                            id='location'
                                            type="text"
                                            className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                            placeholder=''
                                            autoComplete="off"
                                            maxLength={40}
                                            value={formData.location}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Formato del evento</label>
                                        <input
                                            id='format'
                                            type="text"
                                            className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                            placeholder=''
                                            autoComplete="off"
                                            maxLength={40}
                                            value={formData.format}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="event_date" className="form-label">Hora del evento</label>
                                        <input
                                            type="time"
                                            className="form-control --stylebtnVariant"
                                            id="eventTime"
                                            value={formData.eventTime}
                                            onChange={handleChange}

                                        />
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Descuento</label>
                                        <div className="input-group mb-3">
                                            <input
                                                id='discount'
                                                type="number"
                                                value={formData.discount}
                                                className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                                onChange={handleChange}
                                            />
                                            <span className="input-group-text --stylebtnVariant">%</span>
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Cantidad Descuento</label>
                                        <input
                                            id='discountQty'
                                            type="number"
                                            value={formData.discountQty}
                                            className={`form-control --stylebtnVariant text-light centered-placeholder centered-input-container d-flex pad-web`}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Imagen promo</label>
                                        <div className="d-flex align-items-center">
                                            <span className="me-3 text-secondary">
                                                {file ? file.name : "Selecciona un archivo"}
                                            </span>
                                            <label id='input-img' className="d-inline">
                                                <input
                                                    type="file"
                                                    accept=".jpg,.png,.jpeg"
                                                    className="d-none"
                                                    onChange={handleFileSelect}
                                                />
                                                <i className="bi bi-folder-plus px-5 mx-1 border border-dark point"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>
                                Cerrar
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
};

export default NewEventModal;