import TopbarCoparmex from '../main/TopBarCoparmex';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { ResultData } from '../interfaces/UserInterface';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import PromoCostModal from '../modals/PromoCostModal';
import Cropper, { Area } from 'react-easy-crop';
import "../../assets/css/Promotion.css"
import EventCostModal from '../modals/EventCostModal';
import handleLogout from '../../functions/functions';

const UploadPromo = () => {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const luisa_email = localStorage.getItem('luisa_email');

    const maxFileSize = 10 * 1024 * 1024;

    //File
    const [fileName, setFileName] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string>('');

    //Crop
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [isCropping, setIsCropping] = useState<boolean>(false);

    //Inputs
    const [promoTitle, setPromoTitle] = useState<string>('');
    const [promoDescription, setPromoDescription] = useState<string>('');
    const [qty, setQty] = useState<number>(0);

    //Tags
    const [inputValue, setInputValue] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);

    //Datepicker
    const today = new Date();
    const maxEndDate = new Date(today);
    maxEndDate.setDate(today.getDate() + 30);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    //Modal
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalEventOpen, setIsModalEventOpen] = useState(false);

    //Favoritos
    const [isFavorito, setIsFavorito] = useState<boolean>(false);

    //Pay
    const [getHandlePay, setHandlePay] = useState(0);
    const [isNotificationActive, setIsNotificationActive] = useState(false);

    // Image dimensions
    const [imageWidth, setImageWidth] = useState<number>(0);
    const [imageHeight, setImageHeight] = useState<number>(0);

    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');
        const promo_id = urlParams.get('promo_id');

        if (status === 'success') {
            setPayment();
            showPayNotification('Pago completado con éxito');

        } else if (status === 'cancel') {
            showErrorNotification('El pago fue cancelado');
        }

        if (status === 'success' || status === 'cancel') {
            setTimeout(() => {
                urlParams.delete('status');
                urlParams.delete('promo_id');
                const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                window.history.replaceState(null, '', newUrl);
            }, 3000);
        }
        
        async function setPayment() {
            try {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/updatePromoPayment`, {
                    promo_id: promo_id
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${luisa_token}`,
                        }
                    }
                );
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                console.error('Error:', error);
            }
        }
    }, []);

    const checkImageDimensions = (url: string): Promise<{width: number, height: number}> => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
            img.src = url;
        });
    };

    const isFileSizeValid = (file: File): boolean => {
        return file.size <= maxFileSize;
    };

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile && isValidFileType(selectedFile.name)) {
            const url = URL.createObjectURL(selectedFile);
            
            // Check file size first
            if (!isFileSizeValid(selectedFile)) {
                showWarnNotification('El tamaño máximo permitido es de 10MB');
                URL.revokeObjectURL(url);
                return;
            }
            
            // Now check dimensions
            const dimensions = await checkImageDimensions(url);
            setImageWidth(dimensions.width);
            setImageHeight(dimensions.height);
            
            if (dimensions.width > 2000 || dimensions.height > 2000) {
                showWarnNotification('Las dimensiones máximas permitidas son de 2000x2000');
                // Still allow the image to be loaded for cropping
            }
            
            setFileUrl(url);
            setFile(selectedFile);
            setCroppedImage(null);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .png, .jpg o .jpeg');
        }
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile && isValidFileType(droppedFile.name)) {
            const url = URL.createObjectURL(droppedFile);
            
            // Check file size first
            if (!isFileSizeValid(droppedFile)) {
                showWarnNotification('El tamaño máximo permitido es de 10MB');
                URL.revokeObjectURL(url);
                return;
            }
            
            // Now check dimensions
            const dimensions = await checkImageDimensions(url);
            setImageWidth(dimensions.width);
            setImageHeight(dimensions.height);
            
            if (dimensions.width > 2000 || dimensions.height > 2000) {
                showWarnNotification('Las dimensiones máximas permitidas son de 2000x2000');
                // Still allow the image to be loaded for cropping
            }
            
            setFileUrl(url);
            setFile(droppedFile);
            setCroppedImage(null);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .png, .jpg o .jpeg');
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const isValidFileType = (fileName: string) => {
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        return fileExtension && allowedExtensions.includes(fileExtension);
    };

    const handleRemoveFile = () => {
        setFile(null);
        setFileUrl('');
        setCroppedImage(null);
        setImageWidth(0);
        setImageHeight(0);
        // Reset file input value
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) fileInput.value = '';
    };

    const handleValidate = () => {
        let validateFlag = false;
        
        if (!file) {
            showWarnNotification('Selecciona un archivo antes de continuar');
            return;
        }
        
        if (!promoTitle) {
            showWarnNotification('Ingresa el título de la publicación');
            return;
        }
        
        if (!promoDescription) {
            showWarnNotification('Ingresa la descripción de la publicación');
            return;
        }
        
        if (!startDate || !endDate) {
            showWarnNotification('Selecciona la fecha de inicio y fin');
            return;
        }
        
        // Check if the image is valid after cropping
        if (croppedImage) {
            // If we have a cropped image, it's already valid
            setIsModalOpen(true);
        } else {
            // Check if original image needs cropping due to size issues
            if (file.size > maxFileSize) {
                showWarnNotification('El tamaño máximo permitido es de 10MB. Por favor, recorta la imagen para reducir su tamaño.');
                setIsCropping(true);
                return;
            }
            
            // Check if original image needs cropping due to dimension issues
            if (imageWidth > 2000 || imageHeight > 2000) {
                showWarnNotification('Las dimensiones máximas permitidas son de 2000x2000. Por favor, recorta la imagen para reducir sus dimensiones.');
                setIsCropping(true);
                return;
            }
            
            // If we get here, the original image is valid
            setIsModalOpen(true);
        }
    };

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const generateCroppedImage = async () => {
        if (!croppedAreaPixels || !fileUrl) return;

        const canvas = document.createElement('canvas');
        const image = new Image();
        image.src = fileUrl;

        await new Promise((resolve) => (image.onload = resolve));

        const { width, height, x, y } = croppedAreaPixels;
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d')!;
        ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

        return new Promise<string>((resolve) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    const croppedImageUrl = URL.createObjectURL(blob);
                    resolve(croppedImageUrl);
                }
            }, file?.type || 'image/jpeg', 0.85); // 0.85 quality helps reduce file size
        });
    };

    const handleConfirmCrop = async () => {
        const croppedImageUrl = await generateCroppedImage();
        if (croppedImageUrl) {
            // Check dimensions of cropped image
            const dimensions = await checkImageDimensions(croppedImageUrl);
            setImageWidth(dimensions.width);
            setImageHeight(dimensions.height);
            
            setCroppedImage(croppedImageUrl);
            setIsCropping(false);
            
            // Fetch the blob to check its size
            const response = await fetch(croppedImageUrl);
            const blob = await response.blob();
            
            if (blob.size > maxFileSize) {
                showWarnNotification('La imagen sigue siendo demasiado grande después de recortar. Intenta reducir más el área de recorte.');
                // Keep the cropped image but let the user know they might need to crop again
            }
        }
    };

    const handleUploadPromo = async () => {
        if (!file || !startDate || !endDate) return;

        let ini = new Date(startDate);
        let end = new Date(endDate);
        
        const formData = new FormData();
        formData.append('username', `${luisa_email?.split('@')[0]}`);
        formData.append('rfc', `${luisa_rfc}`);
        formData.append('qty', qty.toString());
        formData.append('promo_title', promoTitle);
        formData.append('promo_description', promoDescription);
        formData.append('promo_tags', tags.join(','));
        formData.append('ini_date', ini.getFullYear() + '-' + (ini.getMonth() + 1) + '-' + ini.getDate());
        formData.append('end_date', end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate());
        formData.append('favorite', String(isFavorito));

        if (croppedImage) {
            const response = await fetch(croppedImage);
            const blob = await response.blob();

            // Final check on the cropped blob
            if (blob.size > maxFileSize) {
                showWarnNotification('La imagen sigue siendo demasiado grande. Intenta recortar un área más pequeña.');
                return 'err';
            }

            formData.append('file', blob, file.name);
        } else if (file) {
            // Final check on the original file
            if (file.size > maxFileSize || imageWidth > 2000 || imageHeight > 2000) {
                showWarnNotification('Por favor, recorta la imagen antes de subir.');
                setIsCropping(true);
                return 'err';
            }
            
            formData.append('file', file);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/uploadPromo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=UTF-8',
                    'Authorization': `Bearer ${luisa_token}`
                }
            });

            if (response.status === 200) {
                const data = response.data.data;
                //showNotification('¡La publicación se subió correctamente!')

                setPromoTitle('');
                setPromoDescription('');
                setTags([]);
                setStartDate(null);
                setEndDate(null);
                setFile(null);
                setFileUrl('');
                setCroppedImage(null);
                setIsFavorito(false);
                setImageWidth(0);
                setImageHeight(0);

                return data;

            } else {
                showErrorNotification('Ocurrio un error al realizar subir la publicación');
                return 'err';
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.message;
                    console.log(getError);
                    showErrorNotification('Ocurrio un error al realizar la publicación');
                } else if (error.request) {
                    showErrorNotification('Ocurrio un error al realizar la publicación');
                    console.log('Request made but no response received:', error.request);
                } else {
                    showErrorNotification('Ocurrio un error al procesar la imagen');
                    console.log('Error message:', error.message);
                }
            } else {
                showErrorNotification('Ocurrio un error al realizar la publicación');
            }
            return 'err';
        }
    };

    const handleAddTag = () => {
        if (tags.length > 9) {
            showWarnNotification('Solo puedes agregar hasta 10 palabras');
            return;
        }
        if (inputValue.trim() && !tags.includes(inputValue.trim())) {
            setTags([...tags, inputValue.trim()]);
            setInputValue('');
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddTag();
        }
    };

    const handleRemoveTag = (index: number) => {
        setTags(tags.filter((_, i) => i !== index));
    };

    const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPromoTitle(event.target.value);
    };

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setPromoDescription(event.target.value);
    };

    const handlePay = async (amount: number) => {
        let getPromoId = await handleUploadPromo();
        const setAmount = amount * 100;

        if (getPromoId !== 'err') {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getPaymentLinkPromo`, {
                    rfc: luisa_rfc,
                    amount: setAmount,
                    promo_id: getPromoId,
                    requires_invoice: 1,
                    qty: qty
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });
                if (response.status === 200) {
                    const linkPay = response.data.data;

                    //localStorage.setItem('returnUrl', window.location.href);
                    window.location.href = linkPay;

                    //setIsModalOpen(false);
                }

            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        console.log(getError);

                    } else if (error.request) {
                        console.log('Request made but no response received:', error.request);
                    } else {
                        console.log('Error message:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            }
        } else {
            showErrorNotification('Ocurrio un error al realizar la publicación');
        }
    }

    const showNotification = (text: String) => {
        toast.success(text, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showWarnNotification = (text: String) => {
        if (!isNotificationActive) {
            setIsNotificationActive(true);
            toast.warning(text, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => setIsNotificationActive(false)  // Cuando la notificación se cierra, se marca como inactiva
            });
        }
    };

    const showPayNotification = (text: String) => {
        if (!isNotificationActive) {
            setIsNotificationActive(true);
            toast.success(text, {
                position: "top-right",
                autoClose: 8000,
                closeOnClick: true,
                pauseOnHover: true,
                onClose: () => setIsNotificationActive(false)  // Cuando la notificación se cierra, se marca como inactiva
            });
        }
    };

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <ToastContainer />
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-10 col-lg-8'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'><i className="bi bi-arrow-return-left me-3 point" onClick={() => navigate('/promo')}></i>Subir Nueva Publicidad</h1>
                        <div className="d-flex align-items-center animate__animated animate__fadeIn">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className=" row mb-3 linkup-form-bg">
                                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3 px-5">
                                        <div
                                            className="bg-folder-gray rounded mt-5 py-5 text-center text-white"
                                            onClick={() => !file && document.getElementById('fileInput')?.click()}
                                            onDrop={handleDrop}
                                            onDragOver={handleDragOver}
                                            style={{
                                                cursor: file ? 'default' : 'pointer',
                                                position: 'relative',
                                                height: '345px',
                                            }}
                                        >
                                            {fileUrl ? (
                                                <>
                                                    {isCropping ? (
                                                        <>
                                                            <Cropper
                                                                image={fileUrl}
                                                                crop={crop}
                                                                zoom={zoom}
                                                                aspect={1}
                                                                onCropChange={setCrop}
                                                                onZoomChange={setZoom}
                                                                onCropComplete={onCropComplete}
                                                            />
                                                            <div className="d-flex justify-content-center mt-3 position-absolute fixed-bottom">
                                                                <button
                                                                    className="btn btn-success me-2 z-1"
                                                                    onClick={handleConfirmCrop}
                                                                >
                                                                    Confirmar
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger z-1"
                                                                    onClick={() => setIsCropping(false)}
                                                                >
                                                                    Cancelar
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : croppedImage ? (
                                                        <>
                                                            <img
                                                                src={croppedImage}
                                                                alt="Cropped Preview"
                                                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                                            />
                                                            <div className="d-flex justify-content-center mt-1">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={() => setIsCropping(true)}
                                                                >
                                                                    Recortar
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger ms-2"
                                                                    onClick={handleRemoveFile}
                                                                >
                                                                    Cambiar
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={fileUrl}
                                                                alt="Preview"
                                                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                                            />
                                                            <div className="d-flex justify-content-center mt-1">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={() => setIsCropping(true)}
                                                                >
                                                                    Recortar
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger ms-2"
                                                                    onClick={handleRemoveFile}
                                                                >
                                                                    Cambiar
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <div className='p-5'>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        style={{ display: 'none' }}
                                                        accept=".jpg,.png,.jpeg"
                                                        onChange={handleFileSelect}
                                                    />
                                                    <i className="bi bi-folder-plus px-5 point bg-folder-gray"></i>
                                                    <p className="mt-4">Añade tu publicidad aquí</p>
                                                </div>
                                            )}
                                        </div>
                                        <button type="button" onClick={handleValidate} className="btn btn-primary w-100 mt-5 py-3">Subir Publicidad</button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <div className="mt-4 mb-3">
                                                <label className='--textPrimaryVariant'>Título de la Publicación</label>
                                                <input
                                                    id='title'
                                                    type="text"
                                                    className={`form-control --stylebtnVariant centered-placeholder centered-input-container d-flex pad-web`}
                                                    placeholder='Nueva publicación'
                                                    autoComplete="off"
                                                    maxLength={40}
                                                    value={promoTitle}
                                                    onChange={handleTitleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className='--textPrimaryVariant'>Descripción de la Publicación</label>
                                                <textarea
                                                    id='desc'
                                                    className={`form-control mt-3 centered-placeholder centered-input-container visionTextArea d-flex`}
                                                    placeholder="Descripción"
                                                    rows={3}
                                                    autoComplete="off"
                                                    maxLength={500}
                                                    value={promoDescription}
                                                    onChange={handleDescriptionChange}
                                                ></textarea>
                                            </div>
                                            <div className="mb-3">
                                                <label className='--textPrimaryVariant tooltip-button' data-tooltip="Palabras que mejoran la visibilidad de tu publicidad">
                                                    Palabras clave
                                                    <i className="bi bi-question-circle-fill ms-1"></i>
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        id='keyWords'
                                                        type="text"
                                                        className='form-control centered-placeholder centered-input-container d-flex --stylebtnVariant'
                                                        placeholder='Opcional (recomendado)'
                                                        autoComplete="off"
                                                        value={inputValue}
                                                        onChange={(e) => setInputValue(e.target.value)}
                                                        onKeyDown={handleKeyPress}
                                                    />
                                                    <button onClick={handleAddTag} className="btn btn-primary"><i className="bi bi-plus-circle"></i></button>
                                                </div>

                                                <div className="mt-3">
                                                    {tags.map((tag, index) => (
                                                        <span
                                                            key={index}
                                                            className="btn btn-primary badge me-2"
                                                            style={{ display: 'inline-block', marginBottom: '5px', cursor: 'pointer' }}
                                                            onClick={() => handleRemoveTag(index)}
                                                        >
                                                            <i className="bi bi-x-lg me-1"></i> {tag}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <label className='--textPrimaryVariant'>Fecha de Publicación</label>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', maxWidth: 300 }}>
                                                <DatePicker
                                                    className='btn btn-secondary'
                                                    label="Fecha de inicio"
                                                    value={startDate}
                                                    onChange={(newValue) => {
                                                        setStartDate(newValue);
                                                        if (endDate && newValue && newValue > endDate) {
                                                            setEndDate(null);
                                                        }
                                                    }}
                                                    minDate={today}
                                                    slotProps={{
                                                        textField: {
                                                            variant: 'filled'
                                                        },
                                                        openPickerIcon: { fontSize: 'large' }
                                                    }}
                                                />
                                                <DatePicker
                                                    className='btn btn-secondary'
                                                    label="Fecha de fin"
                                                    value={endDate}
                                                    onChange={(newValue) => setEndDate(newValue)}
                                                    minDate={startDate ? new Date(startDate.getTime() + 24 * 60 * 60 * 1000) : null}
                                                    maxDate={maxEndDate}
                                                    slotProps={{
                                                        textField: {
                                                            variant: 'filled'
                                                        },
                                                        openPickerIcon: { fontSize: 'large' }
                                                    }}
                                                    disabled={!startDate}
                                                />
                                            </div>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <PromoCostModal show={isModalOpen}
                onHide={() => {
                    setIsModalOpen(false);
                }}
                onClick={() => {
                    //handleUploadPromo();
                }}
                callPay={(amount, quantity) => {
                    setQty(quantity)
                    setHandlePay(amount);
                    setIsModalOpen(false);
                    setIsModalEventOpen(true);
                }}
            />

            <EventCostModal show={isModalEventOpen}
                onHide={() => {
                    setIsModalEventOpen(false);
                    setIsModalOpen(true);
                }}
                callPay={() => {
                    //console.log(getHandlePay);
                    handlePay(getHandlePay);
                }}
                amount={getHandlePay}
                texto={'tu publicidad'}
            />
        </>
    )
};

export default UploadPromo;
