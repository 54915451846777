import { useEffect, useState } from 'react';
import React from 'react';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import CreateRoleModal from '../modals/CreateRol';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "../../assets/css/RolesAndPermisions.css";
import 'react-toastify/dist/ReactToastify.css';
import DeleteRole from '../modals/DeleteRole';
import ModuleSection from '../sections/ModuleSection';
import handleLogout from '../../functions/functions';

interface Permissions {
    Vinculación: { [key: string]: boolean };
    Publicidad: { [key: string]: boolean };
    Eventos: { [key: string]: boolean };
    Comisiones: { [key: string]: boolean };
    Luisa: { [key: string]: boolean };
    Configuración: { [key: string]: boolean };
    Administración: { [key: string]: boolean };
}

function RolesPermisos() {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const navigate = useNavigate();
    const [roles, setRoles] = useState<string[]>([]);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedRole, setSelectedRole] = useState("Roles");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [roleToDelete, setRoleToDelete] = useState<string | null>(null);
    const [permissions, setPermissions] = useState<Permissions>({
        Vinculación: { ver: false, crear: false, eliminar: false },
        Publicidad: { ver: false, crear: false, eliminar: false },
        Eventos: { ver: false, crear: false, eliminar: false },
        Comisiones: { ver: false, crear: false, eliminar: false },
        Luisa: { ver: false, crear: false, eliminar: false },
        Configuración: { ver: false, crear: false, eliminar: false },
        Administración: { ver: false, crear: false, eliminar: false },
    });
    const [searchTerm, setSearchTerm] = useState('');

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);



    const handleSave = async (data: { name: string }) => {
        try {

            if (data.name.length > 50) {
                showErrorNotification('El nombre debe ser de máximo 50 caracteres');
                return;
            }

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addRole`, {
                rfc: luisa_rfc,
                name: data.name
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            if (response.status === 200) {
                //console.log(response.data);

                if (response.data.message === 'exists') {
                    showErrorNotification('El rol que intentas crear ya existe');
                }
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    if (getError === '[Luisa-Backend] Error adding linkup profile') {
                        showErrorNotification('Error');
                    }
                    else {
                        showErrorNotification('Error');
                    }
                } else if (error.request) {
                    showErrorNotification('Error');
                    console.log('Request made but no response received:', error.request);
                } else {
                    showErrorNotification('Error');
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
        fetchRole();
        handleCloseModal();
    };

    const fetchRole = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get_roles/${luisa_rfc}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            //console.log(response);

            if (response.status === 200) {
                const rolData = response.data.roles;
                setRoles(rolData);
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    };

    const handleSelectRole = async (role: React.SetStateAction<string>) => {
        setSelectedRole(role);
        setDropdownOpen(false);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getRolesByName`, {
                rfc: luisa_rfc,
                name: role
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });

            if (response.status === 200) {
                const rolData = response.data.roles.roles[0];
                setPermissions({
                    Vinculación: rolData['Vinculación'] ? rolData['Vinculación'][0] : {},
                    Publicidad: rolData['Publicidad'] ? rolData['Publicidad'][0] : {},
                    Eventos: rolData['Eventos'] ? rolData['Eventos'][0] : {},
                    Comisiones: rolData['Comisiones'] ? rolData['Comisiones'][0] : {},
                    Luisa: rolData['Luisa'] ? rolData['Luisa'][0] : {},
                    Configuración: rolData['Configuración'] ? rolData['Configuración'][0] : {},
                    Administración: rolData['Administración'] ? rolData['Administración'][0] : {}
                });

            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    };

    const openDeleteModal = (role: string) => {
        setRoleToDelete(role);
        setShowDeleteModal(true);
    };

    const confirmDeleteRole = async () => {
        if (roleToDelete) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/deleteRole`, {
                    rfc: luisa_rfc,
                    name: roleToDelete,
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });
                if (response.status === 200) {
                    //console.log(response.data);
                    const updatedRoles = roles.filter(role => role !== roleToDelete);
                    setRoles(updatedRoles);
                }

            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        console.log(getError);

                    } else if (error.request) {
                        console.log('Request made but no response received:', error.request);
                    } else {
                        console.log('Error message:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            }
        }
        setShowDeleteModal(false);
        setRoleToDelete(null);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    useEffect(() => {
        fetchRole();
    }, []);

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <TopbarCoparmex />
            {modalOpen && (
                <CreateRoleModal onClose={handleCloseModal} onSave={handleSave} />
            )}
            <ToastContainer />
            <div className="py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'>
                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/configuration')}>
                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                            </svg>
                            Roles y Permisos
                        </h1>
                        <div className='p-3 sub-header'>
                            <h1 className='fw-bolder text-blue mb-4'>Roles</h1>
                            <span>Luisa asigna roles (administradores y afiliados) con permisos específicos, controlando el acceso a funcionalidades según el tipo de usuario en la plataforma.</span>
                            <div className='row mt-5'>
                                <div className='col-md-4 d-flex justify-content-end align-items-center border-end border-primary'>
                                    <button className='btn btn-primary me-5' onClick={handleOpenModal}><i className="bi bi-plus"></i> Crear rol</button>
                                </div>
                                <div className='col-md-6'>
                                    <span>Para poder configurar los permisos de Rol es necesario que primero lo selecciones.</span>
                                    <Dropdown className='h-100 w-100 col-md-6 col-lg-6 col-sm-12 mt-4' show={isDropdownOpen} onToggle={() => setDropdownOpen(!isDropdownOpen)}>
                                        <Dropdown.Toggle className='w-100 text-start' onClick={() => setDropdownOpen(!isDropdownOpen)}>
                                            {selectedRole}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="p-2 w-100">
                                            {roles.length > 0 ? (
                                                roles.map((role, index) => (
                                                    <div key={index}>
                                                        <Dropdown.Item className='d-inline' onClick={() => handleSelectRole(role)}>
                                                            {role}
                                                        </Dropdown.Item>
                                                        <span className='bi-red float-end point' onClick={() => openDeleteModal(role)}>Eliminar Rol</span>
                                                    </div>
                                                ))
                                            ) : (
                                                <span>No hay roles.</span>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end mt-5'>
                            <div className='col-lg-6 col-md-6 col-sm-12 searchBar'>
                                <div className="input-group h-100">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="bi bi-search"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar"
                                        aria-label="vinculacion"
                                        aria-describedby="basic-addon1"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                        </div>
                        <ModuleSection
                            title="Vinculación"
                            roleName={selectedRole}
                            items={[
                                { label: 'Vinculaciones', ids: ['checkUsers', 'checkUsers1', 'checkUsers2'] },
                            ]}
                            searchTerm={searchTerm}
                            permissions={permissions.Vinculación}
                        />
                        <ModuleSection
                            title="Publicidad"
                            roleName={selectedRole}
                            items={[
                                { label: 'Publicaciones', ids: ['newPost', 'newPost1', 'newPost2'] },
                            ]}
                            searchTerm={searchTerm}
                            permissions={permissions.Publicidad}
                        />
                        <ModuleSection
                            title="Eventos"
                            roleName={selectedRole}
                            items={[
                                { label: 'Eventos', ids: ['seeEvent', 'seeEvent1', 'seeEvent2'] },
                            ]}
                            searchTerm={searchTerm}
                            permissions={permissions.Eventos}
                        />
                        <ModuleSection
                            title="Comisiones"
                            roleName={selectedRole}
                            items={[
                                { label: 'Comisiones', ids: ['seeCommission', 'seeCommission1', 'seeCommission2', 'seeCommission3'] },
                            ]}
                            searchTerm={searchTerm}
                            permissions={permissions.Comisiones}
                        />
                        {/* <ModuleSection
                            title="Luisa"
                            roleName={selectedRole}
                            items={[
                                { label: 'Principal', ids: ['seeLuisa', 'seeLuisa1', 'seeLuisa2'] },
                            ]}
                            searchTerm={searchTerm}
                            permissions={permissions.Luisa}
                        /> */}
                        <ModuleSection
                            title="Configuración"
                            roleName={selectedRole}
                            items={[
                                { label: 'Administración de usuarios', ids: ['admUsers', 'admUsers1', 'admUsers2'], tag: 'adminUsers' },
                                { label: 'Roles y permisos', ids: ['role', 'role1', 'role2'], tag: 'rolesPermissions' },
                            ]}
                            searchTerm={searchTerm}
                            permissions={permissions.Configuración}
                        />
                        <ModuleSection
                            title="Administración"
                            roleName={selectedRole}
                            items={[
                                { label: 'Mesa de control', ids: ['ctrlTable', 'ctrlTable1', 'ctrlTable2'], tag: 'controlTable' },
                                { label: 'Bases de conocimiento', ids: ['knwBase', 'knwBase1', 'knwBase2'], tag: 'knowledgeBase' },
                                { label: 'Eventos', ids: ['admEvts', 'admEvts1', 'admEvts2'], tag: 'adminEvents' },
                                { label: 'Costos', ids: ['costs', 'costs1', 'costs2'], tag: 'costs' },
                                { label: 'Comisiones de trabajo', ids: ['workCom', 'workCom1', 'workCom2'], tag: 'workCommissions' },
                                { label: 'Finanzas', ids: ['finance', 'finance1', 'finance2'], tag: 'finance' },
                            ]}
                            searchTerm={searchTerm}
                            permissions={permissions.Administración}
                        />
                    </div>
                </div>
            </div>

            <DeleteRole
                show={showDeleteModal}
                role={roleToDelete || ""}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={confirmDeleteRole}
            />
        </>
    );
}

export default RolesPermisos;