import React, { useState, useEffect, ChangeEvent } from "react";
import { Form, useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../assets/css/Promotion.css";
import Logo from "../../assets/img/logo_luisa.png";
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventCostModal from "../modals/EventCostModal";
import { send } from "process";
import { set } from "date-fns";
import handleLogout from '../../functions/functions';
import { is } from "date-fns/locale";

interface Event {
    _id: string;
    title: string;
    description: string;
    event_date: string;
    event_time: string;
    price_member: number;
    price_general: number;
    banner_image_url: string;
    image_url?: string;
    discount: {
        quantity_discount: number;
        percentage: number;
    };
    members_only: Boolean;
    location: string;
}

const EventDetail = () => {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const luisa_phone = localStorage.getItem('luisa_whatsapp_number');
    const luisa_email = localStorage.getItem('luisa_email');
    const luisa_username = localStorage.getItem("luisa_username");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalEventOpen, setIsModalEventOpen] = useState(false);

    const navigate = useNavigate(); // Inicializar el hook useNavigate

    // State variables for the form inputs
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [paymentLocation, setPaymentLocation] = useState("stripe");
    const [requiresInvoice, setRequiresInvoice] = useState(false);
    const [amount, setAmount] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [percentage, setPercentage] = useState(0);

    const [event, setEvent] = useState<Event | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchParams] = useSearchParams();

    const [qty, setQty] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);

    const [isNotificationActive, setIsNotificationActive] = useState(false);

    const handleQtyTickets = async (event: ChangeEvent<HTMLSelectElement>) => {
        let qtyNum = parseInt(event.target.value);
        setQty(event.target.value);
        if (amount) {
            setTotalAmount(qtyNum * amount);
        }
    }

    const eventId = searchParams.get("id");

    const handleRegistroClick = async (isPay?: boolean) => {
        if (luisa_rfc) {
            const getAttendees = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/events/getAttendeesByRfc`,
                {
                    event_id: eventId,
                    rfc: luisa_rfc
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );
            if (getAttendees.data.qty >= 4) {
                return 'limit reached';
            } else {
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/v1/events/insertAttendee`,
                        {
                            event_id: eventId,
                            rfc: luisa_rfc,
                            payment_location: "transfer",
                            payment_confirmation: "false",
                            requires_invoice: false,
                            is_member: true,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${luisa_token}`,
                            },
                        }
                    );
                    //console.log("Asistente afiliado registrado:", response.data);
                    setIsModalEventOpen(false);
                    if (email || !isPay) {
                        showNotification('Registro exitoso, recuerda realizar tu pago el día del evento');
                    }
                    return response.data.att_id;
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        const status = error.response.status;
                        if (status === 401 || status === 403) {
                            console.log("Token expirado, cerrando sesión...");
                            handleLogout();
                        }
                    }
                    console.error("Error al registrar asistente afiliado:", error);
                    //alert("Ocurrió un error al registrar como afiliado.");
                    return 'err'
                }
            }
        } else {
            setIsModalOpen(true);
        }
    };

    const handleSubmit = async () => {
        try {

            const getAttendees = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/events/getAttendeesByEmail`,
                {
                    event_id: eventId,
                    attendee_email: email,
                    attendee_phone: phone
                },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );
            if (getAttendees.data.qty >= 4) {
                return 'limit reached';
            } else {

                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/events/insertAttendee`,
                    {
                        event_id: eventId,
                        name,
                        phone,
                        email,
                        payment_location: paymentLocation,
                        requires_invoice: requiresInvoice,
                        is_member: undefined,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }
                );
                setIsModalOpen(false);
                return response.data.att_id;
            }
        } catch (error) {
            console.error("Error al registrar asistente:", error);
            //alert("Ocurrió un error al registrar al asistente.");
            return 'err';
        }
    };

    const handlePay = async (total?: number, entriesQty?: number) => {
        let getEventResponse = null;
        let setRfc = null;
        let setPhone = null;
        let setEmail = null;
        let setUsername = null;
        let setIsMember = undefined;

        setPhone = phone;
        setEmail = email;
        setUsername = name;

        if (luisa_phone) {
            setPhone = luisa_phone;
        }

        if (total) {
            setTotalAmount(total);
        }

        if (!email) {
            //getEventResponse = await handleRegistroClick(true);
            setRfc = luisa_rfc;
            setEmail = luisa_email;
            setUsername = luisa_username;
            setIsMember = true;
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(email)) {
                showErrorNotification('Por favor ingresa un correo válido');
                return;
            }
            if (paymentLocation === 'transfer') {
                handleSubmit();
                showNotification('Registro exitoso, recuerda realizar tu pago el día del evento');
                return;
            }
            if (qty === '') {
                showErrorNotification('Selecciona al menos 1 boleto');
                return;
            }
            //getEventResponse = await handleSubmit();
        }

        if (getEventResponse !== 'limit reached') {
            try {
                const requestData: { [key: string]: any } = {
                    rfc: setRfc,
                    phone: setPhone,
                    amount: total ?? totalAmount,
                    event_id: eventId,
                    att_id: getEventResponse,
                    requires_invoice: requiresInvoice,
                    email: setEmail,
                    username: setUsername,
                    entriesQty: entriesQty,
                    payment_location: paymentLocation,
                    is_member: setIsMember
                };

                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getPaymentLinkEventDetail`, requestData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });
                //console.log(response);
                if (response.status === 200) {
                    const linkPay = response.data.data;
                    window.location.href = linkPay;
                }

            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const getError = error.response.data.error;
                        console.log(getError);

                    } else if (error.request) {
                        console.log('Request made but no response received:', error.request);
                    } else {
                        console.log('Error message:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            }
        } else {
            showErrorNotification('Alcanzaste el limite de entradas para este evento');
        }
    }

    const handleValidateName = async (name: string) => {
        if (!/^([^0-9]*)$/.test(name)) {
            setName('');
        }
    }

    const handleValidateNumber = async (number: string) => {
        var regex = /[0-9]|\./;
        if (!regex.test(number))
            setPhone('')

        if (number.length > 9)
            setPhone(number.slice(0, 10));
    }

    const fetchEventDetail = async () => {
        try {
            setIsLoading(true);
            const luisa_token = localStorage.getItem("luisa_token");

            // Fetch event details
            const eventResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/events/getEventbyId`,
                { event_id: eventId },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            let eventData: Event = eventResponse.data.data[0];

            // Fetch the image URL
            const imageResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/get_image_event/${eventData.banner_image_url}`,
                {
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            eventData = {
                ...eventData,
                image_url: imageResponse.data.url,
            };

            setEvent(eventData);

            setQuantity(eventData.discount.quantity_discount);
            setPercentage(eventData.discount.percentage);
            setError(null);

            const urlParams = new URLSearchParams(window.location.search);
            const status = urlParams.get('status');
            const send_email = urlParams.get('email');
            const send_name = urlParams.get('username');
            // console.log(status, send_email, send_name);
            
            if (status === 'success') {
                showPayNotification('Pago completado con éxito, tu registro esta completo');
                // if (send_email && send_name) {
                //     sendEmail(send_email, send_name, eventData);
                // }
            } else if (status === 'cancel') {
                showErrorNotification('El pago fue cancelado, intenta de nuevo más tarde');
            }

            if (status === 'success' || status === 'cancel') {
                setTimeout(() => {
                    urlParams.delete('status');
                    urlParams.delete('email');
                    urlParams.delete('username');
                    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                    window.history.replaceState(null, '', newUrl);
                }, 3000);
            }

        } catch (error) {
            console.error("Error fetching event detail or image:", error);
            setError("Failed to fetch event details");
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const sendEmail = async (email: string, name: string, eventDataEmail: Event) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sendEmailEvent`,
                {
                    email: email,
                    name: name,
                    eventId: eventId,
                    event: eventDataEmail.title,
                    location: eventDataEmail.location,
                    date: eventDataEmail.event_date,
                    time: eventDataEmail.event_time
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            if (response.status === 200) {
                console.log('Email sent');
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    }

    const showNotification = (text: String) => {
        toast.success(text, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showPayNotification = (text: String) => {
        if (!isNotificationActive) {
            setIsNotificationActive(true);
            toast.success(text, {
                position: "top-right",
                autoClose: 8000,
                closeOnClick: true,
                pauseOnHover: true,
                onClose: () => setIsNotificationActive(false)  // Cuando la notificación se cierra, se marca como inactiva
            });
        }
    };

    useEffect(() => {
        if (eventId) {
            fetchEventDetail();
        }
    }, [eventId]);

    useEffect(() => {
        if (event) {
            if (luisa_rfc) {
                setAmount(event.price_member);
            } else {
                setAmount(event.price_general);
            }
        }
    }, [event, luisa_rfc]);

    return (
        <>
            <ToastContainer limit={1}/>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : error ? (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            ) : event && (
                <>
                    <div
                        className="w-100 p-4 bg-coparmex-blue topbar-coparmex position-relative"
                        style={{ zIndex: 10 }}
                    >
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-5 col-sm-5 point">
                                <img className="coparmex-logo" src={Logo} alt="COPARMEX Logo" onClick={() => navigate("/")}/>
                            </div>
                        </div>
                    </div>
                    <div className="linkup-bg h-100">
                        <div className="flex-column align-items-center px-5">
                            <div className="row justify-content-center">
                                <div className="col-sm-12 col-md-10 col-lg-8">
                                    <div className="w-100 mt-4 mb-3">
                                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'>
                                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/events')}>
                                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                                            </svg>
                                            {event.title}
                                        </h1>
                                    </div>

                                    <div className="px-0 pt-0 pb-5 bg-light custom-event-image">
                                        <div className="row w-100 custom-event-image mx-0 mt-0 mb-4 px-0">
                                            <img
                                                className="img-fluid-custom-event px-0"
                                                src={event.image_url || ""}
                                                alt={event.title}
                                            />
                                        </div>

                                        <div className="row w-100 px-4 my-4">
                                            <div className="col-sm-12 col-lg-6">
                                                <h2 className="fw-bolder text-events">{event.title}</h2>
                                            </div>
                                            <div className="col-sm-12 col-lg-6 text-end">
                                                <h3 className="fw-bolder text-events">
                                                    <i>{new Date(event.event_date).toISOString().split('T')[0].split('-').reverse().join('/')}</i>
                                                </h3>
                                            </div>
                                        </div>

                                        {event.members_only && (
                                            <div className="row w-100 px-4 my-4">
                                                <i>(Exclusivo para socios)</i>
                                            </div>
                                        )}

                                        <div className="row w-100 px-4 description-text">
                                            <p>{event.description}</p>
                                        </div>

                                        {((event.members_only && luisa_rfc) || !event.members_only) && (
                                            <div className="row w-100 px-4 my-4">
                                                <button
                                                    className="btn btn-primary btn-lg"
                                                    onClick={() => {
                                                        if (!luisa_rfc) {
                                                            handleRegistroClick();
                                                        } else {
                                                            setIsModalEventOpen(true);
                                                        }
                                                    }}
                                                >
                                                    Registro
                                                </button>
                                            </div>
                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isModalOpen && (
                        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Registro de Asistente No Afiliado</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Nombre</label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="form-control"
                                            placeholder="Nombre completo"
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                                handleValidateName(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label">Teléfono</label>
                                        <input
                                            type="text"
                                            id="phone"
                                            className="form-control"
                                            placeholder="Teléfono"
                                            value={phone}
                                            onChange={(e) => {
                                                setPhone(e.target.value);
                                                handleValidateNumber(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Correo Electrónico</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="form-control"
                                            placeholder="Correo electrónico"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="paymentLocation" className="form-label">Cantidad de boletos</label>
                                        <select
                                            id="paymentLocation"
                                            className="form-select centered-placeholder centered-input-container"
                                            onChange={handleQtyTickets}
                                            value={qty}
                                        >
                                            <option value="">Selecciona la cantidad de boletos</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="paymentLocation" className="form-label">Forma de Pago</label>
                                        <select
                                            id="paymentLocation"
                                            className="form-select"
                                            value={paymentLocation}
                                            onChange={(e) => setPaymentLocation(e.target.value)}
                                        >
                                            <option value="stripe">Stripe (Crédito o Débito)</option>
                                            {/* <option value="transfer">Transferencia</option> */}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="requiresInvoice" className="form-label">¿Requiere Factura?</label>
                                        <select
                                            id="requiresInvoice"
                                            className="form-select"
                                            value={requiresInvoice ? "yes" : "no"}
                                            onChange={(e) =>
                                                setRequiresInvoice(e.target.value === "yes")
                                            }
                                        >
                                            <option value="yes">Sí</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => handlePay()}>
                                    Enviar
                                </Button>
                                <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}

                    <EventCostModal show={isModalEventOpen}
                        onHide={() => {
                            setIsModalEventOpen(false);
                        }}
                        onClick={() => {
                            handleRegistroClick();
                        }}
                        callPay={(total, entriesQty) => {
                            //setAmount(total);
                            handlePay(total, entriesQty);
                        }}
                        amount={amount}
                        quantity={quantity}
                        pctg={percentage}
                        texto={'tus boletos'}
                    />
                </>
            )}

        </>


    );
};

export default EventDetail;