import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../../assets/css/vinculacion.css';
import "../../assets/css/tablaVinculacion.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';
import { LinkupCostData } from '../interfaces/LinkupCostInterface';
import LinkupCostModal from '../modals/LinkupCostModal';
import handleLogout from '../../functions/functions';

const LinkupCost: React.FC = () => {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const luisa_username = localStorage.getItem('luisa_username');
    const [linkCost, setLinkCost] = useState<LinkupCostData[]>([]);

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 10;
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getLinkupCost`, {
                params: {
                    page: currentPage,
                    limit,
                },
                headers: {
                    'Authorization': `Bearer ${luisa_token}`
                }
            });
            if (response.status === 200) {

                const data = response.data.data;
                console.log(data);

                setLinkCost(data);
                setTotalPages(response.data.totalCount);

            } else {
                setLinkCost([]);
            }


        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.message;
                    console.log(getError);
                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                }
            } else {
                console.log('Error message:', error);
            }
        }
    };

    const handleNewLinkupCost = async (costo: number) => {
        try {
            console.log(typeof costo);

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/uploadLinkupCost`,
                {
                    //rfc: luisa_rfc,
                    username: luisa_username,
                    cost: costo,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );
            setIsModalOpen(false);
            console.log(response.data);

            if (response.status === 200) {
                fetchUsers();
                showNotification('Costo registrado exitosamente');
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            showErrorNotification('Ocurrió un error al guardar el costo, intenta de nuevo más tarde');
            console.error("Error: ", error);
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [currentPage]);

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePageClick = (page: number | string) => {
        if (typeof page === 'number') {
            setCurrentPage(page);
        }
    };

    const getPaginationRange = () => {
        const totalPagesToShow = 2; // Número de páginas a mostrar en el rango
        if (totalPages <= totalPagesToShow) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const range = [];
        const leftLimit = Math.max(2, currentPage - 1);
        const rightLimit = Math.min(totalPages - 1, currentPage + 1);

        // Primera página siempre visible
        range.push(1);

        if (leftLimit > 2) {
            range.push("...");
        }

        for (let i = leftLimit; i <= rightLimit; i++) {
            range.push(i);
        }

        if (rightLimit < totalPages - 1) {
            range.push("...");
        }

        // Última página siempre visible
        range.push(totalPages);

        return range;
    };

    const showNotification = (text: String) => {
        toast.success(text, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <ToastContainer />
            <div className='py-3 px-5 linkup-bg '>
                {/* <ToastContainer /> */}
                <div className='row justify-content-center'>

                    <div className="col-sm-10 col-md-12 col-lg-9 mt-4">

                        <div className="row gap-2">
                            <div className="col-sm-10 col-md-12 col-lg-9">

                                <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>
                                    <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/costos')}>
                                        <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                                    </svg>
                                    Costos de Vinculación
                                </h1>
                                <div className='p-3 sub-header'>
                                    <span>Luisa te ayuda a calcular los costos de la vinculación</span>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col-lg-4 col-md-4 col-sm-4 --w-75">
                                <button className='btn btn-linkup px-4 h-100 rounded-pill' onClick={() => setIsModalOpen(true)}>
                                    Nuevo costo
                                </button>
                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12' id='linkupTable'>

                        <div className="table-container mt-4">
                            <section className="wrapper">
                                <main className="row title">
                                    <ul className="d-flex justify-content-between align-items-center w-100 px-3">
                                        <li className="col-4 text-center fw-bold">Fecha de alta</li>
                                        <li className="col-4 text-center fw-bold">Costo unitario</li>
                                        <li className="col-4 text-center fw-bold">Configurado por</li>
                                    </ul>
                                </main>
                            </section>
                            {linkCost.length > 0 ? (
                                <>
                                    {linkCost.map((costData, index) => (
                                        <section key={index}>
                                            <article className="data-users mt-2">
                                                <ul className="d-flex justify-content-between align-items-center w-100 px-3">
                                                    <li className="col-4 text-center">{new Date(costData.fecha).toLocaleDateString()}</li>
                                                    <li className="col-4 text-center">{`$${costData.costo.toFixed(2)}`}</li>
                                                    <li className="col-4 text-center d-flex justify-content-center align-items-center">
                                                        <span className="text-truncate" title={costData.nombre}>
                                                            {costData.nombre}
                                                        </span>
                                                        {index === 0 && (
                                                            <div className="ms-2" style={{ position: 'relative' }}>
                                                                <i className="bi bi-check-circle-fill text-success" 
                                                                data-bs-toggle="tooltip" 
                                                                data-bs-placement="top" 
                                                                title="Costo Vigente"></i>
                                                            </div>
                                                        )}
                                                    </li>
                                                </ul>
                                            </article>
                                        </section>
                                    ))}
                                </>
                            ) : (
                                <p className="text-center">No hay costos registrados.</p>
                            )}
                        </div>
                        </div>
                        {/* Controles del paginador */}
                        <div className="pagination-controls">
                            {currentPage > 1 && (
                                <button onClick={handlePreviousPage} className="pagination-button-prev-next">
                                    <i className="bi bi-chevron-left"></i> Anterior
                                </button>
                            )}

                            {getPaginationRange().map((page, index) => (
                                <button
                                    key={index}
                                    onClick={() => typeof page === 'number' && handlePageClick(page)}
                                    className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                                    disabled={typeof page !== 'number'}
                                >
                                    {page}
                                </button>
                            ))}

                            {currentPage < totalPages && (
                                <button onClick={handleNextPage} className="pagination-button-prev-next">
                                    Siguiente <i className="bi bi-chevron-right"></i>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <LinkupCostModal show={isModalOpen}
                onHide={() => {
                    setIsModalOpen(false);
                }}
                onClick={(costo) => {
                    handleNewLinkupCost(costo);
                }}
            />
        </>
    );
};


export default LinkupCost;
