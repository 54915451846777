import React, { useState, ChangeEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '../../assets/img/logo_luisa.png';
import loadingGif from '../../assets/img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/login.css';
import axios from 'axios';
import config from '../../config/config';

import ConfirmationModal from '../modals/ConfirmationModal';

function Forgot_password(){
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [isValidNumber, setIsValidNumber] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [confirmModalType, setConfirmModalType] = useState('✅');

    const navigate = useNavigate();

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value.replace(/\D/g, '');
        const prefix = value.slice(0, 2);
        if (prefix === '55' || '56' || '33' || '81') {
          if (value.length > 2 && value.length <= 6) {
            value = value.slice(0, 2) + ' ' + value.slice(2);
          } else if (value.length > 6) {
            value = value.slice(0, 2) + ' ' + value.slice(2, 6) + ' ' + value.slice(6, 10);
          }
        } else {
          if (value.length > 3 && value.length <= 6) {
            value = value.slice(0, 3) + ' ' + value.slice(3);
          } else if (value.length > 6) {
            value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 10);
          }
        }
    
        setPhoneNumber(value);
        setPhoneError(false);
        setIsValidNumber(value.length === 12);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) =>{
        event.preventDefault();
        let isValid = true;
        setIsLoading(true);

        if (!isValidNumber) {
            setPhoneError(true);
            isValid = false;
          } else {
            setPhoneError(false);
        }

        if (!isValid) {
            setIsLoading(false);
            return;
        }
        
        console.log("Intenta recuperar contraseña");
        try{
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/forgot_password`, {
            whatsapp_number: phoneNumber,
          });
          console.log(`Valor de la respuesta del server: ${response.status}`);
        }catch(error){
          console.log(error)
        }
        
        
        //if (response.status === 201) {
            //enviar a home o poner un anuncio que ya se envio un mensaje
            setIsLoading(false);
            setConfirmModalType('✅');
            setModalMessage(`Se te enviará un link por whatsapp al numero: (${phoneNumber}) proporcionado para recuperar la contraseña`);
            setIsModalOpen(true);
            
            //window.location.reload();
        //}
         

    };

    return(
    <>
        <div className='flex-column align-items-center py-3 px-5 bg-login-animation'>
            {<ConfirmationModal
                show={isModalOpen}
                onHide={() => {
                setIsModalOpen(false);
                navigate('/change_password');
                }}
                text={modalMessage}
                type={confirmModalType}
                titleText='Recuperar Contraseña'
            />}
            <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
                    <img className="w-50 mt-5" src={Logo} alt="COPARMEX Logo" />
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-4 col-lg-3 p-login'>
                    <h1 className='fw-bolder text-white text-center mb-4 animate__animated animate__fadeInUp'>Recuperar Contraseña</h1>
                    <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input
                            id='tel'
                            type="tel"
                            className={`form-control bg-light centered-placeholder centered-input-container d-flex ${phoneError ? 'is-invalid' : ''}`}
                            placeholder='Número de teléfono'
                            onChange={handlePhoneChange}
                            value={phoneNumber}
                            autoComplete="off"
                            />
                            {phoneError && <div className="invalid-feedback">Por favor, introduce un teléfono válido.</div>}
                        </div>
                        {isValidNumber && (
                        <button type="submit" className="btn btn-dark w-100" disabled={isLoading}>
                            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Continuar'}
                        </button>
                        )}
                     </form>
                </div>
            </div>
            
        </div>
    </>
    )
}

export default Forgot_password;