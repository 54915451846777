import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import loadingGif from '../../assets/img/loading.gif'
import axios from 'axios';
import handleLogout from '../../functions/functions';

interface PromoCostModalProps {
    show: boolean;
    onHide: () => void;
    isLoading?: boolean;
    onClick?: () => void;
    callPay: (amount: number, quantity: number) => void;
}

const PromoCostModal: React.FC<PromoCostModalProps> = ({ show, onHide, isLoading = false, callPay = () => { } }) => {

    const [quantity, setQuantity] = useState(0);
    const [total, setTotal] = useState(0);
    const [activePrice, setActivePrice] = useState(0);

    const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if (inputValue === "") {
            setQuantity(0);
            setTotal(0);
            return;
        }

        let qty = parseInt(inputValue, 10);

        if (qty > 100) {
            qty = 100;
        }

        if (qty < 1) {
            qty = 1;
        }

        setQuantity(qty);
        const tot = ((1 / 80) * (qty * qty)) + activePrice*(qty);
        setTotal(parseFloat(tot.toFixed(2)));
    };

    const fetchCosts = async () => {
        //console.log("try to get the costs")
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/get_business_costs_promotion`, 
                {
                    
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('luisa_token')}`,
                    }
                }
            );
            if (response.status === 200) {
                setActivePrice(response.data.costs[0].price)
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error("Error al obtener costos:", error);
        }
    };

    useEffect(() => {
        fetchCosts();
    });

    return (
        <Modal show={show} onHide={onHide} className='costModal'>
            <Modal.Header closeButton>
                <Modal.Title><h2>Costo por publicidad</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <h4>Costos</h4>

                    <label htmlFor="Personas" className="form-label">Ingresa el público al que quieres llegar</label>
                    <div className="input-group mb-3">
                        <input type="number" className="form-control centered-placeholder centered-input-container"
                            placeholder="Cantidad" aria-label="personas" aria-describedby="basic-addon2"
                            onChange={handleQuantity}
                            value={quantity === 0 ? "" : quantity} // Mostrar vacío si es 0
                            step={1}
                            min={1}
                            max={100}
                        />
                        <span className="input-group-text bg-transparent text-white" id="basic-addon2">Personas</span>
                    </div>
                    <label htmlFor="Total" className="form-label mt-2">Tu total a pagar es de: </label>
                    <div className="input-group">
                        <span className="input-group-text bg-transparent text-white" id="basic-addon2"><i className="bi bi-currency-dollar"></i></span>
                        <input type="number" className="form-control centered-placeholder centered-input-container" readOnly id="total"
                            placeholder='0.0' aria-describedby="total"
                            value={total}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <>
                    <Button variant="danger" onClick={onHide} disabled={isLoading}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={() => { callPay(total, quantity); }}>
                        {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Aceptar'}
                    </Button>
                </>
            </Modal.Footer>
        </Modal>
    );
};

export default PromoCostModal;