import React, { useEffect, useState } from "react";
import axios from "axios";
import profile from './assets/img/img.jpeg'
import TopbarCoparmex from "./components/main/TopBarCoparmex";
import { LinkupProfile } from "./components/interfaces/LinkupProfile";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileCatalogModal from "./components/modals/ProfileCatalogModal";
import handleLogout from './functions/functions';

const PerfilEmpresa = () => {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const luisaID = localStorage.getItem('userID');
    const [linkupProfile, setLinkupProfile] = useState<LinkupProfile[]>([]);
    const [profileImg, setProfileImg] = useState<string>('');
    const location = useLocation();
    //const user_rfc = location.state.rfc;
    const queryParams = new URLSearchParams(location.search);
    const rfc = queryParams.get('id');
    const [modalOpen, setModalOpen] = useState(false);
    const pdfFilename = localStorage.getItem('pdfFilename');
    console.log(pdfFilename);
    

    const navigate = useNavigate();
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    const handleBack = () => {
        navigate('/result_linkup', { state: { id: 1, name: luisaID } });
    }

    useEffect(() => {

        const fetchLinkup = async () => {
            try {

                //const response = await axios.post(`http://localhost:3333/backend/getUserLinkupProfile`,
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getUserLinkupProfile`,
                    {
                        rfc: rfc
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${luisa_token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    const linkupInfo = response.data.business_data;
                    setProfileImg(linkupInfo.profile_image);

                    if (linkupInfo && [linkupInfo].length > 0) {
                        setLinkupProfile([linkupInfo]);
                    }
                }
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        console.log("Error response:", error.response.data.error);
                    } else if (error.request) {
                        console.log(
                            "Request made but no response received:",
                            error.request
                        );
                    } else {
                        console.log("Error message:", error.message);
                    }
                } else {
                    console.error("An unknown error occurred:", error);
                }
            }
        };

        fetchLinkup();
    }, []);

    return (
        <>
            <TopbarCoparmex />
            {linkupProfile.length > 0 && (

                <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                    <div className="row justify-content-center">
                        <div className='col-sm-10 col-md-12 col-lg-9'>
                            <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'><i className="bi bi-arrow-return-left me-3 point" onClick={handleBack}></i>Perfil vinculación</h1>
                            <div className="perfil-empresa mb-3 p-3"><span>Perfil de la Empresa</span></div>
                            <div className="linkup-form-bg d-flex align-items-center flex-sm-column flex-md-row">
                                <div className=" d-flex flex-column justify-content-center align-items-center col-md-6 col-lg-6 col-sm-12 mb-5">
                                    <img
                                        src={profileImg || profile}
                                        alt="Imagen de perfil"
                                        className="profile"
                                    />
                                    <h2 className="text-blue mt-5">{linkupProfile[0].business_name}</h2>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12">
                                    <div className="d-flex">
                                        <div className="col-md-6">
                                            <label className="--textPrimaryVariant">Razón Social</label>
                                            <p className="responsive-truncate">{linkupProfile[0].business_name}</p>

                                        </div>
                                        <div className="col-md-6">
                                            <label className="--textPrimaryVariant">Descripción General</label>
                                            <p className="responsive-truncate">-</p>
                                        </div>
                                    </div>

                                    <div className="d-flex mt-4">
                                        <div className="col-md-6">
                                            <label className="--textPrimaryVariant">Actividad</label>
                                            <p>{linkupProfile[0].business_activity}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="--textPrimaryVariant">Ubicación</label>
                                            <p>{linkupProfile[0].business_location}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="col-md-12">
                                            <label className="--textPrimaryVariant">Principal producto o servicio</label>
                                            <p>{linkupProfile[0].business_product_service}</p>
                                        </div>
                                    </div>
                                    {pdfFilename === '' ? (
                                        <div className="d-flex mt-4">
                                            <div className="col-md-12 text-center">
                                                <label className="--textPrimaryVariant"></label>
                                                <button className="btn btn-primary" onClick={handleOpenModal}>Ver catálogo de productos y servicios</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                </div>
                            </div>

                            <div className="perfil-empresa p-3 mt-5"><span>Perfil de usuario</span></div>
                            <div className="mt-2 linkup-form-bg d-flex flex-sm-column flex-md-row">
                                <div className="col-md-6 col-lg-4 col-sm-12">
                                    <label className="--textPrimaryVariant">Nombre del Representante</label>
                                    <p>{linkupProfile[0].user_name}</p>
                                </div>
                                <div className="col-md-6 col-lg-4 col-sm-12">
                                    <label className="--textPrimaryVariant">Email</label>
                                    <p>{linkupProfile[0].user_mail}</p>
                                </div>
                                <div className="col-md-6 col-lg-4 col-sm-12">
                                    <label className="--textPrimaryVariant">Teléfono</label>
                                    <p>{linkupProfile[0].user_phone}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {modalOpen && (
                <ProfileCatalogModal
                    show={modalOpen}
                    onHide={handleCloseModal}
                />
            )}
        </>
    );
};

export default PerfilEmpresa;
