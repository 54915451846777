import React, { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import "../../assets/css/affiliationCosts.css"
import loadingGif from '../../assets/img/loading.gif'
import handleLogout from '../../functions/functions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AffiliationMonthlyCostProps {
    show: boolean;
    onHide: () => void;
    isLoading?: boolean;
    onClick?: (annualCost: string, profile: string, type: string) => void;
    setActiveButton: (buttonType: string) => void;
    activeButton: string;
}

interface Cost {
    nombre: string;
    valor: string;
    colaboradores: string;
}

const AffiliationMonthlyCost: React.FC<AffiliationMonthlyCostProps> = ({ show, onHide, isLoading = false, onClick, activeButton, setActiveButton }) => {
    //console.log("Luisa-TEST: el valor activo es: ",activeButton);
    //const [activeButton, setActiveButton] = useState('monthly');
    const [profile, setProfile] = useState<string>(""); //para el select en mover el precio anual
    const [collaboratorsAnnual, setCollaboratorsAnnual] = useState<string>("");
    const [costAnnual, setCostAnnual] = useState<string>("");
    const [parcial, setParcial] = useState(false);
    const [costs, setCosts] = useState<Cost[]>([]);
    const [selectedProfile, setSelectedProfile] = useState<string>('');
    const [selectedfield, setSelectedField] = useState<string>('');
    const [collaborators, setCollaborators] = useState<string>('');
    const [annualCost, setAnnualCost] = useState<string>('');
    const [monthlyCosts, setMonthlyCosts] = useState<string[]>(
        Array(12).fill('$0.00') // Inicializa con 12 meses con valor $0.00
    );
    const luisa_token = localStorage.getItem('luisa_token');

    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const [isNotificationActive, setIsNotificationActive] = useState(false);

    const handleButtonClick = (buttonType: string) => {
        setActiveButton(buttonType);
    };

    const handleParcialChange = (event: ChangeEvent<HTMLInputElement>) => {
        setParcial(!parcial);
        handleProfileChange({ target: { value: "" } } as ChangeEvent<HTMLSelectElement>);
    };

    const handleProfileChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selected = event.target.value;
        setSelectedProfile(selected);

        const profileData = costs.find(cost => cost.nombre === selected);
        if (profileData) {
            setCollaborators(profileData.colaboradores);
            setSelectedField(profileData.nombre)
            const cost = parseFloat(profileData.valor);
            setAnnualCost('$' + (cost).toLocaleString('en-us', { minimumFractionDigits: 2 }));

            const baseCost = (cost / 12).toFixed(2);
            const monthlyArray = [];
            let remainingCost = cost;

            for (let i = 0; i < 12; i++) {
                if (i === 0) {
                    monthlyArray.push(`$${remainingCost.toLocaleString('en-US', { minimumFractionDigits: 2 })}`);
                } else {
                    remainingCost -= parseFloat(baseCost);
                    let finalCost = (Math.round(remainingCost)).toFixed(2);
                    monthlyArray.push(`$${parseFloat(finalCost).toLocaleString('en-US', { minimumFractionDigits: 2 })}`);
                }
            }
            setMonthlyCosts(monthlyArray);
        } else {
            setCollaborators('');
            setAnnualCost('');
            setMonthlyCosts(Array(12).fill('$0.00'));
        }
    };

    const handleMonthlyCostChange = (index: number, value: string) => {
        // Si no hay un perfil seleccionado, no permitir la edición
        if (!selectedProfile) {
            showWarnNotification('Selecciona un perfil antes de ingresar la cantidad');
            return;
        }

        // Eliminamos caracteres no numéricos excepto el punto decimal
        let sanitizedValue = value.replace(/[^0-9.]/g, '');

        // Permitimos solo un punto decimal
        const dotMatches = sanitizedValue.match(/\./g) || [];
        if (dotMatches.length > 1) {
            return;
        }

        // Convertimos a número flotante para validaciones
        const parsedValue = parseFloat(sanitizedValue);

        // Definir el costo máximo mensual permitido
        const maxMonthlyCost = parseFloat(annualCost.replace(/[^0-9.-]+/g, ""));

        // Si el valor es mayor al máximo, lo limitamos
        if (!isNaN(parsedValue) && parsedValue > maxMonthlyCost) {
            sanitizedValue = maxMonthlyCost.toFixed(2);
        }

        // Actualizamos el estado con el valor ajustado
        const updatedCosts = [...monthlyCosts];
        updatedCosts[index] = sanitizedValue;
        setMonthlyCosts(updatedCosts);
    };

    const handleMonthlyCostBlur = (index: number) => {
        // Aplicar formato solo cuando el usuario salga del input
        const value = monthlyCosts[index];

        if (value === '') {
            return;
        }

        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            const formattedValue = `$${parsedValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            const updatedCosts = [...monthlyCosts];
            updatedCosts[index] = formattedValue;
            setMonthlyCosts(updatedCosts);
        }
    };

    const handleAnnualCostChange = (value: string) => {
        const inputValue = value;
        const validInput = inputValue.match(/^\d*\.?\d*$/);
        if (validInput) {
            setCostAnnual(inputValue);
        }
    };

    const collaboratorsRanges: { [key: string]: string } = { //se tiene que cambiar si se debe sacar de la BD
        personaFisica: "1",
        microempresa: "1-20",
        pequeña: "21-50",
        mediana: "51-100",
        mediana1: "101-200",
        grande: "201-700",
        grande1: "701+"
    };

    const handleAnnualProfileChange = (value: string) => {
        setProfile(value);
        setCollaboratorsAnnual(collaboratorsRanges[value] || "");
    };

    const fetchCosts = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/get_business_costs_affiliation`,
                {
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 200) {
                setCosts(response.data.result);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error("Error al obtener datos:", error);
        }
    };

    useEffect(() => {
        fetchCosts().catch(() => setCosts([]));
    }, []);

    const handleSave = () => {
        if (onClick) {
            if (activeButton === 'monthly') {
                onClick(monthlyCosts.join(', '), selectedfield, 'monthly');
                handleProfileChange({ target: { value: "" } } as ChangeEvent<HTMLSelectElement>);
            }else{
                onClick(costAnnual, profile, 'annual');
            }
             // Pasando el valor al evento
        }
        //onHide(); 
    };

    const showWarnNotification = (text: String) => {
            if (!isNotificationActive) {
                setIsNotificationActive(true);
                toast.warning(text, {
                    position: "top-right",
                    autoClose: 4000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    onClose: () => setIsNotificationActive(false)  // Cuando la notificación se cierra, se marca como inactiva
                });
            }
        };

    return (
        <Modal show={show} onHide={onHide} className='costModal' size="xl">
            <ToastContainer />
            <Modal.Header closeButton>
                <Modal.Title><h3>Costos</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <h5 className="mb-3">Configura los costos de afiliación</h5>

                    <div className="affiliation-buttons">
                        <button
                            type="button"
                            className={activeButton === 'annual' ? 'affiliation-active' : 'affiliation-inactive'}
                            onClick={activeButton === 'annual' ? undefined : () => handleButtonClick('annual')}
                        >
                            Costo Anual
                        </button>
                        <button
                            type="button"
                            className={activeButton === 'monthly' ? 'affiliation-active' : 'affiliation-inactive'}
                            onClick={activeButton === 'monthly' ? undefined : () => handleButtonClick('monthly')}
                        >
                            Costo Mensual
                        </button>
                    </div>

                    {activeButton === "monthly" ? (
                        <>
                            <div className="form-check form-switch mt-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="parcial"
                                    onChange={handleParcialChange}
                                    checked={parcial}
                                />
                                <label className="form-check-label">Parcializar los pagos anuales</label>
                            </div>

                            <div className="row">
                                {parcial ? (
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="row g-3 align-items-center mt-3 mb-4">
                                            <div className="col-5">
                                                <button type="button" className="btn btn-light w-100 py-2" disabled>Perfil</button>
                                            </div>
                                            <div className="col-6">
                                                <select
                                                    className="form-select py-2"
                                                    value={selectedProfile}
                                                    onChange={handleProfileChange}
                                                >
                                                    <option value="">Selecciona el perfil</option>
                                                    {costs.map((cost) => (
                                                        <option key={cost.nombre} value={cost.nombre}>
                                                            {cost.nombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row g-3 align-items-center mb-4">
                                            <div className="col-5">
                                                <button type="button" className="btn btn-light w-100 py-2" disabled>Colaboradores</button>
                                            </div>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    className="form-control py-2"
                                                    placeholder="Total de colaboradores"
                                                    value={collaborators}
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className="row g-3 align-items-center mb-4">
                                            <div className="col-5">
                                                <button type="button" className="btn btn-light w-100 py-2" disabled>Costos</button>
                                            </div>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    className="form-control py-2"
                                                    placeholder="Introduce el costo anual"
                                                    value={annualCost}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="row g-3 align-items-center mt-3 mb-4">
                                            <div className="col-5">
                                                <button type="button" className="btn btn-light w-100 py-2" disabled>Perfil</button>
                                            </div>
                                            <div className="col-6">
                                                <select
                                                    className="form-select py-2"
                                                    value={selectedProfile}
                                                    disabled
                                                >
                                                    <option value="">Selecciona el perfil</option>
                                                    {costs.map((cost) => (
                                                        <option key={cost.nombre} value={cost.nombre}>
                                                            {cost.nombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row g-3 align-items-center mb-4">
                                            <div className="col-5">
                                                <button type="button" className="btn btn-light w-100 py-2" disabled>Colaboradores</button>
                                            </div>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    className="form-control py-2"
                                                    placeholder="Total de colaboradores"
                                                    value={collaborators}
                                                    readOnly
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row g-3 align-items-center mb-4">
                                            <div className="col-5">
                                                <button type="button" className="btn btn-light w-100 py-2" disabled>Costos</button>
                                            </div>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    className="form-control py-2"
                                                    placeholder="Introduce el costo anual"
                                                    value={annualCost}
                                                    readOnly
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {parcial ? (
                                    <div className="col-lg-7 col-md-7 col-sm-12 row mt-4">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            {months.slice(0, 6).map((month, index) => (
                                                <div key={index} className="btn btn-light w-100 py-0 rounded-pill mb-2 d-flex justify-content-between align-items-center div-focus"
                                                    style={{
                                                        cursor: 'default',
                                                    }}>
                                                    <span className="float-start me-4">{month}</span>
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 bg-transparent text-end input-cost"
                                                        value={monthlyCosts[index]}
                                                        onChange={(e) => handleMonthlyCostChange(index, e.target.value)}
                                                        onBlur={() => handleMonthlyCostBlur(index)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            {months.slice(6).map((month, index) => (
                                                <div key={index + 6} className="btn btn-light w-100 py-0 rounded-pill mb-2 d-flex justify-content-between align-items-center"
                                                    style={{
                                                        cursor: 'default',
                                                    }}>
                                                    <span className="float-start me-4">{month}</span>
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 bg-transparent text-end"
                                                        value={monthlyCosts[index + 6]}
                                                        onChange={(e) => handleMonthlyCostChange(index + 6, e.target.value)}
                                                        onBlur={() => handleMonthlyCostBlur(index + 6)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-lg-7 col-md-7 col-sm-12 row mt-4">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            {months.slice(0, 6).map((month, index) => (
                                                <div key={index} className="btn btn-light w-100 py-0 rounded-pill mb-2 d-flex justify-content-between align-items-center div-focus"
                                                    style={{
                                                        cursor: 'default',
                                                    }}>
                                                    <span className="float-start me-4">{month}</span>
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 bg-transparent text-end input-cost"
                                                        value={monthlyCosts[index]}
                                                        disabled
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            {months.slice(6).map((month, index) => (
                                                <div key={index + 6} className="btn btn-light w-100 py-0 rounded-pill mb-2 d-flex justify-content-between align-items-center"
                                                    style={{
                                                        cursor: 'default',
                                                    }}>
                                                    <span className="float-start me-4">{month}</span>
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 bg-transparent text-end"
                                                        value={monthlyCosts[index + 6]}
                                                        disabled
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}


                            </div>
                        </>
                    ) : (
                        <div className="form-check form-switch mt-3">
                            <div className="row g-3 align-items-center mb-4">
                                <div className="col-5">

                                    <button type="button" className="btn btn-light w-100 py-2" disabled>Perfil</button>
                                </div>
                                <div className="col-6">
                                    <select
                                        className="form-select py-2"
                                        value={profile}
                                        onChange={(e) => handleAnnualProfileChange(e.target.value)}
                                    >
                                        <option value="">Selecciona el perfil</option>
                                        <option value="personaFisica">Persona Física</option>
                                        <option value="microempresa">Microempresa</option>
                                        <option value="pequeña">Pequeña Empresa</option>
                                        <option value="mediana">Mediana Empresa</option>
                                        <option value="mediana1">Mediana Empresa 1</option>
                                        <option value="grande">Grande Empresa</option>
                                        <option value="grande1">Grande Empresa 1</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row g-3 align-items-center mb-4">
                                <div className="col-5">
                                    <button type="button" className="btn btn-light w-100 py-2" disabled>Colaboradores</button>
                                </div>
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control py-2"
                                        placeholder="Total de colaboradores"
                                        value={collaboratorsAnnual}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="row g-3 align-items-center mb-4">
                                <div className="col-5">
                                    <button type="button" className="btn btn-light w-100 py-2" disabled>Costos</button>
                                </div>
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control py-2"
                                        placeholder="Introduce el costo anual"
                                        value={costAnnual}
                                        onChange={(e) => handleAnnualCostChange(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={ () => {onHide(); handleProfileChange({ target: { value: "" } } as ChangeEvent<HTMLSelectElement>);} } disabled={isLoading}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={isLoading}>
                    {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AffiliationMonthlyCost;