import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../../assets/css/Promotion.css";
import { ToastContainer, toast } from 'react-toastify';

interface Attendee {
    name: string;
    phone: string;
    email: string;
    pago: boolean;
    id: string;
    assistance: boolean;
    requires_invoice: boolean;
}

interface EventDetailModalProps {
    show: boolean;
    onClose: () => void;
    eventTitle: string;
    attendees: Attendee[];
    eventId: string;
    onAttendeeUpdate: (attendeeId: string, newStatus: boolean) => void;
}

const ITEMS_PER_PAGE = 8; // Cantidad de asistentes por página

const EventDetailModal: React.FC<EventDetailModalProps> = ({ show, onClose, eventTitle, attendees, eventId, onAttendeeUpdate }) => {
    const [localAttendees, setLocalAttendees] = useState<Attendee[]>(attendees);
    const [searchTerm, setSearchTerm] = useState<string>(''); // Estado para el término de búsqueda
    const [filteredAttendees, setFilteredAttendees] = useState<Attendee[]>(attendees); // Lista filtrada
    const [currentPage, setCurrentPage] = useState<number>(1); // Página actual

    const handleCheck = async (attendeeId: string, currentStatus: boolean) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/events/checkAttendeesbyEvent`, {
                attendee_id: attendeeId,
                event_id: eventId,
                assistance: !currentStatus
            });

            if (response.status === 200) {
                onAttendeeUpdate(attendeeId, !currentStatus);
                showNotification(response.data.mensaje);
            }
        } catch (err: any) {
            showErrorNotification(`Error: ${err.response?.data?.error || err.message}`);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
    
        // Filtrar asistentes
        const filtered = localAttendees.filter((attendee) => {
            const name = attendee.name?.toLowerCase() || '';
            const phone = attendee.phone || '';
            const email = attendee.email?.toLowerCase() || '';
    
            return (
                name.includes(value) ||
                phone.includes(value) ||
                email.includes(value)
            );
        });
    
        setFilteredAttendees(filtered);
        setCurrentPage(1); // Reiniciar a la primera página al buscar
    };
    

    const showNotification = (text: string) => {
        toast.success(text, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showErrorNotification = (text: string) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        setLocalAttendees(attendees);
        setFilteredAttendees(attendees); // Inicializar lista filtrada
    }, [attendees]);

    const getPaginationRange = () => {
        const totalPages = Math.ceil(filteredAttendees.length / ITEMS_PER_PAGE);
        const range = [];
        for (let i = 1; i <= totalPages; i++) {
            range.push(i);
        }
        return range;
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        const totalPages = Math.ceil(filteredAttendees.length / ITEMS_PER_PAGE);
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentAttendees = filteredAttendees.slice(indexOfFirstItem, indexOfLastItem);

    if (!show) return null;

    return (
        <div className="modal d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <ToastContainer />
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"> Lista de Asistentes de: <span className='fw-semibold'>{eventTitle}</span></h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <div className='col-lg-6 col-md-6 col-sm-12 float-end mb-3'>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar asistente"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>

                        {currentAttendees.length > 0 ? (
                            <div className='col-md-12'>
                                <section className="wrapper mt-3">
                                    <main className="row title">
                                        <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                            <li className="fw-bold responsive-truncate">Nombre</li>
                                            <li className='fw-bold'>Teléfono</li>
                                            <li className='fw-bold'>Email</li>
                                            <li className='fw-bold'>Requiere factura</li>
                                            <li className='fw-bold'>Pago</li>
                                            <li className='fw-bold'>Asistencia</li>
                                        </ul>
                                    </main>
                                </section>
                                {currentAttendees.map((attendee) => (
                                    <section key={attendee.id}>
                                        <article className="data-users --style-lista-asistentes">
                                            <ul className="d-flex">
                                                <li className='responsive-truncate me-4'>{attendee.name}</li>
                                                <li className='me-4'>{attendee.phone}</li>
                                                <li className='responsive-truncate me-4'>{attendee.email}</li>
                                                <li className='me-5'>{attendee.requires_invoice ? 'Si' : 'No'}</li>
                                                <li className=''>
                                                    <span className={`px-3 py-1 ${attendee.pago ? '--style-active' : '--style-pendiente'}`}>
                                                        {attendee.pago ? 'Realizado' : 'Pendiente'}
                                                    </span>
                                                </li>
                                                <li className=''>
                                                    <div className="form-group">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkAsistente-${attendee.id}`}
                                                            checked={attendee.assistance}
                                                            onChange={() => handleCheck(attendee.id, attendee.assistance)}
                                                        />
                                                        <label htmlFor={`checkAsistente-${attendee.id}`}></label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </article>
                                    </section>
                                ))}
                            </div>
                        ) : (
                            <p className="text-center">No hay asistentes que coincidan con la búsqueda.</p>
                        )}
                    </div>

                    {/* Controles de paginación */}
                    <div className="pagination-controls">
                        {currentPage > 1 && (
                            <button onClick={handlePreviousPage} className="pagination-button-prev-next">
                                <i className="bi bi-chevron-left"></i> Anterior
                            </button>
                        )}
                        {getPaginationRange().map((page) => (
                            <button
                                key={page}
                                onClick={() => handlePageClick(page)}
                                className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                            >
                                {page}
                            </button>
                        ))}
                        {currentPage < Math.ceil(filteredAttendees.length / ITEMS_PER_PAGE) && (
                            <button onClick={handleNextPage} className="pagination-button-prev-next">
                                Siguiente <i className="bi bi-chevron-right"></i>
                            </button>
                        )}
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventDetailModal;
