import React, { useState } from 'react';
import '../../assets/css/CreateRolModal.css';

interface RoleData {
    name: string;
}

type CreateRoleModalProps = {
    onClose: () => void;
    onSave: (data: RoleData) => void;
};

const CreateRoleModal: React.FC<CreateRoleModalProps> = ({ onClose, onSave }) => {
    const [roleName, setRoleName] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRoleName(event.target.value);
    };

    const handleSaveClick = () => {
        onSave({ name: roleName });
    };
    return (
        <div className="overlay">
            <div className="modal2">
                <h2 className="text-light">Crear Nuevo Rol</h2>
                <hr className="separator" />

                <label className="label">Nuevo Rol</label>
                <input
                    type="text"
                    placeholder="Ingresa el nombre del Rol"
                    className="input"
                    value={roleName}
                    onChange={handleInputChange}
                />

                <div className="d-flex justify-content-end gap-3">
                    <button onClick={onClose} className="btn btn-secondary btnSecondary">Cancelar</button>
                    <button onClick={handleSaveClick} className="btn btn-primary">Guardar</button>
                </div>
            </div>
        </div>
    );
};

export default CreateRoleModal;
