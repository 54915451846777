import axios from 'axios';
import { useEffect, useState } from 'react';
import loadingGif from '../../assets/img/loading.gif';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';
import "../../assets/css/Promotion.css"
import profile from '../../assets/img/profile.jpg'
import { Promo } from '../interfaces/PromoInterface';
import usePermissions from '../../hooks/usePermissions';
import { hasPermission } from '../../functions/functions';
import handleLogout from '../../functions/functions';

const Promotion = () => {

    /* Set app data */
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const luisa_email = localStorage.getItem("luisa_email");

    const [promoInfo, setPromoInfo] = useState<Promo[]>([]);
    const [showFavorites, setShowFavorites] = useState<boolean>(false); // Estado para controlar si se deben ver solo los favoritos o todas las promociones
    const [isAscending, setIsAscending] = useState(false); // False por defecto (orden descendente)

    const [searchTerm, setSearchTerm] = useState<string>('');

    const { permissions } = usePermissions(luisa_rfc || '', luisa_email || '');

    useEffect(() => {
        // Llamada inicial a fetchPromos con el valor predeterminado de orden
        fetchPromos(showFavorites ? true : undefined, isAscending ? 'asc' : 'desc');
    }, []); // Se ejecuta solo una vez al cargar la página

    const navigate = useNavigate();

    const fetchPromos = async (favorite?: boolean, sortOrder?: 'asc' | 'desc') => {
        try {
            const requestBody: any = {
                rfc: luisa_rfc,
            };

            // Incluir 'favorite' solo si está definido
            if (favorite !== undefined) {
                requestBody.favorite = favorite;
            }

            // Incluir 'sortOrder' solo si está definido
            if (sortOrder) {
                requestBody.sortOrder = sortOrder; // El backend debe manejar esto
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/getPromos`,
                requestBody,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 200) {
                const promoData = response.data.promo_data;
                const promosWithImages = await Promise.all(
                    promoData.map(async (promo: Promo) => {
                        const imageUrl = await fetchImage(promo.file_name);
                        return { ...promo, image_url: imageUrl };
                    })
                );
                setPromoInfo(promosWithImages);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error(error);
        }
    };

    const handleShowFavorites = () => {
        // Cambia el estado del filtro de favoritos
        setShowFavorites(prevState => !prevState);

        // Llamar fetchPromos según el nuevo estado
        fetchPromos(showFavorites ? undefined : true);
    };

    const handleSortByDate = () => {
        // Alternar entre ascendente y descendente
        setIsAscending(prevState => !prevState);

        // Llamar a fetchPromos con el nuevo orden
        fetchPromos(showFavorites ? true : undefined, isAscending ? 'desc' : 'asc');
    };


    const handleFavoriteToggle = async (_id: string) => {
        try {
            // Primero, actualizamos localmente el estado de 'favorite'
            setPromoInfo(prevPromos =>
                prevPromos.map(promo =>
                    promo._id === _id
                        ? { ...promo, favorite: !promo.favorite }
                        : promo
                )
            );

            // Luego, enviamos la actualización al backend
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/updateFavorite`, {
                _id,
                favorite: !promoInfo.find(promo => promo._id === _id)?.favorite
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${luisa_token}`,
                    }
                }
            );
            console.log(_id)
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error('Error al actualizar el estado de favorito:', error);
        }
    };


    const fetchImage = async (file_name: String) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getImage`, {
                file_name: file_name
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            if (response.status === 200) {
                //console.log('--IMG--');
                return response.data.data.file_url
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
            return { profile };
        }
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredPromos = promoInfo.filter(promo =>
        promo.promo_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        promo.promo_description.toLowerCase().includes(searchTerm.toLowerCase())
        // promo.keywords?.some(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handlePromoClick = (id: string) => {
        navigate(`/promo_detail?id=${id}`);
    };

    // useEffect(() => {
    //     fetchPromos();
    // }, []);

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className='row justify-content-center p-linkup'>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>
                            Publicidad
                        </h1>
                        <div className='row mt-3'>
                            <div className="col-lg-3 col-md-3 col-sm-6">
                                <button className='btn btn-linkup px-3 w-100 h-100' onClick={handleShowFavorites}>
                                    {showFavorites ? 'Ver Todas' : 'Ver Favoritos'}
                                </button>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6">
                                <button className='btn btn-linkup px-3 w-100 h-100' onClick={handleSortByDate}>
                                    <i className="bi bi-arrow-down-up pe-2"></i>
                                    Ordenar ({isAscending ? 'Ascendente' : 'Descendente'})
                                </button>
                            </div>
                            <div className="col-lg-3 col-md-5 col-sm-12">
                                {hasPermission(permissions, "Publicidad", "crear") ? (
                                    <button className='btn btn-linkup w-100 h-100' onClick={() => navigate('/uploadPromo')}>
                                        Subir Nueva Publicidad
                                    </button>
                                ) : (
                                    <button disabled className='btn btn-linkup w-100 h-100'>
                                        Subir Nueva Publicidad
                                    </button>
                                )}
                            </div>
                            <div className='col-lg-3 col-md-12 col-sm-12 searchBar'>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="bi bi-search"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Título o palabras clave"
                                        aria-label="vinculacion"
                                        aria-describedby="basic-addon1"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-10 col-md-10 col-sm-10'>
                        {filteredPromos.length === 0 ? (
                            <div className="no-promo-message mt-4 p-5">
                                <p>Actualmente, no tenemos publicidad en nuestro espacio. ¡Mantente atento para futuras actualizaciones o aprovecha la oportunidad de subir tu propia publicidad aquí!</p>
                            </div>
                        ) : (
                            filteredPromos.map((promo, index) => (
                                <div className='row mt-5' key={index}>
                                    <div className='col-md-12 p-5 --promo-container'>
                                        <div className='text-primary point'><u>@{promo.username}</u></div>
                                        <div className='--promo gap-3 mt-3 point' onClick={() => handlePromoClick(promo._id)}>
                                            <div className='--img-wrapper'>
                                                <img src={promo.image_url || profile} className='--img-promo' alt="promo"></img>
                                            </div>
                                            <div className='p-4 position-relative w-100'>
                                                <p>
                                                    <i className='text-opacity-50 text-light'>
                                                        Del {new Date(promo.ini_date).toLocaleDateString('es-ES', {
                                                            day: 'numeric',
                                                            month: 'long',
                                                        })}
                                                        <span> al </span>
                                                        {new Date(promo.end_date).toLocaleDateString('es-ES', {
                                                            day: 'numeric',
                                                            month: 'long',
                                                            year: 'numeric',
                                                        })}
                                                    </i>
                                                </p>
                                                <h2>{promo.promo_title}</h2>
                                                <p className='fs'>{promo.promo_description}</p>
                                                <i
                                                    className={promo.favorite ? 'bi bi-heart-fill heart' : 'bi bi-heart heart'}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleFavoriteToggle(promo._id);
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                </div>
            </div>
        </>
    )
};

export default Promotion;