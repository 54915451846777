import axios from 'axios';
import { useEffect, useState } from 'react';
import React from 'react';
import TopbarCoparmex from '../main/TopBarCoparmex';
import Dropdown from 'react-bootstrap/Dropdown';
import "../../assets/css/userManagment.css";
import CreateIndividualUser from '../modals/CreateIndividualUser';
import EditIndividualUser from '../modals/EditIndividualUser';
import imgPassword from "../../assets/img/send_pass.png";
import { useNavigate, useLocation } from 'react-router-dom';
import { userInfo, UserProfile } from '../interfaces/UserProfile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import handleLogout from '../../functions/functions';

interface UserData {
  name: string;
  surnameP: string;
  surnameM: string;
  rfc_user: string;
  email: string;
  phoneNumber: string;
  numEmployee: string;
  selectedRole: string;
}

function AdministracionUsuarios() {
  const navigate = useNavigate();
  const luisa_token = localStorage.getItem('luisa_token');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [users, setUsers] = useState<userInfo[]>([]);
  const luisa_rfc = localStorage.getItem('luisa_rfc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const limit = 10;

  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  // Estados para el manejo de selección y switches
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false);
  const [rowSwitchStates, setRowSwitchStates] = useState<{ [key: number]: boolean }>({});
  const [headerSwitch, setHeaderSwitch] = useState(false); // Estado para el switch principal
  const [toastKey, setToastKey] = useState(0);

  const fetchUsers = async () => {
    try {
      if (search.length >= 4 || search === '') {
        // const response = await axios.post(`http://localhost:3333/backend/getAllUsers`, {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getAllUsers`, {
            rfc: luisa_rfc,
            page: currentPage,
            limit,
            search // Asegúrate de definir este estado o variable previamente
        });

        const users = response.data.users;

        // Inicializar el estado de los switches basado en el valor `active` de cada usuario
        const initialSwitchStates = users.reduce((acc:any, user:any, index:any) => {
          acc[index] = user.active; // Si el usuario está activo, el switch estará encendido
          return acc;
        }, {});

        setUsers(response.data.users);
        setTotalPages(response.data.totalPages);
        setRowSwitchStates(initialSwitchStates);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
      }
        console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    // Resetear las selecciones y switches cuando cambie la página
    setSelectedUsers([]);
    setRowSwitchStates({});
    setSelectAll(false);
    setHeaderSwitch(false);
  
  fetchUsers();
  }, [currentPage, search]);
  
  useEffect(() => {
    // Actualiza el estado `selectAll` en función de los checkboxes seleccionados
    setSelectAll(selectedUsers.length === users.length);

    // Habilita el switch principal si hay dos o más usuarios seleccionados
    setIsSwitchEnabled(selectedUsers.length >= 2);
  }, [selectedUsers, users.length]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedUsers(newSelectAll ? users.map((_, index) => index) : []);
    
    // Activa o desactiva los switches de todas las filas seleccionadas
    const newRowSwitchStates: Record<number, boolean> = {};
    users.forEach((_, index) => {
      newRowSwitchStates[index] = newSelectAll; // Activa o desactiva según el valor de selectAll
    });
    setRowSwitchStates(newRowSwitchStates);
  };

  const handleUserCheckbox = (index: number) => {
    setSelectedUsers(prevSelectedUsers =>
      prevSelectedUsers.includes(index)
        ? prevSelectedUsers.filter(i => i !== index)
        : [...prevSelectedUsers, index]
    );
  };

  const handleHeaderSwitchToggle = async () => {
    // Invertir el estado del switch principal
    const newHeaderSwitchState = !headerSwitch;
    setHeaderSwitch(newHeaderSwitchState);

    // Crear una copia del estado de los switches de las filas
    const newRowSwitchStates = { ...rowSwitchStates };

    // Activa o desactiva switches en filas seleccionadas en función del estado del switch principal
    for (const index of selectedUsers) {
        newRowSwitchStates[index] = newHeaderSwitchState; // Establece el estado para las filas seleccionadas
        
        // Llama a `updateUserAccess` para actualizar el acceso en el backend
        await updateUserAccess(users[index].rfc, newHeaderSwitchState);
    }

    // Actualiza el estado local de los switches de las filas
    setRowSwitchStates(newRowSwitchStates);
};


  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalOpenEdit, setModalOpenEdit] = React.useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setToastKey(prevKey => prevKey + 1);
    setModalOpen(false);
  }

  const handleEdit = async (data: any) => {
    setSelectedUser(data);
    setModalOpenEdit(true);
  }

  const handleCloseModalEdit = () => {
    setToastKey(prevKey => prevKey + 1);
    setModalOpenEdit(false);

  }
  const handleSave = async (data: UserData) => {
    setIsLoading(true);
    try{
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/uploadUser`, 
        {
          name: data.name,
          surnameP: data.surnameP,
          surnameM: data.surnameM,
          rfc_user: data.rfc_user,
          rfc: luisa_rfc,
          email: data.email,
          phoneNumber: data.phoneNumber,
          numEmployee: data.numEmployee,
          selectedRole: data.selectedRole,
        },
        {
          headers: {
            Authorization: `Bearer ${luisa_token}`,
          },
        }
      );
      console.log('Usuario agregado:', response.data);
      if(response.status === 200){
        showNotification(`Se ha creado correctamente el usuario: ${data.name} ${data.surnameP} ${data.surnameM}`);
        await fetchUsers();
        setTimeout(() => {
          handleCloseModal(); // Cierra el modal después de 5 segundos
          setIsLoading(false);
        }, 3000);
      } else if(response.status === 220){
        console.log("El status fue: ", response.status);
          showErrorNotification(`El correo ya se encuentra en uso, favor de usar otro correo`);
          setIsLoading(false);
        //showNotification(`No se ha creado correctamente el usuario: ${data.name} ${data.surnameP} ${data.surnameM}`);
        
      } else {
        setIsLoading(false);
        showErrorNotification(`Ocurrio un error al agregar al usuario: ${data.name} ${data.surnameP} ${data.surnameM}`);
      }

    }catch (error) {
      setIsLoading(false);
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
      }
      showErrorNotification(`Ocurrio un error al agregar al usuario: ${data.name} ${data.surnameP} ${data.surnameM}`);
      console.error('Error al agregar usuario:', error);
    }
  };

  const handleSaveEdit = async (data: any) => {
    //console.log('Usuario editado:', data);
    let response;
    setIsLoading(true);
    try{
       response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/uploadUserEdit`, 
        {
          name: data.name,
          surnameP: data.surnameP,
          surnameM: data.surnameM,
          rfc_user: data.rfc_user,
          email: data.email,
          phoneNumber: data.phoneNumber,
          numEmployee: data.numEmployee,
          selectedRole: data.selectedRole,
          id: data.id
        },
        {
          headers: {
            Authorization: `Bearer ${luisa_token}`,
          },
        }
      );
      console.log('Usuario editado:', response.data);
      if(response.status === 200){
        showNotification(`Se ha editado correctamente el usuario: ${data.name} ${data.surnameP} ${data.surnameM}`);
        await fetchUsers();
        setTimeout(() => {
          handleCloseModalEdit(); // Cierra el modal después de 5 segundos
          setIsLoading(false);
        }, 3000);
      } else if (response.status === 220){
        showErrorNotification(`El correo ya se encuentra en uso, favor de usar otro`);
        setIsLoading(false);
      } else if(response.status === 404){
        showErrorNotification(`No se encontro el usuario a editar: ${data.name} ${data.surnameP} ${data.surnameM}`);
        setIsLoading(false);
      } else {
        showErrorNotification(`Ocurrio un error al editar al usuario: ${data.name} ${data.surnameP} ${data.surnameM}`);
        setIsLoading(false);
      }
    }catch (error) {
      setIsLoading(false);
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
      }
      console.error('Error al editar usuario:', error);
      showErrorNotification(`Ocurrio un error al editar al usuario: ${data.name} ${data.surnameP} ${data.surnameM}`);
    }
  };

  const handlePageClick = (page: number | string) => {
    if (typeof page === 'number') {
      setCurrentPage(page);
    }
  };

  const getPaginationRange = () => {
    const totalPagesToShow = 2; // Número de páginas a mostrar en el rango
    if (totalPages <= totalPagesToShow) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
  
    const range = [];
    const leftLimit = Math.max(2, currentPage - 1);
    const rightLimit = Math.min(totalPages - 1, currentPage + 1);
  
    // Primera página siempre visible
    range.push(1);
  
    if (leftLimit > 2) {
      range.push("...");
    }
  
    for (let i = leftLimit; i <= rightLimit; i++) {
      range.push(i);
    }
  
    if (rightLimit < totalPages - 1) {
      range.push("...");
    }
  
    // Última página siempre visible
    range.push(totalPages);
  
    return range;
  };

  const sendEmail = async (email: string, username: string): Promise<void> => {
    try {
      const response = await axios.post(
        // 'http://localhost:3333/backend/register-email', // Cambia a tu URL real
        `${process.env.REACT_APP_BACKEND_URL}/register-email`,
        {
          email,
          username,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${luisa_token}`, // Asegúrate de tener el token almacenado
          },
        }
      );
  
      if (response.status === 200) {
        console.log('Correo enviado exitosamente:', response.data);
        alert('Correo enviado exitosamente');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
      }
      console.error('Error al enviar el correo:', error);
      if (axios.isAxiosError(error) && error.response) {
        alert(`Error: ${error.response.data}`);
      } else {
        alert('Ocurrió un error inesperado.');
      }
    }
  };

  const updateUserAccess = async (rfc: string, active: boolean) => {
    try {
      const response = await axios.put(
        // 'http://localhost:3333/backend/updateAccess',
        `${process.env.REACT_APP_BACKEND_URL}/updateAccess`,
        { rfc, active },
        {
          headers: {
            Authorization: `Bearer ${luisa_token}`,
          },
        }
      );
      console.log(active)
      console.log(rfc)
      console.log('Respuesta del servidor:', response.data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
      }
      console.error('Error al actualizar el acceso del usuario:', error);
    }
  };

  const showNotification = (text: String) => {
    toast.success(text, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

const showErrorNotification = (text: String) => {
    toast.error(text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

  return (
    <>
        <TopbarCoparmex />
        <ToastContainer  style={{ zIndex: 999999999999 }} key={toastKey}/>
        {modalOpen && (
          <CreateIndividualUser isOpen rfc={localStorage.getItem('luisa_rfc') || ''} onClose={handleCloseModal} onSave={handleSave} isLoading={isLoading} />
        )}
        {modalOpenEdit && (
          <EditIndividualUser isOpen rfc={localStorage.getItem('luisa_rfc') || ''} onClose={handleCloseModalEdit} onSave={handleSaveEdit} selectedUser={selectedUser} isLoading={isLoading} />
        )}
        <div className="py-3 px-5 linkup-bg ">
          <div className="row justify-content-center">
            <div className='col-sm-10 col-md-12 col-lg-9'>
              <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'>
                <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/configuration')}>
                  <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA"/>
                </svg>
                Administración de Usuarios
              </h1>
              <div className='p-3 sub-header'>
                <span>Luisa clasifica usuarios en administradores y afiliados, con accesos y funcionalidades específicas según su rol, incluyendo perfiles personalizados y diversas herramientas.</span>
              </div>
              <div className='mt-3 row'>
                <Dropdown className='h-100 col-md-6 col-lg-6 col-sm-12' show={isDropdownOpen} onToggle={setDropdownOpen}>
                    <Dropdown.Toggle className='h-100 w-25' onClick={() => setDropdownOpen(!isDropdownOpen)}>
                        Acciones
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-2">
                        <Dropdown.Item onClick={handleOpenModal}>Carga individual</Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate('/uploadFile')}>Carga masiva</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                
                  <div className='col-lg-6 col-md-6 col-sm-12 searchBar'>
                    <div className="input-group h-100">
                        <span className="input-group-text" id="basic-addon1">
                            <i className="bi bi-search"></i>
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Buscar nombre o correo electronico"
                            aria-label="vinculacion"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
              </div>
              <div className='col-lg-12 col-md-12 col-sm-12' id='linkupTable'>
                <section className="wrapper">
                    <main className="row title">
                        <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                          <li className="fw-bold ms-5 w-0">
                            <div className="form-group">
                              <input className='' type="checkbox" id="checkUsers" checked={selectAll} onChange={handleSelectAll} />
                              <label className='' htmlFor="checkUsers"></label>
                            </div>
                          </li>
                          <li className="fw-bold">Nombre</li>
                          <li className='fw-bold text-truncate fs-sm'>Correo electrónico</li>
                          <li className='fw-bold'>Teléfono</li>
                          <li className='fw-bold'>roles</li>
                          <li className='fw-bold d-flex' title="Activar/Desactivar cuenta">
                            Acceso
                            <input 
                              className='ms-2 check-box' 
                              type="checkbox" id="switch" 
                              disabled={!isSwitchEnabled} // Habilita o deshabilita el switch
                              onChange={handleHeaderSwitchToggle}
                            />
                            <label className='label-check' htmlFor="switch">Toggle</label>
                          </li>
                        </ul>
                    </main>
                </section>
                {users.map((UserProfile, index) => (
                  <section key={index}>
                    <article className="data-users">
                        <ul className="d-flex">
                            <li className="w-0 ms-5 me-5">
                              <div className="form-group">
                                <input className='' type="checkbox" id={`checkUsers-${index}`} checked={selectedUsers.includes(index)} onChange={() => handleUserCheckbox(index)} />
                                <label className='' htmlFor={`checkUsers-${index}`}></label>
                              </div>
                            </li>
                            <li className='responsive-truncate me-4'>{UserProfile.username}</li>
                            <li className='d-md-block responsive-truncate'>{UserProfile.email}</li>
                            <li className='d-md-block'>{UserProfile.whatsapp_number}</li>
                            <li className='responsive-truncate ms-3'>{UserProfile.rol}</li>
                            <li className='d-flex '>

                              <input 
                                className='ms-2 check-box' 
                                type="checkbox" 
                                id={`switch-acceso-${index}`}
                                checked={rowSwitchStates[index] || false} // Sincroniza con el estado de cada switch de fila
                                onChange={async () => {
                                  const newState = !rowSwitchStates[index]; // Nuevo estado del switch
                    
                                  // Actualiza el estado local
                                  setRowSwitchStates((prevState) => ({
                                    ...prevState,
                                    [index]: newState,
                                  }));
                    
                                  // Llama a la función para actualizar el acceso del usuario
                                  await updateUserAccess(UserProfile.rfc, newState);
                                }}
                              />
                              <label title="Activar/Desactivar cuenta" className='label-check  me-4' htmlFor={`switch-acceso-${index}`}></label>
                              <img 
                                src={imgPassword} 
                                className='send-credentials '
                                alt="Enviar credenciales"
                                title="Enviar correo para crear la contraseña"
                                onClick={() => sendEmail(UserProfile.email, UserProfile.username)} // Aquí pasamos email y username
                              />
                              <i className="bi bi-pencil-square bi-edit ms-3 point fs-4" onClick={() => handleEdit(UserProfile)} title="Editar al usuario"></i>
                              
                            </li>
                        </ul>
                    </article>
                  </section>
                ))} 
              </div>
              {/* Controles del paginador */}
              <div className="pagination-controls">
                {currentPage > 1 && (
                  <button onClick={handlePreviousPage} className="pagination-button-prev-next">
                    <i className="bi bi-chevron-left"></i> Anterior
                  </button>
                )}

                {getPaginationRange().map((page, index) => (
                  <button
                    key={index}
                    onClick={() => typeof page === 'number' && handlePageClick(page)}
                    className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                    disabled={typeof page !== 'number'}
                  >
                    {page}
                  </button>
                ))}

                {currentPage < totalPages && (
                  <button onClick={handleNextPage} className="pagination-button-prev-next">
                    Siguiente <i className="bi bi-chevron-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>   
    </>
  );
}

export default AdministracionUsuarios;
