import React, { useState, useEffect, ChangeEvent } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../assets/css/Promotion.css";
import Logo from "../../assets/img/logo_luisa.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import handleLogout from '../../functions/functions';
import { Promo } from '../interfaces/PromoInterface';
import profile from '../../assets/img/profile.jpg'

const PromoDetail = () => {
    const luisa_token = localStorage.getItem('luisa_token');

    const navigate = useNavigate(); // Inicializar el hook useNavigate

    const [promo, setPromo] = useState<Promo | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchParams] = useSearchParams();

    const [isNotificationActive, setIsNotificationActive] = useState(false);


    const promoId = searchParams.get("id");

    const fetchPromoDetail = async () => {
        try {
            setIsLoading(true);
            const luisa_token = localStorage.getItem("luisa_token");

            // Fetch event details
            const eventResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/getPromoById`,
                { promo_id: promoId },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            let promoData: Promo = eventResponse.data.data[0];

            // Fetch the image URL
            const imageResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getImage`, {
                file_name: promoData.file_name
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            console.log(imageResponse.data.data.file_url);

            promoData = {
                ...promoData,
                image_url: imageResponse.data.data.file_url,
            };
            setPromo(promoData);
            // console.log(promoData);

        } catch (error) {
            console.error("Error fetching event detail or image:", error);
            setError("Failed to fetch event details");
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchImage = async (file_name: String) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getImage`, {
                file_name: file_name
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            if (response.status === 200) {
                //console.log('--IMG--');
                return response.data.data.file_url
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
            return { profile };
        }
    }

    const showNotification = (text: String) => {
        toast.success(text, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showPayNotification = (text: String) => {
        if (!isNotificationActive) {
            setIsNotificationActive(true);
            toast.success(text, {
                position: "top-right",
                autoClose: 8000,
                closeOnClick: true,
                pauseOnHover: true,
                onClose: () => setIsNotificationActive(false)  // Cuando la notificación se cierra, se marca como inactiva
            });
        }
    };

    useEffect(() => {
        if (promoId) {
            fetchPromoDetail();
        }
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');

        if (status === 'success') {
            showPayNotification('Pago completado con éxito');

        } else if (status === 'cancel') {
            showErrorNotification('El pago fue cancelado');
        }

        if (status === 'success' || status === 'cancel') {
            setTimeout(() => {
                urlParams.delete('status');
                const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                window.history.replaceState(null, '', newUrl);
            }, 3000);
        }
    }, [promoId]);

    return (
        <>
            <ToastContainer limit={1} />
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : error ? (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            ) : promo && (
                <>
                    <div
                        className="w-100 p-4 bg-coparmex-blue topbar-coparmex position-relative"
                        style={{ zIndex: 10 }}
                    >
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-5 col-sm-5">
                                <img className="coparmex-logo" src={Logo} alt="COPARMEX Logo" />
                            </div>
                        </div>
                    </div>
                    <div className="linkup-bg h-100">
                        <div className="flex-column align-items-center px-5">
                            <div className="row justify-content-center">
                                <div className='col-lg-10 col-md-10 col-sm-10'>
                                    <div className="w-100 mt-4 mb-3">
                                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'>
                                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/promo')}>
                                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                                            </svg>
                                            {promo.promo_title}
                                        </h1>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-md-12 p-5 --promo-container'>
                                            <div className='text-primary point'><u>@{promo.username}</u></div>
                                            <div className='--promo gap-3 flex-md-column flex-sm-column mt-3'>
                                                <div>
                                                    <img src={promo.image_url || profile} className='--img-promo' alt="promo"></img>
                                                </div>
                                                <div className='p-4 position-relative w-100'>
                                                    <p>
                                                        <i className='text-opacity-50 text-light'>
                                                            Del {new Date(promo.ini_date).toLocaleDateString('es-ES', {
                                                                day: 'numeric',
                                                                month: 'long',
                                                                // year: 'numeric',
                                                            })}
                                                            <span> al </span>
                                                            {new Date(promo.end_date).toLocaleDateString('es-ES', {
                                                                day: 'numeric',
                                                                month: 'long',
                                                                year: 'numeric',
                                                            })}
                                                        </i>
                                                    </p>
                                                    <h2>{promo.promo_title}</h2>
                                                    <p className='fs'>{promo.promo_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            )}

        </>


    );
};

export default PromoDetail;