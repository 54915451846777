import axios from 'axios';
import config from '../config/config';

const environment = "quality";

export default function handleLogout() {
    localStorage.removeItem('luisa_token');
    localStorage.removeItem('luisa_whatsapp_number');
    localStorage.removeItem('luisa_username');
    localStorage.removeItem('luisa_rfc');
    localStorage.removeItem('luisa_business_name');
    localStorage.removeItem('luisa_email');
    localStorage.removeItem('luisa_temporal_account');
    localStorage.removeItem('luisa_thread');
    window.location.reload();
};

export function decodeUnicode(str: string) {
    try {
        str = decodeURIComponent(str);
    } catch (e) {
        console.log('Error in URI decoding:', e);
    }

    str = str.replace(/\\u([\dA-F]{4})/gi, (match, grp) => {
        return String.fromCharCode(parseInt(grp, 16));
    });

    // Regular expression to keep standard ASCII characters and specific Spanish characters
    str = str.replace(/[^\x20-\x7EáéíóúÁÉÍÓÚñÑüÜ]/g, '');

    return str;
}

export function formatPhoneNumber(phoneString: string) {
    // Elimina la parte de @s.whatsapp.net
    const number = phoneString.split('@')[0];

    // Agrega el símbolo '+' al inicio
    const formattedNumber = '+' + number;

    // Divide el número en partes usando substrings
    const countryCode = formattedNumber.substring(0, 4);
    const areaCode = formattedNumber.substring(4, 6);
    const firstPart = formattedNumber.substring(6, 10);
    const secondPart = formattedNumber.substring(10);

    // Formatea el número
    return `${countryCode} ${areaCode} ${firstPart} ${secondPart}`;
};

export function getRawPhoneNumber(phoneString: string) {
    return '+' + phoneString.split('@')[0];
};

export const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()[\]{}|\\/_\-+=.,;:'"`~<>])[A-Za-z\d!@#$%^&*()[\]{}|\\/_\-+=.,;:'"`~<>]{10,}$/;
    return passwordRegex.test(password);
};

export const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const isValidPhoneNumber = (phone: string): boolean => {
    const regex = /^\d{10}$/;
    return regex.test(phone);
};


//interface JwtPayload {
//  exp?: number;
//}

export const isTokenExpired = (token: string | null): boolean => {
    if (!token) return true; // Si no hay token, consideramos que expiró

    try {
        const base64Url = token.split(".")[1]; // Extrae el payload del token
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = JSON.parse(atob(base64)); // Decodifica el payload

        if (!jsonPayload.exp) return true; // Si no tiene expiración, lo consideramos inválido

        return jsonPayload.exp * 1000 < Date.now(); // Convierte a milisegundos y compara con la hora actual
    } catch (error) {
        return true; // Si hay un error al decodificar, lo consideramos expirado
    }
};



/*
export async function getWeekAppointments(selectedDate: string, businessId: string) {
    try {
        const gojimx_token = localStorage.getItem('gojimx_token');

        const response = await axios.get(`$${process.env.REACT_APP_BACKEND_URL}/week_appointments/${selectedDate}?business_id=${businessId}`, {
            headers: {
              Authorization: `Bearer ${gojimx_token}`
            }
          });
        console.log('[Client] Week appointments retrieved successfully.');
        return response.data;
    } catch (error) {
        console.error('[Client ERROR] Error getting week appointments:', error);
        throw error;
    }
}

export async function getAppointments(date: string, businessId: string) {
    try {
        const gojimx_token = localStorage.getItem('gojimx_token');

        const response = await axios.get(`${backendURL}/appointments?date=${date}&businessId=${businessId}`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8', // Ensure correct charset
        'Authorization': `Bearer ${gojimx_token}`
      }
    });

        console.log('[Client] Appointments retrieved successfully.');
        return response.data;
    } catch (error) {
        console.error('[Client ERROR] Error getting appointments:', error);
        throw error;
    }
}
    

export async function getSkills(businessId: string) {
    try {
        const gojimx_token = localStorage.getItem('gojimx_token');
        console.log('Token from localStorage:', gojimx_token);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getSkills`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8', // Ensure correct charset
        'Authorization': `Bearer ${gojimx_token}`
      },
      params: {
        business_id: businessId
      }
    });

        console.log('[Client] Skills retrieved successfully.');
        return response.data.skills;
    } catch (error) {
        console.error('[Client ERROR] Error getting skills:', error);
        throw error;
    }
}

export async function validateToken() {
    const token = localStorage.getItem('gojimx_token');
    if (!token) {
        console.log('Token not found');
        handleLogout();
        return false; // Token no encontrado, retorna false
    }

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/validateToken`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Si la validación es exitosa, retorna true
        return true;
    } catch (error) {
        console.log('Token validation failed:', error);
        handleLogout();
        return false; // Error en la validación del token, retorna false
    }
}

export async function fetchBusinessStatus()  {
    const gojimx_token = localStorage.getItem('gojimx_token');
    const business_id = localStorage.getItem('gojimx_businessId'); 
    if (!business_id || !gojimx_token) {
        console.error('Missing business_id or gojimx_token');
        return;
    }

    try {
        console.log(`Fetching business status from URL: ${process.env.REACT_APP_BACKEND_URL}/get_business/${business_id}`);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get_business/${business_id}`, {
            headers: {
                Authorization: `Bearer ${gojimx_token}`
            }
        });
        const { renata_points } = response.data[0];
        if (Number(renata_points) <= 0 ) {
            localStorage.setItem('gojimx_needs_payment', 'true');
            window.location.replace('/payment'); 
        }
        const { baileys_status } = response.data[0];
        if (!baileys_status) {
            localStorage.setItem('gojimx_baileys_status', 'false');
            window.location.replace('/scanQR'); 
        }
        return response.data[0];
    } catch (error) {
        console.error('Error fetching business status:', error);
    }
};
*/
export async function handleDeductionTransaction(tokens: number) {
    const gojimx_token = localStorage.getItem('gojimx_token');
    const business_id = localStorage.getItem('gojimx_businessId');

    const currentDate = new Date();
    const type = "subtract";
    let msgResp = "Error";

    const usageData = {
        usage: tokens,
        transaction_type: type
    }
    const transactionData = {
        business_id: business_id,
        transaction_date: currentDate,
        transaction_type: type,
        transaction_ammount: tokens,
        transaction_source: "knowledgeBase"
    }
    const configuration = {
        headers: {
            'Authorization': `Bearer ${gojimx_token}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/modifyPoints/${business_id}`, usageData, configuration);

        if (response.status === 200) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addTransaction`, transactionData, configuration);
                if (response.status === 201) {
                    console.log('[Renata-Assistant] New transaction saved successfully');
                    msgResp = 'transaction saved successfully';
                }
            } catch (error) {
                console.error('[Renata-Assistant] Could not save transaction', error);
                msgResp = 'Could not save transaction';
            }
        }
    } catch (error) {
        console.error('[Renata-Assistant] Could not save renata-points modification', error);
        msgResp = "Could not save renata-points modification";
    }
    return { message: msgResp };
}

// cropImage.ts
export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
    });

export async function getCroppedImg(
    imageSrc: string, pixelCrop: { width: number; height: number; x: number; y: number }): Promise<Blob> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) throw new Error('No se pudo obtener el contexto del canvas');

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    );

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            if (blob) resolve(blob);
        }, 'image/jpeg');
    });
};

export function getIniEndMonth(currentDate: Date = new Date()): { inicio: Date; fin: Date } {
    const anio = currentDate.getFullYear();
    const mes = currentDate.getMonth();

    const inicio = new Date(anio, mes, 1);
    const fin = new Date(anio, mes + 1, 0);

    return { inicio, fin };
}

// export const truncateFileName = (name: string, maxLength: number) => {
//     if (name.length <= maxLength) {
//         return name;
//     }
//     const extIndex = name.lastIndexOf('.');
//     const ext = extIndex !== -1 ? name.substring(extIndex) : '';
//     const truncatedName = name.substring(0, maxLength - ext.length - 3);
//     return `${truncatedName}...${ext}`;
// }
export const truncateFileName = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
        return text;
    }

    try {
        const url = new URL(text); // Verifica si es una URL
        const protocol = url.protocol + "//"; // Obtiene "http://" o "https://"
        const host = url.hostname; // Obtiene "maps.app.goo.gl"
        const path = url.pathname + url.search + url.hash; // Obtiene todo el path

        const shortText = `${protocol}${host}/...${path.slice(-10)}`; // Acorta dejando el inicio y el final

        return shortText.length <= maxLength ? shortText : shortText.slice(0, maxLength - 3) + "...";
    } catch (error) {
        // No es una URL, manejarlo como un archivo o texto normal
        const extIndex = text.lastIndexOf('.');
        const ext = extIndex !== -1 ? text.substring(extIndex) : '';
        const truncatedName = text.substring(0, maxLength - ext.length - 3);
        return `${truncatedName}...${ext}`;
    }
};


export const truncateTextByWords = (text: string | undefined, maxWords: number) => {
    if (!text) return ""; // Maneja casos en los que el texto sea undefined o vacío

    const words = text.split(/\s+/); // Divide el texto en palabras
    if (words.length <= maxWords) return text; // Si tiene menos palabras, lo devuelve completo

    return words.slice(0, maxWords).join(" ") + "..."; // Trunca y añade "..."
};

export const hasPermission = (permissions: any, section: string, action: string): boolean => {
    if (!permissions || !permissions[section]) return false;
    const sectionPermissions = permissions[section][0];
    return sectionPermissions?.[action] ?? false;
};
