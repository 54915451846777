import axios from 'axios';
import React, { useEffect, useState } from "react";
import TopbarCoparmex from "../main/TopBarCoparmex";
import "../../assets/css/administration.css";
import { useNavigate } from 'react-router-dom';
import "../../assets/css/ControlTable.css";
import handleLogout from '../../functions/functions';


interface BusinessItem {
    folio: string;
    fecha_pago: string;
    razon_social: string;
    tramite: string;
    status_de_pago: string;
    _id: string;
    rfc: string;
}

const ControlTable = () => {
    const limit = 10;
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const luisa_token = localStorage.getItem('luisa_token');
    const [businessData, setBusinessData] = useState<BusinessItem[]>([]);

    const fetchTramites = async (search = "") => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/get_business_to_afiliate`,
                {
                    params: {
                        search,
                        page: currentPage,
                        limit
                    },
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 201) {
                const { business, totalPages } = response.data;
                const totalFix = Math.round(totalPages / 10);
                setBusinessData(business);
                setTotalPages(totalFix);
            }
        } catch (error) {
            console.error("Error al obtener datos:", error);
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
        }
    };

    useEffect(() => {
        fetchTramites(search);
    }, [search, currentPage, limit]);

    const formatDate = (dateString: string) => {
        if (!dateString) return "Fecha no válida";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "Fecha no válida";
        return new Intl.DateTimeFormat("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        }).format(date);
    };

    const handleRowClick = (item: BusinessItem) => {
        navigate(`/control-table-detail`, { state: { data: item } });
    };

    const handlePageClick = (page: number | string) => {
        if (typeof page === 'number' && page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const getPaginationRange = () => {
        const totalPagesToShow = 2;
        if (totalPages <= totalPagesToShow) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const range = [];
        const leftLimit = Math.max(2, currentPage - 1);
        const rightLimit = Math.min(totalPages - 1, currentPage + 1);

        range.push(1);
        if (leftLimit > 2) range.push("...");
        for (let i = leftLimit; i <= rightLimit; i++) range.push(i);
        if (rightLimit < totalPages - 1) range.push("...");
        range.push(totalPages);

        return range;
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    return (
        <>
            <TopbarCoparmex />
            <div className="py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9 mt-4'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>
                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/administracion')}>
                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                            </svg>
                            Mesa de control
                        </h1>
                        <div className='p-3 sub-header'>
                            <span>Luisa te ayuda a revisar tus pendientes</span>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 searchBar mb-4 mt-4 float-end'>
                            <div className="input-group h-100">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar nombre"
                                    aria-label="vinculacion"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-4' id='linkupTable'>
                            <section className="wrapper">
                                <main className="row title">
                                    <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                        <li className="fw-bold">Folio</li>
                                        <li className='fw-bold'>Fecha de aprobación</li>
                                        <li className='fw-bold'>Socio</li>
                                        <li className='fw-bold'>Trámite</li>
                                        <li className='fw-bold'>Status</li>
                                    </ul>
                                </main>
                            </section>
                            <section>
                                {businessData.length > 0 ? (
                                    businessData.map((item, index) => (
                                        <article
                                            className='data-users mt-3'
                                            key={index}
                                            onClick={() => handleRowClick(item)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <ul className="d-flex">
                                                <li className='responsive-truncate me-4'>{item._id}</li>
                                                <li className='d-md-block'>{formatDate(item.fecha_pago)}</li>
                                                <li className='d-md-block responsive-truncate'>{item.razon_social}</li>
                                                <li className='responsive-truncate ms-3'>Afiliación</li>
                                                <li>
                                                    <span className={`px-3 py-1 ${item.status_de_pago === 'PAGADO'
                                                        ? '--status-style-pagado'
                                                        : item.status_de_pago === 'pendiente'
                                                            ? '--status-style-pendiente'
                                                        : item.status_de_pago === 'proceso'
                                                            ? '--status-style-proceso'
                                                            : '--status-style-devolucion'
                                                        }`}>
                                                        {item.status_de_pago === 'PAGADO' ? (
                                                            'ACEPTADO'
                                                        ) : (
                                                            item.status_de_pago.toUpperCase()
                                                        )}
                                                    </span>
                                                </li>
                                            </ul>
                                        </article>
                                    ))
                                ) : (
                                    <p>No hay datos disponibles</p>
                                )}
                            </section>
                        </div>
                        {/* Paginador */}
                        <div className="pagination-controls">
                            {currentPage > 1 && (
                                <button onClick={handlePreviousPage} className="pagination-button-prev-next">
                                    <i className="bi bi-chevron-left"></i> Anterior
                                </button>
                            )}
                            {getPaginationRange().map((page, index) => (
                                <button
                                    key={index}
                                    onClick={() => typeof page === 'number' && handlePageClick(page)}
                                    className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                                    disabled={typeof page !== 'number'}
                                >
                                    {page}
                                </button>
                            ))}
                            {currentPage < totalPages && (
                                <button onClick={handleNextPage} className="pagination-button-prev-next">
                                    Siguiente <i className="bi bi-chevron-right"></i>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ControlTable;
