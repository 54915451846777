import { Modal, Button } from 'react-bootstrap';
import { Files } from '../interfaces/KnowledgeInterface';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Viewer } from '@react-pdf-viewer/core';

interface LoadFilesProps {
    show: boolean;
    onHide: () => void;
    isLoading?: boolean;
    pdfUrl?: string;
}

const LoadFiles: React.FC<LoadFilesProps> = ({ show, onHide, isLoading = false, pdfUrl }) => {

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Portafolio de la empresa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='animate__animated animate__fadeIn'>
                        <iframe
                            title='modalPdf'
                            src={pdfUrl}
                            width="100%"
                            height="500px"
                            style={{ border: "none" }}
                        >
                            This browser does not support PDFs. Please download the PDF to view it: <a href={pdfUrl}>Download PDF</a>
                        </iframe>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onHide} disabled={isLoading}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LoadFiles;