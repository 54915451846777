import axios from 'axios';
import debounce from 'lodash.debounce';
import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import '../../assets/css/vinculacion.css';
import "../../assets/css/tablaVinculacion.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import { LinkupInformation } from '../interfaces/LinkupResult';
import { Form, Button } from 'react-bootstrap';
import LinkupCostOnDemand from './LinkupCostOnDemand';
import { getIniEndMonth, hasPermission } from '../../functions/functions';
import usePermissions from '../../hooks/usePermissions';
import handleLogout from '../../functions/functions';

const Vinculacion: React.FC = () => {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const luisa_email = localStorage.getItem("luisa_email");
    const [linkupResult, setLinkupResult] = useState<LinkupInformation[]>([]);
    const [allLinkupData, setAllLinkupData] = useState<LinkupInformation[]>([]); // Store the complete dataset
    const [tipoVinculacion, setTipoVinculacion] = useState('');
    const [categoriaVinculacion, setCategoriaVinculacion] = useState('');
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [query, setQuery] = useState('');
    const navigate = useNavigate();
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Start with loading state
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    const [state, setState] = useState(false);
    const [linkupType, setLinkupType] = useState('incluida');
    const [linkupPayment, setLinkupPayment] = useState('');

    const { permissions } = usePermissions(luisa_rfc || '', luisa_email || '');

    const handleProfile = (id_usr: string, fileName: string) => {
        navigate('/result_linkup', { state: { id: 1, name: id_usr, filename: fileName } });
    }

    const handleNewLinkup = () => {
        
        if (linkupPayment === 'disponible') {
            navigate('/profile_linkup', { state: { type: 'onDemand' } });
        } else {
            if (state) {
                handleOpenModal();
            } else {
                navigate('/profile_linkup', { state: { type: linkupType } });
            }
        }
        // navigate('/profile_linkup', { state: { type: linkupType, status: state } });
    }

    const formatCSVCell = (value: string): string => {
        if (!value) return '-';
        const escapedValue = value.replace(/"/g, '""');
        return `"${escapedValue}"`;
    };

    const formatPercentage = (percentageValue: string | number): string => {
        const numValue = typeof percentageValue === 'string'
            ? parseFloat(percentageValue.replace(/,+$/, ''))
            : percentageValue;
        const percentage = (numValue * 100).toFixed(2);

        return `${percentage}%`;
    };

    const downloadCSV = () => {
        const rows: string[][] = [];

        rows.push([
            'Nombre de la vinculación',
            'Direccion',
            'Producto_Servicio',
            'Compatibilidad',
            'Fecha',
            'Tipo',
            'Categorías'
        ]);

        linkupResult.forEach((linkupInfo) => {
            const mainRow = [
                linkupInfo.linkup_name,
                '-',
                '-',
                '-',
                new Date(linkupInfo.linkup_date).toLocaleDateString(),
                linkupInfo.linkup_type,
                linkupInfo.linkup_tags.join(' ')
            ];
            rows.push(mainRow);

            linkupInfo.linkup_information.forEach((business) => {
                const businessRow = [
                    `"${business.business_name} | ${business.linkup_type}"`,
                    formatCSVCell(business.business_location || '-'),
                    formatCSVCell(business.business_product_service || '-'),
                    business.linkup_score
                        ? formatPercentage(business.linkup_score)
                        : '-',
                    '',
                    '',
                    ''
                ];
                rows.push(businessRow);
            });
        });

        const csvContent = rows.map(row =>
            row.map(cell =>
                cell.toString()
            ).join(',')
        ).join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.href = url;
        link.setAttribute('download', 'informe_trimestral.csv');
        link.click();
        URL.revokeObjectURL(url);
    };

    const searchLinkup = async (searchText: string) => {
        console.log(searchText);
    };

    const debouncedSearch = useCallback(
        debounce((searchText) => searchLinkup(searchText), 300),
        []
    );

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
        debouncedSearch(value);
        
        // Apply filter to the display data but keep the complete dataset intact
        if (value) {
            const filteredResults = allLinkupData.filter(item => 
                item.linkup_name.toLowerCase().includes(value.toLowerCase())
            );
            setLinkupResult(filteredResults);
        } else {
            setLinkupResult(allLinkupData);
        }
    };

    const applyFilters = () => {
        let filteredData = [...allLinkupData];
        
        if (tipoVinculacion) {
            filteredData = filteredData.filter(item => 
                item.linkup_type.toLowerCase() === tipoVinculacion.toLowerCase()
            );
        }
        
        if (categoriaVinculacion) {
            filteredData = filteredData.filter(item => 
                item.linkup_tags.some(tag => 
                    tag.toLowerCase() === categoriaVinculacion.toLowerCase()
                )
            );
        }
        
        if (query) {
            filteredData = filteredData.filter(item => 
                item.linkup_name.toLowerCase().includes(query.toLowerCase())
            );
        }
        
        setLinkupResult(filteredData);
    };

    const fetchLinkup = async () => {
        try {
            setIsLoading(true);
            
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getLinkups`, {
                rfc: luisa_rfc,
                linkupType: '',  // Get all data initially
                linkupCat: '',
                linkupSearch: ''
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${luisa_token}`
                }
            });
            
            if (response.status === 200) {
                const linkupData = response.data.data;
                setAllLinkupData(linkupData); // Store the complete dataset
                setLinkupResult(linkupData);   // Display data (may be filtered later)
                const { inicio, fin } = getIniEndMonth();

                if (linkupData.length > 0) {
                    // Reset state before checking conditions
                    setState(false);
                    
                    // Check if any linkup is outside current month or is of type 'mensual'
                    const hasLinkupOutsideRangeOrMensual = linkupData.some((linkupInfo: any) => 
                        !(new Date(linkupInfo.linkup_date) >= inicio && new Date(linkupInfo.linkup_date) <= fin) || 
                        linkupInfo.linkup_type === 'mensual'
                    );
                    
                    if (hasLinkupOutsideRangeOrMensual) {
                        setState(true);
                    }

                    // Check if there's a 'mensual' type linkup in the current month range
                    const hasMensualInRange = linkupData.some((linkupInfo: any) =>
                        new Date(linkupInfo.linkup_date) >= inicio &&
                        new Date(linkupInfo.linkup_date) <= fin &&
                        linkupInfo.linkup_type === 'mensual'
                    );

                    // Check if there's a free ('incluida') linkup
                    const hasFreeLinkup = linkupData.some((linkupInfo: any) =>
                        linkupInfo.linkup_type === 'incluida'
                    );

                    if (!hasFreeLinkup) {
                        // If no free linkup, set linkupType to 'incluida'
                        setLinkupType('incluida');
                        setState(false);
                    } else if (!hasMensualInRange) {
                        // If no monthly linkup in current range, set linkupType to 'mensual'
                        setState(false);
                        setLinkupType('mensual');
                    } else {
                        // Otherwise set to 'onDemand'
                        setLinkupType('onDemand');
                    }
                }
                
                // Once the fetch is complete, mark initial load as done
                setInitialLoadComplete(true);
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLinkupPaymentStatus = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getPaymentStatusLinkup`, {
                rfc: luisa_rfc,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${luisa_token}`
                }
            });
            
            if (response.status === 200) {
                const responseStatus = response.data.mensaje;
                if (responseStatus) {
                    setLinkupPayment(responseStatus.message);
                }
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    };

    useEffect(() => {
        // Load data on component mount
        fetchLinkup();
        fetchLinkupPaymentStatus();
        
        // Check for URL params
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');

        if (status === 'cancel') {
            showErrorNotification('El pago fue cancelado');
            setTimeout(() => {
                urlParams.delete('status');
                const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                window.history.replaceState(null, '', newUrl);
            }, 3000);
        }
    }, []); // Empty dependency array means this runs once on mount

    // Apply filters when filter criteria change
    useEffect(() => {
        if (initialLoadComplete) {
            applyFilters();
        }
    }, [tipoVinculacion, categoriaVinculacion, initialLoadComplete]);

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <ToastContainer />
            <div className='flex-column align-items-center py-3 px-5 linkup-bg '>
                <div className='row justify-content-center p-linkup'>

                    <div className="col-lg-10 col-md-10 col-sm-10">

                        <div className="row gap-2">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>Vinculaciones</h1>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col-lg-4 col-md-4 col-sm-4 --w-75">
                                {hasPermission(permissions, "Vinculación", "crear") ? (
                                    <button className='btn btn-linkup px-3 w-100 h-100' onClick={handleNewLinkup}>
                                        <i className="bi bi-plus-lg pe-2"></i>
                                        GENERAR NUEVA VINCULACIÓN
                                    </button>
                                ) : (
                                    <button disabled className='btn btn-linkup px-3 w-100 h-100'>
                                        <i className="bi bi-plus-lg pe-2"></i>
                                        GENERAR NUEVA VINCULACIÓN
                                    </button>
                                )}

                            </div>
                            <div className="col-lg-1 col-md-2 col-sm-4 tooltip-container">
                                <button className='btn btn-linkup tooltip-button h-100' data-tooltip="Generar informe trimestral" onClick={downloadCSV}>
                                    <i className="bi bi-box-arrow-up"></i>
                                </button>
                            </div>
                            <div className='col-md-2 col-lg-2 d-none d-lg-block'>
                                <Dropdown className='h-100' show={isDropdownOpen} onToggle={setDropdownOpen}>
                                    <Dropdown.Toggle className='h-100' onClick={() => setDropdownOpen(!isDropdownOpen)}>
                                        <i className="bi bi-sliders pe-2"></i>
                                        Filtros
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="p-2">
                                        <Form>
                                            <Form.Group className="mb-3" controlId="dropdownSelect1">
                                                <Form.Label>Tipo de vinculación</Form.Label>
                                                <Form.Select
                                                    value={tipoVinculacion}
                                                    onChange={(e) => setTipoVinculacion(e.target.value)}
                                                >
                                                    <option value="">Seleccione una opción</option>
                                                    <option value="incluida">Incluida</option>
                                                    <option value="mensual">Mensual</option>
                                                    <option value="ondemand">On demand</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="dropdownSelect2">
                                                <Form.Label>Categoria de vinculación</Form.Label>
                                                <Form.Select
                                                    value={categoriaVinculacion}
                                                    onChange={(e) => setCategoriaVinculacion(e.target.value)}
                                                >
                                                    <option value="">Seleccione una opción</option>
                                                    <option value="cliente">Cliente</option>
                                                    <option value="proveedor">Proveedor</option>
                                                    <option value="aliado_estrategico">Aliado estratégico</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Button
                                                variant="primary"
                                                className="w-100 mt-2"
                                                onClick={applyFilters}
                                            >
                                                Aplicar
                                            </Button>
                                        </Form>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12 searchBar'>
                                <div className="input-group h-100">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="bi bi-search"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar vinculación"
                                        aria-label="vinculacion"
                                        aria-describedby="basic-addon1"
                                        value={query}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-10 col-md-10 col-sm-10' id='linkupTable'>
                        <section className="wrapper">
                            <main className="row title">
                                <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                    <li className="widhtFirstli fw-bold">Vinculación</li>
                                    <li className='fw-bold'>Fecha</li>
                                    <li className='d-none d-md-block fw-bold'>Categorías</li>
                                    <li className='d-none d-md-block fw-bold'>Tipo</li>
                                </ul>
                            </main>
                        </section>
                        {isLoading ? (
                            <div className="text-center my-4">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Cargando...</span>
                                </div>
                                <p className="mt-2">Cargando vinculaciones...</p>
                            </div>
                        ) : (
                            <>
                                {linkupResult.length === 0 ? (
                                    <h5 className='text-center mt-2'>No tienes ninguna vinculación</h5>
                                ) : (
                                    <>
                                        {linkupResult.map((linkupInfo, index) => (
                                            <section key={index} className="row-fadeOut-wrapper">
                                                <article className="row transitionLink">
                                                    <ul className="d-flex justify-content-sm-around justify-content-md-center justify-content-lg-center align-items-baseline">
                                                        <li className="widhtFirstli">
                                                            <button className='btn btn-link text-decoration-none' onClick={() => handleProfile(linkupInfo.id, linkupInfo.linkup_filename)}>{linkupInfo.linkup_name}</button>
                                                        </li>
                                                        <li>{new Date(linkupInfo.linkup_date).toLocaleDateString()}</li>
                                                        <li className='d-none d-md-block'>
                                                            {linkupInfo.linkup_tags.map(tag => tag.replace('_', ' ')).join(', ')}
                                                        </li>
                                                        <li className='d-none d-md-block'>{linkupInfo.linkup_type === 'onDemand' ? 'Personalizada' : linkupInfo.linkup_type}</li>
                                                    </ul>
                                                    <ul className="more-content">
                                                        <li>
                                                            <ul>
                                                                {linkupInfo.linkup_information.map((business, index) => (
                                                                    <li key={index}
                                                                        className='d-flex align-items-center'
                                                                    >
                                                                        <span className='fw-bold mb-0 d-flex align-items-center'>
                                                                            <div className="circle-blue me-2"></div>
                                                                            <span className='responsive-truncate'>{business.business_name}</span>
                                                                        </span>
                                                                        <span className='mx-2'>|</span>
                                                                        <span className='text-small responsive-truncate'> {business.linkup_type}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </article>
                                            </section>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {modalOpen && (
                <LinkupCostOnDemand
                    show={modalOpen}
                    onHide={handleCloseModal}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

export default Vinculacion;