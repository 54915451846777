import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Login from '../auth/Login';
//import Main from '../main/Main';
//import Register from '../auth/Register';
import Create_credentials from '../auth/Create_credentials';
import Forgot_password from '../auth/Forgot_password';
import Change_password from '../auth/Change_password';
import Verify_email from '../auth/Verify_email';
//import Settings from '../../Settings';
import PerfilVinculacion from '../linkups/PerfilVinculacion';
import PerfilEmpresa from '../../PerfilEmpresa';
import PerfilUsuario from '../settings/Perfil_Usuario';
import BusinessProfile from '../settings/Business_profile';
import Home from '../../Home';
import Vinculacion from '../linkups/Vinculacion';
import ResultadoVinculacion from '../linkups/ResultadoVinculacion';
import BaseConocimiento from '../settings/KnowledgeTab';
import Configuracion from '../settings/Configuration';
import MenuAdmin from '../Administration/admin';
import AdministracionUsuarios from '../settings/UserManagment';
import CargaMasiva from '../settings/UploadFile';
import RolesPermisos from '../settings/RolesAndPermisions';
import Publicidad from '../promo/Promotion';
import PromoDetail from '../promo/PromoDetail';
import Events from '../events/Events';
import EventDetail from '../events/EventDetail';
import AdminEvents from '../events/AdminEvents';
import SubirPublicidad from '../promo/UploadPromo';
import ControlTable from '../Administration/ControlTable';
import ControlTableDetail from '../Administration/ControlTableDetail';
import { useState } from 'react';
import axios, { AxiosError } from 'axios';
import MenuCostos from '../Administration/Costos';
import AffiliationCosts from '../Administration/Costs-all/affiliation';
import PromoCosts from '../Administration/Costs-all/promotion';
import LinkupCost from '../Administration/LinkupCost';
import NewEvent from '../events/NewEvent';
import WorkCommission from '../Administration/WorkCommission'
import WorkingCommissions from '../WorkingCommissions/WorkingCommissions';
import Finance from '../Administration/Finance/Finance'
import AccountsReceivable from '../Administration/Finance/AccountsReceivable'
import AccountsPayable from '../Administration/Finance/AccountsPayable';

import handleLogout, {isTokenExpired} from '../../functions/functions';


function App() {

  const [adminRole, setAdminRole] = useState(false);
  const luisa_rfc = localStorage.getItem('luisa_rfc');

  const getUserRole = async () => {
    try {
      const luisa_token = localStorage.getItem('luisa_token');
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getAdminRole`, {
        rfc: luisa_rfc
      },{
        headers: {
          Authorization: `Bearer ${luisa_token}`,
        }
      });
      const adminRole = response.data.admin;
      
      if (adminRole) {
        setAdminRole(adminRole);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            console.log("Token expirado, cerrando sesión...");
            handleLogout();
        }
    }
      console.error('Error al obtener usuario:', AxiosError);
    }
  };
  if (luisa_rfc) {
    getUserRole();
  }

  const token = () => {
    return localStorage.getItem('luisa_token'); // Get the token from local storage
    //console.log(isTokenExpired(localStorage.getItem('luisa_token')));
  
    //return !isTokenExpired(localStorage.getItem('luisa_token'));
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <Routes>
          <Route
              path="/"
              element={
                token()
                  ? (adminRole ? <Navigate to="/configuration" /> : <Home />)
                  : <Navigate to="/login" />
              }
            />
            {/* <Route path='/' element={token() ? <Vinculacion /> : <Navigate to="/login" />} /> */}
            {/*<Route path='/register' element={<Register />} />*/}
            <Route path='/create_credentials' element={<Create_credentials />} />
            <Route path='/forgot_password' element={<Forgot_password />} />
            <Route path='/change_password' element={<Change_password />} />
            <Route path='/verify_email' element={<Verify_email />} />
            <Route path='/profile_linkup' element={token() ? <PerfilVinculacion /> : <Navigate to="/login" />} />
            <Route path='/linkup' element={token() ? <Vinculacion /> : <Navigate to="/login" />} />
            <Route path='/result_linkup' element={token() ? <ResultadoVinculacion /> : <Navigate to="/login" />} />
            <Route path='/profile_business' element={token() ? <PerfilEmpresa /> : <Navigate to="/login" />} />
            <Route path='/profile_user' element={token() ? <PerfilUsuario /> : <Navigate to="/login" />} />
            <Route path='/business_profile' element={token() ? <BusinessProfile /> : <Navigate to="/login" />} />
            <Route path='/bases-conocimiento' element={token() ? <BaseConocimiento /> : <Navigate to="/login" />} />
            <Route path='/configuration' element={token() ? <Configuracion /> : <Navigate to="/login" />} />
            <Route path='/administracion-usuarios' element={token() ? <AdministracionUsuarios /> : <Navigate to="/login" />} />
            <Route path='/uploadFile' element={token() ? <CargaMasiva /> : <Navigate to="/login" />} />
            <Route path='/roles-permisos' element={token() ? <RolesPermisos /> : <Navigate to="/login" />} />
            <Route path='/promo' element={token() ? <Publicidad /> : <Navigate to="/login" />} />
            <Route path='/events' element={token() ? <Events /> : <Navigate to="/login" />} />
            <Route path='/admin-events' element={token() ? <AdminEvents /> : <Navigate to="/login" />} />
            <Route path='/event_detail' element={token() ? <EventDetail /> : <EventDetail />} />
            <Route path='/promo_detail' element={token() ? <PromoDetail /> : <PromoDetail />} />

            <Route path='/uploadPromo' element={token() ? <SubirPublicidad /> : <Navigate to="/login" />} />
            <Route path='/control-table' element={token() ? <ControlTable /> : <Navigate to="/login" />} />
            <Route path='/control-table-detail' element={token() ? <ControlTableDetail /> : <Navigate to="/login" />} />
            <Route path='/costos' element={token() ? <MenuCostos /> : <Navigate to="/login" />} />
            <Route path='/work-commission' element={token() ? <WorkCommission /> : <Navigate to="/login" />} />
            <Route path='/Affiliations-costs' element={token() ? <AffiliationCosts /> : <Navigate to="/login" />} />
            <Route path='/promo-costs' element={token() ? <PromoCosts /> : <Navigate to="/login" />} />
            <Route path='/links-costo' element={token() ? <LinkupCost /> : <Navigate to="/login" />} />
            <Route path='/new-event' element={token() ? <NewEvent /> : <Navigate to="/login" />} />
            <Route path='/working_commissions' element={token() ? <WorkingCommissions /> : <Navigate to="/login" />} />
            <Route path='/finance' element={token() ? <Finance /> : <Navigate to="/login" />} />
            <Route path='/accounts-receivable' element={token() ? <AccountsReceivable /> : <Navigate to="/login" />} />
            <Route path='/accounts-payable' element={token() ? <AccountsPayable /> : <Navigate to="/login" />} />
            {

              <Route
                path="/login"
                element={token() ? <Navigate to="/" /> : <Login />}
              />}

            <Route
              path="/home"
              element={token() ? <Home /> : <Navigate to="/login" />}
            />
            <Route path='/administracion' element={token() ? <MenuAdmin /> : <Navigate to="/login" />} />
          </Routes>
        </Router>
      </LocalizationProvider>
    </div>
  );
}

export default App;