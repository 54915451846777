import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useEffect, useState } from 'react';
import handleLogout, { truncateFileName } from '../../functions/functions';
import { Tooltip } from 'react-tooltip'

interface BusinessItem {
    folio: string;
    fecha_pago: string;
    razon_social: string;
    tramite: string;
    status_de_pago: string;
    _id: string;
    rfc: string;
    documento_situacion_fiscal: string;
    imagen_pago: string;
    actividad: string
    principal_producto_servicios: string;
    direccion: string;
}

interface Contacto {
    apellido_materno: string;
    apellido_paterno: string;
    celular: string
    email: string;
    ext: string;
    fecha_nacimiento: string;
    nombre: string;
    telefono_oficina: string;
}

interface LinkupRequest {
    business_clients: string;
    business_supplier: string;
    business_webpage: string;
    business_mision_vision: string;
    business_description: string;
}

const ControlTableDetail: React.FC = () => {
    const location = useLocation();
    const contact_info: Contacto = location.state?.data.contactos[0];
    const general_info: BusinessItem = location.state?.data;
    const [linkupRequest, setLinkupRequest] = useState<LinkupRequest>();
    const [docUrl, setDocUrl] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [productUrl, setProductUrl] = useState('');
    const [pago, setPago] = useState(0);

    const document_url = general_info?.documento_situacion_fiscal;
    const img_url = general_info?.imagen_pago;

    const luisa_token = localStorage.getItem('luisa_token');
    const rfc = general_info?.rfc;


    const navigate = useNavigate();

    const handleAprove = async () => {
        try {
            if (!rfc) {
                showErrorNotification('No se encontró el RFC en los datos.');
                return;
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/afiliate_business`,
                { rfc },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${luisa_token}`,
                    }
                }
            );

            if (response.status === 200) {
                const msg = response.data.data;
                showNotification(msg);
                setPago(1);
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error('Error al aprobar:', error);
            showErrorNotification('Ocurrió un error al validar la afiliación');
        }
    };

    const handleReject = async () => {
        try {
            if (!rfc) {
                showErrorNotification('No se encontró el RFC en los datos.');
                return;
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/decline_business`,
                { rfc, message: 'Declinado' },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${luisa_token}`,
                    }
                }
            );

            if (response.status === 200) {
                const msg = response.data.data;
                showNotification(msg);
                setPago(1);
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error('Error al rechazar:', error);
            showErrorNotification('Ocurrió un error al validar la afiliación');
        }
    };

    const fetchLinkup = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getLinkupRequest`, {
                rfc: rfc
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            if (response.status === 200) {
                const linkupData = response.data.data.linkup_request_data;
                setProductUrl(response.data.data.file_url);
                setLinkupRequest(linkupData);
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    }

    const fetchDocument = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/getImageByUrl`,
                { url: document_url },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${luisa_token}`,
                    }
                }
            );

            if (response.status === 200) {
                const doc_url = response.data.url;
                setDocUrl(doc_url);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error('Error:', error);
        }
    };

    const fetchImg = async () => {
        try {

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/getImageByUrl`,
                { url: img_url },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${luisa_token}`,
                    }
                }
            );

            if (response.status === 200) {
                const img_url = response.data.url;
                setImgUrl(img_url);
                //await new Promise((resolve) => setTimeout(resolve, 2000));
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchLinkup();
        if (document_url) {
            fetchDocument();
            fetchImg();
        }
    }, []);

    useEffect(() => {
        if (general_info.status_de_pago === 'PAGADO' || general_info.status_de_pago === 'proceso') {
            setPago(1);
        }
    }, [general_info.status_de_pago]);

    const showErrorNotification = (text: string) => {
        toast.error(text, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showNotification = (text: string) => {
        toast.success(text, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <TopbarCoparmex />
            <div className="py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9 mt-4'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>
                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/control-table')}>
                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                            </svg>
                            {general_info.razon_social}
                        </h1>
                        <div className="control-table-detail">
                            <ToastContainer />
                            {general_info.status_de_pago === 'PAGihjasdfijlo' || general_info.status_de_pago === 'proceso' ? (
                                <center>
                                    <button className="btn btn-success w-25 me-2" onClick={handleAprove}>
                                        Aprobar
                                    </button>
                                    <button className="btn btn-danger" onClick={handleReject}>
                                        Devolución Administrativa
                                    </button>
                                </center>
                            ) : (
                                <></>
                            )}

                            <div className="flex-column align-items-center py-3 px-5 linkup-bg">
                                {/* Resto del contenido */}
                                <div className="row justify-content-center">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="row mb-5">
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <h5 className="fw-bolder text-blue mb-4">Datos de la vinculación</h5>
                                                <div className="mb-3 linkup-form-bg cards-style min-h-90">
                                                    <p className="mt-1 fw-bolder">
                                                        <span>Clientes:</span> <span className='description-style'> {linkupRequest?.business_clients || <span className='fst-italic'>pendiente...</span>}</span>
                                                    </p>
                                                    <p className="mt-1 fw-bolder">
                                                        <span>Proveedores:</span> <span className='description-style'> {linkupRequest?.business_supplier || <span className='fst-italic'>pendiente...</span>}</span>
                                                    </p>
                                                    <p className="mt-1 fw-bolder">
                                                        <span>Página Web:</span> <span className='description-style'> {linkupRequest?.business_webpage || <span className='fst-italic'>pendiente...</span>}</span>
                                                    </p>
                                                    <p className="mt-1 fw-bolder">
                                                        <span>Misión y Visión:</span><span className='description-style'> {linkupRequest?.business_mision_vision || <span className='fst-italic'>pendiente...</span>}</span>
                                                    </p>
                                                    {/* <button className="btn btn-primary w-100">Ver información completa</button> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <h5 className="fw-bolder text-blue mb-4">Datos de la empresa</h5>
                                                <div className="col-lg-12 col-md-12 col-sm-12 p-3 cards-style min-h-90">
                                                    <p className="mt-1 fw-bolder">
                                                        <span>Descripción General:</span><span className='description-style'> {linkupRequest?.business_description || <span className='fst-italic'>pendiente...</span>}</span>
                                                    </p>
                                                    <p className="mt-1 fw-bolder">
                                                        <span>Razón Social:</span><span className='description-style'> {general_info.razon_social}</span>
                                                    </p>
                                                    <p className="mt-1 fw-bolder">
                                                        <span>Actividad:</span><span className='description-style'> {general_info.actividad}</span>
                                                    </p>
                                                    <p className="mt-1 fw-bolder">
                                                        <span>Principal Producto/Servicio:</span><span className='description-style'> {general_info.principal_producto_servicios}</span>
                                                    </p>
                                                    <p className="mt-1 fw-bolder">
                                                        <span>Ubicación:</span><span className='description-style'> {general_info.direccion || <span className='fst-italic'>pendiente...</span>}</span>
                                                    </p>
                                                    {/* <button className="btn btn-primary w-100" disabled>Ver información completa</button> */}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-12 col-lg-12 col-sm-12 mt-3">
                                            <h5 className="fw-bolder text-blue mb-3">Datos del contacto</h5>
                                            <div className="mb-3 linkup-form-bg cards-style p-4">
                                                <div className="row">
                                                    <div className="col-md-5 col-lg-5 col-sm-12">
                                                        <h5 className="fw-bolder mb-3">Nombre del Representante</h5>
                                                        <p>{contact_info.nombre + ' ' + contact_info.apellido_paterno + ' ' + contact_info.apellido_materno}</p>
                                                    </div>
                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                        <h5 className="fw-bolder mb-3">Teléfono</h5>
                                                        <p>{contact_info.telefono_oficina}</p>
                                                    </div>
                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                        <h5 className="fw-bolder mb-3">Email</h5>
                                                        <Tooltip id="mail"></Tooltip>
                                                        <p data-tooltip-id="mail"
                                                            data-tooltip-content={contact_info.email}
                                                            data-tooltip-place="top">
                                                            {truncateFileName(contact_info.email, 25)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-lg-12 col-sm-12 mt-5">
                                            <h5 className="fw-bolder text-blue mb-4">Documentación</h5>
                                            {(!docUrl && !productUrl) && (
                                                <div className="text-center mt-4">
                                                    <h5 className="fw-bold text-danger">No hay documentos disponibles para mostrar.</h5>
                                                </div>
                                            )}

                                            {docUrl && (
                                                <div className="row mb-4">
                                                    <div className='col-md-6 col-lg-6 col-sm-12'>
                                                        <h4 className="fw-bolder text-blue mb-4">Constancia</h4>
                                                        <iframe
                                                            title='modalPdf'
                                                            src={docUrl}
                                                            width="100%"
                                                            height="600px"
                                                            style={{ border: "none" }}
                                                        >
                                                            Este navegador no soporta los archivos PDF. Por favor descarga el archivo para poder verlo: <a href={docUrl}>Descargar PDF</a>
                                                        </iframe>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-sm-12'>
                                                        <h4 className="fw-bolder text-blue mb-4">Comprobante</h4>
                                                        <center>
                                                            <img className="w-50 mt-5" src={imgUrl} alt="" />
                                                        </center>
                                                    </div>
                                                </div>
                                            )}

                                            {productUrl && (
                                                <div className="row">
                                                    <div className='col-md-6 col-lg-6 col-sm-12'>
                                                        <h4 className="fw-bolder text-blue">Catalogo de productos y servicios</h4>
                                                        <iframe
                                                            title='productos_servicios'
                                                            src={productUrl}
                                                            width="100%"
                                                            height="600px"
                                                            style={{ border: "none" }}
                                                        >
                                                            Este navegador no soporta los archivos PDF. Por favor descarga el archivo para poder verlo: <a href={productUrl}>Descargar PDF</a>
                                                        </iframe>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ControlTableDetail;
