import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo_luisa.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import handleLogout from "../../functions/functions";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import axios from "axios";
import usePermissions from '../../hooks/usePermissions';

const TopbarCoparmex = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState(location.pathname);
    const [adminRole, setAdminRole] = useState(false);
    const [filteredSections, setFilteredSections] = useState<{ name: string; route: string; }[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const luisa_username = localStorage.getItem("luisa_username");
    const luisa_rfc = localStorage.getItem("luisa_rfc");
    const luisa_email = localStorage.getItem("luisa_email");

    const { permissions } = usePermissions(luisa_rfc || '', luisa_email || ''); // Asegurarse de pasar valores válidos

    const sections = [
        { name: "Luisa", route: "/home" },
        { name: "Vinculación", route: "/linkup" },
        { name: "Publicidad", route: "/promo" },
        { name: "Eventos", route: "/events" },
        { name: "Comisiones de trabajo", route: "/working_commissions" },
        { name: "Configuración", route: "/configuration" },
        { name: "Administración", route: "/administracion" },
    ];

    // Agregar las secciones para usuarios y admins
    const sectionRoutes: Record<string, string[]> = {
        "Luisa": ["/", "/home"],
        "Vinculación": ["/linkup", "/profile_linkup", "/result_linkup"],
        "Publicidad": ["/promo", "/uploadPromo"],
        "Eventos": ["/events"],
        "Comisiones de trabajo": ["/working_commissions"],
        "Configuración": ["/configuration", "/administracion-usuarios", "/roles-permisos", "/business_profile"],
        "Administración": ["/administracion", "/control-table", "/bases-conocimiento", "/admin-events", 
                           "/costos", "/promo-costs", "/Affiliations-costs", "/links-costo",
                           "/work-commission", "/finance", "/accounts-payable", "/accounts-receivable"]
    };

    useEffect(() => {
        setActiveButton(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        const timeout = setTimeout(() => setIsLoaded(true), 250);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const luisa_token = localStorage.getItem('luisa_token');
        const getUserRole = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getAdminRole`, {
                    rfc: luisa_rfc,
                }, {
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    }
                });
                setAdminRole(response.data.admin);
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    const status = error.response.status;
                    if (status === 401 || status === 403) {
                        console.log("Token expirado, cerrando sesión...");
                        handleLogout();
                    }
                }
                console.error("Error al obtener rol de administrador:", error);
                setAdminRole(false);
            }
        };
        getUserRole();
    }, [luisa_rfc]);

    useEffect(() => {
        const updatedSections = sections.filter((section) => {
            if (adminRole) {
                return section.name === "Administración" || section.name === "Configuración";
            }

            const sectionPermissions = permissions[section.name];

            if (section.name === "Comisiones de trabajo") {
                return true;
            }

            if (sectionPermissions) {
                if (Array.isArray(sectionPermissions)) {
                    return sectionPermissions[0]?.ver;
                } else {
                    return Object.values(sectionPermissions).some(subSection =>
                        (subSection as any[])[0]?.ver
                    );
                }
            }
            return false;
        });

        setFilteredSections(updatedSections);
    }, [adminRole, permissions]);

    const getActiveSectionForRoute = (currentPath: string): string | null => {
        for (const [sectionName, routes] of Object.entries(sectionRoutes)) {
            if (routes.includes(currentPath)) {
                return sectionName;
            }
            
            for (const route of routes) {
                if (currentPath === "/administracion-usuarios") {
                    return "Configuración"; 
                }
                if (route !== "/" && currentPath.startsWith(route)) {
                    return sectionName;
                }
            }
        }
        return null;
    };

    const activeSection = getActiveSectionForRoute(location.pathname);

    return (
        <>
            {/* Barra superior */}
            <div className="w-100 p-4 bg-coparmex-blue topbar-coparmex position-relative" style={{ zIndex: 10 }}>
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-5 col-sm-5 point ">
                        <img className="coparmex-logo" src={Logo} alt="COPARMEX Logo" onClick={() => navigate("/")} />
                    </div>
                    <div className="col-lg-6 col-md-2 col-sm-2"></div>
                    <div className="col-lg-3 col-md-5 col-sm-5 text-end">
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="outline-secondary btn-lg text-white border border-0 dropToggle"
                                id="dropdown-basic"
                            >
                                {luisa_username} <i className="bi bi-person-circle"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropMenu">
                                <Dropdown.Item
                                    className="btn-primary btnPerfil dropItem"
                                    href="#"
                                    onClick={() => navigate("/profile_user")}
                                >
                                    <i className="bi bi-person-circle me-2"></i>Mi perfil
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="btn-primary btnPerfil dropItem"
                                    href="#"
                                    onClick={() => navigate("/configuration")}
                                >
                                    <i className="bi bi-person-circle me-2"></i>Configuración de cuentas
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="btnCerrarSesion dropItem"
                                    href="#"
                                    onClick={handleLogout}
                                >
                                    <i className="bi bi-box-arrow-left me-2"></i>Cerrar sesión
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            {/* Barra de navegación */}
            <div className="navbar navbar-expand-lg navbar-dark bg-coparmex-dark w-100 px-4 py-2 min-h" style={{ zIndex: 1 }}>
                <div className="container-fluid animate__animated animate__fadeIn">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <div className="row justify-content-center w-100">
                            {filteredSections.map((section, index) => (
                                <Link
                                    key={index}
                                    to={section.route}
                                    className={`col-lg-2 col-md-3 col-sm-4 mx-1 btn ${
                                        activeSection === section.name ? "btn-light" : "btn-outline-light border-0"
                                    }`}
                                    onClick={() => setActiveButton(section.route)}
                                >
                                    {section.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopbarCoparmex;