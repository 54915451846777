import React, { useRef, useEffect, useState, ChangeEvent } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import loadingGif from '../../assets/img/loading.gif';
import { set } from 'date-fns';

interface WorkCommissionData {
    name: string,
    president: string,
    objetive: string,
    icon: string,
    isDelegate: boolean,
    active?: boolean,
    id?: string,
    businesses_rfc?: string[]
}

interface WorkCommissionModal {
    show: boolean;
    onClick: () => void;
    isLoading?: boolean;
    dato: any;
    onEdit?: (workCommissionData: WorkCommissionData, resetForm: () => void) => void;
    onDelete?:(WorkCommissionData: WorkCommissionData) => void;
}

const EditWorkCommissionModal: React.FC<WorkCommissionModal> = ({ show, onClick, dato, isLoading = false, onEdit = () => {}, onDelete = () => {} }) => {
    const [name, setName] = useState('');
    const [president, setPresident] = useState('');
    const [objetive, setObjetive] = useState('');
    const [isDelegate, setIsDelegate] = useState<boolean>(false);
    const [icon, setIcon] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null); // Define el tipo del ref como HTMLDivElement
    const dropdownRefName = useRef<HTMLDivElement>(null); // Define el tipo del ref como HTMLDivElement
    const ignoreOutsideClick = useRef(false); // Flag temporal para ignorar clics
    const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
    const [namePresident, setNamePresident] = useState<string[]>([]);
    const [rfcPresident, setRFCPresident] = useState("");
    const [rfcPresidents, setRFCPresidents] = useState<string[]>([]);

    const [active, setActive] = useState<boolean>(false);
    const [id, setId] = useState('');
    const [business_rfc, setBusiness_rfc] = useState<string[]>([]);
    const [showConfirm, setShowConfirm] = useState(false);

    const luisa_token = localStorage.getItem('luisa_token');

    const iconExamples = [
        // Caras y emociones
        "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", 
        "🙂", "🙃", "😉", "😌", "😍", "🥰", "😘", "😗", "😙", "😚", 
        "😋", "😛", "😜", "🤪", "😝", "🤑", "🤗", "🤭", "🤔", "🤐", 
        "🤨", "😐", "😑", "😶", "😏", "😒", "🙄", "😬", "🤥", "😌", 
        "😔", "😪", "🤤", "😴", "😷", "🤒", "🤕", "🤢", "🤮", "🤧", 
        "🥵", "🥶", "🥴", "😵", "🤯", "🤠", "🥳", "😎", "🤓", "🧐",
      
        // Naturaleza y animales
        "🐶", "🐱", "🐭", "🐹", "🐰", "🦊", "🐻", "🐼", "🐨", "🐯", 
        "🦁", "🐮", "🐷", "🐽", "🐸", "🐵", "🐔", "🐧", "🐦", "🐤", 
        "🐣", "🐥", "🦆", "🦅", "🦉", "🦇", "🐺", "🐗", "🐴", "🦄", 
        "🐝", "🪲", "🐛", "🦋", "🐌", "🐞", "🐜", "🪳", "🦟", "🦗", 
        "🐢", "🐍", "🦎", "🐙", "🦑", "🦀", "🐡", "🐠", "🐟", "🐬", 
        "🐳", "🐋", "🦈", "🐊", "🦧", "🦍", "🦣", "🐘", "🦏", "🦛",
      
        // Comida y bebida
        "🍏", "🍎", "🍐", "🍊", "🍋", "🍌", "🍉", "🍇", "🍓", "🫐", 
        "🥝", "🍒", "🍑", "🥭", "🍍", "🥥", "🥑", "🍆", "🥔", "🥕", 
        "🌽", "🌶️", "🫑", "🥒", "🥬", "🥦", "🧄", "🧅", "🍄", "🥜", 
        "🌰", "🍞", "🥐", "🥖", "🥨", "🥯", "🧇", "🥞", "🧈", "🍳", 
        "🧃", "🍔", "🍟", "🍕", "🌭", "🥪", "🌮", "🌯", "🥗", "🥘",
      
        // Deportes y actividades
        "⚽", "🏀", "🏈", "⚾", "🎾", "🏐", "🏉", "🎱", "🪀", "🏓", 
        "🏸", "🥅", "🏒", "🏑", "🥍", "🏏", "⛳", "🏹", "🎣", "🤿", 
        "🥊", "🥋", "🎽", "🛹", "🛼", "🛷", "⛸️", "🥌", "🎿", "⛷️", 
        "🏂", "🪂", "🏋️", "🤸", "⛹️", "🤺", "🤼", "🤾", "🏌️", "🏇",
      
        // Objetos y cosas varias
        "🎉", "✨", "🌟", "🔥", "💧", "🌈", "☀️", "🌤️", "🌧️", "☂️", 
        "☔", "❄️", "💨", "🌪️", "🌊", "🎈", "🎁", "🎀", "🪁", "🎨", 
        "🎭", "🎷", "🎸", "🎹", "🎺", "🎻", "🥁", "📱", "💻", "🖥️", 
        "🖨️", "🖱️", "🎥", "📸", "📷", "📹", "📟", "📞", "📔", "📕", 
        "📖", "📗", "📘", "📙", "📚", "📜", "📄", "🗞️", "✉️", "📧",
      
        // Viajes y transporte
        "🚗", "🚕", "🚙", "🚌", "🚎", "🏎️", "🚓", "🚑", "🚒", "🚐", 
        "🚚", "🚛", "🚜", "🛴", "🚲", "🛵", "🏍️", "🚨", "🚍", "🚋", 
        "🚝", "🚂", "✈️", "🛫", "🛬", "🚀", "🛸", "🛶", "⛵", "🚤", 
        "🛥️", "🚢", "⚓", "⛽", "🚧", "🚦", "🗺️", "🏔️", "🏕️", "🏖️",
      ];
      

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handlePresidentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPresident(value);
        // Filtrar opciones basadas en el valor ingresado
        const filtered = namePresident.filter((name) =>
            name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    const handleBlur = () => {
        // Validar si el valor coincide con alguna opción
        const index = namePresident.findIndex((name) => name === president);
        if (index === -1) {
            setPresident(''); // Limpiar el input si no coincide
            setRFCPresident(""); // Limpiar el índice
        }
    };

    const handleOptionClick = (option: string) => {
        const index = namePresident.findIndex((name) => name === option);
        setPresident(option); // Seleccionar una opción
        setRFCPresident(rfcPresidents[index]);
        //setRFCPresident(index.toString()); // Guardar el índice
        setFilteredOptions([]); // Ocultar el menú
    };

    const handleObjetiveChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setObjetive(e.target.value);
    };

    const handleDelegateChange = (value: boolean) => {
        setIsDelegate(value);
      };

    const handleEdit = () => {
        const info = {
            name,
            president:rfcPresident,
            objetive,
            icon,
            isDelegate,
            active,
            id,
            businesses_rfc: business_rfc,

        };
        onEdit(info, resetForm);
    };

    const resetForm = () => {
        setName('');
        setPresident('');
        setObjetive('');
        setIsDelegate(false);
        setIcon('');
        setId('');
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node) &&
          !ignoreOutsideClick.current // Solo ejecuta si el clic no viene de los elementos internos
        ) {
          setShowDropdown(false);
        }   if (
            dropdownRefName.current &&
            !dropdownRefName.current.contains(event.target as Node)
          ) {
            setFilteredOptions([]); // Cierra el otro modal
          }
        
        ignoreOutsideClick.current = false; // Resetea el flag después del clic
    }; 

    const handleLoadInfo = (dato:any) =>{
        if (!dato) return; 
        setName(dato.commission_name ||"");
        setPresident(dato.full_name || "");
        setObjetive(dato.description || "");
        setIcon(dato.icon ||"");
        setIsDelegate(dato.is_delegation || false);
        setRFCPresident(dato.admin_business_rfc || "");
        setActive(dato.active? true : false || false);
        setId(dato._id || "");
        setBusiness_rfc(dato.members_rfc || "");       

    }

    const handleDelete = () =>{
        const info = {
            name,
            president:rfcPresident,
            objetive,
            icon,
            isDelegate,
            id
        };
        onDelete(info);

    }

    const handleGetInfoBD = async () =>{
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/getAllPossiblePresidents`,
                {
                    headers: {
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );
            if (response.status === 200) {
                const { names, rfcs } = response.data;
                //setTotalPages(totalFix);
                setNamePresident(names)
                setRFCPresidents(rfcs)
            } else {
                console.log("Ocurrio un error al obtener los nombres de posibles presidentes")
            }

        } catch (error:unknown) {
            if (error instanceof Error) {
                console.log("Error message:", error.message); // Accede a `message` si el error es una instancia de `Error`
            } else {
                console.log("Error desconocido:", error); // Si el error no es un objeto `Error`
            }
        }
    }

    const handleDeleteConfirm = () => {
        setShowConfirm(false); // Cierra el modal de confirmación
        handleDelete(); // Ejecuta la eliminación
    };

    const handleActive = () => {
        setActive(!active);
    };

    useEffect(() => {
        handleGetInfoBD();
        handleLoadInfo(dato);
      }, [show]); 

    useEffect(() => {
        if (showDropdown || filteredOptions) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }
    
        return () => {
            // Limpia el listener para evitar fugas de memoria
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [showDropdown, filteredOptions]);

    return (
        <Modal show={show} onHide={onClick} className="costModal" >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Editar Comisión / Delegación</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className=" mb-3 d-flex align-items-center gap-5">
                        <label className="form-check-label  me-3">
                        <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={!isDelegate}
                            onChange={() => handleDelegateChange(false)}
                        />
                        Comisión
                        </label>
                        <label className="form-check-label me-3">
                        <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={isDelegate}
                            onChange={() => handleDelegateChange(true)}
                        />
                        Delegación
                        </label>
                        <span 
                            className={`px-3 py-1 me-3 ms-auto ${active ? '--style-active' : '--status-style-devolucion'}`}
                            onClick={handleActive} // Llamas a tu función aquí
                            style={{ cursor: 'pointer' , minWidth: '120px'  }} // Cambia el cursor a 'pointer' cuando pasa el mouse
                        >
                            {active ? "activo" : "no activo"}
                        </span>
                    </div>

                    <div className="mb-3 d-flex align-items-center">
                    <label className="form-label me-3" style={{ width: "150px" }}>Nombre:</label>
                        <input
                            type="text"
                            className="form-control centered-placeholder centered-input-container "
                            placeholder="Nombre de la comisión"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                    <div className="mb-3 d-flex align-items-center position-relative">
                    <label className="form-label  me-3" style={{ width: "150px" }}>Presidente: </label>
                    <input
                        type="text"
                        className="form-control centered-placeholder centered-input-container"
                        placeholder="Nombre del presidente"
                        value={president}
                        onChange={handlePresidentChange}
                        onBlur={handleBlur} // Validar al perder el foco
                    />
                    {filteredOptions.length > 0 && (
                        <div ref={dropdownRefName} >
                        <ul
                            className="list-group position-absolute"
                            style={{
                                top: '100%',
                                left: '0',
                                zIndex: 1000,
                                width: '100%',
                                maxHeight: '200px',
                                overflowY: 'auto',
                            }}
                        >
                            {filteredOptions.map((option, index) => (
                                <li
                                    key={index}
                                    className="list-group-item list-group-item-action"
                                    onClick={() => handleOptionClick(option)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {option}
                                </li>
                            ))}
                        </ul>
                        </div>
                    )}
                    </div>
                    <div className="mb-3 d-flex align-items-center">
                    <label className="form-label  me-3" style={{ width: "150px" }}>Objetivo:</label>
                    <textarea
                        className="form-control"
                        placeholder="Objetivo de la comisión"
                        value={objetive}
                        onChange={handleObjetiveChange}
                        rows={4}
                    ></textarea>
                    </div>

                    <div className="mb-3 d-flex align-items-center" >
                        <label className="form-label  me-3" style={{ width: "150px" }}>Selecciona un Icono distintivo:</label>
                        <div className="input-group">
                            <input
                            type="text"
                            className="form-control"
                            id="emojiField"
                            value={icon}
                            placeholder="Haz clic para elegir un icono"
                            readOnly
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                                ignoreOutsideClick.current = true; // Marca el clic como interno
                                setShowDropdown((prev) => !prev); // Alterna el estado del dropdown
                              }}
                            />
                            <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={(event) => {
                                ignoreOutsideClick.current = true; // Marca el clic como interno
                                setShowDropdown((prev) => !prev); // Alterna el estado del dropdown
                              }}
                            >
                            📋
                            </button>
                        </div>
                        </div>

                        {/* Dropdown para seleccionar emojis */}
                        {showDropdown && (
                        <div ref={dropdownRef}  className= "dropdown-menu d-block p-2" style={{ maxWidth: "1950px" }}>
                            {iconExamples.map((icon, index) => (
                            <button
                                key={index}
                                type="button"
                                className="btn btn-light m-1"
                                onClick={() => {
                                setIcon(icon);
                                setShowDropdown(false);
                                }}
                            >
                                {icon}
                            </button>
                            ))}
                        </div>
                        )}


                </form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <button className='btn btn-danger' onClick={() => setShowConfirm(true) }>
                    <i className="bi bi-trash" ></i>
                </button>
                
                <div>
                    <Button variant="light" onClick={onClick} disabled={isLoading}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleEdit} disabled={isLoading || !name || !president || !objetive || !icon} className="ms-2">
                        {isLoading ? <img className="loading" src={loadingGif} alt="Cargando..." /> : 'Guardar Cambios'}
                    </Button>
                </div>
            </Modal.Footer>

            {/* Modal de confirmación */}
            <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Estás seguro de que deseas eliminarla? Esta acción no se puede deshacer.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirm(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

        </Modal>
    );
};

export default EditWorkCommissionModal;