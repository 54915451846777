// DeleteRole.tsx
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface DeleteRoleProps {
    show: boolean;
    role: string;
    onHide: () => void;
    onConfirm: () => void;
}

const DeleteRole: React.FC<DeleteRoleProps> = ({ show, role, onHide, onConfirm }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmar Eliminación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Estás seguro de que deseas eliminar el rol <strong>{role}</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteRole;
