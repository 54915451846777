import axios from 'axios';
import React, { useEffect, useState, ChangeEvent } from 'react';
import pdf from '../../assets/files/terminos_condiciones.pdf';
import loadingGif from '../../assets/img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/main.css';
import '../../assets/css/vinculacion.css'
import '../../assets/css/perfilVinculacion.css'
import ConfirmationModal from '../modals/ConfirmationModal';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import LoadFilesModal from '../modals/LoadFilesModal';
import { truncateFileName } from '../../functions/functions';
import { Tooltip } from 'react-tooltip'
import handleLogout from '../../functions/functions';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js`;

function PerfilVinculacion() {
    const luisa_rfc = localStorage.getItem('luisa_rfc');
    const luisa_token = localStorage.getItem('luisa_token');
    const navigate = useNavigate();

    const [description, setDescription] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [clients, setClients] = useState<string>('');
    const [clientsError, setClientsError] = useState(false);
    const [supplier, setSupplier] = useState<string>('');
    const [supplierError, setSupplierError] = useState(false);
    const [misionVision, setMisionVision] = useState<string>('');
    const [misionVisionError, setMisionVisionError] = useState(false);
    const [checkBoxError, setCheckBoxError] = useState(false);
    const [linkupName, setLinkupName] = useState<string>('');
    const [linkupNameError, setLinkupNameError] = useState(false);
    const [webPage, setWebPage] = useState('');
    const [webPageError, setWebPageError] = useState(false);
    const [webPageInvalid, setWebPageInvalidError] = useState(false);
    const [termsConditions, setTermsConditions] = useState(false);
    const [termsConditionsError, setTermsConditionsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    /* Modals */
    const [confirmModalType, setConfirmModalType] = useState('✅')
    const [confirmModalText, setConfirmModalText] = useState('Resultado de la Operación');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [isFilesModal, setFilesModal] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [linkupPayment, setLinkupPayment] = useState('');
    const [linkupType, setLinkupType] = useState('');

    const [userId, setUserId] = useState('');

    const location = useLocation();
    //let linkupType = '';

    //Files
    const [files, setFiles] = useState<File[]>([]);
    const maxFileSize = 5 * 1024 * 1024; //5MB

    //PDF
    const [showPdfModal, setShowPdfModal] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [pdfUrl, setPdfUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileInfo, setFileInfo] = useState('');

    //multi-step form
    const [currentStep, setCurrentStep] = useState(1);
    const [masterChecked, setMasterChecked] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        cliente: false,
        proveedor: false,
        aliado_estrategico: false,
    });

    const selectedOptions = Object.keys(checkboxes).filter(
        (key) => checkboxes[key as keyof typeof checkboxes]
    );

    const checkstepInfo = (step?: number) => {
        let stepValue = currentStep + 1;
        let flag = 1;

        if (stepValue === 2 || step === 2) {
            if (pdfUrl) {
                flag = 1;
            } else if (files.length === 0) {
                showWarnNotification('Necesitas subir un archivo');
                flag = 0;
            }
        }

        if (stepValue === 3 || step === 3) {
            if (!description.trim()) {
                showWarnNotification('Ingresa una descripción');
                setDescriptionError(true);
                flag = 0;
            }
        }

        if (stepValue === 4 || step === 4) {
            if (!clients.trim()) {
                showWarnNotification('Describe a tus clientes');
                setClientsError(true);
                flag = 0;
            }
        }

        if (stepValue === 5 || step === 5) {
            if (!supplier.trim()) {
                showWarnNotification('Describe a tus proveedores');
                setSupplierError(true);
                flag = 0;
            }
        }
        return flag;
    }

    const displayStep = (stepNumber: number) => {
        if (!checkstepInfo()) return;
        if (stepNumber >= 1 && stepNumber <= 5) {
            setCurrentStep(stepNumber);
        }
    };

    const nextStep = () => {
        if (!checkstepInfo()) return;
        if (currentStep < 5) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const updateProgressBar = () => {
        return ((currentStep - 1) / 4) * 100;
    };

    const handleFileChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
        let valid = true;
        setIsVisible(false);
        setPdfUrl('');
        const file = event.target.files?.[0];

        if (file) {
            if (file.type !== 'application/pdf') {
                valid = false;
                setConfirmModalType('⚠️');
                setModalMessage('Solo se aceptan archivos PDF');
                setIsModalOpen(true);
                event.target.value = "";
            }

            if (file.size > maxFileSize) {
                valid = false;
                setConfirmModalType('⚠️');
                setModalMessage('Solo se aceptan archivos de máximo 5MB');
                setIsModalOpen(true);
                event.target.value = "";
            }

            if (valid) {
                setFiles([file]);
            }
        }
    };

    const handleWebPageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setWebPage(event.target.value);
        setWebPageError(false);
    };

    const handleInvalidWebPageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setWebPage(event.target.value);
        setWebPageInvalidError(false);
    };

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
        setDescriptionError(false);
    };

    const handleClientsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setClients(event.target.value);
        setClientsError(false);
    };

    const handleSupplierChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setSupplier(event.target.value);
        setSupplierError(false);
    };

    const handleMisionVisionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setMisionVision(event.target.value);
        setMisionVisionError(false);
    };

    const handleTermsConditionsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTermsConditions(!termsConditions);
        setTermsConditionsError(false);
    };

    const handleLinkupNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLinkupName(event.target.value);
        setLinkupNameError(false);
    };

    const handleMasterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setMasterChecked(isChecked);
        setCheckboxes({
            cliente: isChecked,
            proveedor: isChecked,
            aliado_estrategico: isChecked,
        });
        setCheckBoxError(false);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setCheckboxes((prev) => ({ ...prev, [name]: checked }));
        setCheckBoxError(false);

        const allChecked = Object.values({ ...checkboxes, [name]: checked }).every(Boolean);
        setMasterChecked(allChecked);
    };

    const handleClosePdfModal = () => {
        setShowPdfModal(false);
    };

    const removeFileModal = (index: number) => {
        const fileToRemove = files[index];
        if (fileToRemove) {
            setFiles(files.filter((_, i) => i !== index));
        }
    };

    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsVisible(false);
        setPdfUrl('');
    };

    const createLinkup = async () => {
        let file_data = fileInfo.replace(/(\r\n|\n|\r)/gm, "");

        let linkType = 'onDemand';
        if (linkupType) {
            linkType = linkupType;
        }

        if (files.length > 0) {
            file_data = '';
        }
        try {
            const formData = new FormData();
            formData.append('rfc', luisa_rfc ?? '');
            formData.append('usr_file', files[0]);
            formData.append('file_data', file_data);
            formData.append('usr_file_name', fileName);
            formData.append('description', description);
            formData.append('clients', clients);
            formData.append('supplier', supplier);
            formData.append('misionVision', misionVision);
            formData.append('searchTags', selectedOptions.join(','));
            formData.append('linkupName', linkupName);
            formData.append('webPage', webPage);
            formData.append('linkupType', linkType);

            //const response = await axios.post(`http://localhost:3333/backend/generateLinkup`, formData, {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generateLinkup`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${luisa_token}`
                }
            });
            if (response.status === 200) {
                try {
                    //console.log(response);
                    if (response.status === 200) {
                        setUserId(response.data.user_id);
                        setConfirmModalText('Correcto')
                        setConfirmModalType('✅');
                        setModalMessage('¡El perfil de vinculación ha sido completado exitosamente!');
                        setIsModalOpen(true);
                    }

                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response) {
                            const getError = error.response.data.error;
                            console.log(getError);
                            if (getError === 'El archivo esta vacio') {
                                setConfirmModalType('⚠️');
                                setModalMessage('El archivo que intentas subir esta vacio.');
                                setIsModalOpen(true);
                            } else if (getError === 'El archivo no cumple la estructura requerida') {
                                setConfirmModalType('⚠️');
                                setModalMessage('El archivo que intentas subir no cumple la estructura requerida. Debe contener al menos un prodcuto y/o servicio');
                                setIsModalOpen(true);
                            } else {
                                setConfirmModalType('❌');
                                setModalMessage('Ocurrió un error al realizar la vinculación de tu perfil, intenta de nuevo más tarde.');
                                setIsModalOpen(true);
                            }
                        } else if (error.request) {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
                            setIsModalOpen(true);
                            console.log('Request made but no response received:', error.request);
                        } else {
                            setConfirmModalType('❌');
                            setModalMessage('Ocurrió un error al realizar la vinculación de tu perfil, intenta de nuevo más tarde.');
                            setIsModalOpen(true);
                            console.log('Error message:', error.message);
                        }
                    } else {
                        console.error('An unknown error occurred:', error);
                    }
                }

                // setDescription('');
                // setTermsConditions(false);
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    setConfirmModalText('Error')
                    if (getError === '[Luisa-Backend] Error adding linkup profile') {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al crear tu perfil de vinculación, ponte en contacto con un administrador.');
                        setIsModalOpen(true);
                    }
                    else {
                        setConfirmModalType('❌');
                        setModalMessage('Ocurrió un error al crear tu perfil de vinculación, intenta de nuevo más tarde.');
                        setIsModalOpen(true);
                    }
                } else if (error.request) {
                    setConfirmModalType('❌');
                    setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
                    setIsModalOpen(true);
                    console.log('Request made but no response received:', error.request);
                } else {
                    setConfirmModalType('❌');
                    setModalMessage('Ocurrió un error al crear tu perfil de vinculación, intenta de nuevo más tarde.');
                    setIsModalOpen(true);
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    };

    const getLinkup = async () => {
        try {
            //const response = await axios.post(`http://localhost:3333/backend/getLinkupRequest`, {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getLinkupRequest`, {
                rfc: luisa_rfc
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${luisa_token}`
                    }
                });
            if (response.status === 200) {
                const linkupData = response.data.data.linkup_request_data;

                setDescription(linkupData.business_description);
                setClients(linkupData.business_clients);
                setSupplier(linkupData.business_supplier);
                setWebPage(linkupData.business_webpage);
                setMisionVision(linkupData.business_mision_vision);
                setLinkupName(linkupData.linkup_name);
                setPdfUrl(response.data.data.file_url);
                setFileName(linkupData.linkup_file_name);
                setFileInfo(linkupData.business_portfolio);
                updateCheckboxes(linkupData.business_tags);

            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.error;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    };

    const showErrorNotification = (text: String) => {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showPayNotification = (text: String) => {
        toast.success(text, {
            position: "top-right",
            autoClose: 8000,
            closeOnClick: true,
            pauseOnHover: true
        });
    }

    const updateCheckboxes = (tags: string[]) => {
        const updatedState = {
            cliente: tags.includes('cliente'),
            proveedor: tags.includes('proveedor'),
            aliado_estrategico: tags.includes('aliado_estrategico'),
        };

        setCheckboxes(updatedState);
    };

    const showWarnNotification = (text: String) => {
        toast.warning(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        console.log(linkupType);
        
        event.preventDefault();
        let valid = true;
        setIsLoading(true);
        //console.log(selectedOptions);

        const isValidUrl = (string: any): boolean => {
            try {
                new URL(string);
                return true;
            } catch {
                return false;
            }
        };

        if (!webPage.trim()) {
            setWebPageError(true);
            valid = false;
        } else if (!isValidUrl(webPage)) {
            setWebPageInvalidError(true);
            valid = false;
        } else {
            setWebPageError(false);
        }

        if (!misionVision.trim()) {
            setMisionVisionError(true);
            valid = false;
        } else {
            setMisionVisionError(false);
        }

        if (!termsConditions) {
            setTermsConditionsError(true);
            showWarnNotification('Marca la casilla de términos y condiciones antes de continuar');
            setIsLoading(false);
            return;
        } else {
            setTermsConditionsError(false);
        }

        if (selectedOptions.length === 0) {
            setCheckBoxError(true);
            valid = false;
        } else {
            setCheckBoxError(false);
        }

        if (!linkupName.trim()) {
            setLinkupNameError(true);
            valid = false;
        } else {
            setLinkupNameError(false);
        }



        if (!valid) {
            console.log('err');
            showWarnNotification('Ingresa los datos solicitados');
            setIsLoading(false);
            return;
        }

        await createLinkup();

        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    const handleNewLinkup = () => {
        navigate('/linkup');
    };

    useEffect(() => {
        const fetchAndProcess = async () => {
            // console.log(location.state.type);

            if (location.state.type === 'incluida') {
                setLinkupType('incluida');
            }
            
            if (location.state.type === 'mensual' || location.state.type === 'incluida' || location.state.type === 'mensualWA') {
                await getLinkup();
            } else {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/getPaymentStatusLinkup`, {
                        rfc: luisa_rfc,
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${luisa_token}`
                        }
                    });

                    let paymentStatus = null;
                    if (response.status === 200) {
                        const responseStatus = response.data.mensaje;
                        if (responseStatus) {
                            paymentStatus = responseStatus.message;
                            setLinkupPayment(paymentStatus);
                        }
                    }

                    await getLinkup();

                    const urlParams = new URLSearchParams(window.location.search);
                    const status = urlParams.get('status');

                    if (status) {
                        if (status === 'success') {
                            if (paymentStatus) {
                                showPayNotification('Pago completado con éxito');
                            } else {
                                navigate('/linkup');
                            }
                        } else if (status === 'cancel') {
                            navigate('/linkup');
                        }
                    } else if (location.state) {
                        setLinkupType(location.state.type);
                        if (!linkupType) {
                            navigate('/linkup');
                        }
                    } else {
                        navigate('/linkup');
                    }

                    if (status === 'success' || status === 'cancel') {
                        setTimeout(() => {
                            urlParams.delete('status');
                            const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                            window.history.replaceState(null, '', newUrl);
                        }, 3000);
                    }
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        const status = error.response.status;
                        if (status === 401 || status === 403) {
                            console.log("Token expirado, cerrando sesión...");
                            handleLogout();
                        }
                    }
                    if (axios.isAxiosError(error)) {
                        if (error.response) {
                            const getError = error.response.data.error;
                            console.log(getError);
                        } else if (error.request) {
                            console.log('Request made but no response received:', error.request);
                        } else {
                            console.log('Error message:', error.message);
                        }
                    } else {
                        console.error('An unknown error occurred:', error);
                    }
                }
            }
        };

        fetchAndProcess();
    }, [location.state, navigate]);

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <div className='flex-column align-items-center py-3 px-5 linkup-bg '>
                <ToastContainer />
                <div className='row justify-content-center'>
                    <div className='col-sm-12 col-md-7 col-lg-7'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp'>Generar Vinculación</h1>

                        <div className="container mt-5">
                            <div className="progress px-1" style={{ height: '3px' }}>
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${updateProgressBar()}%` }}
                                    aria-valuenow={updateProgressBar()}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                ></div>
                            </div>

                            <div className="step-container d-flex justify-content-between">
                                {[1, 2, 3, 4, 5].map((step) => (
                                    <div
                                        key={step}
                                        className={`step-circle ${currentStep >= step ? 'active' : ''}`}
                                        onClick={() => displayStep(step)}
                                    >
                                        {step}
                                    </div>
                                ))}
                            </div>

                            <form noValidate className={`multi-step-form animate__animated animate__fadeInRight`} onSubmit={handleSubmit}>
                                {currentStep === 1 && (
                                    <div className='animate__animated animate__fadeInRight'>
                                        <div className="step step-1 linkup-form-bg">
                                            <div className="mb-3">
                                                <label className='--textPrimaryVariant'>Portafolio de Productos y Servicios</label>
                                                <div>
                                                    <p className='mb- mt-4 text-secondary'>Sube tus archivos para poder continuar</p>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                                            <label id='input-img' className='d-inline'>
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    id="rango"
                                                                    accept='.pdf'
                                                                    onChange={handleFileChangeModal}>
                                                                </input>
                                                                <i className="bi bi-folder-plus px-5 mx-1 border border-dark point"></i>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-1 col-md-3 col-sm-12 text-center no-vr">
                                                            <div className="vr mt-2 btn-primary linkup-vr"></div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-sm-12">
                                                            {isVisible && pdfUrl && (
                                                                <div>
                                                                    <Tooltip id="base"></Tooltip>
                                                                    <div
                                                                        data-tooltip-id="base"
                                                                        data-tooltip-content={fileName}
                                                                        data-tooltip-place="top"
                                                                        className='border --divStyled px-2 mt-3 col-md-6 col-lg-6 col-sm-3 text-center clickable'
                                                                        onClick={() => setFilesModal(true)}
                                                                    >
                                                                        {truncateFileName(fileName, 22)}
                                                                        <span onClick={handleDelete}>
                                                                            <i className="bi bi-x ms-1"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {files.length > 0 && (
                                                                <div>
                                                                    {/* <Tooltip id="mod"></Tooltip> */}
                                                                    {files.map((file, index) => (
                                                                        <div className='border --divStyled px-2 mt-3 col-md-6 col-lg-6 col-sm-3 text-center'
                                                                            key={index}
                                                                        // data-tooltip-id="mod"
                                                                        // data-tooltip-content={file.name}
                                                                        // data-tooltip-place="top">
                                                                        >
                                                                            {/* <i className="bi bi-file-earmark-text me-1"></i> */}
                                                                            {file.name}
                                                                            <span
                                                                                onClick={() => removeFileModal(index)} >
                                                                                <i className="bi bi-x ms-1"></i>
                                                                            </span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-3 justify-content-around'>
                                            <button type="button" className="btn btn-danger btnSecondary float-end col-md-5" onClick={handleNewLinkup}>Regresar</button>
                                            <button type="button" className="btn btn-primary next-step float-end col-md-5" onClick={nextStep}>Continuar</button>
                                        </div>
                                    </div>
                                )}

                                {currentStep === 2 && (
                                    <div className='animate__animated animate__fadeInRight'>
                                        <div className="step step-2 linkup-form-bg">
                                            <div className="mb-3">
                                                <label className=' --textPrimaryVariant'>¿A que se dedica tu empresa?</label>
                                                <textarea
                                                    id='desc'
                                                    className={`form-control mt-3 --textAreaStyled bg-secondary d-flex ${descriptionError ? 'is-invalid' : ''}`}
                                                    value={description}
                                                    placeholder="El propósito de mi empresa es..."
                                                    rows={5}
                                                    onChange={handleDescriptionChange}
                                                    autoComplete="off"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-3 justify-content-around'>
                                            <button type="button" className="btn btn-danger btnSecondary prev-step float-end col-md-5" onClick={prevStep}>Regresar</button>
                                            <button type="button" className="btn btn-primary next-step float-end col-md-5" onClick={nextStep}>Continuar</button>
                                        </div>
                                    </div>
                                )}

                                {currentStep === 3 && (
                                    <div className='animate__animated animate__fadeInRight'>
                                        <div className="step step-3 linkup-form-bg">
                                            <div className="mb-3">
                                                <label className='--textPrimaryVariant'>Describe a tus clientes ideales</label>
                                                <textarea
                                                    id='client'
                                                    className={`form-control mt-3 --textAreaStyled bg-secondary d-flex ${clientsError ? 'is-invalid' : ''}`}
                                                    value={clients}
                                                    placeholder="El mejor tipo de cliente que puedo tener sería"
                                                    rows={3}
                                                    onChange={handleClientsChange}
                                                    autoComplete="off"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-3 justify-content-around'>
                                            <button type="button" className="btn btn-danger btnSecondary prev-step float-end col-md-5" onClick={prevStep}>Regresar</button>
                                            <button type="button" className="btn btn-primary next-step float-end col-md-5" onClick={nextStep}>Continuar</button>
                                        </div>
                                    </div>
                                )}

                                {currentStep === 4 && (
                                    <div className='animate__animated animate__fadeInRight'>
                                        <div className='step step-4 linkup-form-bg'>
                                            <div className="mb-3">
                                                <label className='--textPrimaryVariant'>Describe a tus proveedores</label>
                                                <textarea
                                                    id='supp'
                                                    className={`form-control mt-3 --textAreaStyled bg-secondary d-flex ${supplierError ? 'is-invalid' : ''}`}
                                                    value={supplier}
                                                    placeholder="El mejor tipo de proveedor que puedo tener sería..."
                                                    rows={3}
                                                    onChange={handleSupplierChange}
                                                    autoComplete="off"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-3 justify-content-around'>
                                            <button type="button" className="btn btn-danger btnSecondary prev-step float-end col-md-5" onClick={prevStep}>Regresar</button>
                                            <button type="button" className="btn btn-primary next-step float-end col-md-5" onClick={nextStep}>Continuar</button>
                                        </div>
                                    </div>
                                )}

                                {currentStep === 5 && (
                                    <div className='animate__animated animate__fadeInRight'>
                                        <div className='step step-5 linkup-form-bg'>
                                            <div className="mb-3">
                                                <h5 className='--textPrimaryVariant'>Datos Necesarios</h5>
                                                <p>Necesitamos unos últimos datos para poder completar tu vinculación.</p>
                                                <div className="mt-4 mb-3">
                                                    <label className='--textPrimaryStrong'>URL de página web</label>
                                                    <input
                                                        id='web'
                                                        type="text"
                                                        className={`form-control --style-linkup-inputs centered-placeholder centered-input-container d-flex pad-web ${webPageError || webPageInvalid ? 'is-invalid' : ''}`}
                                                        placeholder='Página web'
                                                        onChange={(e) => {
                                                            handleWebPageChange(e);
                                                            handleInvalidWebPageChange(e);
                                                        }}
                                                        value={webPage}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className='--textPrimaryStrong'>Misión y Visión</label>
                                                    <textarea
                                                        id='supp'
                                                        className={`form-control mt-1 centered-placeholder --style-linkup-inputs d-flex ${misionVisionError ? 'is-invalid' : ''}`}
                                                        value={misionVision}
                                                        placeholder="Misión y Visión"
                                                        rows={3}
                                                        onChange={handleMisionVisionChange}
                                                        autoComplete="off"
                                                    ></textarea>
                                                </div>
                                                <div className="mb-3">
                                                    <label className='--textPrimaryStrong'>Qué estoy buscando</label>
                                                    <div className="form-check">
                                                        <input className={`form-check-input ${checkBoxError ? 'is-invalid' : ''}`}
                                                            type="checkbox"
                                                            checked={masterChecked}
                                                            onChange={handleMasterChange}
                                                        />
                                                        <label className="form-check-label">
                                                            Todos
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className={`form-check-input ${checkBoxError ? 'is-invalid' : ''}`}
                                                            type="checkbox"
                                                            name="cliente"
                                                            checked={checkboxes.cliente}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label className="form-check-label">
                                                            Cliente
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className={`form-check-input ${checkBoxError ? 'is-invalid' : ''}`}
                                                            type="checkbox"
                                                            name="proveedor"
                                                            checked={checkboxes.proveedor}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label className="form-check-label">
                                                            Proveedor
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className={`form-check-input ${checkBoxError ? 'is-invalid' : ''}`}
                                                            type="checkbox"
                                                            name="aliado_estrategico"
                                                            checked={checkboxes.aliado_estrategico}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label className="form-check-label">
                                                            Aliado estratégico
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className='--textPrimaryStrong'>Nombra tu vinculación</label>
                                                    <input
                                                        id='linkName'
                                                        type="text"
                                                        className={`form-control centered-placeholder centered-input-container d-flex --style-linkup-inputs ${linkupNameError ? 'is-invalid' : ''}`}
                                                        placeholder='Ej. Vinculación 1'
                                                        onChange={handleLinkupNameChange}
                                                        value={linkupName}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="terms-position mb-4 d-flex align-items-center">
                                                    <input
                                                        id="tyc"
                                                        className={`form-check-input ${termsConditionsError ? 'is-invalid' : ''}`}
                                                        type="checkbox"
                                                        onChange={handleTermsConditionsChange}
                                                        checked={termsConditions}
                                                    />
                                                    <label className="form-check-label text-dark ps-2 d-flex align-items-center mt-1">
                                                        <span>Aceptar</span><button type='button' className='btn btn-link ps-1' onClick={() => setShowPdfModal(true)}>términos y condiciones</button>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex mt-3 justify-content-around'>
                                            <button type="button" className="btn btn-danger btnSecondary prev-step float-end col-md-5" onClick={prevStep}>Anterior</button>
                                            <button type="submit" className="btn btn-linkup ms-1 float-end col-md-5" disabled={isLoading}>
                                                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Generar vinculación'}
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {/* {currentStep === 6 && (
                                    <div className='animate__animated animate__fadeInRight'>
                                        <div className='step step-5 linkup-form-bg'>
                                            <div className="terms-position mb-4">
                                                <input
                                                    id="tyc"
                                                    className={`form-check-input mt-2 ${termsConditionsError ? 'is-invalid' : ''}`}
                                                    type="checkbox"
                                                    onChange={handleTermsConditionsChange}
                                                    checked={termsConditions}
                                                />
                                                <label className="form-check-label text-dark ps-2">
                                                    <span>Aceptar</span><button type='button' className='btn btn-link' onClick={() => setShowPdfModal(true)}>términos y condiciones</button>
                                                </label>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-linkup w-30 ms-1 float-end" disabled={isLoading}>
                                            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Generar vinculación'}
                                        </button>
                                        <button type="button" className="btn btn-primary prev-step float-end" onClick={prevStep}>Anterior</button>
                                    </div>
                                )} */}
                            </form>
                        </div>

                    </div>
                </div >
            </div >

            <ConfirmationModal show={isModalOpen}
                onHide={() => {
                    setIsModalOpen(false);
                    if (confirmModalText === 'Correcto') {
                        navigate('/result_linkup', { state: { id: 1, name: userId } });
                    }
                }}
                text={modalMessage}
                type={confirmModalType}
                titleText={confirmModalText}
                isLoading={isLoading}
                userId={userId}
            />


            <Modal show={showPdfModal} onHide={handleClosePdfModal} size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Términos y condiciones</Modal.Title>
                </Modal.Header>
                <Modal.Body className='m-2'>
                    <Viewer
                        fileUrl={pdf}
                        plugins={[defaultLayoutPluginInstance]}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleClosePdfModal}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>

            <LoadFilesModal
                show={isFilesModal}
                onHide={() => {
                    setFilesModal(false);
                }}
                isLoading={isLoading}
                pdfUrl={pdfUrl}
            />

        </>
    )
}

export default PerfilVinculacion;
