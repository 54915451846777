// ImageUploaderModal.tsx
import React, { Component, ChangeEvent, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import {getCroppedImg} from '../../functions/functions'; // Importa la función que maneja el recorte

interface ImageUploaderModalProps {
  show: boolean;
  onClose: () => void;
  //onImageUpdate: (newImage: string) => void;
  onImageUpdate: (newImage: string,formData: FormData) => void;
}

const ImageUploaderModal: React.FC<ImageUploaderModalProps> = ({
  show,
  onClose,
  onImageUpdate,
}) => {
  const [isImage, setIsImage] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    width: number;
    height: number;
    x: number;
    y: number;
  } | null>(null);

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(file);
        setIsImage(true);
      }
    }
  };

  const onCropComplete = (_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  /*const handleUpload = async () => {
    if (!image || !croppedAreaPixels) return;

    try {
      const croppedImageBlob = await getCroppedImg(image, croppedAreaPixels);
      const newImageURL = URL.createObjectURL(croppedImageBlob);

      onImageUpdate(newImageURL); // Actualiza la imagen en el perfil
      onClose(); // Cierra el modal
    } catch (error) {
      console.error('Error al subir la imagen:', error);
    }
  };
  */

  const handleUpload = async () => {
    if (!image || !croppedAreaPixels) return;
    try {
      const croppedImageBlob = await getCroppedImg(image, croppedAreaPixels);
      
      // Crear URL para mostrar la imagen
      const newImageURL = URL.createObjectURL(croppedImageBlob);
      
      // Crear FormData para subir la imagen más tarde
      const formData = new FormData();
      formData.append('image', croppedImageBlob, 'profile_image.jpg');
      
      // Devolver tanto la URL como el FormData
      onImageUpdate(newImageURL, formData);
      onClose();
    } catch (error) {
      console.error('Error al procesar la imagen:', error);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Subir Imagen de Perfil</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <input type="file" accept="image/*" onChange={onImageChange} style={{display: "block"}} />
        {image && (
          <div style={{ position: 'relative', height: '300px' }}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1} // Imagen cuadrada
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        )}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleUpload} disabled={!isImage}>
          Subir Imagen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageUploaderModal;
