import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import loadingGif from '../../assets/img/loading.gif'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DisclaimerModal from './DisclaimerModal';

interface EventCostModalProps {
    show: boolean;
    onHide: () => void;
    isLoading?: boolean;
    amount?: number;
    quantity?: number;
    pctg?: number;
    onClick?: () => void;
    callPay?: (total: number, qty: number) => void;
    texto: string;
}

const EventCostModal: React.FC<EventCostModalProps> = ({ show, onHide, isLoading = false, amount, quantity, pctg, texto, callPay = () => { }, onClick }) => {

    const [total, setTotal] = useState(amount);
    const [qty, setQty] = useState('');
    const [method, setMethod] = useState('');
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    const [isNotificationActive, setIsNotificationActive] = useState(false);

    const handleQtyTickets = async (event: ChangeEvent<HTMLSelectElement>) => {
        let qtyNum = parseInt(event.target.value);
        setQty(event.target.value);

        if (amount) {
            let totalAmount = qtyNum * amount;
            if (quantity && pctg) {
                if (qtyNum >= quantity) {
                    totalAmount = totalAmount - (totalAmount * (pctg / 100));
                }
            }
            setTotal(totalAmount);
        }
    }

    const handleMethod = async (event: ChangeEvent<HTMLSelectElement>) => {
        setMethod(event.target.value);
    }

    const handleSave = () => {
        console.log(qty);
        console.log(method);

        if (qty === '') {
            showErrorNotification('Selecciona al menos 1 boleto');
            return;
        }
        if (method === '') {
            showErrorNotification('Selecciona el método de pago');
            return;
        }
        if (total) {
            setShowDisclaimer(true);
            // callPay(total);
            // return;
        }
    };

    const handlePromo = () => {
        if (amount) {
            setShowDisclaimer(true);
        }
    };

    const handleContinue = () => {
        setShowDisclaimer(false);
        console.log('total: ', total);
        console.log('amount: ', amount);
        
        if (total) {
            callPay(total, parseInt(qty));
        }else if (amount) {
            callPay(amount, parseInt(qty));
        }
    };

    const handleCancel = () => {
        setShowDisclaimer(false);
    };

    const showErrorNotification = (text: String) => {
        if (!isNotificationActive) {
            setIsNotificationActive(true);
            toast.error(text, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => setIsNotificationActive(false)
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <Modal show={show && !showDisclaimer} onHide={onHide} className='costModal'>
                <Modal.Header closeButton>
                    {texto === 'tu publicidad' ? (
                        <Modal.Title><h3>Completar Pago - <span className='fs-4'>MXN ${amount}</span> </h3></Modal.Title>
                    ) : (
                        <Modal.Title><h3>Completar Pago - <span className='fs-4'>MXN ${total}</span> </h3></Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <h5 className="mb-3">Completa el pago de {texto}, escoge tu método de pago:</h5>
                        {texto === 'tu publicidad' ? (
                            <>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control centered-placeholder centered-input-container point"
                                        readOnly
                                        onClick={handlePromo}
                                        value="Stripe (Crédito ó Débito)"
                                    />
                                    <span className="input-group-text">
                                        <i className="bi bi-stripe"></i>
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="input-group mb-3">
                                    <select
                                        id="paymentLocation"
                                        className="form-select centered-placeholder centered-input-container"
                                        onChange={handleQtyTickets}
                                        value={qty}
                                    >
                                        <option value="">Selecciona la cantidad de boletos</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                    <span className="input-group-text">
                                        <i className="bi bi-ticket-detailed"></i>
                                    </span>
                                </div>

                                <div className="input-group mb-3">
                                    <select
                                        id="paymentLocation"
                                        className="form-select centered-placeholder centered-input-container"
                                        onChange={handleMethod}
                                        value={method}
                                    >
                                        <option value="0">Selecciona tu método de pago</option>
                                        <option value="1">Stripe (Crédito ó Débito)</option>
                                        {/* <option value="2">Transferencia</option> */}
                                    </select>
                                    <span className="input-group-text">
                                        <i className="bi bi-stripe"></i>
                                    </span>
                                </div>
                            </>
                        )}

                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <>
                        {texto === 'tu publicidad' ? (
                            <>
                                <Button variant="danger" onClick={onHide} disabled={isLoading}>
                                    Cancelar
                                </Button>
                            </>
                        )
                            : (
                                <>
                                    <Button variant="danger" onClick={onHide} disabled={isLoading}>
                                        Cancelar
                                    </Button>
                                    <Button variant="primary" onClick={handleSave} disabled={isLoading}>
                                        {isLoading ? <img className="loading" src={loadingGif} alt="Cargando..." /> : 'Aceptar'}
                                    </Button>
                                </>
                            )}

                    </>
                </Modal.Footer>
            </Modal>

            <DisclaimerModal
                show={showDisclaimer}
                onConfirm={handleContinue}
                onCancel={handleCancel}
                title="Aviso"
                message="Le informamos que para el pago será transferido al proveedor de pago correspondiente y que sus datos serán tratados por dicho proveedor de acuerdo con sus políticas de privacidad."
            />
        </>
    );
};

export default EventCostModal;