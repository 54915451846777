import { useEffect } from 'react';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import TopbarCoparmex from '../main/TopBarCoparmex';
import { useNavigate } from 'react-router-dom';


GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js`;

const Configuration = () => {

    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    return (
        <>
            <TopbarCoparmex></TopbarCoparmex>
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9'>
                    <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeIn'>Configuración</h1>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <div className="mb-3 linkup-form-bg me-2 cards-style" onClick={() => navigate('/administracion-usuarios')}>
                                    <h2 className='text-blue'>Administración de Usuarios</h2>
                                    <p className='mb- mt-4'>Administra a los usuarios que utilizarán la plataforma</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <div className="mb-3 linkup-form-bg cards-style" onClick={() => navigate('/roles-permisos')}>
                                    <h2 className='text-blue'>Roles y Permisos</h2>
                                    <p className='mb- mt-4'>Acceso a secciones y permisos para usuarios.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <div className="mb-3 linkup-form-bg cards-style" onClick={() => navigate('/business_profile')}>
                                    <h2 className='text-blue'>Información Empresa</h2>
                                    <p className='mb- mt-4'>Editar la información de la empresa para mejorar la vinculación.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Configuration;