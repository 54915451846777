import React, { ChangeEvent, useState } from 'react';
import TopbarCoparmex from "../main/TopBarCoparmex";
import "../../assets/css/administration.css";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import "../../assets/css/NewEvent.css";
import loadingGif from '../../assets/img/loading.gif';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { Tooltip } from 'react-tooltip'
import handleLogout from '../../functions/functions';

const NewEvent = () => {
    const navigate = useNavigate();
    const luisa_token = localStorage.getItem('luisa_token');
    const [paymentLocation, setPaymentLocation] = useState("");
    const [eventFormat, setEventFormat] = useState("");
    const [paymentMod, setPaymentMod] = useState("");
    const [fileBanner, setFileBanner] = useState<File | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [fileUrlBanner, setFileUrlBanner] = useState<string>('');

    const [isLoading, setIsLoading] = useState(false);

    const today = new Date();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [exclusive, setExclusive] = useState(false);

    const [formData, setFormData] = useState({
        title: '',
        price: '',
        spots: '',
        locationUrl: '',
        discount: '',
        discount_qty: '',
        description: '',
        genPrice: '',
        location: '',
        eventTime: '',
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { id, value } = event.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const invalidKeys = ["e", "E", "+", "-"];
        if (invalidKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    const isValidFileType = (fileName: string) => {
        const allowedExtensions = ['png', 'jpg', 'jpeg'];
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        return fileExtension && allowedExtensions.includes(fileExtension);
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile && isValidFileType(selectedFile.name)) {
            let url = window.URL.createObjectURL(selectedFile);
            setFileUrl(url);
            setFile(selectedFile);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .png, .jpg o .jpeg');
        }
    };

    const handleFileSelectBanner = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFileBanner = event.target.files?.[0];
        if (selectedFileBanner && isValidFileType(selectedFileBanner.name)) {
            let url = window.URL.createObjectURL(selectedFileBanner);
            setFileUrlBanner(url);
            setFileBanner(selectedFileBanner);
        } else {
            showWarnNotification('Por favor, selecciona un archivo en formato .png, .jpg o .jpeg');
        }
    };

    const handleExclusiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        setExclusive(!exclusive);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log(startDate);

        if (!formData.title || !formData.eventTime || !startDate || !formData.price || !formData.spots || !paymentMod || !fileBanner || !formData.description || !eventFormat) {
            showWarnNotification('Por favor completa todos los campos obligatorios.');
            setIsLoading(false);
            return;
        }

        const data = new FormData();

        if (formData.discount === '') {
            formData.discount = '0';
        }
        if (formData.discount_qty === '') {
            formData.discount_qty = '0';
        }

        Object.entries(formData).forEach(([key, value]) => {
            data.append(key, value);
        });

        if (file) {
            data.append('image_url', file);
        } else {
            data.append('image_url', '');
        }
        if (fileBanner) {
            data.append('banner_image_url', fileBanner);
        }else{
            showWarnNotification("Por favor, selecciona una imagen");
        }

        if (eventFormat === 'presencial' || eventFormat === 'ambos') {
            if (formData.location === '' || formData.locationUrl === '') {
                showWarnNotification("Por favor, escribe la ubicacion del evento y la url de google maps");
                return;
            }
        }

        if (!exclusive) {
           if (formData.genPrice === '') {
                showWarnNotification("Por favor, escribe el precio general");
                return;
            } 
        }else{
            formData.genPrice = '0';
        }

        let ini = new Date(startDate);
        setIsLoading(true);

        data.append('price_member', formData.price);
        data.append('price_general', formData.genPrice);
        data.append('reminder', paymentLocation);
        data.append('location_url', formData.locationUrl);
        data.append('event_format', eventFormat);
        data.append('event_date', ini.getFullYear() + '-' + (ini.getMonth() + 1) + '-' + ini.getDate());
        data.append('event_time', formData.eventTime);
        data.append('event_payment_methods', paymentMod);
        data.append('members_only', exclusive ? 'true' : 'false');

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/v1/events/createEvent`,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${luisa_token}`,
                    },
                }
            );

            if (response.status === 200) {
                setFormData({
                    title: '',
                    price: '',
                    spots: '',
                    locationUrl: '',
                    discount: '',
                    discount_qty: '',
                    description: '',
                    genPrice: '',
                    location: '',
                    eventTime: '',
                });
                setPaymentLocation('');
                setEventFormat('');
                setPaymentMod('');
                setStartDate(null);
                setFile(null);
                setFileBanner(null);
                showNotification('¡El evento se creó correctamente!');
                await new Promise((resolve) => setTimeout(resolve, 2000));
                navigate('/admin-events')
            } else {
                showErrorNotification('Ocurrió un error al crear el evento.');
            }
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setIsLoading(false);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            showErrorNotification('Ocurrió un error al realizar la publicación.');
            console.error(error);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setIsLoading(false);
        }
    };

    const showWarnNotification = (text: string) =>
        toast.warning(text, { position: 'top-right', autoClose: 4000 });
    const showErrorNotification = (text: string) =>
        toast.error(text, { position: 'top-right', autoClose: 3000 });
    const showNotification = (text: string) =>
        toast.success(text, { position: 'top-right', autoClose: 4000 });

    return (
        <>
            <TopbarCoparmex />
            <ToastContainer />
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'>
                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/admin-events')}>
                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                            </svg>
                            Nuevo evento
                        </h1>
                    </div>
                    <form onSubmit={handleSubmit} className='col-md-9 p-4 --form-style'>
                        <div className="">
                            <div className="row">
                                {/* IZQ */}
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className='--textPrimaryVariant'>Título del evento <sup className='bi-red'>*</sup></label>
                                        <input
                                            id='title'
                                            type="text"
                                            className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                            placeholder='Nuevo evento'
                                            autoComplete="off"
                                            maxLength={40}
                                            value={formData.title}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-2 d-flex gap-5 --flex-md-column --flex-lg-row">
                                        <div className='mb-3'>
                                            <label htmlFor="event_date" className="form-label --textPrimaryVariant mb-0">Fecha <sup className='bi-red'>*</sup></label>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                                    <DatePicker
                                                        className='btn btn-secondary'
                                                        value={startDate}
                                                        onChange={(newValue) => {
                                                            setStartDate(newValue);
                                                        }}
                                                        minDate={today}
                                                        slotProps={{
                                                            openPickerIcon: { fontSize: 'large' }
                                                        }}
                                                    />
                                                </div>
                                            </LocalizationProvider>
                                        </div>

                                        <div className='mb-3'>
                                            <label htmlFor="event_date" className="--textPrimaryVariant responsive-truncate mb-0">Horario <sup className='bi-red'>*</sup></label>
                                            <input
                                                type="time"
                                                className="form-control --stylebtnVariant"
                                                id="eventTime"
                                                value={formData.eventTime}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-2 d-flex gap-5 --flex-md-column --flex-lg-row mt-4">
                                        <div>
                                            <label className='--textPrimaryVariant responsive-truncate mb-0'>Precio socios <sup className='bi-red'>*</sup></label>
                                            <div className="input-group">
                                                <span className="input-group-text --stylebtnVariant">$</span>
                                                <input
                                                    id='price'
                                                    type="number"
                                                    value={formData.price}
                                                    className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                    onChange={handleChange}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className='--textPrimaryVariant responsive-truncate mb-0'>Precio general</label>
                                            <div className="input-group">
                                                <span className="input-group-text --stylebtnVariant">$</span>
                                                <input
                                                    id='genPrice'
                                                    type="number"
                                                    value={formData.genPrice}
                                                    className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                    onChange={handleChange}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-4 d-flex gap-4 --flex-md-column --flex-lg-row">
                                        <div>
                                            <label className='--textPrimaryVariant responsive-truncate'>Lugares disponibles <sup className='bi-red'>*</sup></label>
                                            <input
                                                id='spots'
                                                type="number"
                                                className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                placeholder=''
                                                autoComplete="off"
                                                maxLength={40}
                                                value={formData.spots}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            <label className='--textPrimaryVariant mb-1'>Descuento</label>
                                            <div className="input-group">
                                                <input
                                                    id='discount'
                                                    type="number"
                                                    value={formData.discount}
                                                    className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                    onChange={handleChange}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <span className="input-group-text --stylebtnVariant">%</span>
                                            </div>
                                        </div>
                                        <div>
                                            <Tooltip id="discount"></Tooltip>
                                            <label
                                                data-tooltip-id="discount"
                                                data-tooltip-content={'A partir de cuantos boletos aplica el descuento'}
                                                data-tooltip-place="top"
                                                className='--textPrimaryVariant responsive-truncate'>
                                                Cantidad Descuento
                                            </label>
                                            <input
                                                id='discountQty'
                                                type="number"
                                                value={formData.discount_qty}
                                                className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                                onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-0 mb-3">
                                        <label className='--textPrimaryVariant'>Modalidad del pago <sup className='bi-red'>*</sup></label>
                                        <select
                                            id="paymentMod"
                                            className="form-select --stylebtnVariant"
                                            value={paymentMod}
                                            onChange={(e) => { setPaymentMod(e.target.value) }}
                                        >
                                            <option value="">Selecciona el pago</option>
                                            <option value="stripe">Stripe (Crédito o débito)</option>
                                            {/* <option value="transferencia">Transferencia</option> */}
                                            {/* <option value="ambas">Ambas</option> */}
                                        </select>
                                    </div>
                                    <div className="mb-3 mt-4">
                                        <label htmlFor="event_date" className="--textPrimaryVariant">Recordatorio</label>
                                        <select
                                            id="paymentLocation"
                                            className="form-select --stylebtnVariant"
                                            value={paymentLocation}
                                            onChange={(e) => { setPaymentLocation(e.target.value) }}
                                        >
                                            <option value="">Selecciona los dias</option>
                                            <option value="1">1 día</option>
                                            <option value="3">3 días</option>
                                            <option value="5">5 días</option>
                                        </select>
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Imagen del banner <sup className='bi-red'>*</sup></label>
                                        <div className="p-4 --style-imgEvent">
                                            <label id='input-img' className="d-flex flex-column align-items-center">
                                                <input
                                                    type="file"
                                                    accept=".jpg,.png,.jpeg"
                                                    className="d-none"
                                                    onChange={handleFileSelectBanner}
                                                />
                                                <i className="bi bi-folder-plus -style-event"></i>
                                                <span className='text-light'>{fileBanner ? fileBanner.name : "Sube el fondo de tu evento"}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* DER */}
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className='--textPrimaryVariant'>Descripción del evento <sup className='bi-red'>*</sup></label>
                                        <textarea
                                            id='description'
                                            className={`form-control text-dark visionTextArea`}
                                            placeholder="Descripción"
                                            rows={6}
                                            autoComplete="off"
                                            maxLength={500}
                                            value={formData.description}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant responsive-truncate'>Ubicación del evento</label>
                                        <input
                                            id='location'
                                            type="text"
                                            className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                            placeholder=''
                                            autoComplete="off"
                                            maxLength={60}
                                            value={formData.location}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Url de Google Maps</label>
                                        <input
                                            id='locationUrl'
                                            type="text"
                                            className={`form-control --stylebtnVariant text-dark d-flex pad-web`}
                                            placeholder=''
                                            autoComplete="off"
                                            maxLength={2100}
                                            value={formData.locationUrl}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Formato del evento <sup className='bi-red'>*</sup></label>
                                        <select
                                            id="format"
                                            className="form-select --stylebtnVariant"
                                            value={eventFormat}
                                            onChange={(e) => { setEventFormat(e.target.value) }}
                                        >
                                            <option value="">Selecciona el formato</option>
                                            <option value="presencial">Presencial</option>
                                            <option value="online">En linea</option>
                                            <option value="ambos">Ambos</option>
                                        </select>
                                    </div>
                                    <div className="mt-5 mb-5">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="exclusive"
                                                onChange={handleExclusiveChange}
                                                checked={exclusive}
                                            />
                                            <label className="form-check-label" >Evento exclusivo para socios</label>
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <label className='--textPrimaryVariant'>Flyer para redes sociales</label>
                                        <div className="p-4 --style-imgEvent">
                                            <label id='input-img' className="d-flex flex-column align-items-center">
                                                <input
                                                    type="file"
                                                    accept=".jpg,.png,.jpeg"
                                                    className="d-none"
                                                    onChange={handleFileSelect}
                                                />
                                                <i className="bi bi-folder-plus -style-event"></i>
                                                <span className='text-light'>{file ? file.name : "Selecciona un archivo"}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Publicar'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default NewEvent;