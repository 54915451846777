import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import loadingGif from '../../assets/img/loading.gif'

interface PromoCostModalProps {
    show: boolean;
    onHide: () => void;
    isLoading?: boolean;
    onClick?: () => void;
    onSave: (quantity:string) => void;
}

const PromotionCostChangeModal: React.FC<PromoCostModalProps> = ({show,  onHide, isLoading = false, onSave }) => {

    const [quantity, setQuantity] = useState("");
    const [total, setTotal] = useState(0);

    const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const validInput = inputValue.match(/^\d*\.?\d*$/);
        if(validInput){
            setQuantity(inputValue);
        }
        /*if (inputValue === "") {
            setQuantity(0);
            setTotal(0);
            return;
        }

        let qty = parseInt(inputValue, 10);

        if (qty > 100) {
            qty = 100;
        }

        if (qty < 1) {
            qty = 1;
        }
        
        setQuantity(qty);
        const tot = ((1 / 80) * (qty * qty)) + 45*(qty);
        setTotal(parseFloat(tot.toFixed(2)));*/
    };

    const handleSaveClick = () => {
        onSave(quantity); // Pasa la cantidad al callback de la página principal
    };

    return (
        <Modal show={show} onHide={onHide} className='costModal'>
            <Modal.Header >
            
                <Modal.Title><h2>Costos</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <label htmlFor="Personas" className="form-label">Configura los costos unitarios de la Publicidad</label>
                    <div className="input-group mb-3">
                        <button className="button-visual"   style={{
                                padding: "5px 10px", 
                                backgroundColor: "white", 
                                color: "blue", 
                                border: "1px solid blue", 
                                borderRadius: "15px",
                                marginRight: "10px",
                                fontSize: "1.15rem",
                                fontWeight: "bold"
                            }}
                            disabled>
                        Costos
                        </button>
                        <input type="text" className="form-control centered-placeholder centered-input-container"
                            style={{backgroundColor: "gray", color: "white", fontWeight: "bold", borderRadius: "15px", }}
                            placeholder="Costo de la publicidad" aria-label="personas" aria-describedby="basic-addon2"
                            onChange={handleQuantity}
                            value={quantity} // Mostrar vacío si es 0
                        />
                    </div>

                </form>
            </Modal.Body>
            <Modal.Footer>
                <>
                    <Button variant="danger" onClick={onHide} disabled={isLoading}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleSaveClick }>
                        {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar'}
                    </Button>
                </>
            </Modal.Footer>
        </Modal>
    );
};

export default PromotionCostChangeModal;