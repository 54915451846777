import axios from 'axios';
import React, { useEffect, useState } from "react";
import TopbarCoparmex from "../../main/TopBarCoparmex";
import "../../../assets/css/administration.css";
import "../../../assets/css/ControlTable.css";
import { useNavigate } from 'react-router-dom';
import PromotionCostChangeModal from '../../modals/PromotionCostChangeModal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import handleLogout from '../../../functions/functions';

interface CostItem {
    date: string;
    price: number;
    username: string;
    active: boolean;
}

const PromoCosts = () => {
    const limit = 10;
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [businessData, setBusinessData] = useState<CostItem[]>([]);
    const [totalPages, setTotalPages] = useState(1);  
    const [currentPage, setCurrentPage] = useState(1);
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    const [isLoading, setIsLoading] = useState(false);
    const luisa_token = localStorage.getItem('luisa_token');
    const username = localStorage.getItem('luisa_username');
    //const [activeButton, setActiveButton] = useState('annual'); // 'annual' o 'monthly'

    const fetchCosts = async () => {
        console.log("try to get the costs")
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/get_business_costs_promotion`, 
                {
                    
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('luisa_token')}`,
                    },
                    params: {
                        page: currentPage,
                        limit
                    },
                }
            );
            console.log("status: ", response.status)
            if (response.status === 200) {
                console.log("valores: ", response.data.costs)
                setBusinessData(response.data.costs);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error("Error al obtener costos:", error);
        }
    };

    useEffect(() => {
        fetchCosts();
    }, [ currentPage, limit]);


    const showErrorNotification = (text: string) => {
        toast.error(text, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showNotification = (text: string) => {
        toast.success(text, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const formatDate = (dateString: string) => {
        if (!dateString) return "Fecha no válida";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "Fecha no válida";
        return new Intl.DateTimeFormat("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        }).format(date);
    };

    const getPaginationRange = () => {
        const totalPagesToShow = 2;
        if (totalPages <= totalPagesToShow) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const range = [];
        const leftLimit = Math.max(2, currentPage - 1);
        const rightLimit = Math.min(totalPages - 1, currentPage + 1);

        range.push(1);
        if (leftLimit > 2) range.push("...");
        for (let i = leftLimit; i <= rightLimit; i++) range.push(i);
        if (rightLimit < totalPages - 1) range.push("...");
        range.push(totalPages);

        return range;
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handleRowClick = (item: CostItem) => {
        navigate(`/promo-costs`, { state: { data: item } });
    };

    const handlePageClick = (page: number | string) => {
        if (typeof page === 'number' && page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const handleSave = async (quantity:string) => {
        setIsLoading(true);
        try{
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/update_costs_promo`,
            { quantity, username },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${luisa_token}`,
                }
            }
        );
        
        if (response.status === 200) {
            const msg = response.data.data;
            showNotification(msg);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        fetchCosts(); //cargar los nuevos datos a la tabla
        handleCloseModal();
        } catch(error){
            if (axios.isAxiosError(error) && error.response) {
                const status = error.response.status;
                if (status === 401 || status === 403) {
                    console.log("Token expirado, cerrando sesión...");
                    handleLogout();
                }
            }
            console.error('Error al aprobar:', error);
            showErrorNotification('Ocurrió un error al actualizar el precio');
        } finally{
            setIsLoading(false);
        }
        // Lógica para guardar los cambios o llamar al endpoint
    };

    return (
        <>
            <TopbarCoparmex />

            <div className="py-3 px-5 linkup-bg">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9 mt-4'>
                        <h1 className='fw-bolder text-blue mb-4'>
                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/costos')}>
                                <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA" />
                            </svg>
                            Costos de Publicidad
                        </h1>
                        <div className="sub-header p-3">
                            <span>Luisa te ayuda a calcular los costos de la publicidad. El precio base se refiere al precio aproximado por persona alcanzada en la publicidad.</span>
                        </div>
                        <div className="affiliation-buttons">
                            <button
                                className={'affiliation-active'} onClick={handleOpenModal}
                            >
                                Nuevo costo
                            </button>
                        </div>
                        <div className="table-container mt-4">
                        <div className="table-container mt-4">
                            <section className="wrapper">
                                <main className="row title">
                                    <ul className="d-flex justify-content-between align-items-center w-100 px-3">
                                        <li className="col-4 text-center fw-bold">Fecha de alta</li>
                                        <li className="col-4 text-center fw-bold">Precio unitario</li>
                                        <li className="col-4 text-center fw-bold">Usuario</li>
                                    </ul>
                                </main>
                            </section>
                            <section>
                                {businessData.length > 0 ? (
                                    businessData.map((item, index) => (
                                        <article className='data-users mt-2' key={index}>
                                            <ul className="d-flex justify-content-between align-items-center w-100 px-3">
                                                <li className="col-4 text-center">{formatDate(item.date)}</li>
                                                <li className="col-4 text-center">{`$${item.price.toFixed(2)}`}</li>
                                                <li className="col-4 text-center d-flex justify-content-center align-items-center">
                                                    <span className="text-truncate" title={item.username}>
                                                        {item.username}
                                                    </span>
                                                    {item.active && (
                                                        <div className="ms-2" style={{ position: 'relative' }}>
                                                        <i className="bi bi-check-circle-fill text-success" 
                                                        data-bs-toggle="tooltip" 
                                                        data-bs-placement="top" 
                                                        title="Costo Vigente"></i>
                                                    </div>
                                                    )}
                                                </li>
                                            </ul>
                                        </article>
                                    ))
                                ) : (
                                    <p>No hay costos configurados</p>
                                )}
                            </section>
                        </div>
                        </div>
                        {/* Paginador */}
                        <ToastContainer /> 
                        <div className="pagination-controls">
                            {currentPage > 1 && (
                                <button onClick={handlePreviousPage} className="pagination-button-prev-next">
                                    <i className="bi bi-chevron-left"></i> Anterior
                                </button>
                            )}
                            {getPaginationRange().map((page, index) => (
                                <button
                                    key={index}
                                    onClick={() => typeof page === 'number' && handlePageClick(page)}
                                    className={`pagination-button ${page === currentPage ? 'active' : ''}`}
                                    disabled={typeof page !== 'number'}
                                >
                                    {page}
                                </button>
                            ))}
                           
                            {currentPage < totalPages && (
                                <button onClick={handleNextPage} className="pagination-button-prev-next">
                                    Siguiente <i className="bi bi-chevron-right"></i>
                                </button>
                            )}
                           
                        </div>
                         
                    </div>
                </div>
            </div>
            <PromotionCostChangeModal
                show={modalOpen}
                onHide={handleCloseModal}
                onSave={handleSave}
                isLoading={isLoading}

            />
        </>
    );
};


export default PromoCosts;
