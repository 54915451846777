import React, { useState, ChangeEvent, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Logo from '../../assets/img/logo_luisa.png';
import loadingGif from '../../assets/img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/login.css';
import axios from 'axios';
import config from '../../config/config';
import { validatePassword } from '../../functions/functions';
import ConfirmationModal from '../modals/ConfirmationModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Create_credentials(){
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id'); //id from the url 
    //const [codeNumber, setCodeNumber] = useState('');
    //const [codeError, setCodeError] = useState(false);
    //const [isValidCode, setIsValidCode] = useState(false);
    const [samePasswordError, setSamePasswordError] = useState(false);
    const [samePassword, setSamePassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false); 

    const [isLoading, setIsLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [confirmModalType, setConfirmModalType] = useState('✅');

    const [validationsEnabled, setValidationsEnabled] = useState(false); // Estado para controlar las notificaciones
    const [passwordValidations, setPasswordValidations] = useState({
        hasLowercase: false,
        hasUppercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasMinLength: false,
      });

      // Estado para controlar si ya se ha mostrado una notificación para cada validación
    const [validationNotified, setValidationNotified] = useState<{ [key: string]: boolean }>({
        hasLowercase: false,
        hasUppercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasMinLength: false,
    });

    const navigate = useNavigate();

      // Función para mostrar notificaciones
      const showValidationToast = async (isValidated: boolean, validationName: string, toastId: string) => {
        if (isValidated) {
            toast.success(`Validación cumplida: ${validationName}`, {
                toastId,                   
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.error(`Validación incumplida: ${validationName}`, {
                toastId,
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
    

    /*
    const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
       
        if (/^\d*$/.test(value) && value.length <= 5) {
            setCodeNumber(value);
            setCodeError(false);
        }

        setIsValidCode(value.length >= 5);
    };*/

    const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
    };

    const handleSamePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSamePassword(event.target.value);
        if(event.target.value){
            setSamePasswordError(event.target.value !== password ? true : false);
        }else{
            setSamePasswordError(false);
        }
        
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);

        if(samePassword){
            setSamePasswordError(event.target.value !== samePassword ? true : false);
        }
        //agregar las validaciones aqui para los checks
        setPasswordError(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) =>{
        event.preventDefault();
        let isValid = true;
        setIsLoading(true);

        // Verificar cada criterio utilizando expresiones regulares
        const validations = {
            hasLowercase: /[a-z]/.test(password),
            hasUppercase: /[A-Z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            hasMinLength: password.length >= 12,
        };

        setPasswordValidations(validations);
        await showValidationToast(validations.hasLowercase, 'Al menos una letra minúscula', 'lowercase');
        await showValidationToast(validations.hasUppercase, 'Al menos una letra mayúscula', 'uppercase');
        await showValidationToast(validations.hasNumber, 'Al menos un número', 'number');
        await showValidationToast(validations.hasSpecialChar, 'Al menos un carácter especial', 'specialChar');
        await showValidationToast(validations.hasMinLength, 'Mínimo 12 caracteres', 'minLength');
        /*if (!isValidCode) {
            setCodeError(true);
            isValid = false;
          }*/

        if (!validatePassword(password)) {
            setPasswordError(true);
            isValid = false;
        }

        if (!isValid) {
            setIsLoading(false);
            return;
        }
        try{
            console.log("Intenta cambiar la contraseña");
            if (!id){
                console.log("No se detecto el id");
                setIsLoading(false);
                setConfirmModalType('❌');
                setModalMessage('Error en el id, solicite otro correo');
                setIsModalOpen(true);
                return;
            }
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-credentials`, {
              code: id,
              password: password
            });
            console.log(`Valor de la respuesta del server: ${response.status}`);
            if (response.status === 201) {
                //enviar a home o poner un anuncio que ya se envio un mensaje
                setIsLoading(false);
                setConfirmModalType('✅');
                setModalMessage(`Se ha cambiado la contraseña, puede intentar ingresar con esta nueva contraseña`);
                setIsModalOpen(true);
                navigate('/login');
                //window.location.reload();
            }
        }catch (error: unknown) {
            if (axios.isAxiosError(error)) {
              // El error es de Axios, por lo tanto tiene la propiedad response
              if (error.response && error.response.status === 401) {
                console.log(error);
                setIsLoading(false);
                setConfirmModalType('❌');
                setModalMessage('id invalido o caducado, favor de solicitar otro.');
                setIsModalOpen(true);
              }
            } else {
              console.log(error);
              setIsLoading(false);
              setConfirmModalType('❌');
              setModalMessage('Nos encontramos con un problema interno, favor de intentarlo más tarde');
              setIsModalOpen(true);
            }
            console.log(error);
            setIsLoading(false);
            setConfirmModalType('❌');
            setModalMessage('Nos encontramos con un problema interno, favor de intentarlo más tarde');
            setIsModalOpen(true);
          }
       
         

    };

// Efecto para mostrar notificaciones de validaciones
/*
useEffect(() => {
    const triggerValidationToasts = async () => {
        await showValidationToast(passwordValidations.hasLowercase, 'Al menos una letra minúscula', 'lowercase');
        await showValidationToast(passwordValidations.hasUppercase, 'Al menos una letra mayúscula', 'uppercase');
        await showValidationToast(passwordValidations.hasNumber, 'Al menos un número', 'number');
        await showValidationToast(passwordValidations.hasSpecialChar, 'Al menos un carácter especial', 'specialChar');
        await showValidationToast(passwordValidations.hasMinLength, 'Mínimo 12 caracteres', 'minLength');
    };

    triggerValidationToasts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [passwordValidations]);
*/
    return(
    <>

        <div className='flex-column align-items-center py-3 px-5 bg-login-animation'>
        <ToastContainer />
            {<ConfirmationModal
                show={isModalOpen}
                onHide={() => {
                setIsModalOpen(false);
                navigate('/login');
                }}
                text={modalMessage}
                type={confirmModalType}
                titleText='Cambiar Contraseña'
            />}
            <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-7 col-lg-8 text-center'>
                    <img className="w-50 mt-5" src={Logo} alt="COPARMEX Logo" />
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-4 col-lg-3 p-login'>
                    <h1 className='fw-bolder text-white text-center mb-4 animate__animated animate__fadeInUp fs-2'>Crea tu Contraseña</h1>
                    <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
                        <div className="mb-3 position-relative" >
                            <div className="input-group" >
                                <input
                                type={showPassword ? "text" : "password"} 
                                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${passwordError ? 'is-invalid' : ''}`}
                                placeholder='Nueva contraseña'
                                onChange={handlePasswordChange}
                                autoComplete="off"
                                />
                                <span className="input-group-text" onClick={handlePasswordVisibility} style={{ cursor: 'pointer' }}>
                                <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                </span>
                            </div>     
                            {passwordError && <div className="invalid-feedback"> </div>}
                            </div>

                            <div className="mb-3">
                            <div className="input-group mb-3">
                                <input
                                    type="password"
                                    className={`form-control bg-light centered-placeholder centered-input-container d-flex ${samePasswordError ? 'is-invalid' : ''}`}
                                    placeholder="Confirma contraseña"
                                    onChange={handleSamePasswordChange}
                                    autoComplete="off"
                                    value={samePassword}
                                />
                                <span className="input-group-text">
                                    {samePassword ? (
                                    samePasswordError ? (
                                        <i className="bi bi-x-circle-fill text-danger"></i>
                                    ) : (
                                        <i className="bi bi-check-circle-fill text-success"></i>
                                    )
                                    ) : null}
                                </span>
                                </div>
                                {samePasswordError && (
                                <div className="invalid-feedback d-block"></div>
                                )}
                        </div>
                        <button type="submit" className="btn btn-dark w-100" disabled={isLoading || samePasswordError || passwordError || samePassword===""}>
                            {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Continuar'}
                        </button>
                        
                     </form>
                </div>
            </div>
            
        </div>
    </>
    )
}

export default Create_credentials;