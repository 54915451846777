import React, { useState } from 'react';
import axios from 'axios';
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter, Form, FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loadingGif from '../../assets/img/loading.gif';

interface NuevaPublicacionModalProps {
    show: boolean;
    handleClose: () => void;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    fileName: string | null;
    fetchWorkingCommissions: () => Promise<void>;
    workId: string;
}

const NuevaPublicacionModal: React.FC<NuevaPublicacionModalProps> = ({
    show,
    handleClose,
    handleFileChange,
    fileName,
    fetchWorkingCommissions,
    workId
}) => {

    const [isLoading, setIsLoading] = useState(false);
    // const comments = [
    //     {
    //         commented_by_rfc: "RAM910207KKA",
    //         comment: "Comentario de prueba",
    //         date: new Date().toISOString()
    //     }
    // ];
    const [totalSize, setTotalSize] = useState(0);
    const maxFileSize = 100 * 1024 * 1024;
    const maxTotalSize = 1000 * 1024 * 1024;

    const [formData, setFormData] = useState({
        entry_title: "",
        description: "",
        tags: "test",
        files: [] as File[],
        image: null as File | null,
    });

    const [attachedFiles, setAttachedFiles] = useState<File[]>([]);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFormData((prev) => ({
                ...prev,
                image: event.target.files ? event.target.files[0] : null,
            }));
        }
    };

    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            //const selectedFiles = Array.from(event.target.files);
            // setAttachedFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

            let valid = true;
            let newSize = totalSize;
            let newFiles = [...attachedFiles];
            const files = Array.from(event.target.files || []);

            if (totalSize > maxTotalSize) {
                valid = false;
                showWarnNotification('El límite es de 100MB por publicación');
            }

            files.forEach((file) => {
                // console.log(newFiles);
                if (file.size > maxFileSize) {
                    valid = false;
                    showWarnNotification('Solo se aceptan archivos de máximo 100MB');
                } else if (!newFiles.some((f) => f.name === file.name && f.size === file.size)) {
                    newFiles.push(file);
                    newSize += file.size;
                }
            });
            // console.log(valid);

            if (newFiles.length > 10) {
                showWarnNotification("Solo se acepta un máximo de 10 archivos");
                return;
            }

            if (valid) {
                setTotalSize(newSize);
                console.log(files);
                // setAttachedFiles([...files]);
                setAttachedFiles((prevFiles) => [...prevFiles, ...files]);
            }
        }
    };

    const removeFile = (indexToRemove: number) => {
        // setAttachedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
        const fileToRemove = attachedFiles[indexToRemove];
        console.log(fileToRemove);
        
        if (fileToRemove) {
            setAttachedFiles(attachedFiles.filter((_, i) => i !== indexToRemove));
            setTotalSize((prevSize) => prevSize - fileToRemove.size);
        }
    };


    const addEntry = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append("related_work_commission_id", workId);
        formDataToSend.append("entry_title", formData.entry_title);
        formDataToSend.append("description", formData.description);
        formDataToSend.append("tags", formData.tags);
        // formDataToSend.append("comments", JSON.stringify(comments));

        let state = false;

        attachedFiles.forEach((file) => {
            formDataToSend.append('files', file);
            state = true;
        });

        if (formData.image) {
            formDataToSend.append("image", formData.image);
            state = true;
        }

        // Verificar si hay archivos subidos
        if (!formDataToSend.has("files") || formDataToSend.getAll("files").length === 0) {
            state = false;
        }

        if (formData.entry_title === "" || formData.description === "") {
            showWarnNotification("Por favor, llena todos los campos");
            return;
        }

        if (!state) {
            showWarnNotification("Por favor, selecciona al menos una imagen o archivo");
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/createWorkCommissionEntry`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("luisa_token")}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                handleClose();
                setFormData({
                    entry_title: "",
                    description: "",
                    tags: "test",
                    files: [] as File[],
                    image: null,
                });
                showNotification("Publicación creada exitosamente");
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await fetchWorkingCommissions();
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            showErrorNotification("Error al crear la nueva entrada, intenta de nuevo más tarde");
            console.error("Error al crear publicación:", error);
        }
    };

    const showWarnNotification = (text: string) =>
        toast.warning(text, { position: 'top-right', autoClose: 3000 });
    const showErrorNotification = (text: string) =>
        toast.error(text, { position: 'top-right', autoClose: 3000 });
    const showNotification = (text: string) =>
        toast.success(text, { position: 'top-right', autoClose: 3000 });

    return (
        <>
            <ToastContainer />
            <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal">
                <ModalHeader closeButton>
                    <ModalTitle className="fw-bold">Nueva Publicación</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <FormLabel className="file-label">Titulo de la publicación</FormLabel>
                            <FormControl
                                type="text"
                                name="entry_title"
                                placeholder="Título de la publicación"
                                value={formData.entry_title}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel className="file-label mt-4">Foto de la publicación</FormLabel>
                            <div className="file-input-wrapper">
                                <FormControl
                                    type="file"
                                    name="image"
                                    id="select-foto"
                                    accept=".jpg,.jpeg,.png"
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="select-foto" className="custom-file-label">
                                    Seleccionar archivo
                                </label>
                                <span className="text-dark">
                                    <i className="bi bi-file-image"></i> {formData.image ? formData.image.name : 'Ningún archivo seleccionado'}
                                </span>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel className="file-label mt-4">Descripción</FormLabel>
                            <FormControl
                                as="textarea"
                                name="description"
                                rows={5}
                                placeholder="Descripción de la publicación"
                                value={formData.description}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <FormLabel className="file-label mt-4">Archivos adjuntos</FormLabel>
                                    <FormControl
                                        id="select-archivo"
                                        type="file"
                                        multiple
                                        onChange={handleFilesChange}
                                        accept=".pdf,.xlsx,.xls,.txt,.csv,.mp3,.mp4,.mpeg,.mpga,.m4a,.wav,.webm"
                                    />
                                    <label htmlFor="select-archivo" className="custom-file-label label-for-archivo">
                                        Seleccionar archivos
                                    </label>
                                    <FormLabel className="file-label mt-4">Tamaño total: {(totalSize / (1024 * 1024)).toFixed(2)} MB</FormLabel>
                                    {/* <small></small> */}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mt-5 --border-left">
                                    {attachedFiles.length > 0 ? (
                                        attachedFiles.map((file, index) => (
                                            <div
                                                key={index}
                                                className="d-flex align-items-center justify-content-around mt-3"
                                            >
                                                <div className="file-document py-2 px-3">{file.name}</div>
                                                <div
                                                    className="--icon-container text-dark"
                                                    onClick={() => removeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <i className="bi bi-trash --custom-trash p-1"></i>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="text-muted mt-3 ms-3">No hay archivos seleccionados</div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={addEntry}>
                        {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Publicar'}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default NuevaPublicacionModal;
