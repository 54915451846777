import TopbarCoparmex from "../main/TopBarCoparmex";
import "../../assets/css/administration.css";
import { useNavigate } from 'react-router-dom';

const MenuCostos = () => {
    const navigate = useNavigate();


    return (
        <>
            <TopbarCoparmex />
            <div className="flex-column align-items-center py-3 px-5 linkup-bg ">
                <div className="row justify-content-center">
                    <div className='col-sm-10 col-md-12 col-lg-9'>
                        <h1 className='fw-bolder text-blue mb-4 animate__animated animate__fadeInUp mt-4'>
                            <svg className='me-2' width="42" height="25" viewBox="0 0 42 25" fill="none" cursor='pointer' xmlns="http://www.w3.org/2000/svg" onClick={() => navigate('/administracion')}>
                            <path d="M41.5 2C41.5 1.17157 40.8284 0.5 40 0.5C39.1716 0.5 38.5 1.17157 38.5 2H41.5ZM0.93934 11.9393C0.353553 12.5251 0.353553 13.4749 0.93934 14.0607L10.4853 23.6066C11.0711 24.1924 12.0208 24.1924 12.6066 23.6066C13.1924 23.0208 13.1924 22.0711 12.6066 21.4853L4.12132 13L12.6066 4.51472C13.1924 3.92893 13.1924 2.97918 12.6066 2.3934C12.0208 1.80761 11.0711 1.80761 10.4853 2.3934L0.93934 11.9393ZM38.5 2V10H41.5V2H38.5ZM37 11.5H19.5H2V14.5H19.5H37V11.5ZM38.5 10C38.5 10.8284 37.8284 11.5 37 11.5V14.5C39.4853 14.5 41.5 12.4853 41.5 10H38.5Z" fill="#0047BA"/>
                            </svg>
                            Costos
                        </h1>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <div className="mb-3 linkup-form-bg cards-style min-h-cards-cost" onClick={() => navigate('/promo-costs')}>
                                    <h2 className="text-blue">Publicidad</h2>
                                    <p className="mb- mt-4">Configura el costo de la publicidad para socios</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <div className="mb-3 linkup-form-bg cards-style min-h-cards-cost" onClick={() => navigate('/Affiliations-costs')}>
                                    <h2 className='text-blue'>Afiliaciones</h2>
                                    <p className='mb- mt-4'>Configura el costo de las afiliaciones</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <div className="mb-3 linkup-form-bg cards-style min-h-cards-cost" onClick={() => navigate('/links-costo')}>
                                    <h2 className='text-blue'>Vinculaciones</h2>
                                    <p className='mb- mt-4'>Configura el costo de las vinculaciones</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuCostos;